import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import * as _ from 'lodash'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { PasswordValidator } from 'src/app/validators/password.validator'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatDialog, MatSnackBar } from '@angular/material'
import { Store } from 'src/app/models/store'
import { DecimalPipe, CurrencyPipe, Location } from '@angular/common'
import { ModalConfirmationComponent } from 'src/app/components/modal-confirmation/modal-confirmation.component'
import { ModalConfirmationPointsComponent } from 'src/app/components/modal-confirmation-points/modal-confirmation-points.component'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { CompaniesService } from '../../../../services/companies.service'
@Component({
  selector: 'app-store-form',
  templateUrl: './client-points-form.component.html',
  styleUrls: ['./client-points-form.component.scss'],
})
export class ClientPointsFormComponent implements OnInit {
  public formGroup: FormGroup
  public states: Object[] = []
  public plans: Object[] = []
  public storeID: any
  public userID = ''
  public stores: Store[] = []
  public user: any[] = []
  public storeName = ''
  public store: any[] = []
  public selectedStore: any
  public storeOptions: Observable<any[]>
  public pointsConversionFactorOut
  public params = { formGroupValue: [] }
  architects: any

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public location: Location,
    private readonly companiesService: CompaniesService,
  ) {
    this.initForm().catch(e => console.log(e))
  }

  async ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.userID = this.route.snapshot.paramMap.get('customerID')
    this.architects = this.route.snapshot.paramMap.get('architects')

    this.loadInfosUser()

    if (this.architects) {
      await this.loadStoresArchitects().catch(e => console.log(e))
    } else {
      await this.loadStores()
    }
  }

  async initForm () {
    this.formGroup = this.formBuilder.group({
      name: [{ value: null, disabled: true }, [Validators.required]],
      cpf: [{ value: null, disabled: true }, [Validators.required]],
      jcoins: [
        null,
        [Validators.required, Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')],
      ],
      pointsConversionFactorOut: [{ value: null, disabled: true }, []],
      store: [null, [Validators.required]],
      commit: ['', [Validators.max(60)]],
    })

    this.storeOptions = this.formGroup.controls.store.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.stores.filter(store => {
          const newValue = typeof value === 'object' ? value.nomeFantasia : value
          return (
            store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
            store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
            store.cnpj.toLowerCase().includes(newValue.toLowerCase())
          )
        })
      }),
    )
  }

  loadInfosUser () {
    this.authService.customerUserInfo(this.userID).subscribe(
      async response => {
        this.user = response
        this.formGroup.patchValue({
          name: response.firstName + ' ' + response.lastName,
          cpf: response.cpf,
        })
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  async loadStoresArchitects () {
    const { stores } = await this.companiesService
      .listStoresBusinessUnits()
      .catch(err => {
        this.snackBar.open(err.message)
        return { stores: [], TotalRegisters: 0 }
      })
    this.stores = stores
  }

  loadStores () {
    this.storeService.listStores().subscribe(
      async response => {
        this.stores = response
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  calcValuePoints (event) {
    this.formGroup.patchValue({
      store: this.formGroup.value.store,
      jcoins: event.target.value,
      pointsConversionFactorOut:
        this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100),
    })
    this.pointsConversionFactorOut =
      this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100)
  }

  displayStore (store) {
    return store ? `${store.nomeFantasia}` : ''
  }

  calcValue (event) {
    this.stores.forEach(store => {
      if (store.id == event.option.value.id) {
        this.store.push(store)

        this.storeName = store.nomeFantasia
        this.pointsConversionFactorOut =
          this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100)
        this.formGroup.patchValue({
          store: this.formGroup.value.store,
          pointsConversionFactorOut:
            this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100),
        })
      }
    })
  }

  goBack () {
    // this.location.back()
    if (this.architects) {
      this.router.navigate(['./dashboard/clients-architect/architect'])
    } else {
      this.router.navigate(['./dashboard/clients'])
    }
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (!this.formGroup.value.store || !_.isObject(this.formGroup.value.store)) {
      this.snackBar.open('Preencha corretamente o campo Parceiro.')
      return false
    }
    if (this.formGroup.value.commit.length > 60) {
      this.snackBar.open('O campo Comentário tem limite máximo de 60 caracteres')
      return false
    }

    const data = {
      customerId: this.userID,
      storeId: this.formGroup.value.store.id,
      jcoins: parseInt(this.formGroup.value.jcoins),
      commit: this.formGroup.value.commit,
    }

    const dialogRef = this.dialog.open(ModalConfirmationPointsComponent, {
      width: '500px',
      data: {
        store: this.storeName,
        points: this.formGroup.value.jcoins,
        value: this.pointsConversionFactorOut,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.authService.applyPoints(data).subscribe(
          async response => {
            this.snackBar.open('Pontos creditados com sucesso.')
            if (this.architects) {
              this.router.navigate(['./dashboard/clients-architect/architect'])
            } else {
              this.router.navigate(['./dashboard/clients'])
            }
          },
          error => {
            console.log(error.error.message)
            this.snackBar.open(error.error.message)
          },
        )
      }
    })
  }
}
