import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { StoreService } from 'src/app/services/store.service'
import { User } from 'src/app/models/user'
import { ModalUserComponent } from 'src/app/components/modal-user/modal-user.component'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material'
import { UtilService } from 'src/app/services/util.service'
import { PageEvent } from '@angular/material/paginator'
import { ModalResendCredentialsComponent } from '../../../../components/modal-resend-credentials/modal-resend-credentials.component'
import { AuthService } from '../../../../services/auth.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-store-list',
  templateUrl: './store-user-list.component.html',
  styleUrls: ['./store-user-list.component.scss'],
})
export class StoreUserListComponent implements OnInit {
  public headersTable: string[] = ['name', 'phoneNumber', 'emailAddress', 'actions']
  public headersTableDeleted: string[] = ['name', 'phoneNumber', 'emailAddress', 'deletedAt']
  public users: User[] = []
  public storeID: any
  fieldsForm: any
  dataSource: any
  dataSourceDeleted: any
  nomeFantasia: string
  public pageTotal: number
  public params = { storeFormGroupValue: [] }
  userRole: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  pageEvent: PageEvent

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  constructor (
    public storeService: StoreService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public utilService: UtilService,
    public authService: AuthService,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
    })
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.listUsers()
    this.initForm()
    this.getStore()
    this.listProfilesDeleted().catch(err => console.log(err))
    this.userRole = localStorage.getItem('userRole')
  }

  initForm () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameStoreUser',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'phoneStoreUser',
        label: 'Telefone',
        placeholder: 'Telefone',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'emailStoreUser',
        label: 'E-mail',
        placeholder: 'E-mail',
      },
    ]
  }

  getStore () {
    this.storeService
      .getStore(this.storeID)
      .subscribe(
        async response => {
          this.nomeFantasia = response.nomeFantasia
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }

  listUsers () {
    this.storeService
      .listUsersByStore(
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["users_stores.store_id"], "op": "=", "value":"${this.storeID}"}]}`,
      )
      .subscribe(
        async response => {
          response.items.map(user => {
            user.fullName = user.firstName + ' ' + user.lastName
          })
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.items)
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }

  async listProfilesDeleted () {
    const { results } = await this.authService
      .listProfilesDeleted(this.storeID)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
    this.dataSourceDeleted = new MatTableDataSource(results)
  }

  openDialogNewUser () {
    const dialogRef = this.dialog.open(ModalUserComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.storeService.getUserInfo(`email=${result}`).subscribe(
          async response => {
            this.router.navigate(['./dashboard/users/' + response.userId + '/edit/stores/' + this.storeID])
          },
          error => {
            this.router.navigate(['./dashboard/users/new/' + result + '/stores/' + this.storeID])
          },
        )
      }
    })
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.items)
  }

  async openDialogResendCredentials (emailAddress) {
    const dialogRef = this.dialog.open(ModalResendCredentialsComponent, {
      width: '550px',
      data: { text: 'Deseja fazer o reenvio das credenciais do lojista?' },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.authService
          .passwordRecover({ emailAddress })
          .toPromise()
          .then(
            async response => {
              this.snackBar.open('Credenciais enviadas com sucesso.')
            },
            error => {
              alert(error.error.message)
            },
          )
      }
    })
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Usuário?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.deleteProfile(id, this.storeID).then(
            async response => {
              this.listUsers()
              this.listProfilesDeleted().catch(err => console.log(err))
              this.snackBar.open('Usuário removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
