import { FundService } from 'src/app/services/fund.service'
import { MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material'
import { Component, ViewChild, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-funds-list',
  templateUrl: './funds-list.component.html',
  styleUrls: ['./funds-list.component.scss'],
})
export class FundsListComponent implements OnInit {
  headersTable: string[] = ['cnpj', 'name', 'jcoinBalance', 'membership', 'active', 'actions']
  fieldsForm: any
  dataSource: any
  formGroup: FormGroup

  public pageTotal: number

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  params: Params

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public fundService: FundService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
        this.initForm()
      } else {
        this.initForm()
        this.listFunds()
      }
    })
  }

  initForm () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjFund',
        label: 'CNPJ',
        placeholder: 'CNPJ',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameFund',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'select',
        formControlName: 'activeFund',
        label: 'Status',
        placeholder: 'Status',
        list: [
          { key: '1', value: 'ATIVO' },
          { key: '0', value: 'INATIVO' },
        ],
        optionDefault: 'TODOS',
      },
    ]
  }

  listFunds () {
    this.fundService
      .list(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
      .then(response => {
        this.pageTotal = response.total
        this.dataSource = new MatTableDataSource(response.results)
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })
  }

  addFund () {
    this.router.navigate(['./dashboard/funds/form']).catch(err => {
      this.snackBar.open(err.message)
    })
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
