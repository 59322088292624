import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ModalDeleteClosingComponent } from 'src/app/components/modal-delete-closing/modal-delete-closing.component'
import { ModalIntegrationsClosingsComponent } from 'src/app/components/modal-integrations-closings/modal-closings.component'
import JsFileDownloader from 'js-file-downloader'
import { AuthService } from '../../../../services/auth.service'
import { ReportService } from '../../../../services/report.service'

@Component({
  selector: 'app-store-list',
  templateUrl: './closings-incorporations.component.html',
  styleUrls: ['./closings-incorporations.component.scss'],
})
export class ClosingsIncorporationsComponent implements OnInit {
  public headersTable: string[] = ['closingNumber', 'startDateEndendDate', 'actions']
  public closings: any[] = []
  public closingsSelecteds: any[] = []
  public formFilter: FormGroup
  public form: FormGroup

  constructor (
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly reportService: ReportService,
  ) { }

  ngOnInit () {
    this.formFilter = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      paymentDate: [null, [Validators.required]],
    })

    this.listAll()
  }

  create (result) {
    this.authService.closingIntegrationCreate(result).then(
      async response => {
        this.listAll()
        this.snackBar.open('Fechamento criado com sucesso')
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  listAll () {
    this.closings = []

    this.authService.closingsIntegrations().then(
      async response => {
        this.closings = response
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  openModal (): void {
    const dialogRef = this.dialog.open(ModalIntegrationsClosingsComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.create(result)
      }
    })
  }

  delete (id) {
    const dialogRef = this.dialog.open(ModalDeleteClosingComponent, {
      width: '500px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.authService.deleteClosingIncorporations(id).then(
          async response => {
            this.snackBar.open('Fechamento deletado com sucesso.')
            this.listAll()
          },
          error => {
            console.log(error)
            this.snackBar.open(error.error.message)
          },
        )
      }
    })
  }

  export (id) {
    this.reportService.closingIncorporations(id)
      .toPromise()
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return (
              'Fechamento Financeiro Incorporações.' + response.fileUrl.split('.').splice(5, 5).join('.')
            )
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
