<app-logged class="architect-closing-detail" *ngIf="closing" >
  <header class="architect-closing-detail__header">
    <h2>Fechamento Nº: {{closing?.number}} </h2>    
    <div class="architect-closing-detail__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>


  <div class="table-responsive">
    <table mat-table [dataSource]="closing?.stores || []" >

      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element"> {{element.nomeFantasia}} </td>
      </ng-container>

      <ng-container matColumnDef="nd">
        <th mat-header-cell *matHeaderCellDef>ND (Taxa do Cashback)</th>
        <td mat-cell *matCellDef="let element"> 
          {{element.totalNdTax | currency: 'R$'}}

          <button 
            *ngIf="!element.ndPaid"
            mat-icon-button
            matTooltip="Definir ND como paga"          
            matTooltipPosition="left"
            (click)="checkNDPayment(element)"         
          >
            <mat-icon>check_circle</mat-icon>
          </button>

          
          <mat-icon
            *ngIf="element.ndPaid"
            matTooltip="ND já está paga"          
            matTooltipPosition="left"
            style="color: green"
          >
            check
          </mat-icon>

        </td>
      </ng-container>

      <ng-container matColumnDef="nf">
        <th mat-header-cell *matHeaderCellDef>NF (Taxa Administrativa)</th>
        <td mat-cell *matCellDef="let element"> 
          {{element.totalNfTax | currency: 'R$'}}

          <button 
            *ngIf="!element.nfPaid"
            mat-icon-button
            matTooltip="Definir NF como paga"          
            matTooltipPosition="left"
            (click)="checkNFPayment(element)"         
          >
            <mat-icon>check_circle</mat-icon>
          </button>

          
          <mat-icon
            *ngIf="element.nfPaid"
            matTooltip="NF já está paga"          
            matTooltipPosition="left"
            style="color: green"
          >
            check
          </mat-icon>
          

        </td>
      </ng-container>

      <ng-container matColumnDef="totalNfValues">
        <th mat-header-cell *matHeaderCellDef>Valor total das notas</th>
        <td mat-cell *matCellDef="let element"> 
          {{convertCentsToReal(element.totalNfValues) | currency: 'BRL':'R$'}}   
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">

          <button          
            mat-icon-button
            matTooltip="Exportar"          
            matTooltipPosition="left"
            (click)="export(element.id)"              
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>

          <button          
            *ngIf="!element.approvedAt"
            mat-icon-button
            matTooltip="Aprovar todas as notas"          
            matTooltipPosition="left"
            (click)="approveStoreBusinessUnitInvoices(element.id)"              
          >
            <mat-icon>check_circle</mat-icon>
          </button>

        </td>
      </ng-container>
     
      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>

    </table>

  </div>

</app-logged>