<app-logged class="store-form">
  <header class="store-form__header">
    <h2>EDITAR CLIENTE</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" required />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" required />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.lastName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Gênero</mat-label>
              <mat-select formControlName="gender" required>
                <mat-option [value]="'female'">Feminino</mat-option>
                <mat-option [value]="'male'">Masculino</mat-option>
                <mat-option [value]="'none'">Prefiro não falar</mat-option>
                <mat-option [value]="'other'">Outro</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.gender?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.gender) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de Nascimento</mat-label>
              <input type="date" matInput formControlName="birthDate" />
              <mat-error *ngIf="formGroup.controls.birthDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.birthDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" required />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>
            <p style="margin-bottom: 0px; font-weight: bold">Documento</p>
            <mat-form-field>
              <mat-label>Frente</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputDocumentFront.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentFrontImage"
                matSuffix
                mat-icon-button
                (click)="editDocumentFrontImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentFrontImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('documentFront')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedDocumentFrontImage != ''"
                width="350"
                src="{{ croppedDocumentFrontImage }}"
              />
              <input
                hidden
                (change)="selectFileDocumentFront($event)"
                #fileInputDocumentFront
                type="file"
                accept="image/*"
              />
            </mat-form-field>
            <!-- <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="frontImageInvalid"
              >Campo deve ser preenchido</mat-error
            > -->
            <mat-form-field>
              <mat-label>Verso</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputDocumentBehind.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentBehindImage"
                matSuffix
                mat-icon-button
                (click)="editDocumentBehindImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentBehindImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('documentBehind')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedDocumentBehindImage != ''"
                width="350"
                src="{{ croppedDocumentBehindImage }}"
              />
              <input
                hidden
                (change)="selectFileDocumentBehind($event)"
                #fileInputDocumentBehind
                type="file"
                accept="image/*"
              />
            </mat-form-field>
            <!-- <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="behindImageInvalid"
              >Campo deve ser preenchido</mat-error
            > -->
            <mat-form-field>
              <mat-label>Habilitar membership</mat-label>
              <mat-select
                formControlName="membershipEnabled"
                (selectionChange)="changeMembershipEnabled($event)"
                required
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.membershipEnabled?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.membershipEnabled) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="planSelectEnabled">
              <mat-label>Plano</mat-label>
              <mat-select
                formControlName="plan"
                (selectionChange)="changeTypePlan($event)"
                required
              >
                <mat-option [value]="'free'">Free</mat-option>
                <mat-option [value]="'recurrent'">Pagamento Recorrente</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.plan?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.plan) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="firstPaymentEnabled">
              <mat-label>Primeiro pagamento</mat-label>
              <input
                type="date"
                matInput
                formControlName="firstPayment"
                disabled="{{ !firstPaymentEnabled }}"
                required
              />
              <mat-error *ngIf="formGroup.controls.firstPayment?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstPayment) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Segmentação</mat-label>
              <mat-select formControlName="kind" required>
                <mat-option *ngFor="let kind of kinds" [value]="kind.id"
                  >{{ kind.kindName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.kind?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.kind) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Atendentes</mat-label>
              <mat-select formControlName="attendant">
                <mat-option *ngFor="let attendant of attendants" [value]="attendant.id"
                  >{{ attendant.firstName }} {{ attendant.lastName }}
                </mat-option>
                <mat-option [value]="''">Gerente da Conta (Default) </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.attendant?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.attendant) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bloquear pagamentos</mat-label>
              <mat-select formControlName="blockTransaction" required>
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.blockTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.blockTransaction) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="architect">
              <mat-label>Profissão</mat-label>
              <mat-select formControlName="profession">
                <mat-option [value]="'ARCHITECT'">Arquiteto</mat-option>
                <mat-option [value]="'INTERIOR_DESIGNER'">Designer de Interiores</mat-option>
                <mat-option [value]="'LANDSCAPIST'">Paisagista</mat-option>
                <mat-option [value]="'OTHERS'">Outras</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.role?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.role) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="architect">
              <mat-label>Crea</mat-label>
              <input matInput formControlName="crea" />
            </mat-form-field>

            <mat-form-field *ngIf="architect">
              <mat-label>Cau</mat-label>
              <input matInput formControlName="cau" />
            </mat-form-field>

            <mat-form-field *ngIf="architect">
              <mat-label>Asbea</mat-label>
              <input matInput formControlName="asbea" />
            </mat-form-field>

            <mat-form-field *ngIf="architect">
              <mat-label>Escritório</mat-label>
              <input matInput formControlName="companyName" />
            </mat-form-field>

            <div *ngIf="architect">
              <mat-form-field>
                <mat-label>Documento arquiteto (PDF)</mat-label>
                <input matInput disabled />
                <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                  <mat-icon>attach_file</mat-icon>
                </a>
                <span *ngIf="approveDoc" class="filename-placeholder">
                  <mat-icon>description</mat-icon> {{ this.approveDoc }}
                </span>
                <div>
                  <span class="remove-doc-wrapper" style="margin-right: 5px; margin-left: 20px">
                    <button
                      class="btn-primary-light"
                      mat-button
                      *ngIf="customerID && documentUrl"
                      (click)="download($event)"
                    >
                      Download
                    </button>
                  </span>
                  <span class="remove-doc-wrapper">
                    <button
                      class="btn-primary-light"
                      mat-button
                      *ngIf="approveDoc"
                      (click)="removeDocument()"
                    >
                      <mat-icon>cancel</mat-icon> Remover documento
                    </button>
                  </span>
                </div>
                <input
                  hidden
                  (change)="selectFile($event)"
                  #fileInputBanner
                  type="file"
                  id="file"
                />
              </mat-form-field>

            </div>
            <mat-form-field>
              <mat-label>Origem</mat-label>
              <mat-select formControlName="origin">
                <mat-option [value]="'JHSFID'">JHSFID</mat-option>
                <mat-option [value]="'ARCHITECT'">ARQUITETO</mat-option>
              </mat-select>
            </mat-form-field>

            <div style="display: flex">
              <p style="margin-top: 3px; margin-bottom: 5px"><b>LL Loyalty&nbsp;</b></p>
              <mat-icon
                [ngStyle]="{ color: 'green' }"
                *ngIf="createdOnLlLoyalty"
                matTooltip="Criado na LL Loyalty"
              >
                check
              </mat-icon>
              <mat-icon
                matTooltip="Erro ao criar na LL Loyalty"
                [ngStyle]="{ color: 'red' }"
                *ngIf="!createdOnLlLoyalty"
              >
                close
              </mat-icon>
            </div>
            <p *ngIf="!createdOnLlLoyalty" style="margin-top: 0px; font-size: 12px; color: red">
              Click em Salvar para reenviar o cadastro para <u>LL Loyalty</u>
            </p>

            <div style="display: flex">
              <p style="margin-top: 3px; margin-bottom: 5px"><b>Roadpass&nbsp;</b></p>
              <mat-icon
                [ngStyle]="{ color: 'green' }"
                *ngIf="hasRoadpassWallet"
                matTooltip="Criado na RoadPass"
              >
                check
              </mat-icon>
              <mat-icon
                matTooltip="Erro ao criar na RoadPass"
                [ngStyle]="{ color: 'red' }"
                *ngIf="!hasRoadpassWallet"
              >
                close
              </mat-icon>
            </div>
            <p *ngIf="!hasRoadpassWallet" style="margin-top: 0px; font-size: 12px; color: red">
              Click em Salvar para reenviar o cadastro para <u>RoadPass</u>
            </p>

            <div style="display: flex">
              <p style="margin-top: 3px; margin-bottom: 5px"><b>Zoop&nbsp;</b></p>
              <mat-icon
                [ngStyle]="{ color: 'green' }"
                *ngIf="zoopBuyerId"
                matTooltip="Criado na RoadPass"
              >
                check
              </mat-icon>
              <mat-icon
                matTooltip="Erro ao criar na Zoop"
                [ngStyle]="{ color: 'red' }"
                *ngIf="!zoopBuyerId"
              >
                close
              </mat-icon>
            </div>
            <p *ngIf="!zoopBuyerId" style="margin-top: 0px; font-size: 12px; color: red">
              Click em Salvar para reenviar o cadastro para <u>Zoop</u>
            </p>

            <div style="display: flex">
              <p style="margin-top: 3px; margin-bottom: 5px"><b>SalesForce&nbsp;</b></p>
              <mat-icon
                [ngStyle]="{ color: 'green' }"
                *ngIf="salesForceId"
                matTooltip="Criado no SalesForce"
              >
                check
              </mat-icon>
              <mat-icon
                matTooltip="Erro ao criar no SalesForce"
                [ngStyle]="{ color: 'red' }"
                *ngIf="!salesForceId"
              >
                close
              </mat-icon>
            </div>
            <p *ngIf="!salesForceId" style="margin-top: 0px; font-size: 12px; color: red">
              Click em Salvar para reenviar o cadastro para <u>SalesForce</u>
            </p>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
