import { LoggedComponent } from './logged.component'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import {
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatChipsModule,
} from '@angular/material'
import { MatMenuModule } from '@angular/material/menu'
import { CdkAccordionModule } from '@angular/cdk/accordion'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    CdkAccordionModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [LoggedComponent],
  exports: [LoggedComponent],
})
export class LoggedModule { }
