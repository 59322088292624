import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var ArchitectStoresListComponent = /** @class */ (function () {
    function ArchitectStoresListComponent(companiesService, snackBar, router, dialog) {
        this.companiesService = companiesService;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.headersTable = ['cnpj', 'nomeFantasia', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ArchitectStoresListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjArchitectStores',
                label: 'CNPJ',
                placeholder: 'CNPJ',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'nomeFantasiaArchitectStores',
                label: 'Nome fantasia',
                placeholder: 'Nome fantasia',
            },
        ];
    };
    ArchitectStoresListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, stores, TotalRegisters;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .listStoresBusinessUnits("?&page=" + this.page + "&limit=" + this.pageSize)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { stores: [], TotalRegisters: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), stores = _a.stores, TotalRegisters = _a.TotalRegisters;
                        this.dataSource = new MatTableDataSource(stores);
                        this.pageTotal = TotalRegisters;
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectStoresListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Parceiro?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.companiesService.deleteStoresBusinessUnits(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (error) { return console.log(error); });
                                this.snackBar.open('Parceiro removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    ArchitectStoresListComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result > 0) {
                _this.router.navigate(['./dashboard/architect-store/new/' + result]);
            }
        });
    };
    ArchitectStoresListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter.stores;
        this.pageTotal = returnFilter.TotalPages;
        this.dataSource = new MatTableDataSource(returnFilter.stores);
        this.resultsAll = returnFilter.stores;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return ArchitectStoresListComponent;
}());
export { ArchitectStoresListComponent };
