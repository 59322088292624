import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
var ArchitectClosingForm = /** @class */ (function () {
    function ArchitectClosingForm(formBuilder, location, snackBar, architectClosingsService, errorsService) {
        this.formBuilder = formBuilder;
        this.location = location;
        this.snackBar = snackBar;
        this.architectClosingsService = architectClosingsService;
        this.errorsService = errorsService;
        this.disableSubmit = false;
        this.initForm();
    }
    ArchitectClosingForm.prototype.ngOnInit = function () {
    };
    ArchitectClosingForm.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
        });
    };
    ArchitectClosingForm.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectClosingForm.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        data = {
                            startDate: this.formGroup.value.startDate,
                            endDate: this.formGroup.value.endDate,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.disableSubmit = true;
                        return [4 /*yield*/, this.architectClosingsService.createClosing(data)];
                    case 2:
                        response = _a.sent();
                        if (response) {
                            this.snackBar.open('Fechamento criado com sucesso.');
                            this.location.back();
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        this.snackBar.open(error_1.error.message);
                        return [3 /*break*/, 5];
                    case 4:
                        this.disableSubmit = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return ArchitectClosingForm;
}());
export { ArchitectClosingForm };
