import * as tslib_1 from "tslib";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TransactionService = /** @class */ (function () {
    function TransactionService(http) {
        this.http = http;
    }
    TransactionService.prototype.listTransactions = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/transactions" + (filter || '')).pipe(tap(function (_) { return _this.log('cms/transactions'); }), catchError(this.handleError));
    };
    TransactionService.prototype.listTransactionsByClosing = function (closingID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/closings/" + closingID + "/transactions").pipe(tap(function (_) { return _this.log('cms/closings/transactions'); }), catchError(this.handleError));
    };
    TransactionService.prototype.cancelTransaction = function (transactionID, data) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/cms/transactions/" + transactionID + "/cancel", data)
            .pipe(tap(function (_) { return _this.log('cms/transactions/cancel'); }), catchError(this.handleError));
    };
    TransactionService.prototype.cancelTransactionC2C = function (transactionID, data) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/cms/transactions/c2c/" + transactionID + "/cancel", data)
            .pipe(tap(function (_) { return _this.log('/cms/transactions/c2c/cancel'); }), catchError(this.handleError));
    };
    TransactionService.prototype.changeStateTransactionManual = function (transactionID, data) {
        var _this = this;
        return this.http
            .put(environment.apiUrl + "/cms/transactions/" + transactionID + "/manual-transactions/state-machine", data)
            .pipe(tap(function (_) { return _this.log('cms/transactions/manual-transactions/state-machine'); }), catchError(this.handleError));
    };
    TransactionService.prototype.listTransactionsById = function (transactionID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/transactions/" + transactionID).pipe(tap(function (_) { return _this.log('cms/transactions'); }), catchError(this.handleError));
    };
    TransactionService.prototype.listTransactionsByCustomers = function (storeId) {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/customers/transactions?storeId=" + storeId)
            .pipe(tap(function (_) { return _this.log('customers/transactions'); }), catchError(this.handleError));
    };
    TransactionService.prototype.addTransaction = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/transactions", data).pipe(tap(function (_) { return _this.log('cms/transactions'); }), catchError(this.handleError));
    };
    TransactionService.prototype.uploadAuthorizationDocument = function (pathAwsUpload, data) {
        var _this = this;
        var headers = {
            'Content-Type': 'multipart/form-data',
        };
        return this.http
            .put("" + pathAwsUpload, data, { headers: headers })
            .pipe(tap(function (_) { return _this.log('cms/transactions/documents'); }), catchError(this.handleError));
    };
    TransactionService.prototype.getStatus = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/transactions/status").pipe(tap(function (_) { return _this.log('cms/transactions/status'); }), catchError(this.handleError));
    };
    TransactionService.prototype.listTransactionsReservation = function (filter) {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/cms/transactions/reservation" + (filter || ''))
            .pipe(tap(function (_) { return _this.log('cms/transactions/reservation'); }), catchError(this.handleError));
    };
    TransactionService.prototype.transactionsListByReserve = function (paymentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/transactions/payment-future/" + paymentId)
                        .toPromise()];
            });
        });
    };
    TransactionService.prototype.transactionsReserveFindOne = function (paymentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/transactions/reserve/" + paymentId)
                        .toPromise()];
            });
        });
    };
    TransactionService.prototype.getPaymentsStatus = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/transactions/payments/status").pipe(tap(function (_) { return _this.log('cms/transactions/payments/status'); }), catchError(this.handleError));
    };
    TransactionService.prototype.paymentAlter = function (paymentId, newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/transactions/payment/" + paymentId, {
                        totalValue: newValue,
                    })
                        .toPromise()];
            });
        });
    };
    TransactionService.prototype.transactionSendEvent = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(environment.apiUrl + "/cms/transactions/send-event/" + id, data)
                            .toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TransactionService.prototype.transactionsMembershipPlanByCustomer = function (planId, customerId, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/transactions/membership-plan/" + planId + "/customer/" + customerId + (filter || ''))
                        .toPromise()];
            });
        });
    };
    TransactionService.prototype.update = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put(environment.apiUrl + "/cms/transactions/" + id, data).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TransactionService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    TransactionService.prototype.log = function (message) { };
    TransactionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransactionService_Factory() { return new TransactionService(i0.ɵɵinject(i1.HttpClient)); }, token: TransactionService, providedIn: "root" });
    return TransactionService;
}());
export { TransactionService };
