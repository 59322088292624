import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
var UserListComponent = /** @class */ (function () {
    function UserListComponent(storeService, route, utilService) {
        this.storeService = storeService;
        this.route = route;
        this.utilService = utilService;
        this.headersTable = ['name', 'phoneNumber', 'emailAddress', 'actions'];
        this.storeID = null;
    }
    UserListComponent.prototype.ngOnInit = function () {
        if (this.route.snapshot.paramMap.get('storeID')) {
            this.storeID = this.route.snapshot.paramMap.get('storeID');
            this.listUsersByStore();
        }
        else {
            this.listUsers();
        }
    };
    UserListComponent.prototype.listUsers = function () {
        var _this = this;
        this.storeService.listUsersSellers().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                response.items.map(function (user) {
                    user.fullName = user.firstName + ' ' + user.lastName;
                });
                this.dataSource = new MatTableDataSource(response.items);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.paginator._intl.itemsPerPageLabel = 'Itens por página';
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    UserListComponent.prototype.listUsersByStore = function () {
        var _this = this;
        this.storeService.listUsersByStore("?storeId=" + this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSource = new MatTableDataSource(response);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.paginator._intl.itemsPerPageLabel = 'Itens por página';
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    UserListComponent.prototype.applyFilter = function (filterValue, type) {
        switch (type) {
            case 'numberTransaction':
                filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                this.dataSource.filter = filterValue;
                break;
            case 'cpfClient':
                this.dataSource.filter = this.utilService.extractNumbers(filterValue);
                break;
            case 'dateTransaction':
                console.log(filterValue);
                this.dataSource.filter = filterValue;
                break;
            case 'status':
                this.dataSource.filter = filterValue;
                break;
            case 'store':
                filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                this.dataSource.filter = filterValue;
                break;
            default:
                filterValue = filterValue.trim(); // Remove whitespace
                filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                this.dataSource.filter = filterValue;
        }
    };
    return UserListComponent;
}());
export { UserListComponent };
