import * as tslib_1 from "tslib";
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ReportService = /** @class */ (function () {
    function ReportService(http) {
        this.http = http;
    }
    ReportService.prototype.create = function (filter) {
        return this.http.get(environment.apiUrl + "/cms/reports/" + filter, {
            responseType: 'blob',
        });
    };
    ReportService.prototype.storeSellsReport = function (query) {
        return this.http.get(environment.apiUrl + "/cms/reports/transactions-of-period?" + query);
    };
    ReportService.prototype.createReport = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/reports/" + filter).toPromise()];
            });
        });
    };
    ReportService.prototype.createReportPreRegistration = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/reports/pre-registration" + filter).toPromise()];
            });
        });
    };
    ReportService.prototype.listCustomers = function () {
        return this.http.get(environment.apiUrl + "/cms/customer");
    };
    ReportService.prototype.listCustomersArchitects = function () {
        return this.http.get(environment.apiUrl + "/cms/customer-architects");
    };
    ReportService.prototype.listStores = function () {
        return this.http.get(environment.apiUrl + "/cms/stores");
    };
    ReportService.prototype.getStatus = function () {
        return this.http.get(environment.apiUrl + "/cms/transactions/status");
    };
    ReportService.prototype.closingIncorporations = function (query) {
        return this.http.get(environment.apiUrl + "/cms/reports/incorporations/closing-incorporations?closingIncorporationsId=" + query);
    };
    ReportService.prototype.incorporationsConsult = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/reports/incorporations/closing-incorporations/consult").toPromise()];
            });
        });
    };
    ReportService.prototype.storesWithMacroGroup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/reports/stores").toPromise()];
            });
        });
    };
    ReportService.prototype.preRegistrationCompaniesAirports = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/reports/pre-registration-companies-airports").toPromise()];
            });
        });
    };
    ReportService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    ReportService.prototype.log = function (message) { };
    ReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.ɵɵinject(i1.HttpClient)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}());
export { ReportService };
