import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatPaginator, MatSnackBar, MatTableDataSource } from "@angular/material";
import { Params } from "@angular/router";
import { enumCmsPermissions } from "src/app/models/enumCmsPermissions";
import { ArchitectInvoiceService } from "src/app/services/architectInvoice.service";
import { PermissionsService } from "src/app/services/permissions.service";

@Component({
  selector: 'architect-invoice-list',
  templateUrl: './architect-invoice-list.component.html',
  styleUrls: ['./architect-invoice-list.component.scss'],
})
export class ArchitectInvoiceListComponent implements OnInit {
  public headersTable: string[] = ['invoiceNumbber', 'invoiceSerial', 'receiver', 'amount', 'createdAt', 'approved', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any  
  public selectedStoreBusinessUnit: any
  public storeBusinessUnitId: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor(
    private architectInvoiceService: ArchitectInvoiceService,
    private snackBar: MatSnackBar,
    public permissionsService: PermissionsService,
    private location: Location,
  ) {
    this.selectedStoreBusinessUnit = JSON.parse(localStorage.getItem('storeSelected'))   
    this.storeBusinessUnitId = this.selectedStoreBusinessUnit.storeId
  }

  ngOnInit(): void {        
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'select',
        type: 'text',
        formControlName: 'architectInvoiceState',
        label: 'Status',
        placeholder: 'Status',
        list: [
          { key: 'APPROVED', value: 'PAGO' },
          { key: 'CANCELED', value: 'CANCELADO' },
          { key: 'PENDING', value: 'PEDIDO PENDENTE' },
          { key: 'WAITING_VALIDATION', value: 'AGUARDANDO VALIDAÇÃO' },
          { key: 'INVALIDATED', value: 'PEDIDO RECUSADO' },
          { key: '', value: 'TODOS' },          
        ],
        optionDefault: '',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Nota Criada a partir de',
        formControlName: 'architectInvoiceStartDate',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Nota Criada até',
        formControlName: 'architectInvoiceEndDate',
      },
    ]
  }

  async list () {

    const { invoices, TotalRegisters } = await this.architectInvoiceService      
      .list({
          storeBusinessUnitId: this.selectedStoreBusinessUnit.storeId,
          filter: `?&page=${this.page}&limit=${this.pageSize}`
        })
      .catch(err => {
        this.snackBar.open(err.message)
        return { invoices: [], TotalRegisters: 0 }
      })
    this.dataSource = new MatTableDataSource(invoices)
    this.pageTotal = TotalRegisters
  }

  getInvoiceStatus(invoice) {
    return this.architectInvoiceService.getInvoiceStatus(invoice)
  }

  filterCallback (returnFilter) {
    this.returnedFilters = returnFilter.invoices
    this.pageTotal = returnFilter.TotalRegisters
    this.dataSource = new MatTableDataSource(returnFilter.invoices)    
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async cancelInvoice(invoiceId) {
    try{
      const res = await this.architectInvoiceService.cancelInvoice(invoiceId)
      this.list()
    } catch(err) {
      this.snackBar.open("Não foi possível cancelar essa Nota fiscal")
    }
  }

  showEditButton(invoiceData) {
    
    
    if(invoiceData.approved || invoiceData.canceled) return false

    const hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_create_update)
    if(!hasPermission) return false

    return true
    
  }

  showCancelButton(invoiceData) {
  
    if(invoiceData.approved || invoiceData.canceled) return false

    const hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_cancel)    
    if(!hasPermission) return false

    return true
  }

  goBack() {
    this.location.back()
  }

  convertCentsToReal (value) {
    return value / 100
  }
}
