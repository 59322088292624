import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { StoreService } from 'src/app/services/store.service'
import { User } from 'src/app/models/user'
import { UtilService } from 'src/app/services/util.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'

@Component({
  selector: 'app-store-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  public headersTable: string[] = ['name', 'phoneNumber', 'emailAddress', 'actions']
  public dataSource: any
  public storeID: string = null
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor(
    public storeService: StoreService,
    private route: ActivatedRoute,
    public utilService: UtilService,
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('storeID')) {
      this.storeID = this.route.snapshot.paramMap.get('storeID')
      this.listUsersByStore()
    } else {
      this.listUsers()
    }
  }

  listUsers() {
    this.storeService.listUsersSellers().subscribe(
      async response => {
        response.items.map(user => {
          user.fullName = user.firstName + ' ' + user.lastName
        })

        this.dataSource = new MatTableDataSource(response.items)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.paginator._intl.itemsPerPageLabel = 'Itens por página'
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listUsersByStore() {
    this.storeService.listUsersByStore(`?storeId=${this.storeID}`).subscribe(
      async response => {
        this.dataSource = new MatTableDataSource(response)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.paginator._intl.itemsPerPageLabel = 'Itens por página'
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  applyFilter(filterValue: string, type) {
    switch (type) {
      case 'numberTransaction':
        filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue
        break
      case 'cpfClient':
        this.dataSource.filter = this.utilService.extractNumbers(filterValue)
        break
      case 'dateTransaction':
        console.log(filterValue)
        this.dataSource.filter = filterValue
        break
      case 'status':
        this.dataSource.filter = filterValue
        break
      case 'store':
        filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue
        break
      default:
        filterValue = filterValue.trim() // Remove whitespace
        filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue
    }
  }
}
