import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from "@angular/material";
import { Params } from "@angular/router";
import { ModalConfirmComponent } from "src/app/components/modal-confirm/modal-confirm.component";
import { enumCmsPermissions } from "src/app/models/enumCmsPermissions";
import { ArchitectInvoiceService } from "src/app/services/architectInvoice.service";
import { PermissionsService } from "src/app/services/permissions.service";

@Component({
  selector: 'architect-invoice-list-admin',
  templateUrl: './architect-invoice-list-admin.component.html',
  styleUrls: ['./architect-invoice-list-admin.component.scss'],
})
export class ArchitectInvoiceListAdminComponent implements OnInit {
  public headersTable: string[] = ['storeCnpj', 'storeName', 'receiver','invoiceNumbber', 'amount', 'createdAt', 'approved', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any  
  public formGroup: FormGroup
  public params: Params
  public selectedInvoices: any = {}
  public allSelected: boolean = false

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor(
    private architectInvoiceService: ArchitectInvoiceService,
    private snackBar: MatSnackBar,
    public permissionsService: PermissionsService,
    private readonly dialog: MatDialog,
    private location: Location,
  ) {
      
  }

  ngOnInit(): void {    
    if(this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_approve))
      this.headersTable.unshift('bulkSelector')      

    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'select',
        type: 'text',
        formControlName: 'architectInvoiceState',
        label: 'Status',
        placeholder: 'Status',
        list: [
          { key: 'APPROVED', value: 'PAGO' },
          { key: 'CANCELED', value: 'CANCELADO' },
          { key: 'PENDING', value: 'PEDIDO PENDENTE' },
          { key: 'WAITING_VALIDATION', value: 'AGUARDANDO VALIDAÇÃO' },
          { key: 'INVALIDATED', value: 'PEDIDO RECUSADO' },
          { key: '', value: 'TODOS' },          
        ],
        optionDefault: '',
      },
      {
        field: 'input',
        type: 'number',
        label: 'CNPJ do parceiro',
        formControlName: 'architectInvoiceStoreCnpj',
      },
      {
        field: 'input',
        type: 'text',
        label: 'Nome do parceiro',
        formControlName: 'architectInvoiceStoreName',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Nota Criada a partir de',
        formControlName: 'architectInvoiceStartDate',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Nota Criada até',
        formControlName: 'architectInvoiceEndDate',
      },
    ]
  }

  async list () {

    const { invoices, TotalRegisters } = await this.architectInvoiceService      
      .list({filter: `?&page=${this.page}&limit=${this.pageSize}`})
      .catch(err => {
        this.snackBar.open(err.message)
        return { invoices: [], TotalRegisters: 0 }
      })
    this.dataSource = new MatTableDataSource(invoices)
    this.pageTotal = TotalRegisters
    this.selectAllToggle(false)
  }

  filterCallback (returnFilter) {
    this.returnedFilters = returnFilter.invoices
    this.pageTotal = returnFilter.TotalRegisters
    this.dataSource = new MatTableDataSource(returnFilter.invoices)    
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
    this.selectAllToggle(false)
  }

  async cancelInvoice(invoiceId) {

    try{
      const result = await this.dialog
        .open(ModalConfirmComponent, {
          width: '500px',
          data: {
            text: 'Deseja Cancelar essa Nota Fiscal? Essa operação não pode ser desfeita.',
            buttonConfirmText: 'Cancelar',
          },
        })
        .afterClosed()
        .toPromise()
      
      if (result && result !== 'cancel') {
        const res = await this.architectInvoiceService.cancelInvoice(invoiceId)
        this.snackBar.open("Nota fiscal cancelada com sucesso")
        this.list()
      }
    } catch(err) {
      this.snackBar.open("Não foi possível cancelar essa Nota fiscal")
    }

  }

  showCancelButton(invoiceData) {
  
    if(invoiceData.approved || invoiceData.canceled) return false

    const hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_cancel)    
    if(!hasPermission) return false

    return true
  }
  

  getInvoiceStatus(invoice) {
    return this.architectInvoiceService.getInvoiceStatus(invoice)
  }


  async approveInvoices(invoiceIds: string[]) {

    const text = invoiceIds.length > 1
      ? 'Deseja Aprovar as notas fiscais selecionadas? Essa ação irá liberar os Jcoins para todos os respectivos clientes.'
      : 'Deseja Aprovar essa Nota Fiscal? Essa ação irá liberar os Jcoins para os respectivos clientes.'
    
    const successText = invoiceIds.length > 1
    ? 'Notas fiscais aprovadas com sucesso'
    : 'Nota fiscal aprovada com sucesso'

    try{
      const result = await this.dialog
        .open(ModalConfirmComponent, {
          width: '500px',
          data: {
            text: text,
            buttonConfirmText: 'Aprovar',
          },
        })
        .afterClosed()
        .toPromise()
      
      if (result && result !== 'cancel') {
        const res = await this.architectInvoiceService.approveInvoices(invoiceIds)
        this.snackBar.open(successText)
        this.list()
      }
    } catch(err) {
      this.snackBar.open("Não foi possível aprovar essa(s) Nota(s) fiscal(is)")
    }
  }

  canApprove(invoiceData) {
  
    if(invoiceData.approved || invoiceData.canceled || !invoiceData.validated) return false

    const hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_approve)    
    if(!hasPermission) return false

    return true
  }

  goBack() {
    this.location.back()
  }

  selectAllToggle(isChecked) {    
    this.selectedInvoices = {}
    this.dataSource.data.forEach(k => this.selectedInvoices[k.id] = isChecked)
    console.log('this.selectedInvoices',this.selectedInvoices)
  }

  onInvoiceSelect(isChecked, invoiceId) {
    this.selectedInvoices[invoiceId] = isChecked
    if(isChecked == false)
      this.allSelected = false
  }

  isAnySelected() {
    return Object.values(this.selectedInvoices).filter(a => a).length == 0
  }

  bulkApproveInvoices() {
    const invoiceIds = Object.entries(this.selectedInvoices).filter(([k,v]) => v).map(([k,v]) => k)
    this.approveInvoices(invoiceIds)
  }

  convertCentsToReal (value) {
    return value / 100
  }
}
