import * as _ from 'lodash'
import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { FundService } from 'src/app/services/fund.service'
import { MatDialog, MatSnackBar } from '@angular/material'
import { Location } from '@angular/common'
import { Store } from 'src/app/models/store'
import { ModalConfirmationPointsComponent } from 'src/app/components/modal-confirmation-points/modal-confirmation-points.component'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-funds-points-form',
  templateUrl: './fund-points-form.component.html',
  styleUrls: ['./fund-points-form.component.scss'],
})
export class FundPointsFormComponent implements OnInit {
  public formGroup: FormGroup
  public fundId = ''
  public customerId = ''
  public stores: Store[] = []
  public storeName = ''
  public store: any[] = []
  public storeOptions: Observable<any[]>
  public pointsConversionFactorOut
  public params = { formGroupValue: [] }

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    public fundService: FundService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly location: Location,
  ) {
    this.initForm()
  }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.fundId = this.route.snapshot.paramMap.get('fundId')
    this.loadInfosUser().catch(err => {
      console.log(err)
      this.snackBar.open(err.error.message)
    })
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      name: [{ value: null, disabled: true }, [Validators.required]],
      cnpj: [{ value: null, disabled: true }, [Validators.required]],
      jcoins: [
        null,
        [Validators.required, Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')],
      ],
      pointsConversionFactorOut: [{ value: null, disabled: true }, []],
      store: [null, [Validators.required]],
    })

    this.storeOptions = this.formGroup.controls.store.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.stores.filter(store => {
          const newValue = typeof value === 'object' ? value.nomeFantasia : value
          return (
            store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
            store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
            store.cnpj.toLowerCase().includes(newValue.toLowerCase())
          )
        })
      }),
    )

    this.loadStores()
  }

  async loadInfosUser () {
    const fund = await this.fundService.find(this.fundId)
    this.customerId = fund.customerId

    this.formGroup.patchValue({
      name: fund.name,
      cnpj: fund.cnpj,
    })
  }

  loadStores () {
    this.storeService
      .listStores()
      .toPromise()
      .then(
        async response => {
          this.stores = response
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  calcValuePoints (event) {
    this.formGroup.patchValue({
      store: this.formGroup.value.store,
      jcoins: event.target.value,
      pointsConversionFactorOut:
        this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100),
    })
    this.pointsConversionFactorOut =
      this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100)
  }

  displayStore (store) {
    return store ? `${store.nomeFantasia}` : ''
  }

  calcValue (event) {
    this.stores.forEach(store => {
      if (store.id === event.option.value.id) {
        this.store.push(store)

        this.storeName = store.nomeFantasia
        this.pointsConversionFactorOut =
          this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100)
        this.formGroup.patchValue({
          store: this.formGroup.value.store,
          pointsConversionFactorOut:
            this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100),
        })
      }
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (!this.formGroup.value.store || !_.isObject(this.formGroup.value.store)) {
      this.snackBar.open('Preencha corretamente o campo Parceiro.')
      return false
    }

    const data = {
      customerId: this.customerId,
      storeId: this.formGroup.value.store.id,
      jcoins: parseInt(this.formGroup.value.jcoins),
    }

    const dialogRef = this.dialog.open(ModalConfirmationPointsComponent, {
      width: '500px',
      data: {
        store: this.storeName,
        points: this.formGroup.value.jcoins,
        value: this.pointsConversionFactorOut,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'cancel') {
        this.authService
          .applyPoints(data)
          .toPromise()
          .then(
            async response => {
              this.snackBar.open('Pontos creditados com sucesso.')
              await this.router.navigate(['./dashboard/funds'])
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
      }
    })
  }

  goBack () {
    this.location.back()
  }
}
