import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'

import { PreRegistrationService } from '../../../../../services/preRegistration.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-pre-registration-address-list',
  templateUrl: './pre-registration-address-list.component.html',
  styleUrls: ['./pre-registration-address-list.component.scss'],
})
export class PreRegistrationAddressListComponent implements OnInit {
  public headersTable: string[] = ['addressTitle', 'address', 'kind', 'actions']
  public preRegistrationID: string
  // public customer: string
  dataSource: any
  isAddress = false
  public params = { formGroupValue: [] }

  constructor (
    private readonly route: ActivatedRoute,
    public preRegistrationService: PreRegistrationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.preRegistrationID = this.route.snapshot.paramMap.get('preRegistrationID')
    // this.authService.customerUserInfo(this.customerID).subscribe(
    //   async response => {
    //     this.customer = response.firstName + ' ' + response.lastName
    //   },
    //   error => {
    //     this.snackBar.open(error.error.message)
    //   },
    // )
    this.clientAddressList()
  }

  clientAddressList () {
    this.preRegistrationService.listAddress(this.preRegistrationID).then(
      async response => {
        this.isAddress = !!response.length
        this.dataSource = new MatTableDataSource(response)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertKind (kind) {
    switch (kind) {
      case 'HOME':
        return 'Residencial'
        break
      case 'COMPANY':
        return 'Comercial'
        break
      default:
        return ''
    }
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse endereço?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.preRegistrationService.deleteAddress(id).then(
            async response => {
              this.clientAddressList()
              this.snackBar.open('Endereço removido com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
