import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { User } from 'src/app/models/user'
import { ModalHelpComponent } from '../modal-help/modal-help.component'
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions'
import { PermissionsService } from 'src/app/services/permissions.service'
import { LoaderService } from 'src/app/services/loader.service'

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
  styleUrls: ['./logged.component.scss'],
})
export class LoggedComponent implements OnInit {
  public menuOpened: boolean
  public userInfo: User
  public userRole: any
  // public items = ['FINANCEIRO', 'APLICATIVO', 'CLIENTES', 'MARKETING', 'LL LOYALTY', 'INCORPORAÇÃO']
  public items = ['FINANCEIRO', 'APLICATIVO', 'CLIENTES', 'MARKETING', 'LL LOYALTY', 'INCORPORAÇÃO', 'AEROPORTO', 'ARQUITETOS']
  public itemsStorekeeper = ['AEROPORTO']
  public expandedIndex = 0

  public stores: any[] = []
  public userPermissions: any[] = []
  public storeSelected: any

  public showButtonsPreRegistration = false
  public showButtonsArchitect = false
  public showButtonsArchitectPreRegister = false

  public cmsPermissions = enumCmsPermissions

  constructor (
    private readonly router: Router,
    private readonly dialog: MatDialog,
    public permissionsService: PermissionsService,
    private readonly loaderService: LoaderService,
  ) {
    this.menuOpened = !!localStorage.getItem('menuOpened')
  }

  ngOnInit () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userRole = localStorage.getItem('userRole')
    this.stores = JSON.parse(localStorage.getItem('storesUser'))
    this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
  }

  onChangeStore (store) {
    console.log(store)
    this.storeSelected = store
    localStorage.setItem('storeSelected', JSON.stringify(store))

    this.loaderService.isLoading.next(true)

    setTimeout(() => {
      this.router.navigate(['/dashboard/home']).then(() => {
        window.location.reload()
      })
    }, 1500)
  }

  teste () {
    this.router.navigate(['/pagamentos'])
  }

  toggleMenu () {
    this.menuOpened = !this.menuOpened
    this.menuOpened
      ? localStorage.setItem('menuOpened', 'true')
      : localStorage.removeItem('menuOpened')
  }

  openDialogHelp (): void {
    const dialogRef = this.dialog.open(ModalHelpComponent, {
      width: '600px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  showButtons (e) {
    this.showButtonsPreRegistration = !this.showButtonsPreRegistration
  }

  showArchitect (e) {
    this.showButtonsArchitect = !this.showButtonsArchitect
  }

  showArchitectPreRegister (e) {
    this.showButtonsArchitectPreRegister = !this.showButtonsArchitectPreRegister
  }
}
