import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar } from '@angular/material'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import { CategoriesService } from 'src/app/services/categories.service'
import { UploadAdapter } from 'src/app/services/uploadAdapter.service'
import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'
import { ReportService } from '../../../../services/report.service'

@Component({
  selector: 'app-store-form',
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss'],
})
export class CategoriesFormComponent implements OnInit {
  public formGroup: FormGroup

  public categoriesID = ''

  public Editor = ClassicEditor
  public config: any
  public srcResult: any
  public textDescription = ''
  public bannerUrl = ''
  public mainIconUrl = ''
  public menuIconUrl = ''

  private originalFlashSalesImage: any
  private originalBannerExclusiveImage: any
  public croppedFlashSalesImage: any
  private flashSalesImagePosition: any
  private bannerExclusiveImagePosition: any
  private flashSalesImageData: any

  private originalPartnerBannerImage: any
  public croppedPartnerBannerImage: any
  private partnerBannerImagePosition: any
  private partnerBannerImageData: any
  private bannerExclusiveImageData: any

  public croppedBannerExclusiveImage: any
  public bannerExclusivePosition: any
  public bannerExclusiveData: any
  public croppedConciergeImage: any

  public readUrlPartnerBanner: any
  public readUrlFlashSales: any
  public readUrlBannerExclusive: any

  public originalBannerConciergeImage: any
  public croppedBannerConciergeImage: any
  public bannerConciergeImagePosition: any
  public bannerConciergeImageData: any
  public readUrlBannerConcierge: any
  public bannerConciergePosition: any

  public croppedRedirectImage: any
  public originalBannerRedirectImage: any
  public croppedBannerRedirectImage: any
  public bannerRedirectImagePosition: any
  public bannerRedirectImageData: any
  public readUrlBannerRedirect: any
  public bannerRedirectPosition: any

  public croppedDetailImage: any
  public originalBannerDetailImage: any
  public croppedBannerDetailImage: any
  public bannerDetailImagePosition: any
  public bannerDetailImageData: any
  public readUrlBannerDetail: any
  public bannerDetailPosition: any

  public conciergeInvalid = false
  public redirectInvalid = false
  public detailIsTrue = false

  public categoriesControl = new FormControl()
  filteredCategories: Observable<string[]>
  categories: any
  public categoriesListPromise: Promise<any>
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public selectedCategories: any[] = []
  isCategoryJoin = false
  categoryInputInvalid = false

  public selectedStores: any[] = []
  stores: any[] = []
  filteredStores: Observable<string[]>
  public storeControl = new FormControl()

  @ViewChild('categoriesInput', { static: false }) categoriesInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete
  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocompleteStore: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public categoriesService: CategoriesService,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    public reportService: ReportService,
  ) {
    this.config = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'imageUpload',
          'blockQuote',
          'undo',
          'redo',
        ],
      },
      image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
      },
    }
  }

  ngOnInit () {
    if (this.route.snapshot.paramMap.get('categoriesID')) {
      this.categoriesID = this.route.snapshot.paramMap.get('categoriesID')
    }

    this.initForm().catch(error => {
      console.log(error)
    })

    this.reportService.listStores().subscribe(
      response => {
        response.map(item => {
          this.stores.push(item)
          this.filteredStores = this.storeControl.valueChanges.pipe(
            startWith(''),
            map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 20))),
          )
        })
      },
      error => {
        console.log(error)
      },
    )
  }

  async initForm () {
    this.formGroup = this.formBuilder.group({
      heading: [null, [Validators.required]],
      menuIconUrl: [null, [Validators.required]],
      partnerBanner: [null, []],
      flashSales: [null, []],
      order: [null, [Validators.required, Validators.min(1)]],
      bannerLink: [null, []],
      flashSalesLink: [null, []],
      showMain: [null, []],
      description: [null, []],
      hasSubItem: [null, []],
      title: [null, []],
      redirectLink: [null, []],
      detail: [null, []],
      // descomenta aqui
      // isBannerExclusive: [null, []],
      isAirport: [null, []],
      categoryInput: [null, []],
      isCategoryJoin: [null, []],
      isActive: [null, []],
      conciergeLink: [null, []],
      isParking: [null, []],
      isDonation: [null, []],
    })

    // else {
    //   localStorage.removeItem('categoriesInfos')
    // }
    await this.categoriesService
      .listCategories()
      .toPromise()
      .then(
        async response => {
          this.categories = await response.results.filter(
            c => c.id !== this.categoriesID && !c.categoriesIdJoin01 && !c.categoriesIdJoin02,
          )
          await this.filterCategories().catch(err => console.log(err))
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )

    if (this.categoriesID) {
      await this.loadInfos().catch(err => console.log(err))
    }
  }

  private _filterStores (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.stores
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  selectedStore (event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.nomeFantasia && this.selectedStores.length < 1) {
      this.selectedStores.push(event.option.value)
      this.storeInput.nativeElement.value = ''
      this.storeControl.setValue(null)
    }
  }

  remove (value: any): void {
    if (value.nomeFantasia) {
      const index = this.selectedStores.indexOf(value)
      if (index >= 0) {
        this.selectedStores.splice(index, 1)
      }
    }
  }

  async filterCategories () {
    this.filteredCategories = await this.categoriesControl.valueChanges.pipe(
      startWith(''),
      map((category: any) => (category ? this._filter(category) : this.categories.slice(0, 20))),
    )
  }

  private _filter (categories: string): string[] {
    const remove = String(categories).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.categories
      .filter(c => !this.selectedCategories.includes(c))
      .filter(c => `${String(c.heading)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (this.selectedCategories.length < 2) {
      this.selectedCategories.push(event.option.value)
      this.categoriesInput.nativeElement.value = ''
      this.categoriesControl.setValue(null)
    }
    this.selectedCategories.length === 2
      ? (this.categoryInputInvalid = false)
      : (this.categoryInputInvalid = true)
  }

  removeCategories (categories: any): void {
    const index = this.selectedCategories.indexOf(categories)
    if (index >= 0) {
      this.selectedCategories.splice(index, 1)
    }
  }

  checkCategoryJoin (e) {
    this.isCategoryJoin = this.formGroup.value.isCategoryJoin
  }

  async loadInfos () {
    // this.categoriesInfo = JSON.parse(localStorage.getItem('categoriesInfos'))
    await this.categoriesService.findOne(this.categoriesID).then(async response => {
      this.formGroup.patchValue({
        heading: response.heading,
        menuIconUrl: response.menuIconUrl,
        order: response.order,
        bannerLink: response.bannerLink,
        flashSalesLink: response.flashSalesLink,
        showMain: response.showMain,
        description: response.description,
        hasSubItem: response.hasSubItem,
        title: response.title,
        // descomenta aqui
        // isBannerExclusive: response.isBannerExclusive,
        redirectLink: response.redirectLink,
        detail: response.detail,
        categoryJoin: response.categoryJoin,
        isAirport: response.isAirport,
        isActive: !!response.isActive,
        isCategoryJoin: response.categoriesJoins ? response.categoriesJoins.length === 2 : false,
        conciergeLink: response.conciergeLink,
        isParking: response.isParking,
        isDonation: response.isDonation,
      })
      this.croppedPartnerBannerImage = response.bannerUrl
      this.croppedFlashSalesImage = response.flashSalesUrl
      this.menuIconUrl = response.menuIconUrl
      // this.croppedBannerExclusiveImage = response.bannerExclusiveUrl
      this.croppedBannerConciergeImage = response.bannerConciergeUrl
      this.croppedBannerRedirectImage = response.bannerRedirectUrl
      this.croppedBannerDetailImage = response.bannerDetailUrl
      this.detailIsTrue = !!response.bannerDetailUrl
      if (response.categoriesJoins) {
        if (response.categoriesJoins.length === 2) {
          this.isCategoryJoin = true
        }
        if (response.categoriesJoins) {
          response.categoriesJoins.map(item => {
            this.selectedCategories.push(item)
          })
        }
      }

      if (response.categoriesStores) {
        const filter = this.stores.filter(item => {
          return response.categoriesStores.storeId.includes(item.id)
        })
        filter.map(item => {
          this.selectedStores.push(item)
        })
      }
    })
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  selectFile (event, type) {
    const uploadData = new FormData()
    uploadData.append('file', event.target.files[0], event.target.files[0].name)

    this.categoriesService.upload(uploadData).subscribe(
      response => {
        switch (type) {
          case 'menuIconUrl': {
            this.formGroup.get('menuIconUrl').setValue(response.url)
            this.menuIconUrl = response.url
            break
          }
          default:
            break
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  selectFilePartnerBanner (event: Event) {
    const originalPartnerBannerImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalPartnerBannerImage = reader.result
    }
    try {
      reader.readAsDataURL(originalPartnerBannerImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedPartnerBannerImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedPartnerBannerImage = result.img.dataURL
          this.partnerBannerImagePosition = result.config
          this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  selectFileFlashSales (event: Event) {
    const originalFlashSalesImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalFlashSalesImage = reader.result
    }
    try {
      reader.readAsDataURL(originalFlashSalesImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedFlashSalesImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedFlashSalesImage = result.img.dataURL
          this.flashSalesImagePosition = result.config
          this.flashSalesImageData = await base64ToBlob(this.croppedFlashSalesImage)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  selectFileBannerExclusive (event: Event) {
    const originalBannerExclusiveImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalBannerExclusiveImage = reader.result
    }
    try {
      reader.readAsDataURL(originalBannerExclusiveImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedBannerExclusiveImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedBannerExclusiveImage = result.img.dataURL
          this.bannerExclusiveImagePosition = result.config
          this.bannerExclusiveImageData = await base64ToBlob(this.croppedBannerExclusiveImage)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  selectFileConciergeBanner (event: Event) {
    const originalBannerConciergeImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalBannerConciergeImage = reader.result
    }
    try {
      reader.readAsDataURL(originalBannerConciergeImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedBannerConciergeImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedBannerConciergeImage = result.img.dataURL
          this.bannerConciergeImagePosition = result.config
          this.bannerConciergeImageData = await base64ToBlob(this.croppedBannerConciergeImage)
          this._cd.markForCheck()
          this.conciergeInvalid = false
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  selectFileRedirectBanner (event: Event) {
    const originalBannerRedirectImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalBannerConciergeImage = reader.result
    }
    try {
      reader.readAsDataURL(originalBannerRedirectImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedBannerRedirectImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedBannerRedirectImage = result.img.dataURL
          this.bannerRedirectImagePosition = result.config
          this.bannerRedirectImageData = await base64ToBlob(this.croppedBannerRedirectImage)
          this._cd.markForCheck()
          this.redirectInvalid = false
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  selectFileDetailBanner (event: Event) {
    const originalBannerDetailImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalBannerConciergeImage = reader.result
    }
    try {
      reader.readAsDataURL(originalBannerDetailImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedBannerDetailImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedBannerDetailImage = result.img.dataURL
          this.bannerDetailImagePosition = result.config
          this.bannerDetailImageData = await base64ToBlob(this.croppedBannerDetailImage)
          this._cd.markForCheck()
          this.detailIsTrue = !!this.bannerDetailImageData
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editPartnerBannerImage () {
    try {
      const img = this.originalPartnerBannerImage
        ? this.originalPartnerBannerImage
        : await this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.partnerBannerImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedPartnerBannerImage = result.img.dataURL
        this.partnerBannerImagePosition = result.config
        this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async editFlashSalesImage () {
    try {
      const img = this.originalFlashSalesImage
        ? this.originalFlashSalesImage
        : await this.getBase64ImageFromUrl(this.croppedFlashSalesImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.flashSalesImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedFlashSalesImage = result.img.dataURL
        this.flashSalesImagePosition = result.config
        this.flashSalesImageData = await base64ToBlob(this.croppedFlashSalesImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async editBannerExclusiveImage () {
    try {
      const img = this.originalBannerExclusiveImage
        ? this.originalBannerExclusiveImage
        : await this.getBase64ImageFromUrl(this.croppedBannerExclusiveImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.bannerExclusivePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedBannerExclusiveImage = result.img.dataURL
        this.bannerExclusiveImagePosition = result.config
        this.bannerExclusiveImageData = await base64ToBlob(this.croppedBannerExclusiveImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async editConciergeBannerImage () {
    try {
      const img = this.originalBannerConciergeImage
        ? this.originalBannerConciergeImage
        : await this.getBase64ImageFromUrl(this.croppedBannerConciergeImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.bannerConciergePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedBannerConciergeImage = result.img.dataURL
        this.bannerConciergeImagePosition = result.config
        this.bannerConciergeImageData = await base64ToBlob(this.croppedBannerConciergeImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async editRedirectBannerImage () {
    try {
      const img = this.originalBannerRedirectImage
        ? this.originalBannerRedirectImage
        : await this.getBase64ImageFromUrl(this.croppedBannerRedirectImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.bannerRedirectPosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedBannerRedirectImage = result.img.dataURL
        this.bannerRedirectImagePosition = result.config
        this.bannerRedirectImageData = await base64ToBlob(this.croppedBannerRedirectImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async editDetailBannerImage () {
    try {
      const img = this.originalBannerDetailImage
        ? this.originalBannerDetailImage
        : await this.getBase64ImageFromUrl(this.croppedBannerDetailImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.bannerDetailPosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedBannerDetailImage = result.img.dataURL
        this.bannerDetailImagePosition = result.config
        this.bannerDetailImageData = await base64ToBlob(this.croppedBannerDetailImage)
        this._cd.markForCheck()
        this.detailIsTrue = !!this.bannerDetailImageData
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async removeImage (input) {
    if (input === 'flashSales') {
      this.readUrlFlashSales = ''
      this.originalFlashSalesImage = undefined
      this.croppedFlashSalesImage = undefined
      this.flashSalesImagePosition = undefined
      this.flashSalesImageData = undefined
    }
    if (input === 'bannerExclusive') {
      this.readUrlBannerExclusive = ''
      this.originalBannerExclusiveImage = undefined
      this.croppedBannerExclusiveImage = undefined
      this.bannerExclusiveImagePosition = undefined
      this.bannerExclusiveImageData = undefined
    }
    if (input === 'partnerBanner') {
      this.readUrlPartnerBanner = ''
      this.originalPartnerBannerImage = undefined
      this.croppedPartnerBannerImage = undefined
      this.partnerBannerImagePosition = undefined
      this.partnerBannerImageData = undefined
    }
    if (input === 'conciergeBanner') {
      this.readUrlBannerConcierge = ''
      this.originalBannerConciergeImage = undefined
      this.croppedBannerConciergeImage = undefined
      this.bannerConciergeImagePosition = undefined
      this.bannerConciergeImageData = undefined
    }
    if (input === 'redirectBanner') {
      this.readUrlBannerRedirect = ''
      this.originalBannerRedirectImage = undefined
      this.croppedBannerRedirectImage = undefined
      this.bannerRedirectImagePosition = undefined
      this.bannerRedirectImageData = undefined
    }
    if (input === 'detailBanner') {
      this.readUrlBannerDetail = ''
      this.originalBannerDetailImage = undefined
      this.croppedBannerDetailImage = undefined
      this.bannerDetailImagePosition = undefined
      this.bannerDetailImageData = undefined
      this.detailIsTrue = !!this.bannerDetailImageData

      // this.formGroup.patchValue({
      //   detail: '',
      // })
      // this.formGroup.get('detail').setValue('', { onlySelf: true })
      // this.formGroup.get('detail').updateValueAndValidity()
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  change ({ editor }: ChangeEvent) {
    // const EditorData = editor.getData()
    // this.formGroup.get('detail').setValue(EditorData)
  }

  // changeBannerConcierge () {
  //   if (!this.formGroup.value.isBannerConcierge && this.conciergeInvalid) {
  //     this.conciergeInvalid = false
  //   }
  // }

  async submit () {
    if (this.formGroup.value.isDonation && this.selectedStores.length < 1) {
      this.snackBar.open('Selecione o parceiro.')
      return false
    }
    // if (
    //   this.formGroup.value.isBannerConcierge &&
    //   !this.croppedBannerConciergeImage &&
    //   !this.bannerConciergeImageData
    // ) {
    //   this.conciergeInvalid = true
    // }
    if (this.isCategoryJoin) {
      this.selectedCategories.length === 2
        ? (this.categoryInputInvalid = false)
        : (this.categoryInputInvalid = true)
    }
    if (!this.formGroup.valid || this.conciergeInvalid || this.categoryInputInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    if (this.croppedPartnerBannerImage && this.partnerBannerImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlPartnerBanner = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.partnerBannerImageData)
    }
    if (this.croppedFlashSalesImage && this.flashSalesImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlFlashSales = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.flashSalesImageData)
    }

    if (this.croppedBannerExclusiveImage && this.bannerExclusiveImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlBannerExclusive = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.bannerExclusiveImageData)
    }

    if (this.croppedBannerConciergeImage && this.bannerConciergeImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlBannerConcierge = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.bannerConciergeImageData)
    }

    if (this.croppedBannerRedirectImage && this.bannerRedirectImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlBannerRedirect = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.bannerRedirectImageData)
    }

    if (this.croppedBannerDetailImage && this.bannerDetailImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlBannerDetail = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.bannerDetailImageData)
    }

    const data = {
      heading: this.formGroup.value.heading,
      order: this.formGroup.value.order,
      menuIconUrl: this.formGroup.value.menuIconUrl,
      bannerUrl: this.readUrlPartnerBanner,
      bannerLink: this.formGroup.value.bannerLink || '',
      flashSalesUrl: this.readUrlFlashSales,
      flashSalesLink: this.formGroup.value.flashSalesLink || '',
      showMain: !!this.formGroup.value.showMain,
      description: this.formGroup.value.description,
      hasSubItem: !!this.formGroup.value.hasSubItem,
      // descomenta aqui
      // bannerExclusiveUrl: this.readUrlBannerExclusive,
      // isBannerExclusive: !!this.formGroup.value.isBannerExclusive,
      isBannerExclusive: !!this.formGroup.value.isBannerExclusive,
      bannerConciergeUrl: this.readUrlBannerConcierge,
      bannerRedirectUrl: this.readUrlBannerRedirect,
      redirectLink: this.formGroup.value.redirectLink,
      bannerDetailUrl: this.readUrlBannerDetail,
      detail: this.formGroup.value.detail,
      title: this.formGroup.value.title,
      isAirport: !!this.formGroup.value.isAirport,
      categoriesIdJoin01:
        this.selectedCategories.length && this.isCategoryJoin
          ? this.selectedCategories[0].id
          : null,
      categoriesIdJoin02:
        this.selectedCategories.length && this.isCategoryJoin
          ? this.selectedCategories[1].id
          : null,
      isActive: !!this.formGroup.value.isActive,
      conciergeLink: this.formGroup.value.conciergeLink,
      isParking: !!this.formGroup.value.isParking,
      isDonation: !!this.formGroup.value.isDonation,
      storeId: this.selectedStores.length ? this.selectedStores[0].id : undefined,
    }

    if (this.categoriesID) {
      this.categoriesService
        .updateCategories(data, this.categoriesID)
        .toPromise()
        .then(
          async response => {
            if (response.isUpdated) {
              await this.router.navigate(['./dashboard/categories'])
              this.snackBar.open('Categoria atualizada com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    } else {
      this.categoriesService
        .addCategories(data)
        .toPromise()
        .then(
          async response => {
            if (response.isCreated) {
              await this.router.navigate(['./dashboard/categories'])
              this.snackBar.open('Categoria criado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    }
  }
}
