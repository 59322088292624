import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Airports } from '../models/Airports'
import { AnimationPlayer } from '@angular/animations'

@Injectable({
  providedIn: 'root',
})
export class AirportsService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async list (): Promise<{ results: Airports[] }> {
    return this.http.get<{ results: Airports[] }>(`${environment.apiUrl}/cms/airports`).toPromise()
  }

  async getS3Url (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/airports/s3-slot`).toPromise()
  }

  async uploadToS3 (pathAwsUpload: string, data): Promise<void> {
    await this.http.put<any>(`${pathAwsUpload}`, data).toPromise()
  }

  async createAirport (airport: Partial<Airports>): Promise<{ isCreated: boolean, id: string }> {
    return this.http
      .post<{ isCreated: boolean, id: string }>(`${environment.apiUrl}/cms/airport`, airport)
      .toPromise()
  }

  async findOne (airportId: string): Promise<Airports> {
    return this.http.get<Airports>(`${environment.apiUrl}/cms/airport/${airportId}`).toPromise()
  }

  async updateAirport (
    airportId: string,
    airport: Partial<Airports>,
  ): Promise<{ isUpdated: boolean, id: string }> {
    return this.http
      .put<{ isUpdated: boolean, id: string }>(
        `${environment.apiUrl}/cms/airport/${airportId}`,
        airport,
    )
      .toPromise()
  }

  async deleteAirport (airportId: string): Promise<{ isDeleted: boolean, id: string }> {
    return this.http
      .delete<{ isDeleted: boolean, id: string }>(`${environment.apiUrl}/cms/airport/${airportId}`)
      .toPromise()
  }

  async listInformations (informationId: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/airport/${informationId}/information`)
      .toPromise()
  }

  async createInformation (informationId: string, body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/airport/${informationId}/information`, body)
      .toPromise()
  }

  async updateInformation (airportId: string, informationId: string, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/airport/${airportId}/information/${informationId}`, body)
      .toPromise()
  }

  async informationFindOne (informationId: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/airport/information/${informationId}`)
      .toPromise()
  }

  async deleteInformation (informationId: string) {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/airport/information/${informationId}`)
      .toPromise()
  }

  async listInfoFields (informationId: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/airport/information/${informationId}/fields`)
      .toPromise()
  }

  async deleteField (infoFieldId) {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/airport/information/field/${infoFieldId}`)
      .toPromise()
  }

  async createInfoField (informationId: string, body) {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/airport/information/${informationId}/fields`, body)
      .toPromise()
  }

  async infoFieldFindOne (informationId: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/airport/information/fields/${informationId}`)
      .toPromise()
  }

  async updateInfoField (informationId: string, fieldId: string, body): Promise<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}/cms/airport/information/${informationId}/field/${fieldId}`,
        body,
    )
      .toPromise()
  }

  async listCompanyAirport (filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/company-airport${filter || ''}`)
      .toPromise()
  }

  async preRegisterCompaniesAirport (): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration-companies-airports`)
      .toPromise()
  }

  async createAirportCompany (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/company-airport`, body)
      .toPromise()
  }

  async createPreRegisterAirportCompany (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/pre-registration-company-airport`, body)
      .toPromise()
  }

  async fideOneCompanyAirport (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/company-airport/${id}`)
      .toPromise()
  }

  async fideOneCompanyAirportPreRegister (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration-company-airport/${id}`)
      .toPromise()
  }

  async updateAirportCompany (id, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/company-airport/${id}`, body)
      .toPromise()
  }

  async updatePreRegisterAirportCompany (id, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/pre-registration-company-airport/${id}`, body)
      .toPromise()
  }

  async deleteAirportCompany (id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/company-airport/${id}`)
      .toPromise()
  }

  async deletePreRegisterAirportCompany (id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/pre-registration-company-airport/${id}`)
      .toPromise()
  }

  async listCompanyAirportCustomers (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/company-airport/${id}/customers`)
      .toPromise()
  }

  async listCompanyAirportCustomersDelete (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/company-airport/${id}/customers/delete`)
      .toPromise()
  }

  async createAirportCompanyCustomer (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/company-airport-customer`, body)
      .toPromise()
  }

  async deleteAirportCompanyCustomer (id, filter?): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/company-airport-customer/${id}${filter}`)
      .toPromise()
  }

  async listCompanyAirportHistoric (cnpj): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/company-airport-stores/${cnpj}`)
      .toPromise()
  }
}
