import { Component, OnInit, ViewChild } from '@angular/core'
import { Transaction } from 'src/app/models/transaction'
import { TransactionService } from 'src/app/services/transaction.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { CurrencyPipe, Location } from '@angular/common'
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component'
import { PageEvent } from '@angular/material/paginator'
import {
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material'
import { StoreService } from 'src/app/services/store.service'
import { Store } from 'src/app/models/store'
import { ModalChooseTransactionTypeComponent } from 'src/app/components/modal-choose-transaction-type/modal-choose-transaction-type.component'
import { UtilService } from 'src/app/services/util.service'
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { ModalRoadpassUnableToCancel } from 'src/app/components/modal-roadpass-unable-to-cancel/modal-roadpass-unable-to-cancel.component'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-store-list',
  templateUrl: './store-transaction-list.component.html',
  styleUrls: ['./store-transaction-list.component.scss'],
})
export class StoreTransactionListComponent implements OnInit {
  public formGroup: any
  public headersTable: string[]
  public headersTableReserve: string[]
  public listStatus: any[] = []
  public listStatusPaymentDefault: any[] = []
  public transactions: Transaction[] = []
  public isAdmin = false
  public userRole
  public store: Store
  public storeID = ''
  public status: string
  public joinFilter: string
  public listStore: any[] = []
  filters: any[] = []
  filtered: any[] = []
  dataSource: any
  dataSourceReserve: any
  fieldsForm: any
  stores: any[] = []
  listStatusDefault: any[] = []
  futurePaymentEnabled = false
  count = 0

  public pageTotal: number
  public pageTotalReserve: number
  public params: Params
  previousRoute: string
  storeFormGroupValue: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  pageReserve = 1
  pageSizeReserve = 5
  pageSizeOptionsReserve: number[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]

  pageEvent: PageEvent

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor (
    public transactionService: TransactionService,
    public storeService: StoreService,
    private readonly route: ActivatedRoute,
    public router: Router,
    public currencyPipe: CurrencyPipe,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location,
    public utilService: UtilService,
  ) { }

  ngOnInit () {
    if (this.route.snapshot.paramMap.get('storeID')) {
      this.storeID = this.route.snapshot.paramMap.get('storeID')
    }

    this.route.queryParams.subscribe((params) => {
      this.previousRoute = params.previousRoute
      this.storeFormGroupValue = params.storeFormGroupValue
      if (!this.storeID) {
        this.storeID = params.storeID
      }
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
        this.params = {
          formGroupValue: params.formGroupValue,
        }
        this.initForm()
        this.transactionsFutureFilter()
      } else {
        this.initForm()
        this.listTransactions()
      }
      this.params = {
        ...this.params,
        storeID: this.storeID,
        previousRoute: this.previousRoute,
        storeFormGroupValue: this.storeFormGroupValue,
      }
    })

    this.userRole = localStorage.getItem('userRole')
    this.isAdmin = this.userRole == 'ADMIN'

    if (this.userRole === 'ADMIN') {
      this.headersTable = [
        'orderNumber',
        'storeType',
        'storeName',
        'fundName',
        'fullName',
        'customerCpf',
        'description',
        'createdAt',
        'totalValue',
        'state',
        'actions',
      ]
    } else {
      this.headersTable = [
        'orderNumber',
        'fullName',
        'customerCpf',
        'description',
        'createdAt',
        'totalValue',
        'state',
        'actions',
      ]
    }
    if (!this.storeID) {
      this.storeID = ''
    }
    this.storeService.getStore(this.storeID).subscribe(async response => {
      this.store = response
      this.futurePaymentEnabled = response.futurePaymentEnabled
      response.map(item => {
        this.listStore.push({ key: item.nomeFantasia, value: item.nomeFantasia })
      })
    })

    this.transactionService.getStatus().subscribe(async response => {
      Object.keys(response).map(k => {
        this.listStatusDefault = response
        this.listStatus.push({ key: k, value: response[k] })
      })
      this.listStatus.push({ key: 'pendingJHSF', value: 'AGUARDANDO APROVAÇÃO' })
      this.listStatus.sort((a, b) => {
        return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
      })
    })

    this.transactionService.getPaymentsStatus().subscribe(response => {
      Object.keys(response).map(k => {
        this.listStatusPaymentDefault = response
      })
    })
  }

  initForm () {
    if (this.userRole === 'ADMIN' && this.storeID) {
      this.fieldsForm = [
        {
          field: 'input',
          type: 'number',
          formControlName: 'numberTransaction',
          label: 'Número da Transação',
          placeholder: 'Número da Transação',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'cpfTransaction',
          label: 'CPF do Cliente',
          placeholder: 'CPF do Cliente',
        },
        {
          field: 'input',
          type: 'date',
          formControlName: 'dateTransaction',
        },
        {
          field: 'select',
          formControlName: 'status',
          label: 'Status',
          placeholder: 'Status',
          list: this.listStatus,
          optionDefault: 'TODOS',
        },
        {
          field: 'select',
          formControlName: 'typeTransaction',
          label: 'Tipo',
          placeholder: 'Tipo',
          list: [
            { key: 'FUNDO', value: 'FUNDO' },
            { key: 'MEMBERSHIP', value: 'MEMBERSHIP' },
          ],
          optionDefault: 'TODOS',
        },
      ]
    } else {
      if (!this.formGroup) {
        this.formGroup = {
          typeTransaction: ['APP'],
          transactionsTest: ['false'],
        }
      }

      this.fieldsForm = [
        {
          field: 'input',
          type: 'number',
          formControlName: 'numberTransaction',
          label: 'Número da Transação',
          placeholder: 'Número da Transação',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'cpfTransaction',
          label: 'CPF do Cliente',
          placeholder: 'CPF do Cliente',
        },
        {
          field: 'input',
          type: 'date',
          formControlName: 'dateTransaction',
        },
        {
          field: 'select',
          formControlName: 'status',
          label: 'Status',
          placeholder: 'Status',
          list: this.listStatus,
          optionDefault: 'TODOS',
        },
        {
          field: 'select',
          formControlName: 'typeTransaction',
          label: 'Tipo',
          placeholder: 'Tipo',
          list: [
            { key: 'APP', value: 'APP' },
            { key: 'CARTÃO FÍSICO', value: 'CARTÃO FÍSICO' },
            { key: 'FUNDO', value: 'FUNDO' },
            { key: 'MEMBERSHIP', value: 'MEMBERSHIP' },
            { key: 'TRANSFERÊNCIA C2C', value: 'TRANSFERÊNCIA C2C' },
          ],
          optionDefault: 'TODOS',
        },
        {
          field: 'select',
          formControlName: 'transactionsTest',
          label: 'Empresas Teste',
          placeholder: 'Empresas Teste',
          list: [
            { key: 'true', value: 'Sim' },
            { key: 'false', value: 'Não' },
          ],
        },
      ]
    }
  }

  transactionsFuture () {
    this.transactionsFutureFilter()
    let filter = ''
    if (this.storeID) {
      filter = `?filters={"page": ${this.pageReserve}, "pageSize":${this.pageSizeReserve},"filters":[{"fields":["store.id"], "op": "=", "value":"${this.storeID}"}]}`
    } else {
      filter = `?filters={"page": ${this.pageReserve}, "pageSize": ${this.pageSizeReserve}}`
    }

    this.transactionService.listTransactionsReservation(filter).subscribe(
      response => {
        this.pageTotalReserve = response.total
        this.dataSourceReserve = new MatTableDataSource(response.results)
      },
      error => {
        alert(error.error.message)
      },
    )
  }

  transactionsFutureFilter () {
    this.futurePaymentEnabled = true
    this.headersTableReserve = [
      'transactionOrderNumber',
      'nomeFantasia',
      'nameCustomer',
      'cpf',
      'descriptionTransaction',
      'paymentDate',
      'totalValueAnticipated',
      'totalValue',
      'state',
      'actions',
    ]
  }

  listTransactions () {
    if (this.storeID) {
      this.storeService
        .getStore(this.storeID)
        .toPromise()
        .then(response => {
          this.futurePaymentEnabled = response.futurePaymentEnabled
          if (response.futurePaymentEnabled) {
            this.transactionsFuture()
          }
        })
        .catch(err => console.log(err))
      // ?state=PENDING&rewardsState=FUTURE
      const storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
      if (storeSelected && this.userRole != 'ADMIN') {
        this.storeID = storeSelected.storeId
      }
      this.transactionService
        .listTransactions(
          `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["storeId"], "op": "=", "value":"${this.storeID}"}]}`,
        )
        .subscribe(
          async response => {
            (response.results ? response.results : response).map(item => {
              // item.createdAt = item.createdAt.slice(0, 10)
              delete item.updatedAt
              delete item.paidAt
            })
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(response.results)
          },
          error => {
            console.log(error)
            alert(error.error.message)
          },
        )
    } else {
      this.transactionService
        .listTransactions(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
        .subscribe(
          // '?state=PENDING&rewardsState=FUTURE'
          async response => {
            (response.results ? response.results : response).map(item => {
              // item.createdAt = item.createdAt.slice(0, 10)
              delete item.updatedAt
              delete item.paidAt
            })
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(response.results)
          },
          error => {
            console.log(error)
            alert(error.error.message)
          },
        )

      this.storeService.listStores().subscribe(
        async response => {
          this.stores = response
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
      this.transactionsFuture()
    }
  }

  view (transaction) {
    if (transaction.isManual) {
      localStorage.setItem('isManual', 'true')
    } else {
      localStorage.setItem('isManual', 'false')
    }
  }

  openDialogCancelTransaction (transaction): void {
    const dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
      width: '500px',
      data: {
        transaction: transaction,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.cancel(transaction.id, result, transaction.type)
      }
    })
  }

  onAddTransactionClick () {
    if (this.store.futurePaymentEnabled) {
      this.count = this.count + 1
    }
    if (this.store.manualPaymentEnabled) {
      this.count = this.count + 1
    }
    if (this.store.isMembershipActive) {
      this.count = this.count + 1
    }

    if (this.count > 1) {
      this.openDialogTransactionType().catch(err => console.log(err))
    } else if (this.store.futurePaymentEnabled) {
      this.goToPaymentAnticipatedTransaction()
    } else if (this.store.manualPaymentEnabled) {
      this.goToNormalTransaction()
    } else if (this.store.isMembershipActive) {
      this.goToNormalTransaction()
    }
  }

  async openDialogTransactionType () {
    const dialogRef = this.dialog.open(ModalChooseTransactionTypeComponent, {
      width: '500px',
      data: {
        futurePaymentEnabled: this.futurePaymentEnabled,
        manualPaymentEnabled: this.store.manualPaymentEnabled,
        isMembershipActive: this.store.isMembershipActive,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'normal') {
        this.goToNormalTransaction()
      } else if (result == 'manual') {
        this.goToManualTransaction()
      } else if (result == 'paymentAnticipated') {
        this.goToPaymentAnticipatedTransaction()
      }
    })
  }

  goBack () {
    if (this.previousRoute === 'store-list') {
      this.router.navigate(['/dashboard', 'stores'], { queryParams: this.params, skipLocationChange: true })
        .catch((err) => console.log(err))
    } else {
      this.router.navigate(['/dashboard', 'home'])
        .catch((err) => console.log(err))
    }
  }

  goToNormalTransaction () {
    this.router.navigate(['dashboard', 'stores', this.storeID, 'transactions', 'new'], { queryParams: this.params, skipLocationChange: true })
  }

  goToManualTransaction () {
    this.router.navigate(['dashboard', 'stores', this.storeID, 'transactions', 'new-manual'], { queryParams: this.params, skipLocationChange: true })
  }

  goToPaymentAnticipatedTransaction () {
    const reservation = 'paymentAnticipated'
    this.router.navigate(['dashboard', 'stores', this.storeID, 'transactions', 'payment-anticipated'], { queryParams: this.params, skipLocationChange: true })
  }

  openModalWarningUnableToCancel (): Promise<void> {
    const dialogRef = this.dialog.open(ModalRoadpassUnableToCancel, {
      width: '500px',
    })

    return dialogRef.afterClosed().toPromise()
  }

  cancel (transactionID, reason, transactionType) {
    const data = {
      reason: reason,
    }
    let cancelObservable: Observable<any>
    if (transactionType && transactionType.toLowerCase() === 'c2c') { cancelObservable = this.transactionService.cancelTransactionC2C(transactionID, data) } else { cancelObservable = this.transactionService.cancelTransaction(transactionID, data) }

    cancelObservable.subscribe(
      async response => {
        if (response && response.roadPassUnableToCancel) { await this.openModalWarningUnableToCancel() }

        this.snackBar.open('Transação cancelada com sucesso.')
        this.listTransactions()
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertStatus (transaction) {
    switch (transaction.state) {
      case 'PENDING':
        return transaction.isManual ? 'Aguardando aprovação da JHSF' : 'Pendente'
        break
      case 'APPROVED':
        return 'Aprovado'
        break
      case 'REJECTED':
        return 'Rejeitado'
        break
      case 'CANCELED':
        return 'Cancelado'
        break
      default:
        return ''
    }
  }

  convertStatusTransactions (transaction) {
    if (transaction.isManual && transaction.state == 'PENDING') {
      return 'AGUARDANDO APROVAÇÃO DA JHSF'
    } else {
      return this.listStatusDefault[transaction.state]
    }
  }

  convertStatusPayments (payment) {
    if (payment.isManual && payment.state == 'PENDING') {
      return 'AGUARDANDO APROVAÇÃO DA JHSF'
    } else {
      return this.listStatusPaymentDefault[payment.state]
    }
  }

  convertCentsToReal (value) {
    return value / 100
  }

  detail (transacationId, fullName, customerCpf) {
    if (this.userRole === 'ADMIN') {
      this.transactionService
        .listTransactionsById(`${transacationId}?showManualDetails=true`)
        .subscribe(
          response => {
            const dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
              data: {
                customerBalance: response.customerBalance,
                customerCpf: response.customerCpf,
                customerFullName: `${response.customerFirstName} ${response.customerLastName}`,
                customerBirthDate: response.customerBirthDate,
                customerEmailAddress: response.customerEmailAddress,
                customerPhoneNumber: response.customerPhoneNumber,
                customerGender: response.customerGender,
                customerId: response.customerId,
                user: this.userRole,
              },
            })
          },
          error => {
            console.log(error)
            this.snackBar.open(error.error.message)
          },
        )
    } else {
      const dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
        data: {
          customerCpf: customerCpf,
          customerFullName: fullName,
          user: this.userRole,
        },
      })
    }
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      storeID: this.storeID,
      formGroupValue: JSON.stringify(this.formGroup),
      storeFormGroupValue: this.storeFormGroupValue,
      previousRoute: this.previousRoute,
    }
  }

  reciverFeedbackReserve (returnFilterReserve) {
    this.pageTotalReserve = returnFilterReserve.total
    this.dataSourceReserve = new MatTableDataSource(returnFilterReserve.results)
  }

  openDialogSendNotification (e) {
    const { id, customerCpf, totalValue } = e

    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '400px',
      data: {
        text: 'Deseja reenviar essa transação para o cliente ?',
        buttonConfirmText: 'Sim',
      },
    })
    dialogRef
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result != 'cancel') {
          const data = {
            totalValue,
            customerCpf,
          }
          await this.transactionService.transactionSendEvent(id, data).then(
            async response => {
              this.snackBar.open('Notificação enviada com sucesso.')
              this.listTransactions()
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(error => this.snackBar.open(error.error.message))
  }
}
