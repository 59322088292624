import { PrepaymentsService } from 'src/app/services/prepayments.service'
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar, MatTableDataSource } from '@angular/material'
import { startWith, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { CompaniesService } from '../../../../../services/companies.service'
import { ReportService } from '../../../../../services/report.service'
import { PreRegistrationService } from 'src/app/services/preRegistration.service'

@Component({
  selector: 'app-architect-company-customer-form',
  templateUrl: './architect-company-customer-form.component.html',
  styleUrls: ['./architect-company-customer-form.component.scss'],
})
export class ArchitectsCompanyCustomerFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  company: any
  totalCustomers: any
  customer1: any
  filteredCustomers1: Observable<string[]>
  customers: any[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]
  cpf: any
  firstName1: any
  hideCustomer1 = true
  hideEdit1 = true
  addCustomer1 = true
  showFirstName1 = false
  percentage1: any
  totalPercentage = 100
  public selectedCustomers1: any[] = []
  public customerControl1 = new FormControl()

  selectedData: any[] = []

  public headersTable: string[] = ['cpf', 'comissionFee', 'fullName', 'actions']
  dataSource: any
  dataSourceDelete: any

  @ViewChild('customerInput1', { static: false }) customerInput1: ElementRef<HTMLInputElement>
  @ViewChild('auto1', { static: false }) matAutocomplete1: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public reportService: ReportService,
    public preRegistrationService: PreRegistrationService,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      cpf: [null, []],
      percentage: [null, []],
    })

    this.preRegistrationService.listArchitects('').then(
      async response => {
        response = response.results
        response.map(item => {
          this.customers.push({
            cpf: item.preRegistration.cpf,
            name: `${item.preRegistration.firstName} ${item.preRegistration.lastName}`,
          })
        })
      },
      error => {
        console.log(error)
      },
    )

    this.reportService.listCustomersArchitects().subscribe(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers1 = this.customerControl1.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  addCpf (event) {
    event.preventDefault()
    const remaining = Number(this.totalPercentage) - Number(this.formGroup.value.percentage)
    if (this.formGroup.value.percentage && this.cpf && remaining >= 0) {
      this.selectedData.push(
        {
          cpf: this.cpf,
          comissionFee: Number(this.formGroup.value.percentage),
        },
      )
      this.dataSource = new MatTableDataSource(this.selectedData)
      this.totalPercentage =
        Number(this.totalPercentage) - Number(this.formGroup.value.percentage)
      this.formGroup.get('percentage').setValue(null)
      this.customerInput1.nativeElement.value = ''
      this.customerControl1.setValue(null)
      this.cpf = ''
      this.selectedCustomers1 = []
    }
  }

  remove (cpf) {
    const index = this.selectedData.indexOf(cpf)
    if (index >= 0) {
      this.selectedData.splice(index, 1)
      this.totalPercentage = 100
      for (const d of this.selectedData) {
        this.totalPercentage = Number(this.totalPercentage) - Number(d.comissionFee)
      }
      this.dataSource = new MatTableDataSource(this.selectedData)
    }
  }

  private _filter (customer: string): string[] {
    if (!this.selectedCustomers1.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers1.includes(c))
        .filter(c =>
          `${String(c.name)} ${String(c.cpf)}`.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }
  }

  removeCustomer (customer: any, input): void {
    if (input === 1) {
      const index = this.selectedCustomers1.indexOf(customer)
      if (index >= 0) {
        this.selectedCustomers1.splice(index, 1)
      }
      this.cpf = undefined
      this.hideCustomer1 = true
    }
  }

  selected (event: MatAutocompleteSelectedEvent, input): void {
    if (!this.selectedCustomers1.length && input === 1) {
      this.selectedCustomers1.push(event.option.value)
      this.customerInput1.nativeElement.value = ''
      this.customerControl1.setValue(null)
      this.cpf = this.selectedCustomers1[0].cpf
      this.hideCustomer1 = false
    }
  }

  async findOne () {
    await this.companiesService
      .findOne(this.id)
      .then(async response => {
        this.company = response
        this.totalPercentage = 100
        for (const c of response.customers) {
          this.selectedData.push({
            cpf: c.customerCpf,
            comissionFee: c.comissionFee,
            fullName: `${c.firstName} ${c.lastName}`,
          })
          this.totalPercentage =
            Number(this.totalPercentage) - Number(c.comissionFee)
        }
        this.dataSource = new MatTableDataSource(this.selectedData)
      })
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
  }

  valueInputCpf (e, input) {
    if (input === 1 && this.selectedCustomers1.length === 0) {
      this.cpf = e.target.value
    }
  }

  async submit () {
    let total = 0
    for (const d of this.selectedData) {
      total = total + Number(d.comissionFee)
    }
    if (this.totalPercentage !== 0 && total !== 100) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    this.selectedData.map(cs => delete cs.fullName)
    this.companiesService.update(this.id, { customers: this.selectedData }).then(
      async response => {
        if (response) {
          this.snackBar.open('Cliente cadastrado com sucesso.')
          this.router.navigate(['./dashboard/architect-companies'])
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
