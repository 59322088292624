<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Categoria</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/categories">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome da Categoria</mat-label>
              <input matInput formControlName="heading" />
              <mat-error *ngIf="formGroup.controls.heading?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.heading) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Título</mat-label>
              <input matInput formControlName="title" />
              <mat-error *ngIf="formGroup.controls.title?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.title) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Posição / Ordem</mat-label>
              <input type="number" matInput formControlName="order" />
              <mat-error *ngIf="formGroup.controls.order?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.order) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Ícone da Home</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputMenuIconUrl.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <img *ngIf="menuIconUrl != ''" width="200" src="{{ menuIconUrl }}" />
              <input
                hidden
                (change)="selectFile($event, 'menuIconUrl')"
                #fileInputMenuIconUrl
                type="file"
                id="file"
              />
              <mat-error *ngIf="formGroup.controls.menuIconUrl?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.menuIconUrl) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Banner do parceiro</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputPartnerBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedPartnerBannerImage"
                matSuffix
                mat-icon-button
                (click)="editPartnerBannerImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedPartnerBannerImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('partnerBanner')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedPartnerBannerImage != ''"
                width="590"
                src="{{ croppedPartnerBannerImage }}"
              />
              <input
                hidden
                (change)="selectFilePartnerBanner($event)"
                #fileInputPartnerBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Banner link</mat-label>
              <input matInput formControlName="bannerLink" />
              <mat-error *ngIf="formGroup.controls.bannerLink?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bannerLink) }}
              </mat-error>
            </mat-form-field>
<!--
            <mat-form-field>
              <mat-label>flash sales</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputFlashSales.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedFlashSalesImage"
                matSuffix
                mat-icon-button
                (click)="editFlashSalesImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedFlashSalesImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('flashSales')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedFlashSalesImage != ''"
                width="590"
                src="{{ croppedFlashSalesImage }}"
              />
              <input
                hidden
                (change)="selectFileFlashSales($event)"
                #fileInputFlashSales
                type="file"
                accept="image/*"
              />
            </mat-form-field> -->
<!--
            <mat-form-field>
              <mat-label>flash sales link</mat-label>
              <input matInput formControlName="flashSalesLink" />
              <mat-error *ngIf="formGroup.controls.flashSalesLink?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.flashSalesLink) }}
              </mat-error>
            </mat-form-field> -->
            <!-- descomenta aqui -->
            <!-- <mat-form-field>
              <mat-label>Banner Exclusivo</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputBannerExclusive.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerExclusiveImage"
                matSuffix
                mat-icon-button
                (click)="editBannerExclusiveImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerExclusiveImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('bannerExclusive')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedBannerExclusiveImage != ''"
                width="590"
                src="{{ croppedBannerExclusiveImage }}"
              />
              <input
                hidden
                (change)="selectFileBannerExclusive($event)"
                #fileInputBannerExclusive
                type="file"
                accept="image/*"
              />
            </mat-form-field> -->

            <!-- <mat-form-field>
              <mat-label>Banner Concierge</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputConciergeBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerConciergeImage"
                matSuffix
                mat-icon-button
                (click)="editConciergeBannerImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerConciergeImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('conciergeBanner')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedBannerConciergeImage != ''"
                width="590"
                src="{{ croppedBannerConciergeImage }}"
              />
              <input
                hidden
                (change)="selectFileConciergeBanner($event)"
                #fileInputConciergeBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field>
            <mat-error *ngIf="conciergeInvalid">Campo deve ser preenchido</mat-error>

            <mat-form-field>
              <mat-label>Link Concierge</mat-label>
              <input matInput formControlName="conciergeLink" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Banner de Redirecionamento</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputRedirectBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerRedirectImage"
                matSuffix
                mat-icon-button
                (click)="editRedirectBannerImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerRedirectImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('redirectBanner')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedBannerRedirectImage != ''"
                width="590"
                src="{{ croppedBannerRedirectImage }}"
              />
              <input
                hidden
                (change)="selectFileRedirectBanner($event)"
                #fileInputRedirectBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Link do Banner de Redirecionamento</mat-label>
              <input matInput formControlName="redirectLink" />
              <mat-error *ngIf="formGroup.controls.redirectLink?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.redirectLink) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Banner com Detalhes</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputDetailBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerDetailImage"
                matSuffix
                mat-icon-button
                (click)="editDetailBannerImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedBannerDetailImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('detailBanner')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedBannerDetailImage != ''"
                width="590"
                src="{{ croppedBannerDetailImage }}"
              />
              <input
                hidden
                (change)="selectFileDetailBanner($event)"
                #fileInputDetailBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field> -->

            <ckeditor
              *ngIf="detailIsTrue"
              [config]="config"
              [editor]="Editor"
              formControlName="detail"
              (change)="change($event)"
              (ready)="onReady($event)"
            ></ckeditor>

            <mat-form-field class="example-chip-list">
              <mat-label>Parceiro</mat-label>
              <mat-chip-list #chipListStore>
                <mat-chip
                  *ngFor="let store of selectedStores"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="remove(store)"
                >
                  {{ store.nomeFantasia }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  matInput
                  #storeInput
                  [formControl]="storeControl"
                  formControlName="storesInput"
                  [matAutocomplete]="autoStore"
                  [matChipInputFor]="chipListStore"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoStore="matAutocomplete"
                (optionSelected)="selectedStore($event)"
              >
                <mat-option *ngFor="let store of filteredStores | async" [value]="store">
                  {{ store.nomeFantasia }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </mat-card-content>

          <div class="check">
            <input
              formControlName="isActive"
              type="checkbox"
              (change)="checkCategoryJoin($event)"
            />
            <mat-label> &nbsp;Ativo</mat-label>
          </div>

          <div class="check">
            <input formControlName="showMain" type="checkbox" />
            <mat-label> &nbsp;Exibir Principais Parceiros</mat-label>
          </div>

          <div class="check">
            <input formControlName="hasSubItem" type="checkbox" />
            <mat-label> &nbsp;Subcategoria</mat-label>
          </div>
          <!-- descomenta aqui -->
          <!-- <div class="check">
            <input formControlName="isBannerExclusive" type="checkbox" />
            <mat-label> &nbsp;Exibir Banner Exclusivo</mat-label>
          </div> -->

          <!-- <div class="check">
            <input formControlName="isBannerConcierge" type="checkbox" />
            <mat-label> &nbsp;Exibir Banner Concierge</mat-label>
          </div> -->
          <div class="check">
            <input formControlName="isAirport" type="checkbox" />
            <mat-label> &nbsp;Exibir Aeroporto</mat-label>
          </div>

          <div class="check">
            <input
              formControlName="isCategoryJoin"
              type="checkbox"
              (change)="checkCategoryJoin($event)"
            />
            <mat-label> &nbsp;Unificar Categorias</mat-label>
          </div>

          <div class="check">
            <input formControlName="isParking" type="checkbox" />
            <mat-label> &nbsp;Estacionamento</mat-label>
          </div>

          <div class="check">
            <input formControlName="isDonation" type="checkbox" />
            <mat-label> &nbsp;Doação</mat-label>
          </div>

          <mat-form-field *ngIf="isCategoryJoin" style="padding-top: 25px">
            <mat-label>Escolher Categorias para Unificar</mat-label>
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let category of selectedCategories"
                [selectable]="false"
                [removable]="true"
                (removed)="removeCategories(category)"
              >
                {{ category.heading }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                matInput
                #categoriesInput
                [formControl]="categoriesControl"
                formControlName="categoryInput"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
                {{ category.heading }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="categoryInputInvalid"
            >Campo deve ser preenchido</mat-error
          >
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
