<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Consulta Base</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="report()">EXPORTAR</button>
    </div>
  </header>
  <app-form-filter
    maskExample="000.000.000-00"
    actions="form"
    type="integration"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ element.cnpj | mask: '00.000.000/0000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Nome Fansatasia</th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.cpf | mask: '000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="cpfBeneficiary">
        <th mat-header-cell *matHeaderCellDef>CPF do Beneficiário</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cpfBeneficiary | mask: '000.000.000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nameBeneficiary">
        <th mat-header-cell *matHeaderCellDef>Nome do Beneficiário</th>
        <td mat-cell *matCellDef="let element">{{ element.nameBeneficiary }}</td>
      </ng-container>

      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor Pago</th>
        <td mat-cell *matCellDef="let element">{{ element.totalValue | currency: 'BRL':'R$' }}</td>
      </ng-container>

      <ng-container matColumnDef="totalPoints">
        <th mat-header-cell *matHeaderCellDef>Quantidade de Jcoins</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          {{ element.totalPoints }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef>% Jcoins</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            tax: element.tax > '0.05'
          }"
        >
          {{ element.tax | mask: 'separator.4' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCreatedAt">
        <th mat-header-cell *matHeaderCellDef>Data da Integração</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          {{ element.createdAt | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCredit">
        <th mat-header-cell *matHeaderCellDef>Data Baixa</th>
        <td mat-cell *matCellDef="let element">{{ element.dateCredit | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="isClient">
        <th mat-header-cell *matHeaderCellDef>Cliente cadastrado</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isClient" matTooltip="Cadastrado" style="padding-left: 40%"
            >check_circle_outline</mat-icon
          >
          <mat-icon *ngIf="!element.isClient" matTooltip="Não cadastrado" style="padding-left: 40%"
            >cancel</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>

    <app-form-filter
      actions="pagination"
      type="integration"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
