import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { PasswordValidator } from 'src/app/validators/password.validator'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatDialog, MatSnackBar } from '@angular/material'
import { Store } from 'src/app/models/store'
import { ConfigService } from 'src/app/services/config.service'
import { CurrencyPipe } from '@angular/common'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { UploadAdapter } from '../../../../services/uploadAdapter.service'
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-store-form',
  templateUrl: './configs-form.component.html',
  styleUrls: ['./configs-form.component.scss'],
})
export class ConfigsFormComponent implements OnInit {
  public formGroup: FormGroup
  public config: any
  public Editor = ClassicEditor
  public gatewayPayment = false

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public configService: ConfigService,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
    private readonly dialog: MatDialog,
  ) {
    this.initForm()
  }

  ngOnInit () { }

  initForm () {
    this.config = {
      image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
      },
    }

    this.formGroup = this.formBuilder.group({
      // recurrenceValue: [null, [Validators.required]],
      paymentIntentExpirationTimeInMinutes: [null, [Validators.required]],
      mainStoreId: [null, [Validators.required]],
      accountManagerPhoneNumber: [null, [Validators.required]],
      mainStoreCnpj: [null, [Validators.required]],
      accountManagerDefaultMessage: [null, [Validators.required]],
      appVersion: [null, [Validators.required]],
      isAppSelfDeleteButtonActive: [null, [Validators.required]],
      isPaymentActive: [null, [Validators.required]],
      jcoinsExpirationInDays: [null, [Validators.required]],
      rewardsAlias: [null, [Validators.required]],
      recurrencePeriod: [null, [Validators.required]],
      accountManagerEmailAddress: [null, [Validators.required]],
      // taxConversion: [null, []],
      // textWellcome: [null, []],
      titleCategory: [null, []],
      firstAccessPhoneNumber: [null, []],
      paymentZoop: [null, []],
      paymentRoadpass: [null, []],
      currentPaymentProvider: [null, [Validators.required]],
      accountManagerPhoneNumberArchitect: [null, []],
    })

    this.loadInfos()
  }

  loadInfos () {
    this.configService
      .view()
      .toPromise()
      .then(
        async response => {
          this.formGroup.patchValue({
            // recurrenceValue: response.results.recurrenceValue / 100,
            paymentIntentExpirationTimeInMinutes:
              response.results.paymentIntentExpirationTimeInMinutes,
            mainStoreId: response.results.mainStoreId,
            accountManagerPhoneNumber: response.results.accountManagerPhoneNumber,
            mainStoreCnpj: response.results.mainStoreCnpj,
            accountManagerDefaultMessage: response.results.accountManagerDefaultMessage,
            appVersion: response.results.appVersion,
            isAppSelfDeleteButtonActive: response.results.isAppSelfDeleteButtonActive,
            isPaymentActive: response.results.isPaymentActive,
            jcoinsExpirationInDays: response.results.jcoinsExpirationInDays,
            rewardsAlias: response.results.rewardsAlias,
            recurrencePeriod: response.results.recurrencePeriod,
            accountManagerEmailAddress: response.results.accountManagerEmailAddress,
            // taxConversion: response.results.taxConversion,
            // textWellcome: response.results.textWellcome,
            titleCategory: response.results.titleCategory,
            firstAccessPhoneNumber: response.results.firstAccessPhoneNumber ? response.results.firstAccessPhoneNumber : '',
            paymentZoop: response.results.paymentZoop,
            paymentRoadpass: response.results.paymentRoadpass,
            currentPaymentProvider: response.results.currentPaymentProvider,
            accountManagerPhoneNumberArchitect: response.results.accountManagerPhoneNumberArchitect,
          })
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  convertCentsToMoney (value) {
    const money = this.currencyPipe.transform(value, 'BRL')
    // this.formGroup.controls['totalValue'].setValue(money);
    console.log(money)
  }

  onChange (e) {
    if (this.formGroup.value.paymentZoop || this.formGroup.value.paymentRoadpass) {
      this.gatewayPayment = false
    }
    if (!this.formGroup.value.paymentZoop && !this.formGroup.value.paymentRoadpass) {
      this.gatewayPayment = true
    }
  }

  async submit () {
    if (!this.formGroup.value.paymentZoop && !this.formGroup.value.paymentRoadpass) {
      this.gatewayPayment = true
    }
    if (!this.formGroup.valid || this.gatewayPayment) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    const data = {
      mainStoreCnpj: this.formGroup.value.mainStoreCnpj,
      pointsExpirationTimeInDays: this.formGroup.value.pointsExpirationTimeInDays,
      appVersion: this.formGroup.value.appVersion,
      isAppSelfDeleteButtonActive: this.formGroup.value.isAppSelfDeleteButtonActive,
      accountManagerPhoneNumber: this.formGroup.value.accountManagerPhoneNumber,
      accountManagerPhoneNumberArchitect: this.formGroup.value.accountManagerPhoneNumberArchitect,
      accountManagerDefaultMessage: this.formGroup.value.accountManagerDefaultMessage,
      paymentIntentExpirationTimeInMinutes: this.formGroup.value
        .paymentIntentExpirationTimeInMinutes,
      recurrencePeriod: Number(this.formGroup.value.recurrencePeriod),
      // recurrenceValue: this.formGroup.value.recurrenceValue * 100,
      isPaymentActive: this.formGroup.value.isPaymentActive,
      jcoinsExpirationInDays: this.formGroup.value.jcoinsExpirationInDays,
      rewardsAlias: this.formGroup.value.rewardsAlias,
      accountManagerEmailAddress: this.formGroup.value.accountManagerEmailAddress,
      // taxConversion: this.formGroup.value.taxConversion,
      // textWellcome: this.formGroup.value.textWellcome,
      titleCategory: this.formGroup.value.titleCategory,
      firstAccessPhoneNumber: this.formGroup.value.firstAccessPhoneNumber,
      paymentZoop: !!this.formGroup.value.paymentZoop,
      paymentRoadpass: !!this.formGroup.value.paymentRoadpass,
      currentPaymentProvider: this.formGroup.value.currentPaymentProvider || undefined,
    }

    this.configService.update(data).then(
      async response => {
        if (response.isUpdated) {
          this.snackBar.open('Configurações atualizadas com sucesso.')
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  async resetCustomersSessions () {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja realmente reiniciar as sessões dos usuários no APP? Isso fará com que '
            + 'os usuários ativos no app sejam forçados a fazer o login novamente.',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          const res = await this.configService.resetAccessTokens()
          if (res.success) {
            this.snackBar.open('Sessões reiniciadas com sucesso.')
          } else {
            this.snackBar.open('Falha ao reiniciar sessão o usuário.')
          }
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
