import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var AirportCompanyHistoricStoresComponent = /** @class */ (function () {
    function AirportCompanyHistoricStoresComponent(airportsService, snackBar, route) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.route = route;
        this.headersTable = ['cnpjStore', 'nomeFantasia', 'balance', 'createdAt'];
    }
    AirportCompanyHistoricStoresComponent.prototype.ngOnInit = function () {
        this.cnpj = this.route.snapshot.paramMap.get('cnpj');
        this.id = this.route.snapshot.paramMap.get('id');
        this.list(this.cnpj).catch(function (err) { console.log(err); });
        if (this.id)
            this.findOne().catch(function (err) { console.log(err); });
    };
    AirportCompanyHistoricStoresComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.fideOneCompanyAirport(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.company = response;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyHistoricStoresComponent.prototype.list = function (cnpj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService
                            .listCompanyAirportHistoric(cnpj)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        results = (_a.sent()).results;
                        this.dataSource = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    return AirportCompanyHistoricStoresComponent;
}());
export { AirportCompanyHistoricStoresComponent };
