<app-logged class="store-list">
  <header class="store-list__header">
    <h2>USUÁRIOS</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/users/new">ADICIONAR</button>
    </div>
  </header>
  <div class="row">
    <div class="col-3 col-sm-3">
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value, '')" placeholder="Nome">
      </mat-form-field>
    </div>

    <div class="col-3 col-sm-3">
      <mat-form-field>
        <mat-label>Telefone</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value, '')" placeholder="Telefone">
      </mat-form-field>
    </div>

    <div class="col-3 col-sm-3">
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value, '')" placeholder="E-mail">
      </mat-form-field>
    </div>


  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
            routerLink="/dashboard/users/{{element.id}}/edit">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <mat-paginator [pageSizeOptions]="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" showFirstLastButtons></mat-paginator>
  </div>
</app-logged>