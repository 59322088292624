import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component';
import { MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
import { ModalChooseTransactionTypeComponent } from 'src/app/components/modal-choose-transaction-type/modal-choose-transaction-type.component';
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import { ModalRoadpassUnableToCancel } from 'src/app/components/modal-roadpass-unable-to-cancel/modal-roadpass-unable-to-cancel.component';
var StoreTransactionListComponent = /** @class */ (function () {
    function StoreTransactionListComponent(transactionService, storeService, route, router, currencyPipe, snackBar, dialog, location, utilService) {
        this.transactionService = transactionService;
        this.storeService = storeService;
        this.route = route;
        this.router = router;
        this.currencyPipe = currencyPipe;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.utilService = utilService;
        this.listStatus = [];
        this.listStatusPaymentDefault = [];
        this.transactions = [];
        this.isAdmin = false;
        this.storeID = '';
        this.listStore = [];
        this.filters = [];
        this.filtered = [];
        this.stores = [];
        this.listStatusDefault = [];
        this.futurePaymentEnabled = false;
        this.count = 0;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.pageReserve = 1;
        this.pageSizeReserve = 5;
        this.pageSizeOptionsReserve = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
    }
    StoreTransactionListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route.snapshot.paramMap.get('storeID')) {
            this.storeID = this.route.snapshot.paramMap.get('storeID');
        }
        this.route.queryParams.subscribe(function (params) {
            _this.previousRoute = params.previousRoute;
            _this.storeFormGroupValue = params.storeFormGroupValue;
            if (!_this.storeID) {
                _this.storeID = params.storeID;
            }
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
                _this.params = {
                    formGroupValue: params.formGroupValue,
                };
                _this.initForm();
                _this.transactionsFutureFilter();
            }
            else {
                _this.initForm();
                _this.listTransactions();
            }
            _this.params = tslib_1.__assign({}, _this.params, { storeID: _this.storeID, previousRoute: _this.previousRoute, storeFormGroupValue: _this.storeFormGroupValue });
        });
        this.userRole = localStorage.getItem('userRole');
        this.isAdmin = this.userRole == 'ADMIN';
        if (this.userRole === 'ADMIN') {
            this.headersTable = [
                'orderNumber',
                'storeType',
                'storeName',
                'fundName',
                'fullName',
                'customerCpf',
                'description',
                'createdAt',
                'totalValue',
                'state',
                'actions',
            ];
        }
        else {
            this.headersTable = [
                'orderNumber',
                'fullName',
                'customerCpf',
                'description',
                'createdAt',
                'totalValue',
                'state',
                'actions',
            ];
        }
        if (!this.storeID) {
            this.storeID = '';
        }
        this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.store = response;
                this.futurePaymentEnabled = response.futurePaymentEnabled;
                response.map(function (item) {
                    _this.listStore.push({ key: item.nomeFantasia, value: item.nomeFantasia });
                });
                return [2 /*return*/];
            });
        }); });
        this.transactionService.getStatus().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(response).map(function (k) {
                    _this.listStatusDefault = response;
                    _this.listStatus.push({ key: k, value: response[k] });
                });
                this.listStatus.push({ key: 'pendingJHSF', value: 'AGUARDANDO APROVAÇÃO' });
                this.listStatus.sort(function (a, b) {
                    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
                });
                return [2 /*return*/];
            });
        }); });
        this.transactionService.getPaymentsStatus().subscribe(function (response) {
            Object.keys(response).map(function (k) {
                _this.listStatusPaymentDefault = response;
            });
        });
    };
    StoreTransactionListComponent.prototype.initForm = function () {
        if (this.userRole === 'ADMIN' && this.storeID) {
            this.fieldsForm = [
                {
                    field: 'input',
                    type: 'number',
                    formControlName: 'numberTransaction',
                    label: 'Número da Transação',
                    placeholder: 'Número da Transação',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'cpfTransaction',
                    label: 'CPF do Cliente',
                    placeholder: 'CPF do Cliente',
                },
                {
                    field: 'input',
                    type: 'date',
                    formControlName: 'dateTransaction',
                },
                {
                    field: 'select',
                    formControlName: 'status',
                    label: 'Status',
                    placeholder: 'Status',
                    list: this.listStatus,
                    optionDefault: 'TODOS',
                },
                {
                    field: 'select',
                    formControlName: 'typeTransaction',
                    label: 'Tipo',
                    placeholder: 'Tipo',
                    list: [
                        { key: 'FUNDO', value: 'FUNDO' },
                        { key: 'MEMBERSHIP', value: 'MEMBERSHIP' },
                    ],
                    optionDefault: 'TODOS',
                },
            ];
        }
        else {
            if (!this.formGroup) {
                this.formGroup = {
                    typeTransaction: ['APP'],
                    transactionsTest: ['false'],
                };
            }
            this.fieldsForm = [
                {
                    field: 'input',
                    type: 'number',
                    formControlName: 'numberTransaction',
                    label: 'Número da Transação',
                    placeholder: 'Número da Transação',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'cpfTransaction',
                    label: 'CPF do Cliente',
                    placeholder: 'CPF do Cliente',
                },
                {
                    field: 'input',
                    type: 'date',
                    formControlName: 'dateTransaction',
                },
                {
                    field: 'select',
                    formControlName: 'status',
                    label: 'Status',
                    placeholder: 'Status',
                    list: this.listStatus,
                    optionDefault: 'TODOS',
                },
                {
                    field: 'select',
                    formControlName: 'typeTransaction',
                    label: 'Tipo',
                    placeholder: 'Tipo',
                    list: [
                        { key: 'APP', value: 'APP' },
                        { key: 'CARTÃO FÍSICO', value: 'CARTÃO FÍSICO' },
                        { key: 'FUNDO', value: 'FUNDO' },
                        { key: 'MEMBERSHIP', value: 'MEMBERSHIP' },
                        { key: 'TRANSFERÊNCIA C2C', value: 'TRANSFERÊNCIA C2C' },
                    ],
                    optionDefault: 'TODOS',
                },
                {
                    field: 'select',
                    formControlName: 'transactionsTest',
                    label: 'Empresas Teste',
                    placeholder: 'Empresas Teste',
                    list: [
                        { key: 'true', value: 'Sim' },
                        { key: 'false', value: 'Não' },
                    ],
                },
            ];
        }
    };
    StoreTransactionListComponent.prototype.transactionsFuture = function () {
        var _this = this;
        this.transactionsFutureFilter();
        var filter = '';
        if (this.storeID) {
            filter = "?filters={\"page\": " + this.pageReserve + ", \"pageSize\":" + this.pageSizeReserve + ",\"filters\":[{\"fields\":[\"store.id\"], \"op\": \"=\", \"value\":\"" + this.storeID + "\"}]}";
        }
        else {
            filter = "?filters={\"page\": " + this.pageReserve + ", \"pageSize\": " + this.pageSizeReserve + "}";
        }
        this.transactionService.listTransactionsReservation(filter).subscribe(function (response) {
            _this.pageTotalReserve = response.total;
            _this.dataSourceReserve = new MatTableDataSource(response.results);
        }, function (error) {
            alert(error.error.message);
        });
    };
    StoreTransactionListComponent.prototype.transactionsFutureFilter = function () {
        this.futurePaymentEnabled = true;
        this.headersTableReserve = [
            'transactionOrderNumber',
            'nomeFantasia',
            'nameCustomer',
            'cpf',
            'descriptionTransaction',
            'paymentDate',
            'totalValueAnticipated',
            'totalValue',
            'state',
            'actions',
        ];
    };
    StoreTransactionListComponent.prototype.listTransactions = function () {
        var _this = this;
        if (this.storeID) {
            this.storeService
                .getStore(this.storeID)
                .toPromise()
                .then(function (response) {
                _this.futurePaymentEnabled = response.futurePaymentEnabled;
                if (response.futurePaymentEnabled) {
                    _this.transactionsFuture();
                }
            })
                .catch(function (err) { return console.log(err); });
            // ?state=PENDING&rewardsState=FUTURE
            var storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
            if (storeSelected && this.userRole != 'ADMIN') {
                this.storeID = storeSelected.storeId;
            }
            this.transactionService
                .listTransactions("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"storeId\"], \"op\": \"=\", \"value\":\"" + this.storeID + "\"}]}")
                .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    (response.results ? response.results : response).map(function (item) {
                        // item.createdAt = item.createdAt.slice(0, 10)
                        delete item.updatedAt;
                        delete item.paidAt;
                    });
                    this.pageTotal = response.total;
                    this.dataSource = new MatTableDataSource(response.results);
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
        }
        else {
            this.transactionService
                .listTransactions("?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
                .subscribe(
            // '?state=PENDING&rewardsState=FUTURE'
            function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    (response.results ? response.results : response).map(function (item) {
                        // item.createdAt = item.createdAt.slice(0, 10)
                        delete item.updatedAt;
                        delete item.paidAt;
                    });
                    this.pageTotal = response.total;
                    this.dataSource = new MatTableDataSource(response.results);
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
            this.storeService.listStores().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.stores = response;
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
            this.transactionsFuture();
        }
    };
    StoreTransactionListComponent.prototype.view = function (transaction) {
        if (transaction.isManual) {
            localStorage.setItem('isManual', 'true');
        }
        else {
            localStorage.setItem('isManual', 'false');
        }
    };
    StoreTransactionListComponent.prototype.openDialogCancelTransaction = function (transaction) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
            width: '500px',
            data: {
                transaction: transaction,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.cancel(transaction.id, result, transaction.type);
            }
        });
    };
    StoreTransactionListComponent.prototype.onAddTransactionClick = function () {
        if (this.store.futurePaymentEnabled) {
            this.count = this.count + 1;
        }
        if (this.store.manualPaymentEnabled) {
            this.count = this.count + 1;
        }
        if (this.store.isMembershipActive) {
            this.count = this.count + 1;
        }
        if (this.count > 1) {
            this.openDialogTransactionType().catch(function (err) { return console.log(err); });
        }
        else if (this.store.futurePaymentEnabled) {
            this.goToPaymentAnticipatedTransaction();
        }
        else if (this.store.manualPaymentEnabled) {
            this.goToNormalTransaction();
        }
        else if (this.store.isMembershipActive) {
            this.goToNormalTransaction();
        }
    };
    StoreTransactionListComponent.prototype.openDialogTransactionType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                dialogRef = this.dialog.open(ModalChooseTransactionTypeComponent, {
                    width: '500px',
                    data: {
                        futurePaymentEnabled: this.futurePaymentEnabled,
                        manualPaymentEnabled: this.store.manualPaymentEnabled,
                        isMembershipActive: this.store.isMembershipActive,
                    },
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result == 'normal') {
                        _this.goToNormalTransaction();
                    }
                    else if (result == 'manual') {
                        _this.goToManualTransaction();
                    }
                    else if (result == 'paymentAnticipated') {
                        _this.goToPaymentAnticipatedTransaction();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    StoreTransactionListComponent.prototype.goBack = function () {
        if (this.previousRoute === 'store-list') {
            this.router.navigate(['/dashboard', 'stores'], { queryParams: this.params, skipLocationChange: true })
                .catch(function (err) { return console.log(err); });
        }
        else {
            this.router.navigate(['/dashboard', 'home'])
                .catch(function (err) { return console.log(err); });
        }
    };
    StoreTransactionListComponent.prototype.goToNormalTransaction = function () {
        this.router.navigate(['dashboard', 'stores', this.storeID, 'transactions', 'new'], { queryParams: this.params, skipLocationChange: true });
    };
    StoreTransactionListComponent.prototype.goToManualTransaction = function () {
        this.router.navigate(['dashboard', 'stores', this.storeID, 'transactions', 'new-manual'], { queryParams: this.params, skipLocationChange: true });
    };
    StoreTransactionListComponent.prototype.goToPaymentAnticipatedTransaction = function () {
        var reservation = 'paymentAnticipated';
        this.router.navigate(['dashboard', 'stores', this.storeID, 'transactions', 'payment-anticipated'], { queryParams: this.params, skipLocationChange: true });
    };
    StoreTransactionListComponent.prototype.openModalWarningUnableToCancel = function () {
        var dialogRef = this.dialog.open(ModalRoadpassUnableToCancel, {
            width: '500px',
        });
        return dialogRef.afterClosed().toPromise();
    };
    StoreTransactionListComponent.prototype.cancel = function (transactionID, reason, transactionType) {
        var _this = this;
        var data = {
            reason: reason,
        };
        var cancelObservable;
        if (transactionType && transactionType.toLowerCase() === 'c2c') {
            cancelObservable = this.transactionService.cancelTransactionC2C(transactionID, data);
        }
        else {
            cancelObservable = this.transactionService.cancelTransaction(transactionID, data);
        }
        cancelObservable.subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(response && response.roadPassUnableToCancel)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.openModalWarningUnableToCancel()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.snackBar.open('Transação cancelada com sucesso.');
                        this.listTransactions();
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    StoreTransactionListComponent.prototype.convertStatus = function (transaction) {
        switch (transaction.state) {
            case 'PENDING':
                return transaction.isManual ? 'Aguardando aprovação da JHSF' : 'Pendente';
                break;
            case 'APPROVED':
                return 'Aprovado';
                break;
            case 'REJECTED':
                return 'Rejeitado';
                break;
            case 'CANCELED':
                return 'Cancelado';
                break;
            default:
                return '';
        }
    };
    StoreTransactionListComponent.prototype.convertStatusTransactions = function (transaction) {
        if (transaction.isManual && transaction.state == 'PENDING') {
            return 'AGUARDANDO APROVAÇÃO DA JHSF';
        }
        else {
            return this.listStatusDefault[transaction.state];
        }
    };
    StoreTransactionListComponent.prototype.convertStatusPayments = function (payment) {
        if (payment.isManual && payment.state == 'PENDING') {
            return 'AGUARDANDO APROVAÇÃO DA JHSF';
        }
        else {
            return this.listStatusPaymentDefault[payment.state];
        }
    };
    StoreTransactionListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    StoreTransactionListComponent.prototype.detail = function (transacationId, fullName, customerCpf) {
        var _this = this;
        if (this.userRole === 'ADMIN') {
            this.transactionService
                .listTransactionsById(transacationId + "?showManualDetails=true")
                .subscribe(function (response) {
                var dialogRef = _this.dialog.open(ModalCustomerInfoComponent, {
                    data: {
                        customerBalance: response.customerBalance,
                        customerCpf: response.customerCpf,
                        customerFullName: response.customerFirstName + " " + response.customerLastName,
                        customerBirthDate: response.customerBirthDate,
                        customerEmailAddress: response.customerEmailAddress,
                        customerPhoneNumber: response.customerPhoneNumber,
                        customerGender: response.customerGender,
                        customerId: response.customerId,
                        user: _this.userRole,
                    },
                });
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        else {
            var dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
                data: {
                    customerCpf: customerCpf,
                    customerFullName: fullName,
                    user: this.userRole,
                },
            });
        }
    };
    StoreTransactionListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            storeID: this.storeID,
            formGroupValue: JSON.stringify(this.formGroup),
            storeFormGroupValue: this.storeFormGroupValue,
            previousRoute: this.previousRoute,
        };
    };
    StoreTransactionListComponent.prototype.reciverFeedbackReserve = function (returnFilterReserve) {
        this.pageTotalReserve = returnFilterReserve.total;
        this.dataSourceReserve = new MatTableDataSource(returnFilterReserve.results);
    };
    StoreTransactionListComponent.prototype.openDialogSendNotification = function (e) {
        var _this = this;
        var id = e.id, customerCpf = e.customerCpf, totalValue = e.totalValue;
        var dialogRef = this.dialog.open(ModalConfirmComponent, {
            width: '400px',
            data: {
                text: 'Deseja reenviar essa transação para o cliente ?',
                buttonConfirmText: 'Sim',
            },
        });
        dialogRef
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(result && result != 'cancel')) return [3 /*break*/, 2];
                        data = {
                            totalValue: totalValue,
                            customerCpf: customerCpf,
                        };
                        return [4 /*yield*/, this.transactionService.transactionSendEvent(id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Notificação enviada com sucesso.');
                                    this.listTransactions();
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) { return _this.snackBar.open(error.error.message); });
    };
    return StoreTransactionListComponent;
}());
export { StoreTransactionListComponent };
