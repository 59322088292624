<app-logged class="store-form">
  <header class="store-form__header">
    <h2>RELATÓRIOS</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/home">VOLTAR</button>
    </div>
  </header>
  <div class="row">
    <div class="col-3 col-sm-3">
      <mat-form-field>
        <mat-label>Pesquisar Relatório</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          placeholder="Pesquisar Relatório"
        />
      </mat-form-field>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="relatorio">
      <th mat-header-cell *matHeaderCellDef>Relatório</th>
      <td mat-cell *matCellDef="let element">{{ element.report }}</td>
    </ng-container>

    <ng-container matColumnDef="categoria">
      <th mat-header-cell *matHeaderCellDef>Categoria</th>
      <td mat-cell *matCellDef="let element">{{ element.category }}</td>
    </ng-container>

    <ng-container matColumnDef="acao">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="formModal(element.value)">
          <mat-icon>get_app</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
    <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
  </table>
</app-logged>
