<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Parceiros</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="openDialog()">ADICIONAR</button>
    </div>
  </header>
  <app-form-filter
    maskExample="00.000.000/0000-00"
    actions="form"
    type="architectStores"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ element.cnpj | mask: '00.000.000/0000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Nome Fantasia</th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Usuários"
            matTooltipPosition="left"
            routerLink="/dashboard/architect-store-users/{{ element.id }}"
          >
            <mat-icon>supervisor_account</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Editar"
            routerLink="/dashboard/architect-store/{{ element.id }}/edit"
            matTooltipPosition="left"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>

    <app-form-filter
      actions="pagination"
      type="architectStores"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
