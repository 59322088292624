import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { omitBy } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StoreService = /** @class */ (function () {
    function StoreService(http) {
        this.http = http;
    }
    StoreService.prototype.listStoresByClosing = function (storeID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/closings/" + storeID + "/stores").pipe(tap(function (_) { return _this.log('cms/closings/stores'); }), catchError(this.handleError));
    };
    StoreService.prototype.export = function (storeID, closingID) {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/cms/closings/" + closingID + "/stores/" + storeID + "/export")
            .pipe(tap(function (_) { return _this.log('cms/closings/export'); }), catchError(this.handleError));
    };
    StoreService.prototype.listStores = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores" + (filter || '')).pipe(tap(function (_) { return _this.log('cms/stores'); }), catchError(this.handleError));
    };
    StoreService.prototype.listUsers = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles").pipe(tap(function (_) { return _this.log('cms/profiles'); }), catchError(this.handleError));
    };
    StoreService.prototype.listUsersCustomers = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles/customers" + (filter || '')).pipe(tap(function (_) { return _this.log('cms/profiles/customers'); }), catchError(this.handleError));
    };
    StoreService.prototype.listUsersByStore = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles/sellers" + (filter || '')).pipe(tap(function (_) { return _this.log('cms/profiles'); }), catchError(this.handleError));
    };
    StoreService.prototype.getUserInfo = function (query) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/users/?" + query).pipe(tap(function (_) { return _this.log('cms/profiles'); }), catchError(this.handleError));
    };
    StoreService.prototype.listUsersSellers = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles/sellers?page=0&range=100").pipe(tap(function (_) { return _this.log('cms/profiles'); }), catchError(this.handleError));
    };
    StoreService.prototype.addStore = function (data) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/cms/stores", omitBy(data, function (p) { return p === ''; }))
            .pipe(tap(function (_) { return _this.log('cms/stores'); }), catchError(this.handleError));
    };
    StoreService.prototype.updateStore = function (data, storeID) {
        var _this = this;
        return this.http
            .put(environment.apiUrl + "/cms/stores/" + storeID, omitBy(data, function (p) { return p === ''; }))
            .pipe(tap(function (_) { return _this.log('cms/stores'); }), catchError(this.handleError));
    };
    StoreService.prototype.getStore = function (storeID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores/" + storeID).pipe(tap(function (_) { return _this.log('cms/stores'); }), catchError(this.handleError));
    };
    StoreService.prototype.getStoreByCnpj = function (cnpj) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores/search?cnpj=" + cnpj).pipe(tap(function (_) { return _this.log('cms/stores/search'); }), catchError(this.handleError));
    };
    StoreService.prototype.performChargeBack = function (body) {
        var _this = this;
        var options = {
            headers: new HttpHeaders({ 'Content-type': 'application/json' }),
            observer: 'body',
            body: { description: body.description },
        };
        return this.http.delete(environment.apiUrl + "/cms/customers/jcoins/" + body.walletId, options).pipe(tap(function (_) { return _this.log("cms/customers/jcoins/" + body.walletId); }), catchError(this.handleError));
    };
    StoreService.prototype.getSegmentos = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores/segmentos").pipe(tap(function (_) { return _this.log('cms/stores/segmentos'); }), catchError(this.handleError));
    };
    StoreService.prototype.listMacroGroups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/macro-groups").toPromise()];
            });
        });
    };
    StoreService.prototype.listSubStores = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/stores/" + id + "/sub-store").toPromise()];
            });
        });
    };
    StoreService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/stores/" + id).toPromise()];
            });
        });
    };
    StoreService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    StoreService.prototype.log = function (message) { };
    StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.ɵɵinject(i1.HttpClient)); }, token: StoreService, providedIn: "root" });
    return StoreService;
}());
export { StoreService };
