import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
import JsFileDownloader from 'js-file-downloader';
var ClientFormUpdateComponent = /** @class */ (function () {
    function ClientFormUpdateComponent(errorsService, zipCodeService, formBuilder, storeService, authService, router, route, snackBar, _dialog, _cd, imageCompress, customerAttendantService, location) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.customerAttendantService = customerAttendantService;
        this.location = location;
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.states = [];
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.plans = [];
        this.firstPaymentEnabled = false;
        this.planSelectEnabled = false;
        this.params = { formGroupValue: [] };
        this.frontImageInvalid = false;
        this.behindImageInvalid = false;
        this.kinds = [];
        this.attendants = [];
        this.disapprovedDocument = false;
        this.initForm();
    }
    ClientFormUpdateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        // empty
    };
    ClientFormUpdateComponent.prototype.initForm = function () {
        this.customerID = this.route.snapshot.paramMap.get('customerID');
        this.architect = this.route.snapshot.paramMap.get('architect');
        this.formGroup = this.formBuilder.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            gender: [null, [Validators.required]],
            blockTransaction: [null, [Validators.required]],
            birthDate: [null, []],
            emailAddress: [null, [Validators.required, Validators.email]],
            phoneNumber: [null, [Validators.required]],
            membershipEnabled: [null, [Validators.required]],
            plan: [null, [Validators.required]],
            firstPayment: [null, []],
            kind: [null, [Validators.required]],
            attendant: [null, []],
            createdOnLlLoyalty: [null, []],
            crea: [null, []],
            cau: [null, []],
            asbea: [null, []],
            companyName: [null, []],
            companyCnpj: [null, []],
            documentArchitect: [null, []],
            profession: [null, []],
            origin: [null, []],
        });
        if (this.architect) {
            this.customerArchitect().catch(function (err) { return console.log(err); });
        }
        else {
            this.loadCustomerInfo().catch(function (err) { return console.log(err); });
        }
    };
    ClientFormUpdateComponent.prototype.changeTypePlan = function (event) {
        if (event.value === 'recurrent' && this.customer.plan !== 'recurrent-active') {
            this.firstPaymentEnabled = true;
            this.formGroup.get('firstPayment').setValidators([Validators.required]);
            this.formGroup.get('firstPayment').updateValueAndValidity();
        }
        else {
            this.firstPaymentEnabled = false;
            this.formGroup.get('firstPayment').setValidators(null);
            this.formGroup.get('firstPayment').setErrors(null);
        }
    };
    ClientFormUpdateComponent.prototype.changeMembershipEnabled = function (event) {
        if (event.value) {
            this.planSelectEnabled = true;
            this.formGroup.get('plan').setValidators(Validators.required);
            this.formGroup.get('plan').updateValueAndValidity();
        }
        else {
            this.firstPaymentEnabled = false;
            this.planSelectEnabled = false;
            this.formGroup.get('plan').setValidators(null);
            this.formGroup.get('plan').setErrors(null);
            this.formGroup.get('plan').setValue('none');
        }
    };
    ClientFormUpdateComponent.prototype.selectFileDocumentFront = function (event) {
        var _this = this;
        var originalDocumentFrontImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalDocumentFrontImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalDocumentFrontImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedDocumentFrontImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedDocumentFrontImage = result.img.dataURL;
                        this.documentFrontImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentFrontImage)];
                    case 1:
                        _a.documentFrontImageData = _b.sent();
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    ClientFormUpdateComponent.prototype.editDocumentFrontImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalDocumentFrontImage) return [3 /*break*/, 1];
                        _a = this.originalDocumentFrontImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedDocumentFrontImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.documentFrontImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedDocumentFrontImage = result.img.dataURL;
                        this.documentFrontImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentFrontImage)];
                    case 5:
                        _b.documentFrontImageData = _c.sent();
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ClientFormUpdateComponent.prototype.selectFileDocumentBehind = function (event) {
        var _this = this;
        var originalDocumentBehindImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalDocumentBehindImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalDocumentBehindImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedDocumentBehindImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedDocumentBehindImage = result.img.dataURL;
                        this.documentBehindImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentBehindImage)];
                    case 1:
                        _a.documentBehindImageData = _b.sent();
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    ClientFormUpdateComponent.prototype.editDocumentBehindImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalDocumentBehindImage) return [3 /*break*/, 1];
                        _a = this.originalDocumentBehindImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedDocumentBehindImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.documentBehindImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedDocumentBehindImage = result.img.dataURL;
                        this.documentBehindImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentBehindImage)];
                    case 5:
                        _b.documentBehindImageData = _c.sent();
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_2 = _c.sent();
                        this.snackBar.open(error_2.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ClientFormUpdateComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    ClientFormUpdateComponent.prototype.removeImage = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (input === 'documentFront') {
                    this.readUrlDocumentFront = '';
                    this.originalDocumentFrontImage = undefined;
                    this.croppedDocumentFrontImage = undefined;
                    this.documentFrontImagePosition = undefined;
                    this.documentFrontImageData = undefined;
                    this.frontImageInvalid = true;
                }
                if (input === 'documentBehind') {
                    this.readUrlDocumentBehind = '';
                    this.originalDocumentBehindImage = undefined;
                    this.croppedDocumentBehindImage = undefined;
                    this.documentBehindImagePosition = undefined;
                    this.documentBehindImageData = undefined;
                    this.behindImageInvalid = true;
                }
                return [2 /*return*/];
            });
        });
    };
    ClientFormUpdateComponent.prototype.goBack = function () {
        if (this.architect) {
            this.router.navigate(['./dashboard/clients-architect/architect']);
        }
        else {
            this.router.navigate(['./dashboard/clients']);
        }
    };
    ClientFormUpdateComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (this.planSelectEnabled && !this.formGroup.value.plan) {
                            this.formGroup.get('plan').setValue(null);
                            this.formGroup.get('plan').setValidators(Validators.required);
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedDocumentFrontImage && this.documentFrontImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.authService.getS3Url('documentFront')];
                    case 1:
                        response = _a.sent();
                        this.readUrlDocumentFront = response.readUrl;
                        return [4 /*yield*/, this.authService.uploadToS3(response.uploadUrl, this.documentFrontImageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(this.croppedDocumentBehindImage && this.documentBehindImageData)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.authService.getS3Url('documentBehind')];
                    case 4:
                        response = _a.sent();
                        this.readUrlDocumentBehind = response.readUrl;
                        return [4 /*yield*/, this.authService.uploadToS3(response.uploadUrl, this.documentBehindImageData)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        data = {
                            firstName: this.formGroup.value.firstName,
                            lastName: this.formGroup.value.lastName,
                            gender: this.formGroup.value.gender,
                            blockTransaction: this.formGroup.value.blockTransaction,
                            birthDate: this.formGroup.value.birthDate || null,
                            emailAddress: this.formGroup.value.emailAddress,
                            phoneNumber: this.formGroup.value.phoneNumber,
                            membershipEnabled: this.formGroup.value.membershipEnabled,
                            imgDocumentFront: this.readUrlDocumentFront,
                            imgDocumentBehind: this.readUrlDocumentBehind,
                            customerKindId: this.formGroup.value.kind,
                            attendantCustomerId: this.formGroup.value.attendant !== '' ? this.formGroup.value.attendant : null,
                            crea: this.formGroup.value.crea,
                            cau: this.formGroup.value.cau,
                            asbea: this.formGroup.value.asbea,
                            companyName: this.formGroup.value.companyName,
                            companyCnpj: this.formGroup.value.companyCnpj,
                            profession: this.formGroup.value.profession,
                            documentUrl: this.documentUrl ? this.documentUrl : undefined,
                            documentName: this.approveDoc,
                            origin: this.formGroup.value.origin ? this.formGroup.value.origin : undefined,
                        };
                        if (this.formGroup.value.membershipEnabled) {
                            data.plan = this.formGroup.value.plan;
                        }
                        if (this.formGroup.value.plan === 'recurrent') {
                            data.firstPayment = this.formGroup.value.firstPayment;
                        }
                        if (!this.architect) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.authService.customerUpdateArchitect(this.customerID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (response.isUpdated) {
                                        this.snackBar.open('Cliente editado com sucesso.');
                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                        this.router.navigate(['./dashboard/clients']);
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        this.authService.customerUpdate(this.customerID, data).subscribe(
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.snackBar.open('Cliente editado com sucesso.');
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                if (this.architect) {
                                    this.router.navigate(['./dashboard/clients-architect/architect']);
                                }
                                else {
                                    this.router.navigate(['./dashboard/clients']);
                                }
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    ClientFormUpdateComponent.prototype.loadCustomerInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.clientKindList().then(function (response) {
                            _this.kinds = response.results;
                        }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.authService.customerUserInfo(this.customerID).subscribe(
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.customer = response;
                                    this.setInfosCustomer();
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 2:
                        _a.sent();
                        this.customerAttendantService.list().then(function (response) {
                            _this.attendants = response.results;
                        }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientFormUpdateComponent.prototype.setInfosCustomer = function () {
        this.createdOnLlLoyalty = this.customer.createdOnLlLoyalty;
        this.hasRoadpassWallet = this.customer.hasRoadpassWallet;
        this.zoopBuyerId = this.customer.zoopBuyerId;
        this.salesForceId = this.customer.salesForceId;
        this.formGroup.patchValue({
            firstName: this.customer.firstName,
            lastName: this.customer.lastName,
            gender: this.customer.gender,
            blockTransaction: this.customer.blockTransaction,
            birthDate: this.customer.birthDate ? this.customer.birthDate.substring(0, 10) : null,
            emailAddress: this.customer.emailAddress,
            phoneNumber: this.customer.phoneNumber,
            membershipEnabled: this.customer.membershipEnabled,
            kind: this.customer.customerKindId,
            attendant: this.customer.attendantCustomerId ? this.customer.attendantCustomerId : '',
            crea: this.architect ? this.customer.crea : undefined,
            cau: this.architect ? this.customer.cau : undefined,
            asbea: this.architect ? this.customer.asbea : undefined,
            companyName: this.architect ? this.customer.companyName : undefined,
            companyCnpj: this.architect ? this.customer.companyCnpj : undefined,
            profession: this.architect ? this.customer.profession : undefined,
            origin: this.customer.origin,
        });
        if (this.architect) {
            this.documentUrl = this.customer.documentUrl;
            this.approveDoc = this.customer.documentName;
        }
        this.croppedDocumentFrontImage = this.customer.imgDocumentFront;
        this.croppedDocumentBehindImage = this.customer.imgDocumentBehind;
        if (this.customer.plan) {
            if (this.customer.plan === 'recurrent-active') {
                this.formGroup.controls.plan.setValue('recurrent');
            }
            else {
                this.formGroup.controls.plan.setValue(this.customer.plan);
            }
            this.formGroup.controls.firstPayment.setValue(this.customer.firstPayment);
        }
        this.changeMembershipEnabled({ value: this.customer.membershipEnabled });
        this.changeTypePlan({ value: this.customer.plan });
    };
    ClientFormUpdateComponent.prototype.selectFile = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (event.target.files && event.target.files[0]) {
                    reader_1 = new FileReader();
                    reader_1.readAsDataURL(event.target.files[0]); // read file as data url
                    reader_1.onload = function () {
                        _this.documentUrl = reader_1.result;
                    };
                    this.uploadData = new FormData();
                    this.uploadData.append('file', event.target.files[0], event.target.files[0].name);
                    this.approveDoc = event.target.files[0].name;
                }
                else {
                    this.removeDocument();
                }
                return [2 /*return*/];
            });
        });
    };
    ClientFormUpdateComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.uploadData = null;
        this.approveDoc = undefined;
        this.fileInputBanner.nativeElement.value = '';
    };
    ClientFormUpdateComponent.prototype.download = function (event) {
        var _this = this;
        event.preventDefault();
        // eslint-disable-next-line no-new
        new JsFileDownloader({
            url: this.documentUrl,
            nameCallback: function () {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return _this.approveDoc;
            },
        });
    };
    ClientFormUpdateComponent.prototype.customerArchitect = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.authService
                    .findOneCustomerArchitect(this.customerID)
                    .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        console.log(response);
                        if (response.length) {
                            this.customer = response[0];
                            console.log(this.customer);
                            this.setInfosCustomer();
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    console.log(error);
                    alert(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return ClientFormUpdateComponent;
}());
export { ClientFormUpdateComponent };
