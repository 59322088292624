<app-logged class="store-form">
  <header class="store-list__header">
    <h2>INFORMAÇÕES DO PARCEIRO</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
      <button class="btn-primary-light" mat-button mat-button printSectionId="print-section"
        [printStyle]="{ h3: { width: '100%' }, h2: { border: 'solid 1px' } }" ngxPrint>
        Imprimir
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-12" *ngIf="storeID != '' && storeID != null">
          <qrcode [qrdata]="valueQrCode" id="print-section" [width]="256" [elementType]="elementType"
            [errorCorrectionLevel]="'L'"></qrcode>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome Fantasia</mat-label>
              <input matInput formControlName="nomeFantasia" />
              <mat-error *ngIf="formGroup.controls.nomeFantasia?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.nomeFantasia) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Razão Social</mat-label>
              <input matInput formControlName="razaoSocial" />
              <mat-error *ngIf="formGroup.controls.razaoSocial?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.razaoSocial) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <input matInput formControlName="phoneNumber" mask="(00)00000-0000" />
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de abertura</mat-label>
              <input type="date" matInput formControlName="openingDate" />
              <mat-error *ngIf="formGroup.controls.openingDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.openingDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Segmento</mat-label>

              <input type="text" placeholder="Segmento" matInput formControlName="mcc" [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySegment">
                <mat-option *ngFor="let seg of segmentosOptions | async" (blur)="onChange(seg)"
                  value="{{ seg.category }} - {{ seg.description }}">
                 {{ seg.category }} - {{ seg.description }}
                </mat-option>
              </mat-autocomplete>
              <!--<mat-select formControlName="mcc">
                <mat-option *ngFor="let seg of segmentos" (blur)="onChange(seg)"  value="{{ seg.code }} - {{ seg.category }} - {{ seg.description }}">
                  {{ seg.code }} - {{ seg.category }} - {{ seg.description }}
                </mat-option>
              </mat-select>-->

              <!--<mat-select formControlName="mcc">
                <mat-option *ngFor="let seg of segmentos" (blur)="onChange(seg)"  value="{{ seg.code }} - {{ seg.category }} - {{ seg.description }}">
                  {{ seg.code }} - {{ seg.category }} - {{ seg.description }}
                </mat-option>
              </mat-select>-->
              <mat-error *ngIf="formGroup.controls.segmento?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.segmento) }}
              </mat-error>
            </mat-form-field>

            
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Endereço</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CEP</mat-label>
              <input matInput (blur)="loadAddress()" formControlName="zipCode" mask="00000-000" />
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.abbr">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="neighborhood" />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Rua</mat-label>
              <input matInput formControlName="street" />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Número</mat-label>
              <input matInput formControlName="number" />
              <mat-error *ngIf="formGroup.controls.number?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.number) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Complemento</mat-label>
              <textarea matInput formControlName="complement"></textarea>
              <mat-error *ngIf="formGroup.controls.complement?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.complement) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Configurações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Membership Ativo?</mat-label>
              <mat-select formControlName="isMembershipActive" (selectionChange)="changeIsMembershipActive($event)">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isMembershipActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isMembershipActive) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Pagamento ativo?</mat-label>
              <mat-select formControlName="isPayActive" (selectionChange)="changeIsPayActive($event)">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isPayActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isPayActive) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Habilitar pagamento manual?</mat-label>
              <mat-select formControlName="manualPaymentEnabled">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.manualPaymentEnabled?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.manualPaymentEnabled) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Habilitar pagamento futuro?</mat-label>
              <mat-select formControlName="futurePaymentEnabled">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.futurePaymentEnabled?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.futurePaymentEnabled) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Dias para a liquidação (padrão: 30)</mat-label>
              <input type="number" matInput formControlName="liquidationDays" />
              <mat-error *ngIf="formGroup.controls.liquidationDays?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.liquidationDays) }}
              </mat-error>
            </mat-form-field>
            
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-6" *ngIf="isPayActive || isMembershipActive">
          <mat-card-title>Taxas</mat-card-title>
          <mat-card-content>
            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Quanto vale um J-Coin na saida? </mat-label>
              <input matInput type="text" formControlName="pointsConversionFactorOut" currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorOut?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorOut) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Quanto vale um J-Coin na entrada?</mat-label>
              <input matInput type="text" formControlName="pointsConversionFactorIn" currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorIn?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorIn) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Taxa Cashback (J-Coins)</mat-label>
              <input matInput formControlName="taxCashback" mask="separator.4" decimalMarker="," suffix="%" />
              <mat-error *ngIf="formGroup.controls.taxCashback?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxCashback) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Taxa Administrativa JHSF</mat-label>
              <input matInput formControlName="taxAdministrative" mask="separator.4" decimalMarker="," suffix="%" />
              <mat-error *ngIf="formGroup.controls.taxAdministrative?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxAdministrative) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Taxa Transação J-Coins</mat-label>
              <input matInput formControlName="taxRewardsTransaction" mask="separator.4" decimalMarker="," suffix="%" />
              <mat-error *ngIf="formGroup.controls.taxRewardsTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxRewardsTransaction) }}
              </mat-error>
            </mat-form-field>

            <!--
              <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Número Máximo de Pontos por Venda</mat-label>
              <input matInput type="number" formControlName="maximumPointsPerTransaction" />
              <mat-error *ngIf="formGroup.controls.maximumPointsPerTransaction?.invalid">
                {{
                  errorsService.messageErrorFor(
                    formGroup.controls.maximumPointsPerTransaction
                  )
                }}
              </mat-error>
            </mat-form-field>
            -->

            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Expiração dos pontos (dias)</mat-label>
              <input matInput type="number" formControlName="pointsExpiration" />
              <mat-error *ngIf="formGroup.controls.pointsExpiration?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsExpiration) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isPayActive">
              <mat-label>Taxa Transação JHSF Pay</mat-label>
              <input matInput formControlName="taxCreditCardTransaction" mask="separator.4" decimalMarker=","
                suffix="%" />
              <mat-error *ngIf="formGroup.controls.taxCreditCardTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxCreditCardTransaction) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Número Máximo de Parcelas por Transação</mat-label>
              <mat-select formControlName="maximumInstallmentsPerTransaction">
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.maximumInstallmentsPerTransaction?.invalid">
                {{
                errorsService.messageErrorFor(
                formGroup.controls.maximumInstallmentsPerTransaction
                )
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor Mínimo da Parcela</mat-label>
              <input currencyMask matInput formControlName="minimumInstallmentValue"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
              <mat-error *ngIf="formGroup.controls.minimumInstallmentValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.minimumInstallmentValue) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor Mínimo da Transação</mat-label>
              <input currencyMask matInput formControlName="minimumTransactionValue"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
              <mat-error *ngIf="formGroup.controls.minimumTransactionValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.minimumTransactionValue) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </form>
</app-logged>
