import { Component, OnInit, ViewChild } from '@angular/core'
import {
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material'
import { Location } from '@angular/common'
import { PreRegistrationService } from '../../../../services/preRegistration.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { ReportService } from '../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
import { ActivatedRoute, Router, Params } from '@angular/router'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-pre-registration-list',
  templateUrl: './pre-registration-list.component.html',
  styleUrls: ['./pre-registration-list.component.scss'],
})
export class PreRegistrationListComponent implements OnInit {
  public headersTable: string[] = ['cpf', 'name', 'isActive', 'Jcoins', 'actions']
  public preRegistration: any
  public params: Params
  formGroup: FormGroup

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  public pageTotal: number
  fieldsForm: any

  typePreRegistration: string
  filters: string
  public userRole: any

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  constructor (
    public preRegistrationService: PreRegistrationService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    this.userRole = localStorage.getItem('userRole')
    this.typePreRegistration = this.route.snapshot.paramMap.get('type')
    console.log(this.typePreRegistration)
    if (this.typePreRegistration !== 'architects') {
      if (this.typePreRegistration) {
        this.filters = `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["typeRegistration"], "op": "=", "value":"CMS"}]}`
      } else {
        this.filters = `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["typeRegistration"], "op": "<>", "value":"CMS"}]}`
      }

      this.route.queryParams.subscribe((params) => {
        if (params.formGroupValue) {
          this.formGroup = JSON.parse(params.formGroupValue)
        } else {
          this.preRegistrationlist()
        }
      })
    }

    if (this.typePreRegistration === 'architects') {
      this.preRegistrationlistArchitects()
    }

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'namePreRegistration',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfPreRegistration',
        label: 'CPF',
        placeholder: 'CPF',
      },
    ]
  }

  preRegistrationlistArchitects () {
    this.preRegistrationService
      .listArchitects(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
      .then(
        response => {
          response.results.map(user => {
            user.fullName = `${user.preRegistration.firstName} ${user.preRegistration.lastName}`
          })
          this.pageTotal = response.total
          this.preRegistration = new MatTableDataSource(
            response.results.map(r => r.preRegistration),
          )
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  preRegistrationlist () {
    this.preRegistrationService
      .list(this.filters)
      .then(
        response => {
          response.results.map(user => {
            user.fullName = `${user.firstName} ${user.lastName}`
          })
          this.pageTotal = response.total
          this.preRegistration = new MatTableDataSource(response.results)
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Pré-Cadastro?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          if (!this.typePreRegistration || this.typePreRegistration === 'CMS') {
            return this.preRegistrationService.delete(id).then(
              async response => {
                this.preRegistrationlist()
                this.snackBar.open('Pré-Cadastro removido com sucesso.')
              },
              error => {
                console.log(error)
                this.snackBar.open(error.error.message)
              },
            )
          }
          if (this.typePreRegistration === 'architects') {
            return this.preRegistrationService.deleteArchitect(id).then(
              async response => {
                this.preRegistrationlistArchitects()
                this.snackBar.open('Pré-Cadastro removido com sucesso.')
              },
              error => {
                console.log(error)
                this.snackBar.open(error.error.message)
              },
            )
          }
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    returnFilter.results.map(user => {
      user.fullName = `${user.firstName} ${user.lastName}`
    })
    this.pageTotal = returnFilter.total
    if (!this.typePreRegistration || this.typePreRegistration === 'CMS') {
      this.preRegistration = new MatTableDataSource(returnFilter.results)
    }
    if (this.typePreRegistration === 'architects') {
      this.preRegistration = new MatTableDataSource(returnFilter.results.map(r => r.preRegistration))
    }
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async report () {
    let filter
    this.typePreRegistration ? filter = '?typePreRegistration=CMS' : filter = '?typePreRegistration=OTHES,APP,SSO'
    await this.reportService.createReportPreRegistration(filter).then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Relatório de Pré Cadastro.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }
}
