import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import JsFileDownloader from "js-file-downloader";
import { ModalGenericComponent } from "src/app/components/modal-generic/modal-generic.component";
import { ArchitectClosingsService } from "src/app/services/architectClosings.service";

@Component({
  selector: 'architect-closing-details',
  templateUrl: './architect-closing-details.component.html',
  styleUrls: ['./architect-closing-details.component.scss'],
})
export class ArchitectClosingDetails implements OnInit {
  public tableColumns = ['storeName', 'nd', 'nf','totalNfValues',  'actions']
  public architectClosingid;
  public closing;

  constructor(
    private architectClosingsService: ArchitectClosingsService,
    private readonly route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private location: Location,
  ){    
    this.architectClosingid = this.route.snapshot.paramMap.get('id')    
  }

  async ngOnInit() {
    await this.loadClosing()
  }     

  async loadClosing() {
    this.closing = await this.architectClosingsService.getClosing(this.architectClosingid)
  }

  async export(storeBusinessUnitId){
    try {
      const response = await this.architectClosingsService.exportStoreClosing(this.architectClosingid, storeBusinessUnitId)        
      if (response.report) {
        new JsFileDownloader({
          url: response.report,
          nameCallback: (fileName) => {            
            return (
              'Relatório de Fechamento Arquitetos por Parceiro.' +
              (fileName.split('.').pop() || 'xls')
            )
          },
        })
      }        
    } catch(error) {
        this.snackBar.open(error.error.message)
    }
        
  }

  async checkNDPayment(store) {

    try{
      const result = await this.dialog.open(ModalGenericComponent, {
        width: '500px',
        data: {        
          text: 'Deseja checar o pagamento ND para esse parceiro?',
          buttonConfirmText: 'Confirmar pagamento ND'
        }
      }).afterClosed().toPromise()

      if(result && result !== 'cancel') {
        const res = await this.architectClosingsService.checkNDPayment(this.architectClosingid,store.id)
        this.snackBar.open('Pagamento ND checado com sucesso')
        await this.loadClosing()
        if(res.ndPaid && res.nfPaid && !store.approved) {
          this.approveStoreBusinessUnitInvoices(store.id, 'Pagamento ND e NF confirmados.')
        }
      }
      
    } catch(err) {
      this.snackBar.open('Problema ao checar pagamento ND')      
    }    

  }

  async checkNFPayment(store) {

    try{
      const result = await this.dialog.open(ModalGenericComponent, {
        width: '500px',
        data: {        
          text: 'Deseja checar o pagamento NF para esse parceiro?',
          buttonConfirmText: 'Confirmar pagamento NF'
        }
      }).afterClosed().toPromise()

      if(result && result !== 'cancel') {
        const res = await this.architectClosingsService.checkNFPayment(this.architectClosingid, store.id)
        this.snackBar.open('Pagamento NF checado com sucesso')
        await this.loadClosing()
        if(res.ndPaid && res.nfPaid && !store.approved) {
          this.approveStoreBusinessUnitInvoices(store.id, 'Pagamento ND e NF confirmados.')
        } 
      }
      
    } catch(err) {
      this.snackBar.open('Problema ao checar pagamento NF')      
    }        
  }

  async approveStoreBusinessUnitInvoices(storeBusinessUnitId, additionaltext = '') {

    try{
      const result = await this.dialog.open(ModalGenericComponent, {
        width: '500px',
        data: {        
          text: `${additionaltext} Deseja aprovar o pagamento de todas Notas fiscais desse parceiro? Essa ação irá distribuir os JCoins para os arquitetos envolvidos`,
          buttonConfirmText: 'Aprovar todas'
        }
      }).afterClosed().toPromise()

      if(result && result !== 'cancel') {
        const res = await this.architectClosingsService.approveAllStoreBusinessInvoices(this.architectClosingid, storeBusinessUnitId)
        this.snackBar.open('Notas Fiscais aprovadas com sucesso!')
        await this.loadClosing()
      }
      
    } catch(err) {
      this.snackBar.open('Problema ao aprovar as notas fiscais desse fechamento')      
    }        

  }

  goBack() {
    this.location.back()
  }

  convertCentsToReal (value) {
    if(value)
      return value / 100
    else return value
  }

}