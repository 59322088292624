import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompaniesService = /** @class */ (function () {
    function CompaniesService(http) {
        this.http = http;
    }
    CompaniesService.prototype.create = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/companies", body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.list = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/companies" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/companies/" + id)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.update = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/companies/" + id, body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.delete = function (id, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/companies/" + id)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.searchForCompaniesByNameOrCnpj = function (searchTerm) {
        return this.http.get(environment.apiUrl + "/cms/companies/search?search=" + searchTerm, {
            headers: new HttpHeaders({ 'noloading': 'true' })
        })
            .toPromise();
    };
    CompaniesService.prototype.listStoresBusinessUnits = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/store-business-units" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.createStoresBusinessUnits = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/store-business-units", body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.createCampaignStoresBusinessUnits = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/campaign-store-business-units", body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.findOneStoreBusinessUnit = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/store-business-units/" + id)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.findOneCampaignStoreBusinessUnit = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/campaign-store-business-units/" + id)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.updateStoresBusinessUnits = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/store-business-units/" + id, body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.updateCampaignStoresBusinessUnits = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/campaign-store-business-units/" + id, body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.deleteStoresBusinessUnits = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/store-business-units/" + id)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.usersListStoresBusinessUnits = function (id, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/users-store-business-unit/" + id + (filter || ''))
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.createStoreBusinessUnitUser = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/auth/cms/store-business-unit-user-signup", body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.userStoresBusinessUnit = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/user-store-business-unit/" + id)
                        .toPromise()];
            });
        });
    };
    CompaniesService.prototype.updateStoreBusinessUnitUser = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/users-store-business-unit/" + id, body)
                        .toPromise()];
            });
        });
    };
    CompaniesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompaniesService_Factory() { return new CompaniesService(i0.ɵɵinject(i1.HttpClient)); }, token: CompaniesService, providedIn: "root" });
    return CompaniesService;
}());
export { CompaniesService };
