<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!userID">NOVO USUÁRIO</h2>
    <h2 *ngIf="userID">EDITAR USUÁRIO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstName) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.lastName) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Telefone</mat-label>
              <input matInput formControlName="phoneNumber" mask="(00)00000-0000" />
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field> -->

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="!roleAdmin">
              <mat-label>Perfil de acesso</mat-label>
              <mat-select formControlName="role">
                <mat-option [value]="'STOREKEEPER'">TOTAL</mat-option>
                <mat-option [value]="'STOREKEEPER_SALES'">VENDAS</mat-option>
                <mat-option [value]="'STOREKEEPER_AIRPORT'">AEROPORTO</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.role?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.role) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isCustomerService && roleAdmin">
              <mat-label>Perfil de acesso</mat-label>
              <mat-select formControlName="role">
                <mat-option [value]="'CUSTOMER_SERVICE'">ATENDIMENTO</mat-option>
                <mat-option [value]="'ADMIN'">ADMINISTRADOR</mat-option>
                <mat-option [value]="'EMPTY'">UNIDADE DE NEGÓCIO ARQUITETO</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.role?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.role) }}
              </mat-error>
            </mat-form-field>                      
          </mat-card-content>
          <div *ngIf="userRole === 'ADMIN' && roleAdmin">
            <mat-card-title >Permissões - Projeto Arquiteto</mat-card-title>
            <mat-card-content>
              <div *ngFor="let permission of architectPermissions | keyvalue; let i=index" >
                <label>
                  <input type="checkbox" 
                  [checked]="selectedPermissions[permission.key]"
                  [value]="permission.key"
                  (change)="changePermissions($event)"/> {{permission.value}} 
                </label>
              </div>
            </mat-card-content>

            <mat-card-title >Permissões - Outros</mat-card-title>
            <mat-card-content>
              <div *ngFor="let permission of otherPermissions | keyvalue; let i=index" >
                <label>
                  <input type="checkbox" 
                  [checked]="selectedPermissions[permission.key]"
                  [value]="permission.key"
                  (change)="changePermissions($event)"/> {{permission.value}} 
                </label>
              </div>
            </mat-card-content>
          </div>

          <mat-card-title>Parceiro(s)</mat-card-title>
          <mat-card-content>
            <a class="btn-primary-light" (click)="openDialog()" mat-button> Adicionar </a>
            <p class="msg-error-not-store">{{ msgErrorNotStores }}</p>

            <div class="group-radio" *ngIf="storesSelected.length">
              <p *ngIf="storesSelected.length">Parceiro Principal</p>
              <div class="itens" *ngFor="let store of storesSelected; let i = index">
                <div class="detail">
                  <input
                    *ngIf="userID != ''"
                    type="radio"
                    name="storeMain"
                    id="store-{{ store.id }}"
                    (click)="changeStoreMain($event)"
                    value="{{ store.id }}"
                    checked="{{ isChecked(store.id) }}"
                  />
                  <input
                    *ngIf="userID == ''"
                    type="radio"
                    name="storeMain"
                    id="store-{{ store.id }}"
                    (click)="changeStoreMain($event)"
                    value="{{ store.id }}"
                    checked="{{ isChecked(store.id) }}"
                  />
                  <label for="store-{{ store.id }}">{{ store.nomeFantasia }}</label
                  ><br />
                </div>
                <div class="actions">
                  <button
                    mat-icon-button
                    matTooltip="Remover"
                    matTooltipPosition="left"
                    (click)="removeStore(store)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <p class="msg-error-not-store" *ngIf="!storeIdValidation">Campo deve ser preenchido</p>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
