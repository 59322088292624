<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">NOVO PARCEIRO</h2>
    <h2 *ngIf="id">EDITAR PARCEIRO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-stores">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome Fantasia</mat-label>
              <input matInput formControlName="nomeFantasia" required />
              <mat-error *ngIf="formGroup.controls.nomeFantasia?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.nomeFantasia) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Razão Social</mat-label>
              <input matInput formControlName="razaoSocial" required />
              <mat-error *ngIf="formGroup.controls.razaoSocial?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.razaoSocial) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" required />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" required />
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de abertura</mat-label>
              <input type="date" matInput formControlName="openingDate" required />
              <mat-error *ngIf="formGroup.controls.openingDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.openingDate) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Endereço</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CEP</mat-label>
              <input
                matInput
                formControlName="zipCode"
                (blur)="loadAddress()"
                mask="00000-000"
                required
              />
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Rua</mat-label>
              <input matInput formControlName="street" required />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Número</mat-label>
              <input matInput formControlName="addressNumber" required />
              <mat-error *ngIf="formGroup.controls.addressNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.addressNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Complemento</mat-label>
              <input matInput formControlName="complement" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="neighborhood" required />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" required />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="state" required>
                <mat-option *ngFor="let state of states" [value]="state.abbr">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Taxas</mat-card-title>
          <mat-card-content>
            <!-- <mat-form-field>
              <mat-label>Quanto vale um J-Coin na saida? </mat-label>
              <input
                matInput
                type="text"
                formControlName="pointsConversionFactorOut"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorOut?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorOut) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Quanto vale um J-Coin na entrada?</mat-label>
              <input
                matInput
                type="text"
                formControlName="pointsConversionFactorIn"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorIn?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorIn) }}
              </mat-error>
            </mat-form-field> -->

            <mat-form-field>
              <mat-label>Taxa Cashback (J-Coins)</mat-label>
              <input
                matInput
                formControlName="rateCashback"
                mask="separator.4"
                [dropSpecialCharacters]="false"
                decimalMarker=","
                suffix="%"
                required
              />
              <mat-error *ngIf="formGroup.controls.rateCashback?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rateCashback) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Taxa Administrativa JHSF</mat-label>
              <input
                matInput
                formControlName="rateAdministrative"
                mask="separator.4"
                [dropSpecialCharacters]="false"
                decimalMarker=","
                suffix="%"
                required
              />
              <mat-error *ngIf="formGroup.controls.rateAdministrative?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rateAdministrative) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Taxa Transação J-Coins</mat-label>
              <input
                matInput
                formControlName="rateRewardsTransaction"
                mask="separator.4"
                [dropSpecialCharacters]="false"
                decimalMarker=","
                suffix="%"
              />
              <mat-error *ngIf="formGroup.controls.rateRewardsTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rateRewardsTransaction) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Expiração dos pontos (dias)</mat-label>
              <input matInput type="number" formControlName="pointsExpiration" />
              <mat-error *ngIf="formGroup.controls.pointsExpiration?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsExpiration) }}
              </mat-error>
            </mat-form-field> -->
          </mat-card-content>
          <!-- </div>
        <div class="col-12 col-sm-6"> -->
          <mat-card-content>
            <mat-card-title>Campanhas</mat-card-title>
            <mat-label>Campanhas</mat-label>
            <mat-form-field>
              <mat-select formControlName="campaigns" required>
                <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{
                  campaign.description
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.campaigns?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.campaigns) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
