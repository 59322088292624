import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModalChooseStoreComponent } from '../../../../components/modal-choose-store/modal-choose-store.component';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
import { UtilService } from 'src/app/services/util.service';
var getEnumKeyByEnumValue = UtilService.getEnumKeyByEnumValue;
var UserFormComponent = /** @class */ (function () {
    function UserFormComponent(errorsService, zipCodeService, formBuilder, storeService, authService, router, route, snackBar, dialog, location, configService) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.configService = configService;
        this.states = [];
        this.plans = [];
        this.userID = '';
        this.stores = [];
        this.storesSelected = [];
        this.headersTable = [
            'cnpj',
            'nomeFantasia',
            'razaoSocial',
            'mccCode',
            'mccCategory',
            'actions',
        ];
        this.email = '';
        this.msgErrorNotStores = '';
        this.storeIdValidation = true;
        this.roleAdmin = false;
        this.isCustomerService = false;
        this.architectPermissions = [
            enumCmsPermissions.architect_invoice_cancel,
            enumCmsPermissions.architect_invoice_delete,
            enumCmsPermissions.architect_invoice_approve,
            enumCmsPermissions.architect_admin_menu,
        ].map(function (p) { return getEnumKeyByEnumValue(enumCmsPermissions, p); })
            .reduce(function (acc, key) {
            var _a;
            return (tslib_1.__assign((_a = {}, _a[key] = enumCmsPermissions[key], _a), acc));
        }, {});
        this.otherPermissions = [
            enumCmsPermissions.integrator,
        ].map(function (p) { return getEnumKeyByEnumValue(enumCmsPermissions, p); })
            .reduce(function (acc, key) {
            var _a;
            return (tslib_1.__assign((_a = {}, _a[key] = enumCmsPermissions[key], _a), acc));
        }, {});
        this.selectedPermissions = {};
        this.selectedPermissions = Object.keys(tslib_1.__assign({}, this.otherPermissions, this.architectPermissions))
            .reduce(function (acc, permissionKey) {
            var _a;
            return (tslib_1.__assign((_a = {}, _a[permissionKey] = false, _a), acc));
        }, {});
        this.initForm().catch(function (err) { return console.log(err); });
    }
    UserFormComponent.prototype.ngOnInit = function () {
        this.userRole = localStorage.getItem('userRole');
    };
    UserFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userInfo;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userInfo = JSON.parse(localStorage.getItem('userInfo'));
                        this.storeUser = userInfo.storeId;
                        if (this.route.snapshot.paramMap.get('storeID')) {
                            this.storeID = this.route.snapshot.paramMap.get('storeID');
                        }
                        if (this.route.snapshot.paramMap.get('userID')) {
                            this.userID = this.route.snapshot.paramMap.get('userID');
                        }
                        if (this.route.snapshot.paramMap.get('email')) {
                            this.email = this.route.snapshot.paramMap.get('email');
                        }
                        this.formGroup = this.formBuilder.group({
                            firstName: [null, [Validators.required]],
                            lastName: [null, [Validators.required]],
                            emailAddress: [this.email != '' ? this.email : null, [Validators.required, Validators.email]],
                            phoneNumber: [null, [Validators.required]],
                            storeId: [null, [Validators.required]],
                            otherStoreIds: [null, []],
                            role: [null, [Validators.required]],
                        });
                        if (!this.userID) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService
                                .userInfo(this.userID)
                                .toPromise()
                                .then(function (response) {
                                _this.formGroup.patchValue({
                                    firstName: response.firstName,
                                    lastName: response.lastName,
                                    emailAddress: response.emailAddress,
                                    phoneNumber: response.phoneNumber,
                                    storeId: response.storeId,
                                    role: response.role,
                                });
                                _this.storeID = _this.storeID ? _this.storeID : response.storeId;
                                _this.userStoreID = response.storeId;
                                _this.loadStores(response);
                                response.permissions.forEach(function (permissionKey) {
                                    _this.selectedPermissions[permissionKey] = true;
                                });
                            }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.loadStores();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.config()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserFormComponent.prototype.isChecked = function (id) {
        if (id === this.userStoreID) {
            return 'checked';
        }
    };
    UserFormComponent.prototype.getStoreById = function () {
        var _this = this;
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.storesSelected), _c = _b.next(); !_c.done; _c = _b.next()) {
                var s = _c.value;
                if (s.id === this.storeID) {
                    return;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                data = {
                    id: response.id,
                    nomeFantasia: response.nomeFantasia,
                };
                this.storesSelected.push(data);
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    UserFormComponent.prototype.loadStores = function (res) {
        var _this = this;
        this.storeService.listStores().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.stores = response;
                if (this.userID) {
                    data_1 = {};
                    res.allStoreIds.forEach(function (store) {
                        data_1 = {
                            id: store.storeId,
                            nomeFantasia: store.storeName,
                        };
                        _this.storesSelected.push(data_1);
                    });
                    this.formGroup.patchValue({
                        otherStoreIds: this.storesSelected,
                    });
                }
                this.getStoreById();
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    UserFormComponent.prototype.changeStoreMain = function (event) {
        if (this.mainStoreId === event.target.value) {
            this.roleAdmin = true;
        }
        else {
            this.roleAdmin = false;
            this.formGroup.get('role').setValue(null);
            this.formGroup.get('role').setValidators([Validators.required]);
            this.formGroup.get('role').updateValueAndValidity();
        }
        this.storeIdValidation = true;
        this.formGroup.get('storeId').setValue(event.target.value);
    };
    UserFormComponent.prototype.changePermissions = function (evt) {
        var permissionKey = evt.target.value;
        this.selectedPermissions[permissionKey] = evt.target.checked;
    };
    UserFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var otherStoreIds, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                otherStoreIds = [];
                if (this.storesSelected.length > 0) {
                    this.msgErrorNotStores = '';
                    if (this.formGroup.value.storeId == null || this.formGroup.value.storeId == '') {
                        this.storeIdValidation = false;
                    }
                    else {
                        this.storeIdValidation = true;
                    }
                }
                else {
                    this.msgErrorNotStores = 'Campo deve ser preenchido';
                    return [2 /*return*/, false];
                }
                if (this.storesSelected.length > 1) {
                    this.storesSelected.forEach(function (store) {
                        if (store.id != _this.formGroup.value.storeId) {
                            otherStoreIds.push(store.id);
                        }
                    });
                }
                data = {
                    firstName: this.formGroup.value.firstName,
                    lastName: this.formGroup.value.lastName,
                    emailAddress: this.formGroup.value.emailAddress,
                    phoneNumber: this.formGroup.value.phoneNumber,
                    storeId: this.formGroup.value.storeId,
                    otherStoreIds: otherStoreIds,
                    role: this.formGroup.value.role
                };
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (this.userID) {
                    if (this.roleAdmin) {
                        data.permissionsToAdd = Object.entries(this.selectedPermissions)
                            .filter(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return checked;
                        })
                            .map(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return permissionName;
                        });
                        data.permissionsToRemove = Object.entries(this.selectedPermissions)
                            .filter(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return !checked;
                        })
                            .map(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return permissionName;
                        });
                    }
                    this.authService.update(data, this.userID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.snackBar.open('Usuário atualizado com sucesso.');
                            this.location.back();
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    if (this.roleAdmin) {
                        data.permissions = Object.entries(this.selectedPermissions)
                            .filter(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return checked;
                        })
                            .map(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return permissionName;
                        });
                    }
                    this.authService.signup(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.isCreated) {
                                this.snackBar.open('Usuário criado com sucesso.');
                                this.location.back();
                            }
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    UserFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    UserFormComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalChooseStoreComponent, {
            width: '500px',
            data: {
                stores: this.stores,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.msgErrorNotStores = '';
                _this.showStores(result);
            }
        });
    };
    UserFormComponent.prototype.showStores = function (store) {
        var _this = this;
        this.storesSelected.push(store);
        this.stores = this.stores.filter(function (f) { return !_this.storesSelected.includes(f); });
    };
    UserFormComponent.prototype.removeStore = function (store) {
        if (store.id == this.formGroup.value.storeId || this.storesSelected.length == 0) {
            this.formGroup.get('storeId').setValue('');
        }
        this.storesSelected = this.storesSelected.filter(function (item) { return item.id !== store.id; });
        this.stores.push(store);
    };
    UserFormComponent.prototype.config = function () {
        var _this = this;
        this.configService
            .view()
            .toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.mainStoreId = response.results.mainStoreId;
                if (response.results.mainStoreId === this.storeID) {
                    this.roleAdmin = true;
                    this.formGroup.get('role').setValidators(null);
                    this.formGroup.get('role').updateValueAndValidity();
                    this.formGroup.get('role').setErrors(null);
                }
                console.log(response.results.mainStoreId);
                console.log(this.storeUser);
                if (response.results.mainStoreId === this.storeUser)
                    this.isCustomerService = true;
                console.log(this.isCustomerService);
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    return UserFormComponent;
}());
export { UserFormComponent };
