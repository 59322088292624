import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Params } from '@angular/router';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import JsFileDownloader from 'js-file-downloader';
import * as moment from 'moment';
var IntegrationsListComponent = /** @class */ (function () {
    function IntegrationsListComponent(authService, snackBar, dialog, reportService) {
        this.authService = authService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.reportService = reportService;
        this.headersTable = ['cnpj', 'nomeFantasia', 'cpf', 'name', 'cpfBeneficiary', 'nameBeneficiary', 'totalValue', 'totalPoints', 'tax', 'dateCreatedAt', 'dateCredit', 'isClient'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    IntegrationsListComponent.prototype.ngOnInit = function () {
        // erro
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameIntegration',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfIntegration',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Inicio',
                formControlName: 'dateInitIntegration',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Fim',
                formControlName: 'dateEndIntegration',
            },
            {
                field: 'select',
                formControlName: 'percentageIntegration',
                label: '% Jcoins',
                list: [
                    { key: '0.05', value: '% > 0.05' },
                    { key: 'all', value: 'TODOS' },
                ],
            },
        ];
    };
    IntegrationsListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var now, firstDay, lastDay, _a, results, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        now = new Date();
                        firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                        lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                        return [4 /*yield*/, this.authService
                                .clientsIncorporations("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"incorporation_integration.createdAt\"], \"op\": \">=\", \"value\":\"" + moment(firstDay).format('YYYY-MM-DD') + "\"}, {\"fields\":[\"incorporation_integration.createdAt\"], \"op\": \"<=\", \"value\":\"" + moment(lastDay).format('YYYY-MM-DD') + "\"}]}")
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = total;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    IntegrationsListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse beneficiário',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.authService.clientsBeneficiaryDelete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (err) { console.log(err); });
                                this.snackBar.open('Beneficiário removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    IntegrationsListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    IntegrationsListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.incorporationsConsult().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // eslint-disable-next-line no-new
                                new JsFileDownloader({
                                    url: response.fileUrl,
                                    nameCallback: function () {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        return 'Consulta Base.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return IntegrationsListComponent;
}());
export { IntegrationsListComponent };
