<app-logged class="store-list">
  <header class="store-list__header">
    <h2>CLIENTES</h2>
    <div class="store-list__header__actions">
      <button *ngIf="!architect" class="btn-primary-light" mat-button (click)="report()">
        EXPORTAR
      </button>
      <button
        *ngIf="userRole != 'CUSTOMER_SERVICE' && !architect"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/clients/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>

  <div *ngIf="!architect">
    <app-form-filter
      maskExample="000.000.000-00"
      actions="form"
      type="client"
      [fieldsForm]="fieldsForm"
      [formGroupValue]="formGroup"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>

  <div *ngIf="architect">
    <app-form-filter
      maskExample="000.000.000-00"
      actions="form"
      type="clientArchitect"
      [fieldsForm]="fieldsForm"
      [formGroupValue]="formGroup"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.cpf | mask: '000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="jcoinBalance">
        <th mat-header-cell *matHeaderCellDef>Saldo J-Coins</th>
        <td mat-cell *matCellDef="let element">
          <div
            *ngIf="element.totalFuture == 0 || element.totalFuture == null; else jcoinsFuture"
            matTooltip="Saldo Atual"
          >
            {{ element.jcoinBalance }}
          </div>
          <ng-template #jcoinsFuture>
            <div matTooltip="Saldo Atual / Saldo Futuro">
              {{ element.jcoinBalance }} / {{ element.totalFuture }}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="membership">
        <th mat-header-cell *matHeaderCellDef>Membership</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            *ngIf="element.membershipActive"
            style="cursor: pointer"
            matTooltip="Transações Membership"
            (click)="listCustomerInfo(element.id)"
            [ngStyle]="{
              color: element.typePlanMembership ? 'green' : '#ffaf00'
            }"
            >check_circle</mat-icon
          >
          <mat-icon *ngIf="!element.membershipActive">close</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="!architect"
            mat-icon-button
            matTooltip="Movimentações de JCoins"
            matTooltipPosition="left"
            routerLink="/dashboard/client/{{ element.id }}/movement-jcoins"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button
            *ngIf="architect"
            mat-icon-button
            matTooltip="Movimentações de JCoins"
            matTooltipPosition="left"
            routerLink="/dashboard/client/{{ element.id }}/movement-jcoins/architect"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Endereço"
            matTooltipPosition="left"
            routerLink="/dashboard/clients/{{ element.id }}/list/address"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>home</mat-icon>
          </button>
          <button
            *ngIf="!architect"
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/clients/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="architect"
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/clients/{{ element.id }}/edit/architect"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="userRole != 'CUSTOMER_SERVICE' && !architect"
            [disabled]="!element.membershipActive"
            mat-icon-button
            matTooltip="Creditar Pontos"
            matTooltipPosition="left"
            routerLink="/dashboard/clients/{{ element.id }}/points"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>money</mat-icon>
          </button>
          <button
            *ngIf="userRole != 'CUSTOMER_SERVICE' && architect"
            [disabled]="!element.membershipActive"
            mat-icon-button
            matTooltip="Creditar Pontos"
            matTooltipPosition="left"
            routerLink="/dashboard/clients/{{ element.id }}/points/architects"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>money</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Reenviar Credenciais"
            matTooltipPosition="left"
            (click)="openDialogResendCredentials(element.id)"
          >
            <mat-icon>send</mat-icon>
          </button>
          <button
            *ngIf="userRole != 'CUSTOMER_SERVICE' && !architect"
            mat-icon-button
            matTooltip="Questionários"
            matTooltipPosition="left"
            routerLink="/dashboard/quiz/customer/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>list</mat-icon>
          </button>
          <button
            *ngIf="userRole != 'CUSTOMER_SERVICE' && !architect"
            mat-icon-button
            matTooltip="Planos Membership"
            matTooltipPosition="left"
            routerLink="/dashboard/memberships/plans/customer/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>list_alt</mat-icon>
          </button>
          <button
            *ngIf="userRole != 'CUSTOMER_SERVICE'"
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter
      *ngIf="!architect"
      type="client"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      [formGroupValue]="formGroup"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>

    <app-form-filter
      *ngIf="architect"
      type="clientArchitect"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      [formGroupValue]="formGroup"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>
</app-logged>
