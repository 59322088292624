import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import numeral from 'numeral';
var ArchitectStoresFormComponent = /** @class */ (function () {
    function ArchitectStoresFormComponent(errorsService, snackBar, formBuilder, companiesService, route, router, zipCodeService, storeService, campaignService, businessUnitsService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.companiesService = companiesService;
        this.route = route;
        this.router = router;
        this.zipCodeService = zipCodeService;
        this.storeService = storeService;
        this.campaignService = campaignService;
        this.businessUnitsService = businessUnitsService;
        this.campaigns = [];
        this.states = [];
        this.states = [
            {
                abbr: 'AC',
                name: 'Acre',
            },
            {
                abbr: 'AL',
                name: 'Alagoas',
            },
            {
                name: 'Amapá',
                abbr: 'AP',
            },
            {
                name: 'Amazonas',
                abbr: 'AM',
            },
            {
                name: 'Bahia',
                abbr: 'BA',
            },
            {
                name: 'Ceará',
                abbr: 'CE',
            },
            {
                name: 'Distrito Federal',
                abbr: 'DF',
            },
            {
                name: 'Espírito Santo',
                abbr: 'ES',
            },
            {
                name: 'Goiás',
                abbr: 'GO',
            },
            {
                name: 'Maranhão',
                abbr: 'MA',
            },
            {
                name: 'Mato Grosso',
                abbr: 'MT',
            },
            {
                name: 'Mato Grosso do Sul',
                abbr: 'MS',
            },
            {
                name: 'Minas Gerais',
                abbr: 'MG',
            },
            {
                name: 'Pará',
                abbr: 'PA',
            },
            {
                name: 'Paraíba',
                abbr: 'PB',
            },
            {
                name: 'Paraná',
                abbr: 'PR',
            },
            {
                name: 'Pernambuco',
                abbr: 'PE',
            },
            {
                name: 'Piauí',
                abbr: 'PI',
            },
            {
                name: 'Rio de Janeiro',
                abbr: 'RJ',
            },
            {
                name: 'Rio Grande do Norte',
                abbr: 'RN',
            },
            {
                name: 'Rio Grande do Sul',
                abbr: 'RS',
            },
            {
                name: 'Rondônia',
                abbr: 'RO',
            },
            {
                name: 'Roraima',
                abbr: 'RR',
            },
            {
                name: 'Santa Catarina',
                abbr: 'SC',
            },
            {
                name: 'São Paulo',
                abbr: 'SP',
            },
            {
                name: 'Sergipe',
                abbr: 'SE',
            },
            {
                name: 'Tocantins',
                abbr: 'T',
            },
        ];
    }
    ArchitectStoresFormComponent.prototype.ngOnInit = function () {
        this.cnpjSelected = this.route.snapshot.paramMap.get('cnpj');
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            cnpj: [
                this.cnpjSelected,
                [Validators.required, Validators.minLength(14), Validators.maxLength(14)],
            ],
            nomeFantasia: [null, [Validators.required]],
            razaoSocial: [null, [Validators.required]],
            phoneNumber: [null, [Validators.required]],
            openingDate: [null, [Validators.required]],
            emailAddress: [null, [Validators.required]],
            zipCode: [null, [Validators.required]],
            street: [null, [Validators.required]],
            addressNumber: [null, [Validators.required]],
            complement: [null, []],
            neighborhood: [null, [Validators.required]],
            city: [null, [Validators.required]],
            state: [null, [Validators.required]],
            // pointsConversionFactorOut: [null, []],
            // pointsConversionFactorIn: [null, []],
            rateCashback: [null, [Validators.required]],
            rateAdministrative: [null, [Validators.required]],
            // rateRewardsTransaction: [null, []],
            // pointsExpiration: [null, []],
            campaigns: [null, [Validators.required]],
        });
        this.loadCampaigns().catch(function (err) { return console.log(err); });
        this.businessUnits().catch(function (err) { return console.log(err); });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
        if (this.cnpjSelected) {
            this.findOneStore().catch(function (err) { return console.log(err); });
        }
    };
    ArchitectStoresFormComponent.prototype.loadCampaigns = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.campaignService
                            .listCampaigns()
                            .subscribe(function (response) {
                            _this.campaigns = response.results;
                        }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectStoresFormComponent.prototype.businessUnits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.businessUnitsService
                            .list()
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.businessUnitsList = response.businessUnits;
                                console.log(this.businessUnitsList);
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectStoresFormComponent.prototype.findOneStore = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storeFind, storeBusinessUnits, campaignsStoreBusines, _a, campaignStoreBusines;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.storeService.getStoreByCnpj(this.cnpjSelected).toPromise().catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                    case 1:
                        storeFind = _b.sent();
                        console.log(storeFind);
                        return [4 /*yield*/, this.companiesService
                                .listStoresBusinessUnits("?cnpj=" + this.cnpjSelected)
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                            })];
                    case 2:
                        storeBusinessUnits = _b.sent();
                        this.storeBusiness =
                            storeBusinessUnits.stores.length > 0 ? storeBusinessUnits.stores[0] : undefined;
                        if (!this.storeBusiness) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.companiesService.findOneCampaignStoreBusinessUnit(this.storeBusiness.id)];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        _a = undefined;
                        _b.label = 5;
                    case 5:
                        campaignsStoreBusines = _a;
                        campaignStoreBusines = campaignsStoreBusines && campaignsStoreBusines.campaignStore.length > 0 ?
                            campaignsStoreBusines.campaignStore[0].campaign.id :
                            undefined;
                        if (campaignStoreBusines) {
                            this.campaignStoreId = campaignsStoreBusines.campaignStore[0].campaignStoreId;
                        }
                        if (this.storeBusiness || storeFind) {
                            this.formGroup.patchValue({
                                cnpj: this.store.cnpj,
                                nomeFantasia: this.storeBusiness ? this.storeBusiness.nomeFantasia : storeFind.nomeFantasia,
                                razaoSocial: this.storeBusiness ? this.storeBusiness.razaoSocial : storeFind.razaoSocial,
                                phoneNumber: this.storeBusiness ? this.storeBusiness.phoneNumber : storeFind.phoneNumber,
                                openingDate: this.storeBusiness ?
                                    this.storeBusiness.openingDate.substring(0, 10) :
                                    storeFind.openingDate.substring(0, 10),
                                emailAddress: this.storeBusiness ? this.storeBusiness.emailAddress : storeFind.emailAddress,
                                zipCode: this.storeBusiness ? this.storeBusiness.zipCode : storeFind.zipCode,
                                street: this.storeBusiness ? this.storeBusiness.street : storeFind.street,
                                addressNumber: this.storeBusiness ? this.storeBusiness.number : storeFind.number,
                                complement: this.storeBusiness ? this.storeBusiness.complement : storeFind.complement,
                                neighborhood: this.storeBusiness ? this.storeBusiness.neighborhood : storeFind.neighborhood,
                                city: this.storeBusiness ? this.storeBusiness.city : storeFind.city,
                                state: this.storeBusiness ? this.storeBusiness.state : storeFind.state,
                                // pointsConversionFactorOut: response.pointsConversionFactorOut,
                                // pointsConversionFactorIn: response.pointsConversionFactorIn,
                                rateCashback: this.storeBusiness ?
                                    numeral(this.storeBusiness.rateCashback).multiply(100).value() :
                                    '',
                                rateAdministrative: this.storeBusiness ?
                                    numeral(this.storeBusiness.rateAdministrative).multiply(100).value() :
                                    '',
                                // rateRewardsTransaction: response.taxRewardsTransaction,
                                // pointsExpiration: response.pointsExpiration,
                                campaigns: campaignStoreBusines,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectStoresFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService.findOneStoreBusinessUnit(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var campaignsStoreBusines, campaignStoreBusines;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.store = response.length > 0 ? response[0] : undefined;
                                        return [4 /*yield*/, this.companiesService.findOneCampaignStoreBusinessUnit(this.id)];
                                    case 1:
                                        campaignsStoreBusines = _a.sent();
                                        campaignStoreBusines = campaignsStoreBusines.campaignStore.length > 0 ?
                                            campaignsStoreBusines.campaignStore[0].campaign.id :
                                            undefined;
                                        if (campaignStoreBusines) {
                                            this.campaignStoreId = campaignsStoreBusines.campaignStore[0].campaignStoreId;
                                        }
                                        if (this.store.cnpj) {
                                            this.formGroup.get('cnpj').disable();
                                        }
                                        this.cnpj = this.store.cnpj;
                                        this.formGroup.patchValue({
                                            cnpj: this.store.cnpj,
                                            nomeFantasia: this.store.nomeFantasia,
                                            razaoSocial: this.store.razaoSocial,
                                            phoneNumber: this.store.phoneNumber,
                                            openingDate: this.store.openingDate.substring(0, 10),
                                            emailAddress: this.store.emailAddress,
                                            zipCode: this.store.zipCode,
                                            street: this.store.street,
                                            addressNumber: this.store.number,
                                            complement: this.store.complement,
                                            neighborhood: this.store.neighborhood,
                                            city: this.store.city,
                                            state: this.store.state,
                                            rateCashback: numeral(this.store.rateCashback).multiply(100).value(),
                                            rateAdministrative: numeral(this.store.rateAdministrative).multiply(100).value(),
                                            campaigns: campaignStoreBusines,
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectStoresFormComponent.prototype.loadAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zipCode;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                zipCode = this.formGroup.controls.zipCode.value;
                if (zipCode.length === 8) {
                    this.zipCodeService.getAddress(zipCode).subscribe(function (address) {
                        _this.formGroup.patchValue({
                            state: address.uf,
                            city: address.localidade,
                            neighborhood: address.bairro,
                            street: address.logradouro,
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    ArchitectStoresFormComponent.prototype.convertPercentToNumber = function (value) {
        if (value) {
            return numeral(Number(value)).divide(100).value();
        }
        else {
            return (value = 0);
        }
    };
    ArchitectStoresFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, businessUnitsFilter;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    cnpj: this.formGroup.value.cnpj || this.cnpj,
                    nomeFantasia: this.formGroup.value.nomeFantasia,
                    razaoSocial: this.formGroup.value.razaoSocial,
                    phoneNumber: this.formGroup.value.phoneNumber,
                    openingDate: this.formGroup.value.openingDate,
                    emailAddress: this.formGroup.value.emailAddress,
                    zipCode: this.formGroup.value.zipCode,
                    street: this.formGroup.value.street,
                    number: this.formGroup.value.addressNumber,
                    complement: this.formGroup.value.complement || '',
                    neighborhood: this.formGroup.value.neighborhood,
                    city: this.formGroup.value.city,
                    state: this.formGroup.value.state,
                    // pointsConversionFactorOut: this.formGroup.value.pointsConversionFactorOut,
                    // pointsConversionFactorIn: this.formGroup.value.pointsConversionFactorIn,
                    rateCashback: this.convertPercentToNumber(this.formGroup.value.rateCashback),
                    rateAdministrative: this.convertPercentToNumber(this.formGroup.value.rateAdministrative),
                };
                businessUnitsFilter = this.businessUnitsList.filter(function (bu) { return bu.name === 'architect'; });
                if (this.id || this.storeBusiness) {
                    if (this.storeBusiness && !this.id)
                        this.id = this.storeBusiness.id;
                    this.companiesService.updateStoresBusinessUnits(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var dataCampaign;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.id) return [3 /*break*/, 2];
                                    dataCampaign = {
                                        storeId: response.id,
                                        businessUnitId: businessUnitsFilter ? businessUnitsFilter[0].id : '',
                                        campaignId: this.formGroup.value.campaigns,
                                    };
                                    return [4 /*yield*/, this.companiesService.updateCampaignStoresBusinessUnits(this.campaignStoreId, dataCampaign).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        console.log(response);
                                                        return [4 /*yield*/, this.router.navigate(['./dashboard/architect-stores'])];
                                                    case 1:
                                                        _a.sent();
                                                        this.snackBar.open('Parceiro atualizado com sucesso.');
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); })];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.companiesService.createStoresBusinessUnits(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var dataCampaign;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.id) return [3 /*break*/, 2];
                                    dataCampaign = {
                                        storeId: response.id,
                                        businessUnitId: businessUnitsFilter ? businessUnitsFilter[0].id : '',
                                        campaignId: this.formGroup.value.campaigns,
                                    };
                                    return [4 /*yield*/, this.companiesService.createCampaignStoresBusinessUnits(dataCampaign).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        console.log(response);
                                                        return [4 /*yield*/, this.router.navigate(['./dashboard/architect-stores'])];
                                                    case 1:
                                                        _a.sent();
                                                        this.snackBar.open('Parceiro cadastrado com sucesso.');
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); })];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return ArchitectStoresFormComponent;
}());
export { ArchitectStoresFormComponent };
