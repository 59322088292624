import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ArchitectClosingsService = /** @class */ (function () {
    function ArchitectClosingsService(http) {
        this.http = http;
    }
    ArchitectClosingsService.prototype.findAll = function (_a) {
        var page = _a.page, range = _a.range;
        return this.http
            .get(environment.apiUrl + "/cms/architect-invoice-closings/?page=" + page + "&range=" + range)
            .toPromise();
    };
    ArchitectClosingsService.prototype.createClosing = function (data) {
        return this.http.post(environment.apiUrl + "/cms/architect-invoice-closings/", data).toPromise();
    };
    ArchitectClosingsService.prototype.getClosing = function (closingId) {
        return this.http.get(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId).toPromise();
    };
    ArchitectClosingsService.prototype.removeClosing = function (closingId) {
        return this.http.delete(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId).toPromise();
    };
    ArchitectClosingsService.prototype.checkNDPayment = function (closingId, storeBusinessUnitId) {
        return this.http.put(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId + "/check-nd-paymnent/" + storeBusinessUnitId, {}).toPromise();
    };
    ArchitectClosingsService.prototype.checkNFPayment = function (closingId, storeBusinessUnitId) {
        return this.http.put(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId + "/check-nf-paymnent/" + storeBusinessUnitId, {}).toPromise();
    };
    ArchitectClosingsService.prototype.approveAllStoreBusinessInvoices = function (closingId, storeBusinessUnitId) {
        return this.http.put(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId + "/approve-invoices/" + storeBusinessUnitId, {}).toPromise();
    };
    ArchitectClosingsService.prototype.exportClosing = function (closingId) {
        return this.http.get(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId + "/export").toPromise();
    };
    ArchitectClosingsService.prototype.exportStoreClosing = function (closingId, storeBusinessUnitId) {
        return this.http.get(environment.apiUrl + "/cms/architect-invoice-closings/" + closingId + "/export/" + storeBusinessUnitId).toPromise();
    };
    ArchitectClosingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArchitectClosingsService_Factory() { return new ArchitectClosingsService(i0.ɵɵinject(i1.HttpClient)); }, token: ArchitectClosingsService, providedIn: "root" });
    return ArchitectClosingsService;
}());
export { ArchitectClosingsService };
