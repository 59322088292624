import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import numeral from 'numeral';
import { ModalChooseCampaignTypeComponent } from './../../../../components/modal-choose-campaign-type/modal-choose-campaign-type.component';
import { ModalPartnerEditionMacroBindingInformation } from './../../../../components/modal-partner-edition-macro-binding-information/modal-partner-edition-macro-binding-information.component';
var StoreFormComponent = /** @class */ (function () {
    function StoreFormComponent(errorsService, zipCodeService, formBuilder, storeService, router, route, snackBar, datePipe, configService, dialog, campaignService) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.datePipe = datePipe;
        this.configService = configService;
        this.dialog = dialog;
        this.campaignService = campaignService;
        this.states = [];
        this.storeID = '';
        this.cnpjSelected = null;
        this.title = 'app';
        this.elementType = 'string';
        this.valueQrCode = '';
        this.segmentos = [];
        this.bankCodes = [];
        this.configs = [];
        this.params = { storeFormGroupValue: [] };
        this.campaigns = [];
        this.isMembershipActive = false;
        this.isPayActive = false;
        this.macroGroups = [];
        this.groups = [];
        this.subStoreNone = [];
        this.subStoreNameInvalid = false;
        this.macroGroupIdInvalid = false;
        this.messageMacroGroupError = false;
        this.messageSubError = false;
        this.subAndMacroInvalid = false;
        this.isUpdate = false;
        this.initForm();
        this.states = [
            {
                abbr: 'AC',
                name: 'Acre',
            },
            {
                abbr: 'AL',
                name: 'Alagoas',
            },
            {
                name: 'Amapá',
                abbr: 'AP',
            },
            {
                name: 'Amazonas',
                abbr: 'AM',
            },
            {
                name: 'Bahia',
                abbr: 'BA',
            },
            {
                name: 'Ceará',
                abbr: 'CE',
            },
            {
                name: 'Distrito Federal',
                abbr: 'DF',
            },
            {
                name: 'Espírito Santo',
                abbr: 'ES',
            },
            {
                name: 'Goiás',
                abbr: 'GO',
            },
            {
                name: 'Maranhão',
                abbr: 'MA',
            },
            {
                name: 'Mato Grosso',
                abbr: 'MT',
            },
            {
                name: 'Mato Grosso do Sul',
                abbr: 'MS',
            },
            {
                name: 'Minas Gerais',
                abbr: 'MG',
            },
            {
                name: 'Pará',
                abbr: 'PA',
            },
            {
                name: 'Paraíba',
                abbr: 'PB',
            },
            {
                name: 'Paraná',
                abbr: 'PR',
            },
            {
                name: 'Pernambuco',
                abbr: 'PE',
            },
            {
                name: 'Piauí',
                abbr: 'PI',
            },
            {
                name: 'Rio de Janeiro',
                abbr: 'RJ',
            },
            {
                name: 'Rio Grande do Norte',
                abbr: 'RN',
            },
            {
                name: 'Rio Grande do Sul',
                abbr: 'RS',
            },
            {
                name: 'Rondônia',
                abbr: 'RO',
            },
            {
                name: 'Roraima',
                abbr: 'RR',
            },
            {
                name: 'Santa Catarina',
                abbr: 'SC',
            },
            {
                name: 'São Paulo',
                abbr: 'SP',
            },
            {
                name: 'Sergipe',
                abbr: 'SE',
            },
            {
                name: 'Tocantins',
                abbr: 'T',
            },
        ];
    }
    StoreFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadCampaigns();
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = localStorage.getItem('userRole');
    };
    StoreFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cnpjSelected = this.route.snapshot.paramMap.get('cnpj');
                        this.storeID = this.route.snapshot.paramMap.get('storeID');
                        this.isUpdate = !!this.storeID;
                        this.valueQrCode = this.storeID;
                        this.formGroup = this.formBuilder.group({
                            nomeFantasia: [null, [Validators.required]],
                            razaoSocial: [null, [Validators.required]],
                            complement: ['', []],
                            cnpj: [
                                this.cnpjSelected,
                                [Validators.required, Validators.minLength(14), Validators.maxLength(14)],
                            ],
                            phoneNumber: [null, [Validators.required]],
                            emailAddress: [null, [Validators.required]],
                            mcc: [null, [Validators.required]],
                            mccDescription: [null, [Validators.required]],
                            mccCategory: [null, [Validators.required]],
                            mccCode: [null, []],
                            openingDate: [null, [Validators.required]],
                            liquidationDays: [null, [Validators.required]],
                            isMembershipActive: [null, [Validators.required]],
                            isPayActive: [null, [Validators.required]],
                            taxCashback: [null, []],
                            manualPaymentEnabled: [null, [Validators.required]],
                            futurePaymentEnabled: [null, [Validators.required]],
                            isConciergePaymentStore: [false, [Validators.required]],
                            taxAdministrative: [null, []],
                            taxRewardsTransaction: [null, []],
                            taxCreditCardTransaction: [null, []],
                            pointsConversionFactorIn: [null, []],
                            pointsConversionFactorOut: [null, []],
                            pointsExpiration: [null, []],
                            maximumInstallmentsPerTransaction: [null, [Validators.required]],
                            minimumInstallmentValue: [null, [Validators.required]],
                            // maximumPointsPerTransaction: [null, []],
                            minimumTransactionValue: [null, [Validators.required]],
                            street: [null, [Validators.required]],
                            number: [null, [Validators.required]],
                            neighborhood: [null, [Validators.required]],
                            city: [null, [Validators.required]],
                            state: [null, [Validators.required]],
                            zipCode: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
                            typePayment: [null, []],
                            isSubStore: [null, []],
                            macroGroup: [null, []],
                            subStores: this.formBuilder.array([this.createMacroGroupFormGroup()]),
                            bankCode: [null, [Validators.required]],
                            agency: [null, [Validators.required]],
                            accountNumber: [null, [Validators.required]],
                            accountDigit: [null, [Validators.required]],
                            accountType: [null, [Validators.required]],
                        });
                        if (this.cnpjSelected > 0) {
                            this.formGroup.get('cnpj').enable();
                            this.loadInfosConfigs();
                        }
                        else {
                            this.formGroup.get('cnpj').disable();
                        }
                        this.segmentosOptions = this.formGroup.controls.mcc.valueChanges.pipe(startWith(''), map(function (value) {
                            return _this.segmentos.filter(function (seg) {
                                var newValue = typeof value === 'object' ? value : value;
                                if (newValue != null &&
                                    seg.category != null &&
                                    seg.description != null &&
                                    seg.code != null) {
                                    return (seg.category == value ||
                                        seg.description == value ||
                                        seg.code == value ||
                                        seg.description.includes(newValue.toUpperCase()) ||
                                        seg.description.includes(newValue.toLowerCase()) ||
                                        seg.category.includes(newValue.toUpperCase()) ||
                                        seg.category.includes(newValue.toLowerCase()) ||
                                        seg.code.includes(newValue.toUpperCase()) ||
                                        seg.code.includes(newValue.toLowerCase()));
                                }
                            });
                        }));
                        return [4 /*yield*/, this.loadSegmentos()];
                    case 1:
                        _a.sent();
                        if (!(this.cnpjSelected > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.loadInfosStoreByCnpj()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.loadInfosStore()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.listMacroGroups();
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreFormComponent.prototype.listMacroGroups = function () {
        var _this = this;
        this.storeService.listMacroGroups().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.macroGroups = response.results;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreFormComponent.prototype.changeIsSubStore = function (e) {
        var e_1, _a, e_2, _b;
        this.isSubStore = e;
        var subStores = this.formGroup.get('subStores');
        if (!e) {
            try {
                for (var _c = tslib_1.__values(subStores.value), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var s = _d.value;
                    s.subStoreName = null;
                    s.subStoreId = null;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            try {
                for (var _e = tslib_1.__values(subStores.controls), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var s = _f.value;
                    s.value.subStoreName = null;
                    s.value.subStoreId = null;
                    this.subStoreNone.push(s.value);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
            this.subStoreNameInvalid = false;
            this.macroGroupIdInvalid = false;
            this.subAndMacroInvalid = false;
            this.messageSubError = false;
        }
        // if (!e) {
        //   for (const s of subStores.controls) {
        //     s.value.subStoreName = null
        //     s.value.macroGroupId = null
        //     s.value.subStoreId = null
        //     this.subStoreNone.push(s.value)
        //   }
        //   this.subStoreNameInvalid = false
        //   this.macroGroupIdInvalid = false
        //   this.subAndMacroInvalid = false
        // }
        // for (const n of Object.keys(this.subStoreNone)) {
        //   subStores.removeAt(0)
        //   subStores.reset()
        // }
    };
    StoreFormComponent.prototype.keyPress = function (e) {
        var subStores = this.formGroup.get('subStores');
        this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName;
    };
    StoreFormComponent.prototype.validStoreName = function (e) {
        var subStores = this.formGroup.get('subStores');
        this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName;
    };
    StoreFormComponent.prototype.changeMacroGroup = function (e) {
        var e_3, _a;
        var subStores = this.formGroup.get('subStores');
        try {
            for (var _b = tslib_1.__values(subStores.value), _c = _b.next(); !_c.done; _c = _b.next()) {
                var s = _c.value;
                s.id = !s.id ? null : s.id;
                s.macroGroupId = !s.macroGroupId ? null : s.macroGroupId;
                s.subStoreId = !s.subStoreId ? null : s.subStoreId;
                s.subStoreName = !s.subStoreName ? null : s.subStoreName;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.macroGroupIdInvalid = !subStores.controls[0].value.macroGroupId;
    };
    StoreFormComponent.prototype.addMacroGroupFormGroup = function (event) {
        event.preventDefault();
        var subStores = this.formGroup.get('subStores');
        this.subAndMacroInvalid = false;
        subStores.push(this.createMacroGroupFormGroup());
    };
    StoreFormComponent.prototype.removeOrClearMacroGroup = function (event, i) {
        event.preventDefault();
        var subStores = this.formGroup.get('subStores');
        if (i >= 1) {
            subStores.controls[i].value.subStoreName = null;
            subStores.controls[i].value.macroGroupId = null;
            subStores.controls[i].value.subStoreId = null;
            this.subStoreNone.push(subStores.controls[i].value);
            subStores.removeAt(i);
        }
        else {
            subStores.controls[i].value.subStoreName = null;
            subStores.controls[i].value.macroGroupId = null;
            subStores.controls[i].value.subStoreId = null;
            this.subStoreNone.push(subStores.controls[i].value);
            subStores.reset();
        }
    };
    StoreFormComponent.prototype.createMacroGroupFormGroup = function () {
        return new FormGroup({
            subStoreName: new FormControl(undefined),
            subStoreId: new FormControl(undefined),
            macroGroupId: new FormControl(undefined),
            id: new FormControl(undefined),
        });
    };
    StoreFormComponent.prototype.loadInfosStoreByCnpj = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storeService.getStoreByCnpj(this.cnpjSelected).toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.store = response;
                        this.isUpdate = !!response;
                        this.setInfosStore();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    StoreFormComponent.prototype.dynamicPhoneMask = function () {
        var mask = '(00)0000-00009';
        if (this.formGroup.value.phoneNumber && this.formGroup.value.phoneNumber.length > 10) {
            mask = '(00)00000-0000';
        }
        return mask;
    };
    StoreFormComponent.prototype.loadInfosStore = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.store = response;
                        this.setInfosStore();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    StoreFormComponent.prototype.loadInfosConfigs = function () {
        var _this = this;
        this.configService.view().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.configs = response;
                this.formGroup.patchValue({
                    pointsExpiration: this.configs.results.jcoinsExpirationInDays,
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            alert(error.error.message);
        });
    };
    StoreFormComponent.prototype.changeIsMembershipActive = function (event) {
        this.isMembershipActive = event.value;
        if (!this.isMembershipActive && !this.isPayActive) {
            this.isCardPayment = false;
            this.isJcoinsPayment = false;
            this.isJcoinsPriorityPayment = false;
            this.formGroup.get('pointsConversionFactorOut').setValidators(Validators.min(1));
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorOut').setErrors(null);
            this.formGroup.get('pointsConversionFactorIn').setValidators(Validators.min(1));
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorIn').setErrors(null);
            this.formGroup.get('taxCashback').setValidators(null);
            this.formGroup.get('taxCashback').updateValueAndValidity();
            this.formGroup.get('taxCashback').setErrors(null);
            this.formGroup.get('taxAdministrative').setValidators(null);
            this.formGroup.get('taxAdministrative').updateValueAndValidity();
            this.formGroup.get('taxAdministrative').setErrors(null);
            this.formGroup.get('taxRewardsTransaction').setValidators(null);
            this.formGroup.get('taxRewardsTransaction').updateValueAndValidity();
            this.formGroup.get('taxRewardsTransaction').setErrors(null);
            this.formGroup.get('pointsExpiration').setValidators(null);
            this.formGroup.get('pointsExpiration').updateValueAndValidity();
            this.formGroup.get('pointsExpiration').setErrors(null);
            this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null);
            this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity();
            this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null);
            this.formGroup.get('minimumInstallmentValue').setValidators(null);
            this.formGroup.get('minimumInstallmentValue').updateValueAndValidity();
            this.formGroup.get('minimumInstallmentValue').setErrors(null);
            this.formGroup.get('minimumTransactionValue').setValidators(null);
            this.formGroup.get('minimumTransactionValue').updateValueAndValidity();
            this.formGroup.get('minimumTransactionValue').setErrors(null);
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
        }
        if (!this.isMembershipActive && this.isPayActive) {
            this.isCardPayment = false;
            this.isJcoinsPayment = false;
            this.isJcoinsPriorityPayment = false;
            this.formGroup.get('pointsConversionFactorOut').setValidators(Validators.min(1));
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorOut').setErrors(null);
            this.formGroup.get('pointsConversionFactorIn').setValidators(Validators.min(1));
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorIn').setErrors(null);
            this.formGroup.get('taxCashback').setValidators(null);
            this.formGroup.get('taxCashback').updateValueAndValidity();
            this.formGroup.get('taxCashback').setErrors(null);
            this.formGroup.get('taxAdministrative').setValidators(null);
            this.formGroup.get('taxAdministrative').updateValueAndValidity();
            this.formGroup.get('taxAdministrative').setErrors(null);
            this.formGroup.get('pointsExpiration').setValidators(null);
            this.formGroup.get('pointsExpiration').updateValueAndValidity();
            this.formGroup.get('pointsExpiration').setErrors(null);
        }
    };
    StoreFormComponent.prototype.changeIsPayActive = function (event) {
        this.isPayActive = event.value;
        if (!this.isPayActive && !this.isMembershipActive) {
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
            this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null);
            this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity();
            this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null);
            this.formGroup.get('minimumInstallmentValue').setValidators(null);
            this.formGroup.get('minimumInstallmentValue').updateValueAndValidity();
            this.formGroup.get('minimumInstallmentValue').setErrors(null);
        }
        if (!this.isPayActive && this.isMembershipActive) {
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
        }
        if (this.isPayActive) {
            this.formGroup.get('taxCreditCardTransaction').setValidators([Validators.required]);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('maximumInstallmentsPerTransaction').setValidators([Validators.required]);
            this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity();
            this.formGroup.get('minimumInstallmentValue').setValidators([Validators.required, null]);
            this.formGroup.get('minimumInstallmentValue').updateValueAndValidity();
            this.formGroup.get('minimumTransactionValue').setValidators([Validators.required, null]);
            this.formGroup.get('minimumTransactionValue').updateValueAndValidity();
        }
    };
    StoreFormComponent.prototype.setInfosStore = function () {
        var _this = this;
        var e_4, _a;
        this.isSubStore = this.store.isSubStore;
        this.isMembershipActive = this.store.isMembershipActive;
        this.store.isPayActive = this.store.isPayActive;
        this.isPayActive = this.store.isPayActive;
        this.isActiveRoadpass = this.store.isActiveRoadpass;
        this.isZoopEnabled = this.store.isZoopEnabled;
        this.idCampaign = this.store.idCampaign;
        this.descriptionCampaign = this.store.descriptionCampaign;
        if (this.store.isCardPayment && this.store.isJcoinsPayment) {
            this.typePayment = 'cardPaymentAndJcoinsPayment';
        }
        if (!this.store.isCardPayment && this.store.isJcoinsPayment) {
            this.typePayment = 'jcoinsPayment';
        }
        if (this.store.isCardPayment && !this.store.isJcoinsPayment) {
            this.typePayment = 'cardPayment';
        }
        if (this.store.isJcoinsPriorityPayment) {
            this.typePayment = 'jcoinsPriorityPayment';
        }
        try {
            for (var _b = tslib_1.__values(this.store.subStoreAndMacroGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                var sm = _c.value;
                var subStores = this.formGroup.get('subStores');
                subStores.push(this.createMacroGroupFormGroup());
                var mg = sm.macroGroup.map(function (mg) { return mg.id; });
                var sb = sm.subStore.map(function (sb) { return sb.subStoreName; });
                var sbId = sm.subStore.map(function (sb) { return sb.id; });
                this.groups.push({
                    subStoreName: sb[0],
                    subStoreId: sbId[0],
                    macroGroupId: mg[0],
                    id: sm.id,
                });
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        if (!this.store.mccCode) {
            var filterMcc = this.segmentos.filter(function (s) { return s.description === _this.store.mccDescription; });
            if (filterMcc.length) {
                this.store.mccCode = filterMcc[0].code;
            }
        }
        // this.groups.sort((a, b) => {
        //   return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0
        // })
        this.formGroup.patchValue({
            nomeFantasia: this.store.nomeFantasia,
            razaoSocial: this.store.razaoSocial,
            complement: this.store.complement != null ? this.store.complement : '',
            cnpj: this.store.cnpj,
            emailAddress: this.store.emailAddress,
            phoneNumber: this.store.phoneNumber.replace('+55', ''),
            openingDate: this.store.openingDate.substring(0, 10),
            liquidationDays: this.store.liquidationDays,
            mcc: this.store.mccCategory + ' - ' + this.store.mccDescription,
            mccCategory: this.store.mccCategory,
            mccDescription: this.store.mccDescription,
            mccCode: this.store.mccCode,
            isMembershipActive: this.store.isMembershipActive,
            isPayActive: this.store.isPayActive,
            manualPaymentEnabled: this.store.manualPaymentEnabled,
            futurePaymentEnabled: this.store.futurePaymentEnabled,
            isConciergePaymentStore: this.store.isConciergePaymentStore || false,
            maximumInstallmentsPerTransaction: this.store.maximumInstallmentsPerTransaction,
            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
            // maximumPointsPerTransaction: this.store.maximumPointsPerTransaction,
            taxCreditCardTransaction: numeral(this.store.taxCreditCardTransaction).multiply(100).value(),
            pointsExpiration: this.store.pointsExpiration,
            minimumTransactionValue: this.store.minimumTransactionValue / 100,
            pointsConversionFactorIn: this.store.pointsConversionFactorIn / 100,
            pointsConversionFactorOut: this.store.pointsConversionFactorOut / 100,
            taxCashback: numeral(this.store.taxCashback).multiply(100).value(),
            taxAdministrative: numeral(this.store.taxAdministrative).multiply(100).value(),
            taxRewardsTransaction: numeral(this.store.taxRewardsTransaction).multiply(100).value(),
            street: this.store.street,
            number: this.store.number,
            neighborhood: this.store.neighborhood,
            city: this.store.city,
            state: this.store.state,
            zipCode: this.store.zipCode,
            typePayment: this.typePayment,
            isSubStore: this.store.isSubStore,
            subStores: this.groups,
            bankCode: this.store.bank ? this.store.bank.bankCode : null,
            agency: this.store.bank ? this.store.bank.agency : null,
            accountNumber: this.store.bank ? this.store.bank.accountNumber : null,
            accountDigit: this.store.bank ? this.store.bank.accountDigit : null,
            accountType: this.store.bank ? this.store.bank.accountType : null,
        });
        if (this.store.pointsConversionFactorIn) {
            this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
        }
        if (this.store.pointsConversionFactorOut) {
            this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
        }
    };
    StoreFormComponent.prototype.displaySegment = function (seg) {
        return seg ? "" + seg : '';
    };
    StoreFormComponent.prototype.convertPercentToNumber = function (value) {
        if (value) {
            return numeral(Number(value)).divide(100).value();
        }
        else {
            return (value = 0);
        }
    };
    StoreFormComponent.prototype.changeTypePayment = function (e) {
        this.typePayment = e.value;
        if (e.value === 'cardPaymentAndJcoinsPayment' || e.value === 'jcoinsPriorityPayment') {
            if (e.value === 'cardPaymentAndJcoinsPayment') {
                this.isCardPayment = true;
                this.isJcoinsPayment = true;
                this.isJcoinsPriorityPayment = false;
            }
            if (e.value === 'jcoinsPriorityPayment') {
                this.isCardPayment = false;
                this.isJcoinsPayment = false;
                this.isJcoinsPriorityPayment = true;
            }
            this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
            this.formGroup.get('taxCashback').setValidators([Validators.required]);
            this.formGroup.get('taxCashback').updateValueAndValidity();
            this.formGroup.get('taxAdministrative').setValidators([Validators.required]);
            this.formGroup.get('taxAdministrative').updateValueAndValidity();
            this.formGroup.get('taxRewardsTransaction').setValidators([Validators.required]);
            this.formGroup.get('taxRewardsTransaction').updateValueAndValidity();
            this.formGroup.get('pointsExpiration').setValidators([Validators.required]);
            this.formGroup.get('pointsExpiration').updateValueAndValidity();
            this.formGroup.get('maximumInstallmentsPerTransaction').setValidators([Validators.required]);
            this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity();
            this.formGroup.get('minimumInstallmentValue').setValidators([Validators.required]);
            this.formGroup.get('minimumInstallmentValue').updateValueAndValidity();
            this.formGroup.get('minimumTransactionValue').setValidators([Validators.required]);
            this.formGroup.get('minimumTransactionValue').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
        }
        if (e.value === 'cardPayment') {
            this.isCardPayment = true;
            this.isJcoinsPayment = false;
            this.isJcoinsPriorityPayment = false;
            this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
            this.formGroup.get('taxCashback').setValidators([Validators.required]);
            this.formGroup.get('taxCashback').updateValueAndValidity();
            this.formGroup.get('taxAdministrative').setValidators([Validators.required]);
            this.formGroup.get('taxAdministrative').updateValueAndValidity();
            this.formGroup.get('pointsExpiration').setValidators([Validators.required]);
            this.formGroup.get('pointsExpiration').updateValueAndValidity();
            this.formGroup.get('maximumInstallmentsPerTransaction').setValidators([Validators.required]);
            this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity();
            this.formGroup.get('minimumInstallmentValue').setValidators([Validators.required]);
            this.formGroup.get('minimumInstallmentValue').updateValueAndValidity();
            this.formGroup.get('minimumTransactionValue').setValidators([Validators.required]);
            this.formGroup.get('minimumTransactionValue').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
            this.formGroup.get('taxRewardsTransaction').setValidators(null);
            this.formGroup.get('taxRewardsTransaction').updateValueAndValidity();
            this.formGroup.get('taxRewardsTransaction').setErrors(null);
            this.formGroup.get('taxRewardsTransaction').setValidators(null);
            this.formGroup.get('taxRewardsTransaction').updateValueAndValidity();
            this.formGroup.get('taxRewardsTransaction').setErrors(null);
        }
        if (e.value === 'jcoinsPayment') {
            this.isCardPayment = false;
            this.isJcoinsPayment = true;
            this.isJcoinsPriorityPayment = false;
            this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
            this.formGroup.get('taxRewardsTransaction').setValidators([Validators.required]);
            this.formGroup.get('taxRewardsTransaction').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorIn').setValidators(Validators.min(1));
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorIn').setErrors(null);
            this.formGroup.get('taxAdministrative').setValidators(null);
            this.formGroup.get('taxAdministrative').updateValueAndValidity();
            this.formGroup.get('taxAdministrative').setErrors(null);
            this.formGroup.get('taxCashback').setValidators(null);
            this.formGroup.get('taxCashback').updateValueAndValidity();
            this.formGroup.get('taxCashback').setErrors(null);
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity();
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
            this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null);
            this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity();
            this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null);
            this.formGroup.get('minimumInstallmentValue').setValidators(null);
            this.formGroup.get('minimumInstallmentValue').updateValueAndValidity();
            this.formGroup.get('minimumInstallmentValue').setErrors(null);
            this.formGroup.get('minimumTransactionValue').setValidators(null);
            this.formGroup.get('minimumTransactionValue').updateValueAndValidity();
            this.formGroup.get('minimumTransactionValue').setErrors(null);
        }
    };
    StoreFormComponent.prototype.loadCampaigns = function () {
        var _this = this;
        this.campaignService
            .listCampaigns()
            .subscribe(function (response) {
            _this.campaigns = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    StoreFormComponent.prototype.verifyFormValidation = function () {
        if (!this.formGroup.valid ||
            this.subStoreNameInvalid ||
            this.macroGroupIdInvalid ||
            this.subAndMacroInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        return true;
    };
    StoreFormComponent.prototype.openDialogCampaignType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var macroGroupsIds_1, defaultCampaignsList_1, extraCampaignsList_1, dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.verifyFormValidation()) {
                    if (this.isCurrencyInputValueValid()) {
                        if (this.storeID) {
                            macroGroupsIds_1 = [];
                            this.formGroup.get('subStores')
                                .value.map(function (macro) { return macroGroupsIds_1.push(macro.macroGroupId); });
                            this.campaignService.verifyStores({ macroGroupsIds: macroGroupsIds_1, storesId: this.storeID })
                                .toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var parsedBindedMacroList, dialogRef;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    parsedBindedMacroList = [];
                                    response.results.forEach(function (macro) {
                                        if (parsedBindedMacroList
                                            .includes(macro.participatingCampaignWithMacroGroup.macroGroups.id)) {
                                            macro.participatingCampaignWithMacroGroup.macroGroups = {};
                                        }
                                        else {
                                            parsedBindedMacroList
                                                .push(macro.participatingCampaignWithMacroGroup.macroGroups.id);
                                        }
                                        if (parsedBindedMacroList
                                            .includes(macro.participatingCampaignWithMacroGroup.campaigns.id)) {
                                            macro.participatingCampaignWithMacroGroup.campaigns = {};
                                        }
                                        else {
                                            parsedBindedMacroList
                                                .push(macro.participatingCampaignWithMacroGroup.campaigns.id);
                                        }
                                    });
                                    if (response.results.length > 0) {
                                        dialogRef = this.dialog.open(ModalPartnerEditionMacroBindingInformation, {
                                            width: '500px',
                                            data: response,
                                        });
                                        dialogRef.afterClosed().subscribe(function (result) {
                                            if (result !== 'cancel') {
                                                _this.submit().catch(function (err) { return console.log(err); });
                                            }
                                        });
                                    }
                                    else {
                                        this.submit().catch(function (err) { return console.log(err); });
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) { return console.log(error); });
                        }
                        else {
                            defaultCampaignsList_1 = [];
                            extraCampaignsList_1 = [];
                            this.campaigns.map(function (campaign) {
                                if (campaign.isDefault) {
                                    defaultCampaignsList_1.push(campaign);
                                }
                                else {
                                    extraCampaignsList_1.push(campaign);
                                }
                            });
                            dialogRef = this.dialog.open(ModalChooseCampaignTypeComponent, {
                                width: '500px',
                                data: {
                                    campaigns: { default: defaultCampaignsList_1, extra: extraCampaignsList_1 },
                                },
                            });
                            dialogRef.afterClosed().subscribe(function (result) {
                                if (result !== 'cancel') {
                                    _this.campaignId = result.campaignId;
                                    _this.defaultCampaignId = result.defaultCampaignId;
                                    _this.submit().catch(function (err) { return console.log(err); });
                                }
                            });
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    StoreFormComponent.prototype.isCurrencyInputValueValid = function () {
        var parsedPointsConvertionFactorIn = Math.round(this.formGroup.value.pointsConversionFactorIn * 100);
        var parsedPointsConvertionFactorOut = Math.round(this.formGroup.value.pointsConversionFactorOut * 100);
        if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
            (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
            this.snackBar.open('Escolha um valor maior ou igual a R$1,00');
            return false;
        }
        return true;
    };
    StoreFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_5, _a, e_6, _b, taxAdm, taxAdm, taxAdm, parsedMinimunTransactionValue, parsedMinimunInstallmentValue, parsedPointsConvertionFactorIn, parsedPointsConvertionFactorOut, data, _c, _d, sn, unique, subStores_1, _e, _f, a, subStores;
            var _this = this;
            return tslib_1.__generator(this, function (_g) {
                if (typeof this.formGroup.value.taxAdministrative === 'string') {
                    taxAdm = this.formGroup.value.taxAdministrative.replace(',', '.');
                    this.formGroup.value.taxAdministrative = Number(taxAdm);
                }
                if (typeof this.formGroup.value.taxRewardsTransaction === 'string') {
                    taxAdm = this.formGroup.value.taxRewardsTransaction.replace(',', '.');
                    this.formGroup.value.taxRewardsTransaction = Number(taxAdm);
                }
                if (typeof this.formGroup.value.taxCashback === 'string') {
                    taxAdm = this.formGroup.value.taxCashback.replace(',', '.');
                    this.formGroup.value.taxCashback = Number(taxAdm);
                }
                parsedMinimunTransactionValue = Math.round(this.formGroup.value.minimumTransactionValue * 100);
                parsedMinimunInstallmentValue = Math.round(this.formGroup.value.minimumInstallmentValue * 100);
                parsedPointsConvertionFactorIn = Math.round(this.formGroup.value.pointsConversionFactorIn * 100);
                parsedPointsConvertionFactorOut = Math.round(this.formGroup.value.pointsConversionFactorOut * 100);
                if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
                    (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
                    this.snackBar.open('Escolha um valor maior ou igual a R$1,00');
                    return [2 /*return*/, false];
                }
                data = {
                    nomeFantasia: this.formGroup.value.nomeFantasia,
                    razaoSocial: this.formGroup.value.razaoSocial,
                    emailAddress: this.formGroup.value.emailAddress,
                    phoneNumber: this.formGroup.value.phoneNumber ? '+55' + this.formGroup.value.phoneNumber : null,
                    openingDate: this.formGroup.value.openingDate,
                    liquidationDays: this.formGroup.value.liquidationDays,
                    mccCategory: this.formGroup.value.mccCategory,
                    mccDescription: this.formGroup.value.mccDescription,
                    mccCode: this.formGroup.value.mccCode,
                    taxCashback: this.convertPercentToNumber(this.formGroup.value.taxCashback),
                    taxAdministrative: this.convertPercentToNumber(this.formGroup.value.taxAdministrative),
                    taxRewardsTransaction: this.convertPercentToNumber(this.formGroup.value.taxRewardsTransaction),
                    taxCreditCardTransaction: this.convertPercentToNumber(this.formGroup.value.taxCreditCardTransaction),
                    pointsExpiration: this.formGroup.value.pointsExpiration,
                    street: this.formGroup.value.street,
                    number: this.formGroup.value.number,
                    neighborhood: this.formGroup.value.neighborhood,
                    city: this.formGroup.value.city,
                    state: this.formGroup.value.state,
                    zipCode: this.formGroup.value.zipCode,
                    complement: this.formGroup.value.complement,
                    isMembershipActive: this.formGroup.value.isMembershipActive,
                    isPayActive: this.formGroup.value.isPayActive,
                    manualPaymentEnabled: this.formGroup.value.manualPaymentEnabled,
                    futurePaymentEnabled: this.formGroup.value.futurePaymentEnabled,
                    isConciergePaymentStore: this.formGroup.value.isConciergePaymentStore,
                    maximumInstallmentsPerTransaction: this.formGroup.value.maximumInstallmentsPerTransaction
                        ? this.formGroup.value.maximumInstallmentsPerTransaction
                        : 0,
                    // "maximumPointsPerTransaction": this.formGroup.value.maximumPointsPerTransaction,
                    minimumTransactionValue: parsedMinimunTransactionValue,
                    minimumInstallmentValue: parsedMinimunInstallmentValue,
                    pointsConversionFactorIn: parsedPointsConvertionFactorIn,
                    pointsConversionFactorOut: parsedPointsConvertionFactorOut,
                    isCardPayment: this.isCardPayment,
                    isJcoinsPayment: this.isJcoinsPayment,
                    isJcoinsPriorityPayment: this.isJcoinsPriorityPayment,
                    macro: this.formGroup.value.subStores,
                    isSubStore: this.isSubStore,
                    bank: {
                        bankNumber: this.formGroup.value.bankCode,
                        agency: this.formGroup.value.agency,
                        account: this.formGroup.value.accountNumber,
                        digit: this.formGroup.value.accountDigit,
                        accountType: this.formGroup.value.accountType,
                    },
                    defaultCampaignId: this.defaultCampaignId,
                    campaignId: this.campaignId,
                    cnpj: '',
                };
                if (this.subStoreNone.length) {
                    try {
                        for (_c = tslib_1.__values(this.subStoreNone), _d = _c.next(); !_d.done; _d = _c.next()) {
                            sn = _d.value;
                            data.macro.push(sn);
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                    data.macro = tslib_1.__spread(new Set(data.macro));
                }
                unique = tslib_1.__spread(new Set(data.macro));
                data.macro = unique;
                if (this.isSubStore) {
                    subStores_1 = this.formGroup.get('subStores');
                    if (subStores_1.controls.length) {
                        this.subStoreNameInvalid = !subStores_1.controls[0].value.subStoreName;
                        this.macroGroupIdInvalid = !subStores_1.controls[0].value.macroGroupId;
                        try {
                            for (_e = tslib_1.__values(subStores_1.controls), _f = _e.next(); !_f.done; _f = _e.next()) {
                                a = _f.value;
                                if (a.value.subStoreName && !a.value.macroGroupId) {
                                    this.messageMacroGroupError = true;
                                }
                                else {
                                    this.messageMacroGroupError = false;
                                }
                                if (this.isSubStore && !a.value.subStoreName && a.value.macroGroupId) {
                                    this.messageSubError = true;
                                }
                                else {
                                    this.messageSubError = false;
                                }
                            }
                        }
                        catch (e_6_1) { e_6 = { error: e_6_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                            }
                            finally { if (e_6) throw e_6.error; }
                        }
                    }
                    else {
                        this.subAndMacroInvalid = true;
                    }
                }
                if (this.cnpjSelected > 0) {
                    data.cnpj = this.formGroup.value.cnpj;
                }
                if (this.messageMacroGroupError) {
                    this.snackBar.open('Escolha um Macro Grupo para Sub-empresa');
                    return [2 /*return*/, false];
                }
                if (this.messageSubError) {
                    this.snackBar.open('Escolha uma Sub-empresa para o Macro Grupo');
                    return [2 /*return*/, false];
                }
                subStores = this.formGroup.get('subStores');
                this.macroGroupIdInvalid = !subStores.value.find(function () { return true; }).macroGroupId;
                if (!this.formGroup.value.futurePaymentEnabled &&
                    !this.formGroup.value.manualPaymentEnabled &&
                    !this.formGroup.value.isMembershipActive) {
                    this.snackBar.open('Escolher opção de configuração');
                    return [2 /*return*/, false];
                }
                this.verifyFormValidation();
                if (!this.storeID) {
                    this.storeService.addStore(data).subscribe(function (_) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.snackBar.open('Parceiro cadastrado com sucesso.');
                            this.router.navigate(['dashboard', 'stores']);
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.storeService.updateStore(data, this.storeID).subscribe(function (_) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.snackBar.open('Parceiro atualizado com sucesso.');
                            this.router.navigate(['dashboard', 'stores']);
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        console.log(error.error.message);
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    StoreFormComponent.prototype.onChange = function (event) {
        this.formGroup.patchValue({
            mccCategory: event.category,
            mccDescription: event.description,
            mccCode: event.code,
        });
    };
    StoreFormComponent.prototype.loadSegmentos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storeService.getSegmentos().toPromise()
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.segmentos = response;
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreFormComponent.prototype.loadAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zipCode;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                zipCode = this.formGroup.controls.zipCode.value;
                if (zipCode.length === 8) {
                    this.zipCodeService.getAddress(zipCode).subscribe(function (address) {
                        _this.formGroup.patchValue({
                            state: address.uf,
                            city: address.localidade,
                            neighborhood: address.bairro,
                            street: address.logradouro,
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return StoreFormComponent;
}());
export { StoreFormComponent };
