import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var ArchitectCompanyListComponent = /** @class */ (function () {
    function ArchitectCompanyListComponent(companiesService, snackBar, dialog) {
        this.companiesService = companiesService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['cnpj', 'companyName', 'isCpf', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ArchitectCompanyListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjArchitectCompany',
                label: 'CNPJ',
                placeholder: 'CNPJ',
            },
            {
                field: 'select',
                formControlName: 'isCpfArchitectCompany',
                label: 'Tem CPF cadastrado ?',
                placeholder: 'Tem CPF cadastrado',
                list: [
                    { key: 'true', value: 'Sim' },
                    { key: 'false', value: 'Não' },
                ],
                optionDefault: 'TODOS',
            },
        ];
    };
    ArchitectCompanyListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, companies, TotalRegisters;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .list()
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { companies: [], TotalRegisters: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), companies = _a.companies, TotalRegisters = _a.TotalRegisters;
                        this.dataSource = new MatTableDataSource(companies);
                        this.pageTotal = TotalRegisters;
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectCompanyListComponent.prototype.remove = function (id, preRegistration) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa Empresa?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.companiesService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (error) { return console.log(error); });
                                this.snackBar.open('Empresa removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    ArchitectCompanyListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter.companies;
        this.pageTotal = returnFilter.TotalPages;
        this.dataSource = new MatTableDataSource(returnFilter.companies);
        this.resultsAll = returnFilter.companies;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return ArchitectCompanyListComponent;
}());
export { ArchitectCompanyListComponent };
