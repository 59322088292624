import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var AirportCompanyListComponent = /** @class */ (function () {
    function AirportCompanyListComponent(airportsService, snackBar, dialog) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['cnpj', 'name', 'isCpf', 'balance', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    AirportCompanyListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameAirportCompany',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjAirportCompany',
                label: 'CNPJ',
                placeholder: 'CNPJ',
            },
            {
                field: 'select',
                formControlName: 'isCpfAirportCompany',
                label: 'Tem CPF cadastrado',
                placeholder: 'Tem CPF cadastrado',
                list: [
                    { key: 'true', value: 'Sim' },
                    { key: 'false', value: 'Não' },
                ],
                optionDefault: 'TODOS',
            },
        ];
    };
    AirportCompanyListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, results, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.airportsService
                            .listCompanyAirport()
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = total;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyListComponent.prototype.remove = function (id, preRegistration) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa Empresa Aeroporto?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    if (preRegistration) {
                        return [2 /*return*/, this.airportsService.deletePreRegisterAirportCompany(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.list().catch(function (error) { return console.log(error); });
                                    this.snackBar.open('Empresa Aeroporto removida com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    }
                    else {
                        return [2 /*return*/, this.airportsService.deleteAirportCompany(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.list().catch(function (error) { return console.log(error); });
                                    this.snackBar.open('Empresa Aeroporto removida com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    }
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    AirportCompanyListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return AirportCompanyListComponent;
}());
export { AirportCompanyListComponent };
