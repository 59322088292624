<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Pré-Cadastro</h2>
    <div class="store-form__header__actions">
      <!-- <button
        *ngIf="typePreRegistration"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration/CMS"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button> -->
      <!-- <button
        *ngIf="typePreRegistration"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration/CMS"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button> -->
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="cpf" mask="000.000.000-00" required />
              <mat-error *ngIf="formGroup.controls.cpf?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cpf) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" required />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" required />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.lastName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Gênero</mat-label>
              <mat-select formControlName="gender">
                <mat-option [value]="'female'">Feminino</mat-option>
                <mat-option [value]="'male'">Masculino</mat-option>
                <mat-option [value]="'none'">Prefiro não falar</mat-option>
                <mat-option [value]="'other'">Outro</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de Nascimento</mat-label>
              <input type="date" matInput formControlName="birthDate" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
            </mat-form-field>

            <mat-form-field *ngIf="typePreRegistration === 'architects'">
              <mat-label>Profissão</mat-label>
              <mat-select formControlName="profession">
                <mat-option [value]="'ARCHITECT'">Arquiteto</mat-option>
                <mat-option [value]="'INTERIOR_DESIGNER'">Designer de Interiores</mat-option>
                <mat-option [value]="'LANDSCAPIST'">Paisagista</mat-option>
                <mat-option [value]="'OTHERS'">Outras</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.role?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.role) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typePreRegistration === 'architects'">
              <mat-label>Crea</mat-label>
              <input matInput formControlName="crea" />
            </mat-form-field>

            <mat-form-field *ngIf="typePreRegistration === 'architects'">
              <mat-label>Cau</mat-label>
              <input matInput formControlName="cau" />
            </mat-form-field>

            <mat-form-field *ngIf="typePreRegistration === 'architects'">
              <mat-label>Asbea</mat-label>
              <input matInput formControlName="asbea" />
            </mat-form-field>

            <mat-form-field *ngIf="typePreRegistration === 'architects'">
              <mat-label>Escritório</mat-label>
              <input matInput formControlName="companyName" />
            </mat-form-field>

            <mat-form-field *ngIf="typePreRegistration === 'architects' && notCpnj">
              <mat-label>CNPJ do escritório</mat-label>
              <input
                matInput
                formControlName="companiesInput"
                placeholder="CNPJ"
                mask="00.000.000/0000-00"
                (input)="valueInput($event)"
              />
            </mat-form-field>

            <mat-form-field
              class="example-chip-list"
              *ngIf="typePreRegistration === 'architects' && !notCpnj"
            >
              <mat-label>CNPJ do escritório</mat-label>
              <mat-chip-list #chipListCompanies>
                <mat-chip
                  *ngFor="let company of selectedCompanies"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="remove(company)"
                >
                  {{ company.cnpj }} - {{ company.companyName }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  matInput
                  #storeInput
                  [formControl]="companyControl"
                  formControlName="companiesInput"
                  [matAutocomplete]="autoCompany"
                  [matChipInputFor]="chipListCompanies"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (input)="valueInput($event)"
                />
              </mat-chip-list>
              <mat-autocomplete #autoCompany="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
                  {{ company.cnpj }} - {{ company.companyName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div *ngIf="typePreRegistration === 'architects'">
              <mat-form-field>
                <mat-label>Documento arquiteto (PDF)</mat-label>
                <input matInput disabled />
                <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                  <mat-icon>attach_file</mat-icon>
                </a>
                <span *ngIf="approveDoc" class="filename-placeholder">
                  <mat-icon>description</mat-icon> {{ this.approveDoc }}
                </span>
                <div>
                  <span class="remove-doc-wrapper" style="margin-right: 5px; margin-left: 20px">
                    <button
                      class="btn-primary-light"
                      mat-button
                      *ngIf="preRegistrationID && documentUrl"
                      (click)="download($event)"
                    >
                      Download
                    </button>
                  </span>
                  <span class="remove-doc-wrapper">
                    <button
                      class="btn-primary-light"
                      mat-button
                      *ngIf="approveDoc"
                      (click)="removeDocument()"
                    >
                      <mat-icon>cancel</mat-icon> Remover documento
                    </button>
                  </span>
                </div>
                <input
                  hidden
                  (change)="selectFile($event)"
                  #fileInputBanner
                  type="file"
                  id="file"
                />
              </mat-form-field>
            </div>

            <p style="margin-bottom: 0px; font-weight: bold">Documento</p>
            <mat-form-field>
              <mat-label>Frente</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputDocumentFront.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentFrontImage"
                matSuffix
                mat-icon-button
                (click)="editDocumentFrontImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentFrontImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('documentFront')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedDocumentFrontImage != ''"
                width="350"
                src="{{ croppedDocumentFrontImage }}"
              />
              <input
                hidden
                (change)="selectFileDocumentFront($event)"
                #fileInputDocumentFront
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Verso</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputDocumentBehind.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentBehindImage"
                matSuffix
                mat-icon-button
                (click)="editDocumentBehindImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentBehindImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('documentBehind')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedDocumentBehindImage != ''"
                width="350"
                src="{{ croppedDocumentBehindImage }}"
              />
              <input
                hidden
                (change)="selectFileDocumentBehind($event)"
                #fileInputDocumentBehind
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field *ngIf="showPlan">
              <mat-label>Habilitar membership</mat-label>
              <mat-select
                formControlName="isMembership"
                (selectionChange)="changeMembershipEnabled($event)"
                required
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="formGroup.controls.isMembership?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isMembership) }}
              </mat-error> -->
            </mat-form-field>

            <mat-form-field *ngIf="planSelectEnabled && showPlan">
              <mat-label>Plano</mat-label>
              <mat-select
                formControlName="planMembership"
                (selectionChange)="changeTypePlan($event)"
                required
              >
                <mat-option [value]="'free'">Free</mat-option>
                <mat-option [value]="'recurrent'">Pagamento Recorrente</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="formGroup.controls.planMembership?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.planMembership) }}
              </mat-error> -->
            </mat-form-field>
            <mat-form-field *ngIf="firstPaymentEnabled">
              <mat-label>Primeiro pagamento</mat-label>
              <input type="date" matInput formControlName="firstPayment" required />
              <mat-error *ngIf="formGroup.controls.firstPayment?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstPayment) }}
              </mat-error>
            </mat-form-field>
            <div *ngIf="!typePreRegistration || typePreRegistration === 'CMS'">
              <p style="margin-bottom: 0px; font-weight: bold">Creditar Pontos</p>
              <mat-form-field>
                <mat-label>Quantidade de Pontos</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="jcoins"
                  (blur)="calcValuePoints($event)"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Parceiro</mat-label>
                <input
                  type="text"
                  placeholder="Parceiro"
                  matInput
                  formControlName="store"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayStore"
                  (optionSelected)="calcValue($event)"
                >
                  <mat-option *ngFor="let store of storeOptions | async" [value]="store">
                    {{ store.nomeFantasia }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="jcoinsInvalid"
                >Campo deve ser preenchido
              </mat-error>

              <mat-form-field>
                <mat-label>Valor equivalente em R$</mat-label>
                <input
                  matInput
                  formControlName="pointsConversionFactorOut"
                  currencyMask
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                />
                <mat-error *ngIf="formGroup.controls.pointsConversionFactorOut?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorOut) }}
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>

          <div *ngIf="typePreRegistration === 'architects'">
            <p style="font-weight: bold; font-size: 14px">Documento aprovado ?</p>
            <input
              type="radio"
              formControlName="step1"
              value="true"
              (click)="changeApproved(true)"
              checked="{{ isChecked(disapprovedDocument) }}"
            />
            <label> Sim</label>
            <br />
            <input
              type="radio"
              formControlName="step1"
              value="false"
              (click)="changeApproved(false)"
              checked="{{ isChecked(disapprovedDocument) }}"
            />
            <label> Não</label>
          </div>

          <mat-form-field *ngIf="typePreRegistration === 'architects' && disapprovedDocument">
            <mat-label>Motivo</mat-label>
            <input matInput formControlName="disapproved" required />
            <mat-error *ngIf="disapprovedDocument && formGroup.controls.disapproved?.invalid">
              {{ errorsService.messageErrorFor(formGroup.controls.disapproved) }}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="preRegistrationID">
            <mat-label>Origem</mat-label>
            <mat-select formControlName="origin">
              <mat-option [value]="'JHSFID'">JHSFID</mat-option>
              <mat-option [value]="'ARCHITECT'">ARQUITETO</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
