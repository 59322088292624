<mat-sidenav-container class="logged">
  <mat-sidenav #sidenav mode="side" [opened]="menuOpened" class="logged__sidenav">
    <div mat-card>
      <div class="__logo">
        <img src="assets/images/logo.svg" alt="Logo JHSF ID" />
      </div>
    </div>
    <div class="__infos">
      Olá, {{ userInfo.firstName }}!
      <a href="#" class="edit-password" routerLink="/auth/change-password">(Editar senha)</a>
    </div>

    <ng-template #architectBlock>
      <button mat-list-item (click)="showArchitect($event)">
        Empresas
        <mat-icon *ngIf="showButtonsArchitect" aria-label="Example thumbs up SVG icon">
          keyboard_arrow_up</mat-icon
        >
        <mat-icon *ngIf="!showButtonsArchitect" aria-label="Example thumbs up SVG icon">
          keyboard_arrow_down
        </mat-icon>
      </button>

      <button
        style="background-color: #696969"
        mat-list-item
        routerLink="/dashboard/architect-companies"
        *ngIf="showButtonsArchitect"
      >
        Cadastro
      </button>

      <button mat-list-item (click)="showArchitectPreRegister($event)">
        Arquiteto
        <mat-icon *ngIf="showButtonsArchitectPreRegister" aria-label="Example thumbs up SVG icon">
          keyboard_arrow_up</mat-icon
        >
        <mat-icon *ngIf="!showButtonsArchitectPreRegister" aria-label="Example thumbs up SVG icon">
          keyboard_arrow_down
        </mat-icon>
      </button>
      <button
        style="background-color: #696969"
        mat-list-item
        routerLink="/dashboard/pre-registration-architects/architects"
        *ngIf="showButtonsArchitectPreRegister"
      >
        Pré-cadastro Arquitetos
      </button>

      <button mat-list-item routerLink="/dashboard/architect-stores">Lojistas</button>
      <button mat-list-item routerLink="/dashboard/clients-architect/architect">
        Gestão de Clientes
      </button>
      <button mat-list-item routerLink="/dashboard/admin-architect-invoice-list">
        Notas Fiscais
      </button>
      <cdk-accordion>
        <cdk-accordion-item          
          #accordionItem="cdkAccordionItem"
          role="button"
          tabindex="0"
        >
          <div class="accordion-item" (click)="accordionItem.toggle()">
            Financeiro
            <mat-icon
              aria-hidden="false"
              *ngIf="accordionItem.expanded"
              aria-label="Example thumbs up SVG icon"
            >
              keyboard_arrow_up</mat-icon
            >
            <mat-icon
              aria-hidden="false"
              *ngIf="!accordionItem.expanded"
              aria-label="Example thumbs up SVG icon"
            >
              keyboard_arrow_down
            </mat-icon>
          </div>
          <div role="region" [style.display]="accordionItem.expanded ? '' : 'none'">
            <button mat-list-item routerLink="/dashboard/architect-closings/list">
              Fechamento Arquitetos
            </button>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
    </ng-template>

    <div *ngIf="userRole == 'EMPTY'; else elseBlock0" class="logged__sidenav__actions">
      <mat-action-list class="logged__sidenav__actions">
        <cdk-accordion>
          <cdk-accordion-item
            *ngIf="permissionsService.userHasPermission(cmsPermissions.architect_admin_menu)"
            #accordionItem="cdkAccordionItem"
            role="button"
            tabindex="0"
          >
            <div class="accordion-item" (click)="accordionItem.toggle()">
              CASHBACK ARQUITETOS
              <mat-icon
                aria-hidden="false"
                *ngIf="accordionItem.expanded"
                aria-label="Example thumbs up SVG icon"
              >
                keyboard_arrow_up</mat-icon
              >
              <mat-icon
                aria-hidden="false"
                *ngIf="!accordionItem.expanded"
                aria-label="Example thumbs up SVG icon"
              >
                keyboard_arrow_down
              </mat-icon>
            </div>
            <div role="region" [style.display]="accordionItem.expanded ? '' : 'none'">
              <ng-template [ngTemplateOutlet]="architectBlock"></ng-template>
            </div>
          </cdk-accordion-item>
        </cdk-accordion>
        <button mat-list-item routerLink="/login">Sair</button>
      </mat-action-list>
    </div>

    <ng-template #elseBlock0>
      <mat-action-list *ngIf="userRole == 'ADMIN'" class="logged__sidenav__actions">
        <cdk-accordion>
          <cdk-accordion-item
            *ngFor="let item of items; let index = index"
            #accordionItem="cdkAccordionItem"
            role="button"
            tabindex="0"
            [attr.id]="'accordion-header-' + index"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + index"
          >
            <div class="accordion-item" (click)="accordionItem.toggle()">
              {{ item }}
              <mat-icon
                aria-hidden="false"
                *ngIf="accordionItem.expanded"
                aria-label="Example thumbs up SVG icon"
              >
                keyboard_arrow_up</mat-icon
              >
              <mat-icon
                aria-hidden="false"
                *ngIf="!accordionItem.expanded"
                aria-label="Example thumbs up SVG icon"
              >
                keyboard_arrow_down
              </mat-icon>
            </div>
            <div
              role="region"
              *ngIf="index === 0"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <button mat-list-item routerLink="/dashboard/home">Dashboard</button>
              <button mat-list-item routerLink="/dashboard/stores" *ngIf="userRole == 'ADMIN'">
                Parceiros
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/transactions"
                *ngIf="userRole == 'ADMIN'"
              >
                Transações
              </button>
              <button mat-list-item routerLink="/dashboard/closings" *ngIf="userRole == 'ADMIN'">
                Fechamento
              </button>
              <button mat-list-item routerLink="/dashboard/receivables" *ngIf="userRole == 'ADMIN'">
                Recebíveis
              </button>
              <button mat-list-item routerLink="/dashboard/financial-receivables">
                Recebíveis Financeiros
              </button>
              <button mat-list-item routerLink="/dashboard/prepayments" *ngIf="userRole == 'ADMIN'">
                Antecipações
              </button>
              <button mat-list-item routerLink="/dashboard/reports">Relatórios</button>
              <button mat-list-item routerLink="/dashboard/macro-group" *ngIf="userRole == 'ADMIN'">
                Macro Grupo
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/stores/export-to-zoop"
                *ngIf="userRole == 'ADMIN'"
              >
                Exportar parceiros pra zoop
              </button>
            </div>
            <div
              role="region"
              *ngIf="index === 1"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <button mat-list-item routerLink="/dashboard/categories" *ngIf="userRole == 'ADMIN'">
                Categorias
              </button>
              <button mat-list-item routerLink="/dashboard/regulations" *ngIf="userRole == 'ADMIN'">
                Regulamentos
              </button>
              <button mat-list-item routerLink="/dashboard/terms" *ngIf="userRole == 'ADMIN'">
                Termos
              </button>
              <button mat-list-item routerLink="/dashboard/configs" *ngIf="userRole == 'ADMIN'">
                Configurações
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/memberships/plans"
                *ngIf="userRole == 'ADMIN'"
              >
                Planos Membership
              </button>
              <button mat-list-item routerLink="/dashboard/airports" *ngIf="userRole == 'ADMIN'">
                Aeroporto
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/banners-pages-app"
                *ngIf="userRole == 'ADMIN'"
              >
                Páginas APP (Banners)
              </button>
            </div>
            <div
              role="region"
              *ngIf="index === 2"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <button
                mat-list-item
                routerLink="/dashboard/client-import"
                *ngIf="userRole == 'ADMIN'"
              >
                Importar Clientes
              </button>

              <button mat-list-item *ngIf="userRole == 'ADMIN'" (click)="showButtons($event)">
                Pré-Cadastro
                <mat-icon
                  *ngIf="showButtonsPreRegistration"
                  aria-label="Example thumbs up SVG icon"
                >
                  keyboard_arrow_up</mat-icon
                >
                <mat-icon
                  *ngIf="!showButtonsPreRegistration"
                  aria-label="Example thumbs up SVG icon"
                >
                  keyboard_arrow_down
                </mat-icon>
              </button>

              <button
                style="background-color: #696969"
                mat-list-item
                routerLink="/dashboard/pre-registration/CMS"
                *ngIf="showButtonsPreRegistration"
              >
                Clientes incorporação/aeroporto
              </button>

              <button
                style="background-color: #696969"
                mat-list-item
                routerLink="/dashboard/pre-registration"
                *ngIf="showButtonsPreRegistration"
              >
                Outros clientes
              </button>

              <button mat-list-item routerLink="/dashboard/clients" *ngIf="userRole == 'ADMIN'">
                Gestão de Clientes
              </button>
              <button mat-list-item routerLink="/dashboard/funds" *ngIf="userRole == 'ADMIN'">
                Gestão de Fundos
              </button>
              <button mat-list-item routerLink="/dashboard/client-kind" *ngIf="userRole == 'ADMIN'">
                Segmentação de Clientes
              </button>
              <button mat-list-item routerLink="/dashboard/attendant" *ngIf="userRole == 'ADMIN'">
                Atendentes
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/clients/export-to-zoop"
                *ngIf="userRole == 'ADMIN'"
              >
                Exportar clientes pra zoop
              </button>
            </div>
            <div
              role="region"
              *ngIf="index == 3"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <!-- <button mat-list-item routerLink="/dashboard/users" *ngIf="userRole == 'ADMIN'">Gestão de Usuários</button> -->
              <button
                mat-list-item
                routerLink="/dashboard/segmentation"
                *ngIf="userRole == 'ADMIN'"
              >
                Segmentações
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/notifications"
                *ngIf="userRole == 'ADMIN'"
              >
                Notificações
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/notification-types"
                *ngIf="userRole == 'ADMIN'"
              >
                Tipo de notificações
              </button>
              <button mat-list-item routerLink="/dashboard/quiz/list" *ngIf="userRole == 'ADMIN'">
                Questionários
              </button>
            </div>

            <div
              role="region"
              *ngIf="index == 4 && userRole == 'ADMIN'"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <!-- <button mat-list-item routerLink="/dashboard/users" *ngIf="userRole == 'ADMIN'">Gestão de Usuários</button> -->
              <button mat-list-item routerLink="/dashboard/campaign" *ngIf="userRole == 'ADMIN'">
                Campanhas
              </button>
            </div>

            <div
              role="region"
              *ngIf="index == 5 && userRole == 'ADMIN'"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <button mat-list-item routerLink="/dashboard/beneficiary" *ngIf="userRole == 'ADMIN'">
                Beneficiário
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/integrations"
                *ngIf="userRole == 'ADMIN'"
              >
                Consulta Base
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/documents-not-integration"
                *ngIf="userRole == 'ADMIN'"
              >
                CPF não integrados
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/closings-incorporations"
                *ngIf="userRole == 'ADMIN'"
              >
                Fechamento Fincanceiro
              </button>
            </div>
            <div
              role="region"
              *ngIf="
                (index == 6 && userRole == 'ADMIN') ||
                (index == 6 && userRole === 'STOREKEEPER_AIRPORT')
              "
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <button
                mat-list-item
                routerLink="/dashboard/airport-companies"
                *ngIf="userRole == 'ADMIN' || userRole === 'STOREKEEPER_AIRPORT'"
              >
                Cadastro
              </button>
              <button
                mat-list-item
                routerLink="/dashboard/pre-register-airports-companies"
                *ngIf="userRole == 'ADMIN' || userRole === 'STOREKEEPER_AIRPORT'"
              >
                Pré Cadastro CNPJ
              </button>
            </div>
            <div
              role="region"
              *ngIf="index == 7 && userRole == 'ADMIN'"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-item-content' + index"
              [attr.aria-labelledby]="'accordion-header-' + index"
            >
              <ng-template [ngTemplateOutlet]="architectBlock"></ng-template>
            </div>
          </cdk-accordion-item>
        </cdk-accordion>
        <button mat-list-item routerLink="/login">Sair</button>
      </mat-action-list>

      <div *ngIf="storeSelected && storeSelected.type === 'architect'; else elseBlock1">
        <mat-action-list class="logged__sidenav__actions">
          <button mat-list-item routerLink="/dashboard/home">Dashboard</button>
          <button
            *ngIf="permissionsService.userHasPermission(cmsPermissions.architect_invoice_list)"
            mat-list-item
            routerLink="/dashboard/architect-invoice-list"
          >
            Notas fiscais
          </button>
          <button
            *ngIf="
              permissionsService.userHasPermission(cmsPermissions.architect_invoice_create_update)
            "
            mat-list-item
            routerLink="/dashboard/architect-invoice/new"
          >
            Criar Nota Fiscal
          </button>
          <button mat-list-item routerLink="/login">Sair</button>
        </mat-action-list>
      </div>
      <ng-template #elseBlock1>
        <mat-action-list
          *ngIf="userRole != 'ADMIN' && userRole != 'CUSTOMER_SERVICE'"
          class="logged__sidenav__actions"
        >
          <button mat-list-item routerLink="/dashboard/home">Dashboard</button>
          <button mat-list-item routerLink="/dashboard/stores/{{ userInfo.storeId }}/transactions">
            Transações
          </button>
          <button mat-list-item routerLink="/dashboard/financial-receivables">
            Recebíveis Financeiros
          </button>
          <button mat-list-item routerLink="/dashboard/reports">Relatórios</button>
          <button mat-list-item routerLink="/dashboard/store/{{ storeSelected.storeId }}/qrcode">
            Configurações
          </button>
          <mat-action-list
            *ngIf="userRole == 'STOREKEEPER_AIRPORT'"
            class="logged__sidenav__actions"
          >
            <cdk-accordion>
              <cdk-accordion-item
                *ngFor="let item of itemsStorekeeper; let index = index"
                #accordionItem="cdkAccordionItem"
                role="button"
                tabindex="0"
                [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index"
              >
                <div class="accordion-item" (click)="accordionItem.toggle()">
                  {{ item }}
                  <mat-icon
                    aria-hidden="false"
                    *ngIf="accordionItem.expanded"
                    aria-label="Example thumbs up SVG icon"
                  >
                    keyboard_arrow_up</mat-icon
                  >
                  <mat-icon
                    aria-hidden="false"
                    *ngIf="!accordionItem.expanded"
                    aria-label="Example thumbs up SVG icon"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                </div>
                <div
                  role="region"
                  *ngIf="index === 0 && userRole === 'STOREKEEPER_AIRPORT'"
                  [style.display]="accordionItem.expanded ? '' : 'none'"
                  [attr.id]="'accordion-item-content' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index"
                >
                  <button
                    mat-list-item
                    routerLink="/dashboard/airport-companies"
                    *ngIf="userRole == 'STOREKEEPER_AIRPORT'"
                  >
                    Cadastro
                  </button>
                </div>
              </cdk-accordion-item>
            </cdk-accordion>
          </mat-action-list>
          <!-- <button
                mat-list-item
                routerLink="/dashboard/store-reports/sells"
                *ngIf="userRole !== 'ADMIN'"
              >
                Relatório de Transações
              </button>
      -->
          <button mat-list-item routerLink="/login">Sair</button>
        </mat-action-list>

        <mat-action-list *ngIf="userRole == 'CUSTOMER_SERVICE'" class="logged__sidenav__actions">
          <button mat-list-item routerLink="/dashboard/clients">Gestão de Clientes</button>
          <button mat-list-item (click)="showButtons($event)">
            Pré-Cadastro
            <mat-icon *ngIf="showButtonsPreRegistration" aria-label="Example thumbs up SVG icon">
              keyboard_arrow_up</mat-icon
            >
            <mat-icon *ngIf="!showButtonsPreRegistration" aria-label="Example thumbs up SVG icon">
              keyboard_arrow_down
            </mat-icon>
          </button>

          <button
            style="background-color: #696969"
            mat-list-item
            routerLink="/dashboard/pre-registration/CMS"
            *ngIf="showButtonsPreRegistration"
          >
            Clientes incorporação/aeroporto
          </button>

          <button
            style="background-color: #696969"
            mat-list-item
            routerLink="/dashboard/pre-registration"
            *ngIf="showButtonsPreRegistration"
          >
            Outros clientes
          </button>

          <button mat-list-item routerLink="/login">Sair</button>
        </mat-action-list>
      </ng-template>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="logged__toolbar">
      <button mat-icon-button (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="logged__logo" *ngIf="!menuOpened">
        <img src="assets/images/logo.svg" alt="Logo JHSF ID" />
      </div>
      <div style="position: absolute; top: 15px; right: 10px; color: #fff; display: block">
        <button mat-button [matMenuTriggerFor]="menu" *ngIf="!!storeSelected">
          {{ storeSelected.storeName }}
          {{ storeSelected.type === 'architect' ? '(Arquitetos)' : '' }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngFor="let store of stores"
            (click)="onChangeStore(store)"
            value="{{ store }}"
          >
            {{ store.storeName }} {{ store.type === 'architect' ? '(Arquitetos)' : '' }}
          </button>
        </mat-menu>
        <button mat-button (click)="openDialogHelp()">
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <main class="logged__content">
      <ng-content></ng-content>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
