import { HttpResponse, } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
var RequestInterceptor = /** @class */ (function () {
    function RequestInterceptor(router, loaderService) {
        this.router = router;
        this.loaderService = loaderService;
        this.requests = [];
    }
    RequestInterceptor.prototype.removeRequest = function (req) {
        var i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    };
    RequestInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        // set loading
        if (!request.headers.has('noloading'))
            this.loaderService.isLoading.next(true);
        var token = localStorage.getItem('token');
        var url = new URL(request.url);
        if (token && !("" + (url.origin + url.pathname)).includes('s3.amazonaws.com')) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
            });
        }
        var storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
        if (storeSelected) {
            request = request.clone({
                setHeaders: {
                    storeId: storeSelected.storeId,
                },
            });
        }
        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json',
                },
            });
        }
        else if (request.headers.get('Content-Type') == 'multipart/form-data') {
            request = request.clone({
                headers: request.headers.delete('Content-Type', 'multipart/form-data'),
            });
        }
        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
                .delete('noloading'),
        });
        return next.handle(request).pipe(map(function (event) {
            if (event instanceof HttpResponse) {
                console.log('event--->>>', event);
                _this.loaderService.isLoading.next(_this.requests.length > 0);
            }
            return event;
        }), catchError(function (error) {
            if (error.status === 401) {
                if (error.error.status) {
                    _this.router.navigate(['/']);
                }
                else {
                    _this.router.navigate(['/']);
                }
            }
            _this.loaderService.isLoading.next(false);
            return throwError(error);
        }));
    };
    return RequestInterceptor;
}());
export { RequestInterceptor };
