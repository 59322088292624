import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { Store } from 'src/app/models/store'
import { MatDialog, MatSnackBar } from '@angular/material'
import { DatePipe } from '@angular/common'
import { map, startWith } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { User } from 'src/app/models/user'
import { ConfigService } from 'src/app/services/config.service'
import numeral from 'numeral'
import { CampaignService } from 'src/app/services/campaign.service'
import { ModalChooseCampaignTypeComponent } from './../../../../components/modal-choose-campaign-type/modal-choose-campaign-type.component'
import { UtilService } from './../../../../services/util.service'
import { ModalConfirmationComponent } from 'src/app/components/modal-confirmation/modal-confirmation.component'
import { ModalPartnerEditionMacroBindingInformation } from './../../../../components/modal-partner-edition-macro-binding-information/modal-partner-edition-macro-binding-information.component'

@Component({
  selector: 'app-store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss'],
})
export class StoreFormComponent implements OnInit {
  public formGroup: FormGroup
  public items: FormArray
  public states: Object[] = []
  public storeID = ''
  public store: Store
  public cnpjSelected = null
  public title = 'app'
  public elementType = 'string'
  public valueQrCode = ''
  public segmentos: any[] = []
  public bankCodes: any[] = []
  public userInfo: User
  public configs: any = []

  public params = { storeFormGroupValue: [] }
  public campaigns: any[] = []

  public isMembershipActive = false
  public isPayActive = false
  public userRole: any
  public isCardPayment: boolean
  public isJcoinsPayment: boolean
  public isJcoinsPriorityPayment: boolean
  public typePayment: string
  public macroGroups: any[] = []
  public isSubStore: boolean
  public groups: any[] = []
  public subStoreNone: any[] = []
  public subStoreNameInvalid = false
  public macroGroupIdInvalid = false
  public messageMacroGroupError = false
  public messageSubError = false
  public subAndMacroInvalid = false
  public isActiveRoadpass: boolean
  public isZoopEnabled: boolean
  public idCampaign: string
  public descriptionCampaign: string
  public isUpdate = false
  public campaignId: string
  public defaultCampaignId: string

  public segmentosOptions: Observable<any[]>
  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private readonly datePipe: DatePipe,
    public configService: ConfigService,
    public dialog: MatDialog,
    public campaignService: CampaignService,
  ) {
    this.initForm()
    this.states = [
      {
        abbr: 'AC',
        name: 'Acre',
      },
      {
        abbr: 'AL',
        name: 'Alagoas',
      },
      {
        name: 'Amapá',
        abbr: 'AP',
      },
      {
        name: 'Amazonas',
        abbr: 'AM',
      },
      {
        name: 'Bahia',
        abbr: 'BA',
      },
      {
        name: 'Ceará',
        abbr: 'CE',
      },
      {
        name: 'Distrito Federal',
        abbr: 'DF',
      },
      {
        name: 'Espírito Santo',
        abbr: 'ES',
      },
      {
        name: 'Goiás',
        abbr: 'GO',
      },
      {
        name: 'Maranhão',
        abbr: 'MA',
      },
      {
        name: 'Mato Grosso',
        abbr: 'MT',
      },
      {
        name: 'Mato Grosso do Sul',
        abbr: 'MS',
      },
      {
        name: 'Minas Gerais',
        abbr: 'MG',
      },
      {
        name: 'Pará',
        abbr: 'PA',
      },
      {
        name: 'Paraíba',
        abbr: 'PB',
      },
      {
        name: 'Paraná',
        abbr: 'PR',
      },
      {
        name: 'Pernambuco',
        abbr: 'PE',
      },
      {
        name: 'Piauí',
        abbr: 'PI',
      },
      {
        name: 'Rio de Janeiro',
        abbr: 'RJ',
      },
      {
        name: 'Rio Grande do Norte',
        abbr: 'RN',
      },
      {
        name: 'Rio Grande do Sul',
        abbr: 'RS',
      },
      {
        name: 'Rondônia',
        abbr: 'RO',
      },
      {
        name: 'Roraima',
        abbr: 'RR',
      },
      {
        name: 'Santa Catarina',
        abbr: 'SC',
      },
      {
        name: 'São Paulo',
        abbr: 'SP',
      },
      {
        name: 'Sergipe',
        abbr: 'SE',
      },
      {
        name: 'Tocantins',
        abbr: 'T',
      },
    ]
  }

  ngOnInit () {
    this.loadCampaigns()
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
    })
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userRole = localStorage.getItem('userRole')
  }

  async initForm () {
    this.cnpjSelected = this.route.snapshot.paramMap.get('cnpj')
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.isUpdate = !!this.storeID
    this.valueQrCode = this.storeID

    this.formGroup = this.formBuilder.group({
      nomeFantasia: [null, [Validators.required]],
      razaoSocial: [null, [Validators.required]],
      complement: ['', []],
      cnpj: [
        this.cnpjSelected,
        [Validators.required, Validators.minLength(14), Validators.maxLength(14)],
      ],
      phoneNumber: [null, [Validators.required]],
      emailAddress: [null, [Validators.required]],
      mcc: [null, [Validators.required]],
      mccDescription: [null, [Validators.required]],
      mccCategory: [null, [Validators.required]],
      mccCode: [null, []],
      openingDate: [null, [Validators.required]],
      liquidationDays: [null, [Validators.required]],
      isMembershipActive: [null, [Validators.required]],
      isPayActive: [null, [Validators.required]],
      taxCashback: [null, []],
      manualPaymentEnabled: [null, [Validators.required]],
      futurePaymentEnabled: [null, [Validators.required]],
      isConciergePaymentStore: [false, [Validators.required]],
      taxAdministrative: [null, []],
      taxRewardsTransaction: [null, []],
      taxCreditCardTransaction: [null, []],
      pointsConversionFactorIn: [null, []],
      pointsConversionFactorOut: [null, []],
      pointsExpiration: [null, []],
      maximumInstallmentsPerTransaction: [null, [Validators.required]],
      minimumInstallmentValue: [null, [Validators.required]],
      // maximumPointsPerTransaction: [null, []],
      minimumTransactionValue: [null, [Validators.required]],
      street: [null, [Validators.required]],
      number: [null, [Validators.required]],
      neighborhood: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      zipCode: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      typePayment: [null, []],
      isSubStore: [null, []],
      macroGroup: [null, []],
      subStores: this.formBuilder.array([this.createMacroGroupFormGroup()]),
      bankCode: [null, [Validators.required]],
      agency: [null, [Validators.required]],
      accountNumber: [null, [Validators.required]],
      accountDigit: [null, [Validators.required]],
      accountType: [null, [Validators.required]],
    })

    if (this.cnpjSelected > 0) {
      this.formGroup.get('cnpj').enable()
      this.loadInfosConfigs()
    } else {
      this.formGroup.get('cnpj').disable()
    }

    this.segmentosOptions = this.formGroup.controls.mcc.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.segmentos.filter(seg => {
          const newValue = typeof value === 'object' ? value : value
          if (
            newValue != null &&
            seg.category != null &&
            seg.description != null &&
            seg.code != null
          ) {
            return (
              seg.category == value ||
              seg.description == value ||
              seg.code == value ||
              seg.description.includes(newValue.toUpperCase()) ||
              seg.description.includes(newValue.toLowerCase()) ||
              seg.category.includes(newValue.toUpperCase()) ||
              seg.category.includes(newValue.toLowerCase()) ||
              seg.code.includes(newValue.toUpperCase()) ||
              seg.code.includes(newValue.toLowerCase())
            )
          }
        })
      }),
    )

    await this.loadSegmentos()
    if (this.cnpjSelected > 0) {
      await this.loadInfosStoreByCnpj()
    } else {
      await this.loadInfosStore()
    }

    this.listMacroGroups()
  }

  listMacroGroups () {
    this.storeService.listMacroGroups().then(
      async response => {
        this.macroGroups = response.results
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  changeIsSubStore (e) {
    this.isSubStore = e
    const subStores = this.formGroup.get('subStores') as FormArray
    if (!e) {
      for (const s of subStores.value) {
        s.subStoreName = null
        s.subStoreId = null
      }
      for (const s of subStores.controls) {
        s.value.subStoreName = null
        s.value.subStoreId = null
        this.subStoreNone.push(s.value)
      }
      this.subStoreNameInvalid = false
      this.macroGroupIdInvalid = false
      this.subAndMacroInvalid = false
      this.messageSubError = false
    }
    // if (!e) {
    //   for (const s of subStores.controls) {
    //     s.value.subStoreName = null
    //     s.value.macroGroupId = null
    //     s.value.subStoreId = null
    //     this.subStoreNone.push(s.value)
    //   }
    //   this.subStoreNameInvalid = false
    //   this.macroGroupIdInvalid = false
    //   this.subAndMacroInvalid = false
    // }

    // for (const n of Object.keys(this.subStoreNone)) {
    //   subStores.removeAt(0)
    //   subStores.reset()
    // }
  }

  keyPress (e) {
    const subStores = this.formGroup.get('subStores') as FormArray
    this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName
  }

  validStoreName (e) {
    const subStores = this.formGroup.get('subStores') as FormArray
    this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName
  }

  changeMacroGroup (e) {
    const subStores = this.formGroup.get('subStores') as FormArray
    for (const s of subStores.value) {
      s.id = !s.id ? null : s.id
      s.macroGroupId = !s.macroGroupId ? null : s.macroGroupId
      s.subStoreId = !s.subStoreId ? null : s.subStoreId
      s.subStoreName = !s.subStoreName ? null : s.subStoreName
    }
    this.macroGroupIdInvalid = !subStores.controls[0].value.macroGroupId
  }

  addMacroGroupFormGroup (event) {
    event.preventDefault()
    const subStores = this.formGroup.get('subStores') as FormArray
    this.subAndMacroInvalid = false
    subStores.push(this.createMacroGroupFormGroup())
  }

  removeOrClearMacroGroup (event, i: number) {
    event.preventDefault()
    const subStores = this.formGroup.get('subStores') as FormArray
    if (i >= 1) {
      subStores.controls[i].value.subStoreName = null
      subStores.controls[i].value.macroGroupId = null
      subStores.controls[i].value.subStoreId = null
      this.subStoreNone.push(subStores.controls[i].value)
      subStores.removeAt(i)
    } else {
      subStores.controls[i].value.subStoreName = null
      subStores.controls[i].value.macroGroupId = null
      subStores.controls[i].value.subStoreId = null
      this.subStoreNone.push(subStores.controls[i].value)
      subStores.reset()
    }
  }

  createMacroGroupFormGroup (): FormGroup {
    return new FormGroup({
      subStoreName: new FormControl(undefined),
      subStoreId: new FormControl(undefined),
      macroGroupId: new FormControl(undefined),
      id: new FormControl(undefined),
    })
  }

  async loadInfosStoreByCnpj () {
    this.storeService.getStoreByCnpj(this.cnpjSelected).toPromise().then(
      async response => {
        this.store = response
        this.isUpdate = !!response
        this.setInfosStore()
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  dynamicPhoneMask () {
    let mask = '(00)0000-00009'
    if (this.formGroup.value.phoneNumber && this.formGroup.value.phoneNumber.length > 10) {
      mask = '(00)00000-0000'
    }
    return mask
  }

  async loadInfosStore () {
    this.storeService.getStore(this.storeID).subscribe(
      async response => {
        this.store = response
        this.setInfosStore()
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  loadInfosConfigs () {
    this.configService.view().subscribe(
      async response => {
        this.configs = response
        this.formGroup.patchValue({
          pointsExpiration: this.configs.results.jcoinsExpirationInDays,
        })
      },
      error => {
        alert(error.error.message)
      },
    )
  }

  changeIsMembershipActive (event) {
    this.isMembershipActive = event.value
    if (!this.isMembershipActive && !this.isPayActive) {
      this.isCardPayment = false
      this.isJcoinsPayment = false
      this.isJcoinsPriorityPayment = false

      this.formGroup.get('pointsConversionFactorOut').setValidators(Validators.min(1))
      this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity()
      this.formGroup.get('pointsConversionFactorOut').setErrors(null)

      this.formGroup.get('pointsConversionFactorIn').setValidators(Validators.min(1))
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity()
      this.formGroup.get('pointsConversionFactorIn').setErrors(null)

      this.formGroup.get('taxCashback').setValidators(null)
      this.formGroup.get('taxCashback').updateValueAndValidity()
      this.formGroup.get('taxCashback').setErrors(null)

      this.formGroup.get('taxAdministrative').setValidators(null)
      this.formGroup.get('taxAdministrative').updateValueAndValidity()
      this.formGroup.get('taxAdministrative').setErrors(null)

      this.formGroup.get('taxRewardsTransaction').setValidators(null)
      this.formGroup.get('taxRewardsTransaction').updateValueAndValidity()
      this.formGroup.get('taxRewardsTransaction').setErrors(null)

      this.formGroup.get('pointsExpiration').setValidators(null)
      this.formGroup.get('pointsExpiration').updateValueAndValidity()
      this.formGroup.get('pointsExpiration').setErrors(null)

      this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null)
      this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity()
      this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null)

      this.formGroup.get('minimumInstallmentValue').setValidators(null)
      this.formGroup.get('minimumInstallmentValue').updateValueAndValidity()
      this.formGroup.get('minimumInstallmentValue').setErrors(null)

      this.formGroup.get('minimumTransactionValue').setValidators(null)
      this.formGroup.get('minimumTransactionValue').updateValueAndValidity()
      this.formGroup.get('minimumTransactionValue').setErrors(null)

      this.formGroup.get('taxCreditCardTransaction').setValidators(null)
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('taxCreditCardTransaction').setErrors(null)
    }

    if (!this.isMembershipActive && this.isPayActive) {
      this.isCardPayment = false
      this.isJcoinsPayment = false
      this.isJcoinsPriorityPayment = false

      this.formGroup.get('pointsConversionFactorOut').setValidators(Validators.min(1))
      this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity()
      this.formGroup.get('pointsConversionFactorOut').setErrors(null)
      this.formGroup.get('pointsConversionFactorIn').setValidators(Validators.min(1))
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity()
      this.formGroup.get('pointsConversionFactorIn').setErrors(null)
      this.formGroup.get('taxCashback').setValidators(null)
      this.formGroup.get('taxCashback').updateValueAndValidity()
      this.formGroup.get('taxCashback').setErrors(null)
      this.formGroup.get('taxAdministrative').setValidators(null)
      this.formGroup.get('taxAdministrative').updateValueAndValidity()
      this.formGroup.get('taxAdministrative').setErrors(null)
      this.formGroup.get('pointsExpiration').setValidators(null)
      this.formGroup.get('pointsExpiration').updateValueAndValidity()
      this.formGroup.get('pointsExpiration').setErrors(null)
    }
  }

  changeIsPayActive (event) {
    this.isPayActive = event.value
    if (!this.isPayActive && !this.isMembershipActive) {
      this.formGroup.get('taxCreditCardTransaction').setValidators(null)
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('taxCreditCardTransaction').setErrors(null)
      this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null)
      this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity()
      this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null)
      this.formGroup.get('minimumInstallmentValue').setValidators(null)
      this.formGroup.get('minimumInstallmentValue').updateValueAndValidity()
      this.formGroup.get('minimumInstallmentValue').setErrors(null)
    }
    if (!this.isPayActive && this.isMembershipActive) {
      this.formGroup.get('taxCreditCardTransaction').setValidators(null)
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('taxCreditCardTransaction').setErrors(null)
    }
    if (this.isPayActive) {
      this.formGroup.get('taxCreditCardTransaction').setValidators([Validators.required])
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('maximumInstallmentsPerTransaction').setValidators([Validators.required])
      this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity()
      this.formGroup.get('minimumInstallmentValue').setValidators([Validators.required, null])
      this.formGroup.get('minimumInstallmentValue').updateValueAndValidity()
      this.formGroup.get('minimumTransactionValue').setValidators([Validators.required, null])
      this.formGroup.get('minimumTransactionValue').updateValueAndValidity()
    }
  }

  setInfosStore () {
    this.isSubStore = this.store.isSubStore
    this.isMembershipActive = this.store.isMembershipActive
    this.store.isPayActive = this.store.isPayActive
    this.isPayActive = this.store.isPayActive
    this.isActiveRoadpass = this.store.isActiveRoadpass
    this.isZoopEnabled = this.store.isZoopEnabled

    this.idCampaign = this.store.idCampaign
    this.descriptionCampaign = this.store.descriptionCampaign

    if (this.store.isCardPayment && this.store.isJcoinsPayment) {
      this.typePayment = 'cardPaymentAndJcoinsPayment'
    }
    if (!this.store.isCardPayment && this.store.isJcoinsPayment) {
      this.typePayment = 'jcoinsPayment'
    }
    if (this.store.isCardPayment && !this.store.isJcoinsPayment) {
      this.typePayment = 'cardPayment'
    }
    if (this.store.isJcoinsPriorityPayment) {
      this.typePayment = 'jcoinsPriorityPayment'
    }

    for (const sm of this.store.subStoreAndMacroGroup) {
      const subStores = this.formGroup.get('subStores') as FormArray
      subStores.push(this.createMacroGroupFormGroup())
      const mg = sm.macroGroup.map(mg => mg.id)
      const sb = sm.subStore.map(sb => sb.subStoreName)
      const sbId = sm.subStore.map(sb => sb.id)

      this.groups.push({
        subStoreName: sb[0],
        subStoreId: sbId[0],
        macroGroupId: mg[0],
        id: sm.id,
      })
    }

    if (!this.store.mccCode) {
      const filterMcc = this.segmentos.filter(s => s.description === this.store.mccDescription)
      if (filterMcc.length) {
        this.store.mccCode = filterMcc[0].code
      }
    }
    // this.groups.sort((a, b) => {
    //   return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0
    // })
    this.formGroup.patchValue({
      nomeFantasia: this.store.nomeFantasia,
      razaoSocial: this.store.razaoSocial,
      complement: this.store.complement != null ? this.store.complement : '',
      cnpj: this.store.cnpj,
      emailAddress: this.store.emailAddress,
      phoneNumber: this.store.phoneNumber.replace('+55', ''),
      openingDate: this.store.openingDate.substring(0, 10),
      liquidationDays: this.store.liquidationDays,
      mcc: this.store.mccCategory + ' - ' + this.store.mccDescription,
      mccCategory: this.store.mccCategory,
      mccDescription: this.store.mccDescription,
      mccCode: this.store.mccCode,
      isMembershipActive: this.store.isMembershipActive,
      isPayActive: this.store.isPayActive,
      manualPaymentEnabled: this.store.manualPaymentEnabled,
      futurePaymentEnabled: this.store.futurePaymentEnabled,
      isConciergePaymentStore: this.store.isConciergePaymentStore || false,
      maximumInstallmentsPerTransaction: this.store.maximumInstallmentsPerTransaction,
      minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
      // maximumPointsPerTransaction: this.store.maximumPointsPerTransaction,
      taxCreditCardTransaction: numeral(this.store.taxCreditCardTransaction).multiply(100).value(),
      pointsExpiration: this.store.pointsExpiration,
      minimumTransactionValue: this.store.minimumTransactionValue / 100,
      pointsConversionFactorIn: this.store.pointsConversionFactorIn / 100,
      pointsConversionFactorOut: this.store.pointsConversionFactorOut / 100,
      taxCashback: numeral(this.store.taxCashback).multiply(100).value(),
      taxAdministrative: numeral(this.store.taxAdministrative).multiply(100).value(),
      taxRewardsTransaction: numeral(this.store.taxRewardsTransaction).multiply(100).value(),
      street: this.store.street,
      number: this.store.number,
      neighborhood: this.store.neighborhood,
      city: this.store.city,
      state: this.store.state,
      zipCode: this.store.zipCode,
      typePayment: this.typePayment,
      isSubStore: this.store.isSubStore,
      subStores: this.groups,
      bankCode: this.store.bank ? this.store.bank.bankCode : null,
      agency: this.store.bank ? this.store.bank.agency : null,
      accountNumber: this.store.bank ? this.store.bank.accountNumber : null,
      accountDigit: this.store.bank ? this.store.bank.accountDigit : null,
      accountType: this.store.bank ? this.store.bank.accountType : null,
    })

    if (this.store.pointsConversionFactorIn) {
      this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity()
    }

    if (this.store.pointsConversionFactorOut) {
      this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity()
    }
  }

  displaySegment (seg) {
    return seg ? `${seg}` : ''
  }

  convertPercentToNumber (value) {
    if (value) {
      return numeral(Number(value)).divide(100).value()
    } else {
      return (value = 0)
    }
  }

  changeTypePayment (e) {
    this.typePayment = e.value
    if (e.value === 'cardPaymentAndJcoinsPayment' || e.value === 'jcoinsPriorityPayment') {
      if (e.value === 'cardPaymentAndJcoinsPayment') {
        this.isCardPayment = true
        this.isJcoinsPayment = true
        this.isJcoinsPriorityPayment = false
      }
      if (e.value === 'jcoinsPriorityPayment') {
        this.isCardPayment = false
        this.isJcoinsPayment = false
        this.isJcoinsPriorityPayment = true
      }

      this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity()
      this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity()
      this.formGroup.get('taxCashback').setValidators([Validators.required])
      this.formGroup.get('taxCashback').updateValueAndValidity()
      this.formGroup.get('taxAdministrative').setValidators([Validators.required])
      this.formGroup.get('taxAdministrative').updateValueAndValidity()
      this.formGroup.get('taxRewardsTransaction').setValidators([Validators.required])
      this.formGroup.get('taxRewardsTransaction').updateValueAndValidity()
      this.formGroup.get('pointsExpiration').setValidators([Validators.required])
      this.formGroup.get('pointsExpiration').updateValueAndValidity()
      this.formGroup.get('maximumInstallmentsPerTransaction').setValidators([Validators.required])
      this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity()
      this.formGroup.get('minimumInstallmentValue').setValidators([Validators.required])
      this.formGroup.get('minimumInstallmentValue').updateValueAndValidity()
      this.formGroup.get('minimumTransactionValue').setValidators([Validators.required])
      this.formGroup.get('minimumTransactionValue').updateValueAndValidity()

      this.formGroup.get('taxCreditCardTransaction').setValidators(null)
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('taxCreditCardTransaction').setErrors(null)
    }
    if (e.value === 'cardPayment') {
      this.isCardPayment = true
      this.isJcoinsPayment = false
      this.isJcoinsPriorityPayment = false

      this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity()
      this.formGroup.get('taxCashback').setValidators([Validators.required])
      this.formGroup.get('taxCashback').updateValueAndValidity()
      this.formGroup.get('taxAdministrative').setValidators([Validators.required])
      this.formGroup.get('taxAdministrative').updateValueAndValidity()
      this.formGroup.get('pointsExpiration').setValidators([Validators.required])
      this.formGroup.get('pointsExpiration').updateValueAndValidity()
      this.formGroup.get('maximumInstallmentsPerTransaction').setValidators([Validators.required])
      this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity()
      this.formGroup.get('minimumInstallmentValue').setValidators([Validators.required])
      this.formGroup.get('minimumInstallmentValue').updateValueAndValidity()
      this.formGroup.get('minimumTransactionValue').setValidators([Validators.required])
      this.formGroup.get('minimumTransactionValue').updateValueAndValidity()

      this.formGroup.get('taxCreditCardTransaction').setValidators(null)
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('taxCreditCardTransaction').setErrors(null)

      this.formGroup.get('taxRewardsTransaction').setValidators(null)
      this.formGroup.get('taxRewardsTransaction').updateValueAndValidity()
      this.formGroup.get('taxRewardsTransaction').setErrors(null)

      this.formGroup.get('taxRewardsTransaction').setValidators(null)
      this.formGroup.get('taxRewardsTransaction').updateValueAndValidity()
      this.formGroup.get('taxRewardsTransaction').setErrors(null)
    }
    if (e.value === 'jcoinsPayment') {
      this.isCardPayment = false
      this.isJcoinsPayment = true
      this.isJcoinsPriorityPayment = false
      this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity()
      this.formGroup.get('taxRewardsTransaction').setValidators([Validators.required])
      this.formGroup.get('taxRewardsTransaction').updateValueAndValidity()

      this.formGroup.get('pointsConversionFactorIn').setValidators(Validators.min(1))
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity()
      this.formGroup.get('pointsConversionFactorIn').setErrors(null)

      this.formGroup.get('taxAdministrative').setValidators(null)
      this.formGroup.get('taxAdministrative').updateValueAndValidity()
      this.formGroup.get('taxAdministrative').setErrors(null)

      this.formGroup.get('taxCashback').setValidators(null)
      this.formGroup.get('taxCashback').updateValueAndValidity()
      this.formGroup.get('taxCashback').setErrors(null)

      this.formGroup.get('taxCreditCardTransaction').setValidators(null)
      this.formGroup.get('taxCreditCardTransaction').updateValueAndValidity()
      this.formGroup.get('taxCreditCardTransaction').setErrors(null)

      this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null)
      this.formGroup.get('maximumInstallmentsPerTransaction').updateValueAndValidity()
      this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null)

      this.formGroup.get('minimumInstallmentValue').setValidators(null)
      this.formGroup.get('minimumInstallmentValue').updateValueAndValidity()
      this.formGroup.get('minimumInstallmentValue').setErrors(null)

      this.formGroup.get('minimumTransactionValue').setValidators(null)
      this.formGroup.get('minimumTransactionValue').updateValueAndValidity()
      this.formGroup.get('minimumTransactionValue').setErrors(null)
    }
  }

  loadCampaigns () {
    this.campaignService
      .listCampaigns()
      .subscribe(
        response => {
          this.campaigns = response.results
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  verifyFormValidation () {
    if (
      !this.formGroup.valid ||
      this.subStoreNameInvalid ||
      this.macroGroupIdInvalid ||
      this.subAndMacroInvalid
    ) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    return true
  }

  async openDialogCampaignType () {
    if (this.verifyFormValidation()) {
      if (this.isCurrencyInputValueValid()) {
        if (this.storeID) {
          const macroGroupsIds = []
          this.formGroup.get('subStores')
            .value.map(macro => macroGroupsIds.push(macro.macroGroupId))
          this.campaignService.verifyStores(
            { macroGroupsIds: macroGroupsIds, storesId: this.storeID },
          )
            .toPromise().then(
              async response => {
                const parsedBindedMacroList = []
                response.results.forEach(macro => {
                  if (parsedBindedMacroList
                    .includes(macro.participatingCampaignWithMacroGroup.macroGroups.id)) {
                    macro.participatingCampaignWithMacroGroup.macroGroups = {}
                  } else {
                    parsedBindedMacroList
                      .push(macro.participatingCampaignWithMacroGroup.macroGroups.id)
                  }

                  if (parsedBindedMacroList
                    .includes(macro.participatingCampaignWithMacroGroup.campaigns.id)) {
                    macro.participatingCampaignWithMacroGroup.campaigns = {}
                  } else {
                    parsedBindedMacroList
                      .push(macro.participatingCampaignWithMacroGroup.campaigns.id)
                  }
                })
                if (response.results.length > 0) {
                  const dialogRef = this.dialog.open(ModalPartnerEditionMacroBindingInformation, {
                    width: '500px',
                    data: response,
                  })

                  dialogRef.afterClosed().subscribe(result => {
                    if (result !== 'cancel') {
                      this.submit().catch(err => console.log(err))
                    }
                  })
                } else {
                  this.submit().catch(err => console.log(err))
                }
              },
              error => console.log(error),
            )
        } else {
          const defaultCampaignsList = []
          const extraCampaignsList = []

          this.campaigns.map(campaign => {
            if (campaign.isDefault) {
              defaultCampaignsList.push(campaign)
            } else {
              extraCampaignsList.push(campaign)
            }
          })
          const dialogRef = this.dialog.open(ModalChooseCampaignTypeComponent, {
            width: '500px',
            data: {
              campaigns: { default: defaultCampaignsList, extra: extraCampaignsList },
            },
          })

          dialogRef.afterClosed().subscribe(result => {
            if (result !== 'cancel') {
              this.campaignId = result.campaignId
              this.defaultCampaignId = result.defaultCampaignId
              this.submit().catch(err => console.log(err))
            }
          })
        }
      }
    }
  }

  isCurrencyInputValueValid () {
    const parsedPointsConvertionFactorIn =
      Math.round(this.formGroup.value.pointsConversionFactorIn * 100)
    const parsedPointsConvertionFactorOut =
      Math.round(this.formGroup.value.pointsConversionFactorOut * 100)
    if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
      (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
      this.snackBar.open('Escolha um valor maior ou igual a R$1,00')
      return false
    }
    return true
  }

  async submit () {
    if (typeof this.formGroup.value.taxAdministrative === 'string') {
      const taxAdm = this.formGroup.value.taxAdministrative.replace(',', '.')
      this.formGroup.value.taxAdministrative = Number(taxAdm)
    }

    if (typeof this.formGroup.value.taxRewardsTransaction === 'string') {
      const taxAdm = this.formGroup.value.taxRewardsTransaction.replace(',', '.')
      this.formGroup.value.taxRewardsTransaction = Number(taxAdm)
    }

    if (typeof this.formGroup.value.taxCashback === 'string') {
      const taxAdm = this.formGroup.value.taxCashback.replace(',', '.')
      this.formGroup.value.taxCashback = Number(taxAdm)
    }

    const parsedMinimunTransactionValue =
      Math.round(this.formGroup.value.minimumTransactionValue * 100)
    const parsedMinimunInstallmentValue =
      Math.round(this.formGroup.value.minimumInstallmentValue * 100)
    const parsedPointsConvertionFactorIn =
      Math.round(this.formGroup.value.pointsConversionFactorIn * 100)
    const parsedPointsConvertionFactorOut =
      Math.round(this.formGroup.value.pointsConversionFactorOut * 100)

    if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
      (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
      this.snackBar.open('Escolha um valor maior ou igual a R$1,00')
      return false
    }

    const data = {
      nomeFantasia: this.formGroup.value.nomeFantasia,
      razaoSocial: this.formGroup.value.razaoSocial,
      emailAddress: this.formGroup.value.emailAddress,
      phoneNumber: this.formGroup.value.phoneNumber ? '+55' + this.formGroup.value.phoneNumber : null,
      openingDate: this.formGroup.value.openingDate,
      liquidationDays: this.formGroup.value.liquidationDays,
      mccCategory: this.formGroup.value.mccCategory,
      mccDescription: this.formGroup.value.mccDescription,
      mccCode: this.formGroup.value.mccCode,
      taxCashback: this.convertPercentToNumber(this.formGroup.value.taxCashback),
      taxAdministrative: this.convertPercentToNumber(this.formGroup.value.taxAdministrative),
      taxRewardsTransaction: this.convertPercentToNumber(
        this.formGroup.value.taxRewardsTransaction,
      ),
      taxCreditCardTransaction: this.convertPercentToNumber(
        this.formGroup.value.taxCreditCardTransaction,
      ),
      pointsExpiration: this.formGroup.value.pointsExpiration,
      street: this.formGroup.value.street,
      number: this.formGroup.value.number,
      neighborhood: this.formGroup.value.neighborhood,
      city: this.formGroup.value.city,
      state: this.formGroup.value.state,
      zipCode: this.formGroup.value.zipCode,
      complement: this.formGroup.value.complement,
      isMembershipActive: this.formGroup.value.isMembershipActive,
      isPayActive: this.formGroup.value.isPayActive,
      manualPaymentEnabled: this.formGroup.value.manualPaymentEnabled,
      futurePaymentEnabled: this.formGroup.value.futurePaymentEnabled,
      isConciergePaymentStore: this.formGroup.value.isConciergePaymentStore,
      maximumInstallmentsPerTransaction: this.formGroup.value.maximumInstallmentsPerTransaction
        ? this.formGroup.value.maximumInstallmentsPerTransaction
        : 0,
      // "maximumPointsPerTransaction": this.formGroup.value.maximumPointsPerTransaction,
      minimumTransactionValue: parsedMinimunTransactionValue,
      minimumInstallmentValue: parsedMinimunInstallmentValue,
      pointsConversionFactorIn: parsedPointsConvertionFactorIn,
      pointsConversionFactorOut: parsedPointsConvertionFactorOut,
      isCardPayment: this.isCardPayment,
      isJcoinsPayment: this.isJcoinsPayment,
      isJcoinsPriorityPayment: this.isJcoinsPriorityPayment,
      macro: this.formGroup.value.subStores,
      isSubStore: this.isSubStore,
      bank: {
        bankNumber: this.formGroup.value.bankCode,
        agency: this.formGroup.value.agency,
        account: this.formGroup.value.accountNumber,
        digit: this.formGroup.value.accountDigit,
        accountType: this.formGroup.value.accountType,
      },
      defaultCampaignId: this.defaultCampaignId,
      campaignId: this.campaignId,
      cnpj: '',
    }

    if (this.subStoreNone.length) {
      for (const sn of this.subStoreNone) {
        data.macro.push(sn)
      }
      data.macro = [...new Set(data.macro)]
    }

    const unique = [...new Set(data.macro)]
    data.macro = unique

    if (this.isSubStore) {
      const subStores = this.formGroup.get('subStores') as FormArray
      if (subStores.controls.length) {
        this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName
        this.macroGroupIdInvalid = !subStores.controls[0].value.macroGroupId
        for (const a of subStores.controls) {
          if (a.value.subStoreName && !a.value.macroGroupId) {
            this.messageMacroGroupError = true
          } else {
            this.messageMacroGroupError = false
          }
          if (this.isSubStore && !a.value.subStoreName && a.value.macroGroupId) {
            this.messageSubError = true
          } else {
            this.messageSubError = false
          }
        }
      } else {
        this.subAndMacroInvalid = true
      }
    }
    if (this.cnpjSelected > 0) {
      data.cnpj = this.formGroup.value.cnpj
    }

    if (this.messageMacroGroupError) {
      this.snackBar.open('Escolha um Macro Grupo para Sub-empresa')
      return false
    }

    if (this.messageSubError) {
      this.snackBar.open('Escolha uma Sub-empresa para o Macro Grupo')
      return false
    }
    const subStores = this.formGroup.get('subStores') as FormArray
    this.macroGroupIdInvalid = !subStores.value.find(() => true).macroGroupId
    if (
      !this.formGroup.value.futurePaymentEnabled &&
      !this.formGroup.value.manualPaymentEnabled &&
      !this.formGroup.value.isMembershipActive
    ) {
      this.snackBar.open('Escolher opção de configuração')
      return false
    }

    this.verifyFormValidation()

    if (!this.storeID) {
      this.storeService.addStore(data).subscribe(
        async _ => {
          this.snackBar.open('Parceiro cadastrado com sucesso.')
          this.router.navigate(['dashboard', 'stores'])
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.storeService.updateStore(data, this.storeID).subscribe(
        async _ => {
          this.snackBar.open('Parceiro atualizado com sucesso.')
          this.router.navigate(['dashboard', 'stores'])
        },
        error => {
          console.log(error.error.message)
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  onChange (event) {
    this.formGroup.patchValue({
      mccCategory: event.category,
      mccDescription: event.description,
      mccCode: event.code,
    })
  }

  async loadSegmentos () {
    await this.storeService.getSegmentos().toPromise()
      .then(async response => {
        this.segmentos = response

        // .sort((a, b) => {
        //   a.category = a.category.toUpperCase()
        //   b.category = b.category.toUpperCase()

        //   if (a.category < b.category) {
        //     return -1
        //   } else if (a.category > b.category) {
        //     return 1
        //   }

        //   return 0
        // })
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  async loadAddress () {
    const zipCode = this.formGroup.controls.zipCode.value

    if (zipCode.length === 8) {
      this.zipCodeService.getAddress(zipCode).subscribe((address: any) => {
        this.formGroup.patchValue({
          state: address.uf,
          city: address.localidade,
          neighborhood: address.bairro,
          street: address.logradouro,
        })
      })
    }
  }
}
