import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from 'src/app/services/uploadAdapter.service';
import { base64ToBlob } from 'base64-blob';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
var CategoriesFormComponent = /** @class */ (function () {
    function CategoriesFormComponent(errorsService, formBuilder, categoriesService, authService, snackBar, router, route, _dialog, _cd, imageCompress, reportService) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.categoriesService = categoriesService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.reportService = reportService;
        this.categoriesID = '';
        this.Editor = ClassicEditor;
        this.textDescription = '';
        this.bannerUrl = '';
        this.mainIconUrl = '';
        this.menuIconUrl = '';
        this.conciergeInvalid = false;
        this.redirectInvalid = false;
        this.detailIsTrue = false;
        this.categoriesControl = new FormControl();
        this.separatorKeysCodes = [ENTER, COMMA];
        this.selectedCategories = [];
        this.isCategoryJoin = false;
        this.categoryInputInvalid = false;
        this.selectedStores = [];
        this.stores = [];
        this.storeControl = new FormControl();
        this.config = {
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'imageUpload',
                    'blockQuote',
                    'undo',
                    'redo',
                ],
            },
            image: {
                toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
            },
        };
    }
    CategoriesFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route.snapshot.paramMap.get('categoriesID')) {
            this.categoriesID = this.route.snapshot.paramMap.get('categoriesID');
        }
        this.initForm().catch(function (error) {
            console.log(error);
        });
        this.reportService.listStores().subscribe(function (response) {
            response.map(function (item) {
                _this.stores.push(item);
                _this.filteredStores = _this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.stores.slice(0, 20)); }));
            });
        }, function (error) {
            console.log(error);
        });
    };
    CategoriesFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formGroup = this.formBuilder.group({
                            heading: [null, [Validators.required]],
                            menuIconUrl: [null, [Validators.required]],
                            partnerBanner: [null, []],
                            flashSales: [null, []],
                            order: [null, [Validators.required, Validators.min(1)]],
                            bannerLink: [null, []],
                            flashSalesLink: [null, []],
                            showMain: [null, []],
                            description: [null, []],
                            hasSubItem: [null, []],
                            title: [null, []],
                            redirectLink: [null, []],
                            detail: [null, []],
                            // descomenta aqui
                            // isBannerExclusive: [null, []],
                            isAirport: [null, []],
                            categoryInput: [null, []],
                            isCategoryJoin: [null, []],
                            isActive: [null, []],
                            conciergeLink: [null, []],
                            isParking: [null, []],
                            isDonation: [null, []],
                        });
                        // else {
                        //   localStorage.removeItem('categoriesInfos')
                        // }
                        return [4 /*yield*/, this.categoriesService
                                .listCategories()
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _a;
                                var _this = this;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, response.results.filter(function (c) { return c.id !== _this.categoriesID && !c.categoriesIdJoin01 && !c.categoriesIdJoin02; })];
                                        case 1:
                                            _a.categories = _b.sent();
                                            return [4 /*yield*/, this.filterCategories().catch(function (err) { return console.log(err); })];
                                        case 2:
                                            _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        // else {
                        //   localStorage.removeItem('categoriesInfos')
                        // }
                        _a.sent();
                        if (!this.categoriesID) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.loadInfos().catch(function (err) { return console.log(err); })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.stores
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.nomeFantasia)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    CategoriesFormComponent.prototype.selectedStore = function (event) {
        if (event.option.value.nomeFantasia && this.selectedStores.length < 1) {
            this.selectedStores.push(event.option.value);
            this.storeInput.nativeElement.value = '';
            this.storeControl.setValue(null);
        }
    };
    CategoriesFormComponent.prototype.remove = function (value) {
        if (value.nomeFantasia) {
            var index = this.selectedStores.indexOf(value);
            if (index >= 0) {
                this.selectedStores.splice(index, 1);
            }
        }
    };
    CategoriesFormComponent.prototype.filterCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.categoriesControl.valueChanges.pipe(startWith(''), map(function (category) { return (category ? _this._filter(category) : _this.categories.slice(0, 20)); }))];
                    case 1:
                        _a.filteredCategories = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype._filter = function (categories) {
        var _this = this;
        var remove = String(categories).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.categories
            .filter(function (c) { return !_this.selectedCategories.includes(c); })
            .filter(function (c) { return ("" + String(c.heading)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    CategoriesFormComponent.prototype.selected = function (event) {
        if (this.selectedCategories.length < 2) {
            this.selectedCategories.push(event.option.value);
            this.categoriesInput.nativeElement.value = '';
            this.categoriesControl.setValue(null);
        }
        this.selectedCategories.length === 2
            ? (this.categoryInputInvalid = false)
            : (this.categoryInputInvalid = true);
    };
    CategoriesFormComponent.prototype.removeCategories = function (categories) {
        var index = this.selectedCategories.indexOf(categories);
        if (index >= 0) {
            this.selectedCategories.splice(index, 1);
        }
    };
    CategoriesFormComponent.prototype.checkCategoryJoin = function (e) {
        this.isCategoryJoin = this.formGroup.value.isCategoryJoin;
    };
    CategoriesFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // this.categoriesInfo = JSON.parse(localStorage.getItem('categoriesInfos'))
                    return [4 /*yield*/, this.categoriesService.findOne(this.categoriesID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var filter;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    heading: response.heading,
                                    menuIconUrl: response.menuIconUrl,
                                    order: response.order,
                                    bannerLink: response.bannerLink,
                                    flashSalesLink: response.flashSalesLink,
                                    showMain: response.showMain,
                                    description: response.description,
                                    hasSubItem: response.hasSubItem,
                                    title: response.title,
                                    // descomenta aqui
                                    // isBannerExclusive: response.isBannerExclusive,
                                    redirectLink: response.redirectLink,
                                    detail: response.detail,
                                    categoryJoin: response.categoryJoin,
                                    isAirport: response.isAirport,
                                    isActive: !!response.isActive,
                                    isCategoryJoin: response.categoriesJoins ? response.categoriesJoins.length === 2 : false,
                                    conciergeLink: response.conciergeLink,
                                    isParking: response.isParking,
                                    isDonation: response.isDonation,
                                });
                                this.croppedPartnerBannerImage = response.bannerUrl;
                                this.croppedFlashSalesImage = response.flashSalesUrl;
                                this.menuIconUrl = response.menuIconUrl;
                                // this.croppedBannerExclusiveImage = response.bannerExclusiveUrl
                                this.croppedBannerConciergeImage = response.bannerConciergeUrl;
                                this.croppedBannerRedirectImage = response.bannerRedirectUrl;
                                this.croppedBannerDetailImage = response.bannerDetailUrl;
                                this.detailIsTrue = !!response.bannerDetailUrl;
                                if (response.categoriesJoins) {
                                    if (response.categoriesJoins.length === 2) {
                                        this.isCategoryJoin = true;
                                    }
                                    if (response.categoriesJoins) {
                                        response.categoriesJoins.map(function (item) {
                                            _this.selectedCategories.push(item);
                                        });
                                    }
                                }
                                if (response.categoriesStores) {
                                    filter = this.stores.filter(function (item) {
                                        return response.categoriesStores.storeId.includes(item.id);
                                    });
                                    filter.map(function (item) {
                                        _this.selectedStores.push(item);
                                    });
                                }
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        // this.categoriesInfo = JSON.parse(localStorage.getItem('categoriesInfos'))
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    CategoriesFormComponent.prototype.selectFile = function (event, type) {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append('file', event.target.files[0], event.target.files[0].name);
        this.categoriesService.upload(uploadData).subscribe(function (response) {
            switch (type) {
                case 'menuIconUrl': {
                    _this.formGroup.get('menuIconUrl').setValue(response.url);
                    _this.menuIconUrl = response.url;
                    break;
                }
                default:
                    break;
            }
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    CategoriesFormComponent.prototype.selectFilePartnerBanner = function (event) {
        var _this = this;
        var originalPartnerBannerImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalPartnerBannerImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalPartnerBannerImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedPartnerBannerImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 1:
                        _a.partnerBannerImageData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CategoriesFormComponent.prototype.selectFileFlashSales = function (event) {
        var _this = this;
        var originalFlashSalesImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalFlashSalesImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalFlashSalesImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedFlashSalesImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedFlashSalesImage = result.img.dataURL;
                        this.flashSalesImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedFlashSalesImage)];
                    case 1:
                        _a.flashSalesImageData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CategoriesFormComponent.prototype.selectFileBannerExclusive = function (event) {
        var _this = this;
        var originalBannerExclusiveImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalBannerExclusiveImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalBannerExclusiveImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedBannerExclusiveImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedBannerExclusiveImage = result.img.dataURL;
                        this.bannerExclusiveImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerExclusiveImage)];
                    case 1:
                        _a.bannerExclusiveImageData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CategoriesFormComponent.prototype.selectFileConciergeBanner = function (event) {
        var _this = this;
        var originalBannerConciergeImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalBannerConciergeImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalBannerConciergeImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedBannerConciergeImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedBannerConciergeImage = result.img.dataURL;
                        this.bannerConciergeImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerConciergeImage)];
                    case 1:
                        _a.bannerConciergeImageData = _b.sent();
                        this._cd.markForCheck();
                        this.conciergeInvalid = false;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CategoriesFormComponent.prototype.selectFileRedirectBanner = function (event) {
        var _this = this;
        var originalBannerRedirectImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalBannerConciergeImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalBannerRedirectImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedBannerRedirectImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedBannerRedirectImage = result.img.dataURL;
                        this.bannerRedirectImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerRedirectImage)];
                    case 1:
                        _a.bannerRedirectImageData = _b.sent();
                        this._cd.markForCheck();
                        this.redirectInvalid = false;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CategoriesFormComponent.prototype.selectFileDetailBanner = function (event) {
        var _this = this;
        var originalBannerDetailImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalBannerConciergeImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalBannerDetailImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedBannerDetailImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedBannerDetailImage = result.img.dataURL;
                        this.bannerDetailImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerDetailImage)];
                    case 1:
                        _a.bannerDetailImageData = _b.sent();
                        this._cd.markForCheck();
                        this.detailIsTrue = !!this.bannerDetailImageData;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CategoriesFormComponent.prototype.editPartnerBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalPartnerBannerImage) return [3 /*break*/, 1];
                        _a = this.originalPartnerBannerImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.partnerBannerImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 5:
                        _b.partnerBannerImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.editFlashSalesImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalFlashSalesImage) return [3 /*break*/, 1];
                        _a = this.originalFlashSalesImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedFlashSalesImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.flashSalesImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedFlashSalesImage = result.img.dataURL;
                        this.flashSalesImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedFlashSalesImage)];
                    case 5:
                        _b.flashSalesImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_2 = _c.sent();
                        this.snackBar.open(error_2.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.editBannerExclusiveImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_3;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalBannerExclusiveImage) return [3 /*break*/, 1];
                        _a = this.originalBannerExclusiveImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedBannerExclusiveImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.bannerExclusivePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedBannerExclusiveImage = result.img.dataURL;
                        this.bannerExclusiveImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerExclusiveImage)];
                    case 5:
                        _b.bannerExclusiveImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_3 = _c.sent();
                        this.snackBar.open(error_3.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.editConciergeBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_4;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalBannerConciergeImage) return [3 /*break*/, 1];
                        _a = this.originalBannerConciergeImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedBannerConciergeImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.bannerConciergePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedBannerConciergeImage = result.img.dataURL;
                        this.bannerConciergeImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerConciergeImage)];
                    case 5:
                        _b.bannerConciergeImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_4 = _c.sent();
                        this.snackBar.open(error_4.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.editRedirectBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_5;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalBannerRedirectImage) return [3 /*break*/, 1];
                        _a = this.originalBannerRedirectImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedBannerRedirectImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.bannerRedirectPosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedBannerRedirectImage = result.img.dataURL;
                        this.bannerRedirectImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerRedirectImage)];
                    case 5:
                        _b.bannerRedirectImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_5 = _c.sent();
                        this.snackBar.open(error_5.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.editDetailBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_6;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalBannerDetailImage) return [3 /*break*/, 1];
                        _a = this.originalBannerDetailImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedBannerDetailImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.bannerDetailPosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedBannerDetailImage = result.img.dataURL;
                        this.bannerDetailImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerDetailImage)];
                    case 5:
                        _b.bannerDetailImageData = _c.sent();
                        this._cd.markForCheck();
                        this.detailIsTrue = !!this.bannerDetailImageData;
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_6 = _c.sent();
                        this.snackBar.open(error_6.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.removeImage = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (input === 'flashSales') {
                    this.readUrlFlashSales = '';
                    this.originalFlashSalesImage = undefined;
                    this.croppedFlashSalesImage = undefined;
                    this.flashSalesImagePosition = undefined;
                    this.flashSalesImageData = undefined;
                }
                if (input === 'bannerExclusive') {
                    this.readUrlBannerExclusive = '';
                    this.originalBannerExclusiveImage = undefined;
                    this.croppedBannerExclusiveImage = undefined;
                    this.bannerExclusiveImagePosition = undefined;
                    this.bannerExclusiveImageData = undefined;
                }
                if (input === 'partnerBanner') {
                    this.readUrlPartnerBanner = '';
                    this.originalPartnerBannerImage = undefined;
                    this.croppedPartnerBannerImage = undefined;
                    this.partnerBannerImagePosition = undefined;
                    this.partnerBannerImageData = undefined;
                }
                if (input === 'conciergeBanner') {
                    this.readUrlBannerConcierge = '';
                    this.originalBannerConciergeImage = undefined;
                    this.croppedBannerConciergeImage = undefined;
                    this.bannerConciergeImagePosition = undefined;
                    this.bannerConciergeImageData = undefined;
                }
                if (input === 'redirectBanner') {
                    this.readUrlBannerRedirect = '';
                    this.originalBannerRedirectImage = undefined;
                    this.croppedBannerRedirectImage = undefined;
                    this.bannerRedirectImagePosition = undefined;
                    this.bannerRedirectImageData = undefined;
                }
                if (input === 'detailBanner') {
                    this.readUrlBannerDetail = '';
                    this.originalBannerDetailImage = undefined;
                    this.croppedBannerDetailImage = undefined;
                    this.bannerDetailImagePosition = undefined;
                    this.bannerDetailImageData = undefined;
                    this.detailIsTrue = !!this.bannerDetailImageData;
                    // this.formGroup.patchValue({
                    //   detail: '',
                    // })
                    // this.formGroup.get('detail').setValue('', { onlySelf: true })
                    // this.formGroup.get('detail').updateValueAndValidity()
                }
                return [2 /*return*/];
            });
        });
    };
    CategoriesFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    CategoriesFormComponent.prototype.change = function (_a) {
        var editor = _a.editor;
        // const EditorData = editor.getData()
        // this.formGroup.get('detail').setValue(EditorData)
    };
    // changeBannerConcierge () {
    //   if (!this.formGroup.value.isBannerConcierge && this.conciergeInvalid) {
    //     this.conciergeInvalid = false
    //   }
    // }
    CategoriesFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, response, response, response, response, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.formGroup.value.isDonation && this.selectedStores.length < 1) {
                            this.snackBar.open('Selecione o parceiro.');
                            return [2 /*return*/, false];
                        }
                        // if (
                        //   this.formGroup.value.isBannerConcierge &&
                        //   !this.croppedBannerConciergeImage &&
                        //   !this.bannerConciergeImageData
                        // ) {
                        //   this.conciergeInvalid = true
                        // }
                        if (this.isCategoryJoin) {
                            this.selectedCategories.length === 2
                                ? (this.categoryInputInvalid = false)
                                : (this.categoryInputInvalid = true);
                        }
                        if (!this.formGroup.valid || this.conciergeInvalid || this.categoryInputInvalid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedPartnerBannerImage && this.partnerBannerImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 1:
                        response = _a.sent();
                        this.readUrlPartnerBanner = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.partnerBannerImageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(this.croppedFlashSalesImage && this.flashSalesImageData)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 4:
                        response = _a.sent();
                        this.readUrlFlashSales = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.flashSalesImageData)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!(this.croppedBannerExclusiveImage && this.bannerExclusiveImageData)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 7:
                        response = _a.sent();
                        this.readUrlBannerExclusive = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.bannerExclusiveImageData)];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9:
                        if (!(this.croppedBannerConciergeImage && this.bannerConciergeImageData)) return [3 /*break*/, 12];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 10:
                        response = _a.sent();
                        this.readUrlBannerConcierge = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.bannerConciergeImageData)];
                    case 11:
                        _a.sent();
                        _a.label = 12;
                    case 12:
                        if (!(this.croppedBannerRedirectImage && this.bannerRedirectImageData)) return [3 /*break*/, 15];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 13:
                        response = _a.sent();
                        this.readUrlBannerRedirect = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.bannerRedirectImageData)];
                    case 14:
                        _a.sent();
                        _a.label = 15;
                    case 15:
                        if (!(this.croppedBannerDetailImage && this.bannerDetailImageData)) return [3 /*break*/, 18];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 16:
                        response = _a.sent();
                        this.readUrlBannerDetail = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.bannerDetailImageData)];
                    case 17:
                        _a.sent();
                        _a.label = 18;
                    case 18:
                        data = {
                            heading: this.formGroup.value.heading,
                            order: this.formGroup.value.order,
                            menuIconUrl: this.formGroup.value.menuIconUrl,
                            bannerUrl: this.readUrlPartnerBanner,
                            bannerLink: this.formGroup.value.bannerLink || '',
                            flashSalesUrl: this.readUrlFlashSales,
                            flashSalesLink: this.formGroup.value.flashSalesLink || '',
                            showMain: !!this.formGroup.value.showMain,
                            description: this.formGroup.value.description,
                            hasSubItem: !!this.formGroup.value.hasSubItem,
                            // descomenta aqui
                            // bannerExclusiveUrl: this.readUrlBannerExclusive,
                            // isBannerExclusive: !!this.formGroup.value.isBannerExclusive,
                            isBannerExclusive: !!this.formGroup.value.isBannerExclusive,
                            bannerConciergeUrl: this.readUrlBannerConcierge,
                            bannerRedirectUrl: this.readUrlBannerRedirect,
                            redirectLink: this.formGroup.value.redirectLink,
                            bannerDetailUrl: this.readUrlBannerDetail,
                            detail: this.formGroup.value.detail,
                            title: this.formGroup.value.title,
                            isAirport: !!this.formGroup.value.isAirport,
                            categoriesIdJoin01: this.selectedCategories.length && this.isCategoryJoin
                                ? this.selectedCategories[0].id
                                : null,
                            categoriesIdJoin02: this.selectedCategories.length && this.isCategoryJoin
                                ? this.selectedCategories[1].id
                                : null,
                            isActive: !!this.formGroup.value.isActive,
                            conciergeLink: this.formGroup.value.conciergeLink,
                            isParking: !!this.formGroup.value.isParking,
                            isDonation: !!this.formGroup.value.isDonation,
                            storeId: this.selectedStores.length ? this.selectedStores[0].id : undefined,
                        };
                        if (this.categoriesID) {
                            this.categoriesService
                                .updateCategories(data, this.categoriesID)
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/categories'])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Categoria atualizada com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        else {
                            this.categoriesService
                                .addCategories(data)
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isCreated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/categories'])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Categoria criado com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CategoriesFormComponent;
}());
export { CategoriesFormComponent };
