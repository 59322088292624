import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormStatementByCustomerComponent = /** @class */ (function () {
    function ModalFormStatementByCustomerComponent(errorsService, snackBar, formBuilder, reportService, dialogRef, data) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
        this.customerInvalid = false;
        this.beginDateInvalid = false;
        this.endDateInvalid = false;
        this.customers = [];
        this.id = [];
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormStatementByCustomerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            customersInput: [null, []],
            beginDate: [null, []],
            endDate: [null, []],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
    };
    ModalFormStatementByCustomerComponent.prototype._filter = function (customer) {
        var _this = this;
        if (!this.selectedCustomers.length) {
            var remove_1 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_1 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers.includes(c); })
                .filter(function (c) {
                return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove_1.toLowerCase());
            })
                .slice(0, 20);
        }
    };
    ModalFormStatementByCustomerComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    ModalFormStatementByCustomerComponent.prototype.selected = function (event) {
        if (!this.selectedCustomers.length) {
            this.selectedCustomers.push(event.option.value);
            this.customerInput.nativeElement.value = '';
            this.customerControl.setValue(null);
            event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true);
        }
    };
    ModalFormStatementByCustomerComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormStatementByCustomerComponent.prototype.changeBeginDate = function (e, cpf) {
        if (!cpf) {
            this.beginDateInvalid = !this.formGroup.value.beginDate;
        }
        else if (this.formGroup.value.endDate) {
            this.formGroup.value.beginDate && this.beginDateInvalid
                ? (this.beginDateInvalid = false)
                : (this.beginDateInvalid = true);
        }
        else if (!this.formGroup.value.endDateDate && !this.formGroup.value.beginDate) {
            this.beginDateInvalid = false;
            this.endDateInvalid = false;
        }
    };
    ModalFormStatementByCustomerComponent.prototype.changeEndDate = function (e, cpf) {
        if (!cpf) {
            this.endDateInvalid = !this.formGroup.value.endDate;
        }
        else if (this.formGroup.value.beginDate) {
            this.formGroup.value.endDate && this.endDateInvalid
                ? (this.endDateInvalid = false)
                : (this.endDateInvalid = true);
        }
        else if (!this.formGroup.value.beginDate && !this.formGroup.value.endDate) {
            this.beginDateInvalid = false;
            this.endDateInvalid = false;
        }
    };
    ModalFormStatementByCustomerComponent.prototype.submit = function (cpf) {
        var _this = this;
        if (!cpf) {
            this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true);
            this.beginDateInvalid = !this.formGroup.value.beginDate;
            this.endDateInvalid = !this.formGroup.value.endDate;
            this.customersCPF = this.selectedCustomers.map(function (c) { return c.cpf; });
            if (this.beginDateInvalid || this.endDateInvalid || this.customerInvalid) {
                this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                return false;
            }
        }
        var filter = '';
        if (cpf) {
            if (this.formGroup.value.beginDate || this.formGroup.value.endDate) {
                this.beginDateInvalid = !this.formGroup.value.beginDate;
                this.endDateInvalid = !this.formGroup.value.endDate;
                if (this.beginDateInvalid || this.endDateInvalid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return false;
                }
                filter = "statement-customer?cpf=" + cpf + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                filter = "statement-customer?cpf=" + cpf;
            }
        }
        else {
            filter = "statement-customer?cpf=" + this.customersCPF + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        }
        this.reportService
            .createReport(filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Extrato por Cliente.' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormStatementByCustomerComponent;
}());
export { ModalFormStatementByCustomerComponent };
