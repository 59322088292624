import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from "rxjs/operators";
import { ErrorsService } from "src/app/core/services/errors.service";
import { ArchitectInvoiceService } from "src/app/services/architectInvoice.service";
import { CompaniesService } from "src/app/services/companies.service";


@Component({
  selector: 'architect-invoice-form',
  templateUrl: './architect-invoice-form.component.html',
  styleUrls: ['./architect-invoice-form.component.scss'],
})
export class ArchitectInvoiceFormComponent implements OnInit {

  public formGroup: FormGroup  
  private selectedStoreBusinessUnit: any
  public documentIsChanged: boolean = false
  private fileUrl: string
  private urlUploadS3: string
  private publicUrl: string  
  currentPdfUrl: string
  currentFilename: string
  public architectInvoiceId: string
  fileName: string  
  documentUrl: string    
  @ViewChild('fileInput', { static: false }) 
  private fileInput: ElementRef
  targets$: Observable<string[]>;
  loadingReceivers: boolean = false
  updateTooltipMessage: string  = ''  

  constructor(
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,    
    private http: HttpClient,
    private companiesService: CompaniesService,
    private architectInvoiceService: ArchitectInvoiceService,
    private readonly snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
  ) {
    if (this.route.snapshot.paramMap.get('architectInvoiceId')) {
      this.architectInvoiceId = this.route.snapshot.paramMap.get('architectInvoiceId')
    }
    this.updateTooltipMessage = this.architectInvoiceId ? 'Não é possível alterar esses dados. É necessário cancelar e criar uma nova nota fiscal para isso' : ''
    
    this.initForm();
  }

  async initForm() {    
    this.selectedStoreBusinessUnit = JSON.parse(localStorage.getItem('storeSelected')) 
    
   

    this.formGroup = this.formBuilder.group({
      invoiceNumber: [null, [Validators.required]],
      invoiceSerial: [null, [Validators.required]],
      totalValue: [null, [Validators.required]],
      targetDisplayName: [null, [Validators.required]],
      targetDocument: [null, [Validators.required]],      
      type: ["PF", [Validators.required]],      
    })

    if(this.architectInvoiceId) {      
      this.formGroup.get('targetDisplayName').disable()
      this.formGroup.get('type').disable()
      this.formGroup.get('totalValue').disable()
      try {
        const invoice = await this.architectInvoiceService.get(this.architectInvoiceId)        
        const receiver = await this.searchReceiver(invoice.type, invoice.targetDocument)
        this.formGroup.patchValue({
          invoiceNumber: invoice.invoiceNumber,
          invoiceSerial: invoice.invoiceSerial,
          totalValue: this.convertCentsToReal(invoice.totalValue),
          targetDocument: invoice.targetDocument,
          type: invoice.type,
          targetDisplayName: this.getReceiverDisplayNames(receiver[0])
        })
        this.currentPdfUrl = invoice.pdfUrl
        this.currentFilename = invoice.pdfUrl.split('/').pop()
      } catch(error) {        
        this.snackBar.open(error.error.message)
      }
    }

    this.targets$ = this.formGroup.get('targetDisplayName')!.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter((targetNameOrDocument) => !!targetNameOrDocument),
      switchMap(targetNameOrDocument => {
        return this.searchReceiver(this.formGroup.get('type').value , targetNameOrDocument)       
      })
    );

    
  }

  ngOnInit(): void {
    
  }

  async searchReceiver(type, termToSearch) {

    this.loadingReceivers = true
    let receivers: Promise<any>
    try {
      receivers =  type === 'PF' 
      ? await this.getArchitects(termToSearch)
      : await this.getCompanies(termToSearch)

    } catch (error) {      
    } finally {
      this.loadingReceivers = false
    }   
    
    return receivers
  }

  getReceiverDisplayNames(receiver) {

    let displayName = ''
    if(receiver.companyName){
      displayName = receiver.companyName + ' - CNPJ: ' + receiver.cnpj
    }
    else {
      displayName = receiver.firstName + ' ' + receiver.lastName + ' - CPF: ' + receiver.cpf 
    }

    return displayName
  }

  onTargetTypeChange() {
    this.formGroup.patchValue({
      targetDocument: '',
      targetDisplayName: ''
    })
  }

  onTargetChange(target) {

    let displayName = this.getReceiverDisplayNames(target)
    let targetDocument = ''
    
    if(target.companyName)
      targetDocument = target.cnpj
    else
      targetDocument = target.cpf

    this.formGroup.patchValue({
      targetDocument: targetDocument,
      targetDisplayName: displayName
    })
  }

  goBack() {
    this.location.back()
  }

  getArchitects(targetNameOrDocument) { 
    return this.architectInvoiceService.searchArchitectForInvoice(targetNameOrDocument).toPromise()
  }

  async getCompanies(targetNameOrDocument) : Promise<any[]>{
    const res = await this.companiesService.searchForCompaniesByNameOrCnpj(targetNameOrDocument)
   
    return res
  }

  async submit() {

    if(!this.architectInvoiceId)
      return this.createInvoiceSubmit()
    else
      return this.updateInvoiceSubmit()
  }

  async createInvoiceSubmit() {

    if (!this.formGroup.valid || !this.fileUrl) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    try {
      let response = await this.architectInvoiceService.getUploadUrl(this.selectedStoreBusinessUnit.storeId, this.fileName).toPromise()
      this.urlUploadS3 = response.url
      this.publicUrl = response.publicUrl
    } catch (error){
      this.snackBar.open(error.error.message)
      return false;
    }

    await this.architectInvoiceService.uploadInvoicePdf(this.urlUploadS3, this.fileUrl).toPromise()
    const totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '')
    const data = {
      invoiceNumber: this.formGroup.value.invoiceNumber,
      invoiceSerial: this.formGroup.value.invoiceSerial,
      totalValueInCents: parseInt(totalValue),      
      targetDocument: this.formGroup.value.targetDocument,
      pdfUrl: this.publicUrl,
      type: this.formGroup.value.type,
      storeId: this.selectedStoreBusinessUnit.storeId,
    }

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    try {
      const response = await this.architectInvoiceService.createInvoice(data).toPromise()
      if (response) {
        this.snackBar.open('Nota fiscal criada com sucesso.')
        this.location.back()
      }
    } catch(error) {
      this.snackBar.open(error.error.message)
    }
  }

  async updateInvoiceSubmit() {

    if (!this.formGroup.valid ) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (!this.fileUrl && this.documentIsChanged) {
      this.snackBar.open('Selecione o novo arquivo para anexar à nota fiscal')
      return false
    }
  
    const data = {
      invoiceNumber: this.formGroup.value.invoiceNumber,
      invoiceSerial: this.formGroup.value.invoiceSerial,
    }


    try {
      const response = await this.architectInvoiceService.updateInvoice(data, this.architectInvoiceId).toPromise()
      let snackBarMessage = 'Nota fiscal alterada com sucesso.'

      if(this.documentIsChanged && response) {
        try {
          
          let uploadUrlResponse = await this.architectInvoiceService.getUploadUrl(this.selectedStoreBusinessUnit.storeId, this.fileName).toPromise()
          const urlUploadS3 = uploadUrlResponse.url
          const publicUrl = uploadUrlResponse.publicUrl         
      
          await this.architectInvoiceService.uploadInvoicePdf(urlUploadS3, this.fileUrl).toPromise()

          const responseUpdatePdfUrl = await this.architectInvoiceService.updateInvoice({pdfUrl: publicUrl}, this.architectInvoiceId).toPromise()

          if(responseUpdatePdfUrl) {
            try {
                await this.architectInvoiceService.removeInvoicePdf(this.architectInvoiceId, this.currentPdfUrl)
            } catch (e) {
              console.log("Não foi possivel excluir o arquivo antigo")
            }
          } else {
            throw new Error();
          }

        } catch(error) {
          snackBarMessage = 'Dados da nota fiscal alterados, mas não foi possivel alterar o documento. Tente novamente mais tarde.'
        }        
      }
      
      if (response) {
        this.snackBar.open(snackBarMessage)
        this.location.back()
      }
    } catch(error) {
      this.snackBar.open(error.error.message)
    }

  }


  imgLoadError () {
    console.log("IMAGE ERROR")
    this.documentUrl = null
  }

  async selectFile (event) {
    this.fileUrl = event.target.files[0]
    this.fileName = event.target.files[0].name    

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0])
      reader.onload = evt => {
        // called once readAsDataURL is completed
        this.documentUrl = (<any>evt).target.result
      }
    } else {
      this.removeDocument()
    }
  }

  removeDocument () {
    this.documentUrl = null    
    this.fileName = undefined
    this.fileInput.nativeElement.value = ''
  }

  changeCurrentDocument () {
    this.documentUrl = null    
    this.documentIsChanged = true
  }
  cancelDocumentChange () {    
    this.removeDocument()
    this.documentIsChanged = false
  }

  convertCentsToReal (value) {
    return value / 100
  }
  
}