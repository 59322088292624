import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ReportService } from '../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-accumulation',
  templateUrl: './modal-form-accumulation.component.html',
  styleUrls: ['./modal-form-accumulation.component.scss'],
})
export class ModalFormAccumulationComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public selectedStores: any[] = []
  public statusControl = new FormControl()
  public selectedStatus: any[] = []
  public filter: string
  stores: any[] = []
  status: any[] = []
  visible = true
  selectable = true
  filteredStores: Observable<string[]>
  filteredStatus: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('statusInput', { static: false }) statusInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocomplete: MatAutocomplete
  @ViewChild('autoStatus', { static: false }) matAutocompleteStatus: MatAutocomplete

  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalFormAccumulationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transaction: boolean
    },
    public reportService: ReportService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      beginDate: [null, []],
      endDate: [null, []],
      beginDateJcoins: [null, []],
      endDateJcoins: [null, []],
      storesInput: [null, []],
      statusInput: [null, []],
    })

    this.reportService.listStores().subscribe(
      response => {
        response.map(item => {
          this.stores.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.reportService.getStatus().subscribe(response => {
      Object.keys(response).map(k => {
        if (k === 'APPROVED' || k === 'CANCELED') {
          this.status.push({ key: k, value: response[k] })
        }
      })
      this.status.sort((a, b) => {
        return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
      })
    })

    this.filteredStores = this.storeControl.valueChanges.pipe(
      startWith(''),
      map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 20))),
    )

    this.filteredStatus = this.statusControl.valueChanges.pipe(
      startWith(''),
      map((status: any) => (status ? this._filterStatus(status) : this.status.slice(0, 20))),
    )
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  private _filterStores (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.stores
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  private _filterStatus (status: string): string[] {
    const remove = String(status)

    if (typeof remove !== 'string') {
      return
    }
    return this.status
      .filter(c => !this.selectedStatus.includes(c))
      .filter(c => `${String(c.value)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  remove (value: any): void {
    if (value.nomeFantasia) {
      const index = this.selectedStores.indexOf(value)
      if (index >= 0) {
        this.selectedStores.splice(index, 1)
      }
    }
    if (value.key) {
      const index = this.selectedStatus.indexOf(value)
      if (index >= 0) {
        this.selectedStatus.splice(index, 1)
      }
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.nomeFantasia) {
      this.selectedStores.push(event.option.value)
      this.storeInput.nativeElement.value = ''
      this.storeControl.setValue(null)
    }
    if (event.option.value.key) {
      this.selectedStatus.push(event.option.value)
      this.statusInput.nativeElement.value = ''
      this.statusControl.setValue(null)
    }
  }

  submit (transaction) {
    const storesIds = this.selectedStores.map(c => c.id)
    const status = this.selectedStatus.map(c => c.key)

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.formGroup.value.beginDate && this.formGroup.value.beginDateJcoins) {
      this.snackBar.open('Preencha apenas uma data inicial.')
      return false
    }

    if (this.formGroup.value.endDate && this.formGroup.value.endDateJcoins) {
      this.snackBar.open('Preencha apenas uma data final.')
      return false
    }

    if (!transaction) {
      if (this.selectedStores.length &&
        this.selectedStatus.length &&
        this.formGroup.value.beginDate &&
        this.formGroup.value.endDate) {
        this.filter = `accumulation?cardMachine=false&transactionsState=${status}&storesIds=${storesIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else if (this.selectedStores.length) {
        this.filter = `accumulation?cardMachine=false&storesIds=${storesIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else if (this.selectedStatus.length) {
        this.filter = `accumulation?cardMachine=false&transactionsState=${status}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        this.filter = `accumulation?cardMachine=false&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      }
      if (this.selectedStores.length &&
        this.selectedStatus.length &&
        this.formGroup.value.beginDateJcoins &&
        this.formGroup.value.endDateJcoins) {
        this.filter = `accumulation?cardMachine=false&transactionsState=${status}&storesIds=${storesIds}&beginDateJcoins=${this.formGroup.value.beginDateJcoins}&endDateJcoins=${this.formGroup.value.endDateJcoins}`
      } else if (this.selectedStores.length) {
        this.filter = `accumulation?cardMachine=false&storesIds=${storesIds}&beginDateJcoins=${this.formGroup.value.beginDateJcoins}&endDateJcoins=${this.formGroup.value.endDateJcoins}`
      } else if (this.selectedStatus.length) {
        this.filter = `accumulation?cardMachine=false&transactionsState=${status}&beginDateJcoins=${this.formGroup.value.beginDateJcoins}&endDateJcoins=${this.formGroup.value.endDateJcoins}`
      } else {
        this.filter = `accumulation?cardMachine=false&beginDateJcoins=${this.formGroup.value.beginDateJcoins}&endDateJcoins=${this.formGroup.value.endDateJcoins}`
      }
    }

    if (transaction) {
      if (this.selectedStores.length &&
        this.selectedStatus.length &&
        this.formGroup.value.beginDate &&
        this.formGroup.value.endDate) {
        this.filter = `accumulation-transactions?cardMachine=false&transactionsState=${status}&storesIds=${storesIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else if (this.selectedStores.length) {
        this.filter = `accumulation-transactions?cardMachine=false&storesIds=${storesIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else if (this.selectedStatus.length) {
        this.filter = `accumulation-transactions?cardMachine=false&transactionsState=${status}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        this.filter = `accumulation-transactions?cardMachine=false&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      }
    }

    this.reportService
      .createReport(this.filter)
      .then(async response => {
        if (transaction) {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.fileUrl,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return 'Relatório de Acúmulo transacional.' + response.fileUrl.split('.').splice(5, 5).join('.')
            },
          })
        } else {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.fileUrl,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return 'Relatório de Acúmulo.' + response.fileUrl.split('.').splice(5, 5).join('.')
            },
          })
        }
      })

      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
