import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
import { ModalResendCredentialsComponent } from 'src/app/components/modal-resend-credentials/modal-resend-credentials.component';
import JsFileDownloader from 'js-file-downloader';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var ClientListComponent = /** @class */ (function () {
    function ClientListComponent(storeService, route, dialog, snackBar, authService, utilService, router, reportService) {
        this.storeService = storeService;
        this.route = route;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.authService = authService;
        this.utilService = utilService;
        this.router = router;
        this.reportService = reportService;
        this.headersTable = [
            'name',
            'cpf',
            'phoneNumber',
            'emailAddress',
            'jcoinBalance',
            'membership',
            'actions',
        ];
        this.users = [];
        this.storeID = null;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ClientListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userRole = localStorage.getItem('userRole');
        this.architect = this.route.snapshot.paramMap.get('architect');
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
            }
            else {
                _this.customerId = _this.route.snapshot.paramMap.get('customerID');
                if (_this.route.snapshot.paramMap.get('storeID')) {
                    _this.storeID = _this.route.snapshot.paramMap.get('storeID');
                    _this.listUsersByStore();
                }
                else if (_this.customerId) {
                    _this.userFindOne(_this.customerId).catch(function (err) { return console.log(err); });
                }
                else {
                    _this.listUsers();
                }
            }
            _this.initForm();
        });
    };
    ClientListComponent.prototype.initForm = function () {
        if (!this.customerId) {
            this.fieldsForm = [
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'nameClient',
                    label: 'Nome',
                    placeholder: 'Nome',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'cpfClient',
                    label: 'CPF do Cliente',
                    placeholder: 'CPF do Cliente',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'phoneClient',
                    label: 'Telefone',
                    placeholder: 'Telefone',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'emailClient',
                    label: 'E-mail',
                    placeholder: 'E-mail',
                },
            ];
        }
    };
    ClientListComponent.prototype.listUsers = function () {
        var _this = this;
        var filter = this.architect ?
            "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"architect\"], \"op\": \"=\", \"value\":\"true\"} ]}" :
            "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"architect\"], \"op\": \"=\", \"value\":\"false\"} ]}";
        this.storeService
            .listUsersCustomers(filter)
            .toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                response.items.map(function (user) {
                    user.fullName = user.firstName + " " + user.lastName;
                });
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.items);
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    ClientListComponent.prototype.listUsersByStore = function () {
        var _this = this;
        this.storeService.listUsersByStore("?storeId=" + this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.users = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    ClientListComponent.prototype.listCustomerInfo = function (userID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.authService
                    .customerUserInfo(userID)
                    .toPromise()
                    .then(function (response) {
                    _this.router.navigateByUrl("/dashboard/memberships/transactions/plan/" + response.planId + "/customer/" + userID);
                }, function (error) {
                    alert(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ClientListComponent.prototype.userFindOne = function (customerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storeService
                    .listUsersCustomers("?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ", \"filters\":[{\"fields\":[\"customers.id\"], \"op\": \"=\", \"value\":\"" + customerId + "\"}]}")
                    .toPromise()
                    .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        response.items.map(function (user) {
                            user.fullName = user.firstName + " " + user.lastName;
                            _this.cpf = user.cpf;
                        });
                        this.pageTotal = response.total;
                        this.dataSource = new MatTableDataSource(response.items);
                        this.fieldsForm = [
                            {
                                field: 'input',
                                type: 'text',
                                formControlName: 'nameClient',
                                label: 'Nome',
                                placeholder: 'Nome',
                            },
                            {
                                field: 'input',
                                type: 'text',
                                formControlName: 'cpfClient',
                                label: 'CPF do Cliente',
                                placeholder: 'CPF do Cliente',
                                valueDefault: this.cpf,
                            },
                            {
                                field: 'input',
                                type: 'text',
                                formControlName: 'phoneClient',
                                label: 'Telefone',
                                placeholder: 'Telefone',
                            },
                            {
                                field: 'input',
                                type: 'text',
                                formControlName: 'emailClient',
                                label: 'E-mail',
                                placeholder: 'E-mail',
                            },
                        ];
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    alert(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ClientListComponent.prototype.openDialogResendCredentials = function (userID) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalResendCredentialsComponent, {
            width: '550px',
            data: { text: 'Deseja fazer o reenvio das credenciais do cliente?' },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result > 0) {
                _this.authService.resendCredentials(userID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.snackBar.open('Credenciais enviadas com sucesso.');
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    alert(error.error.message);
                });
            }
        });
    };
    ClientListComponent.prototype.remove = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.dialog
                    .open(ModalConfirmComponent, {
                    data: {
                        text: 'Deseja remover esse Cliente?',
                        buttonConfirmText: 'Remover',
                    },
                })
                    .afterClosed()
                    .toPromise()
                    .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        if (result && result !== 'cancel') {
                            return [2 /*return*/, this.authService.deleteCustomer(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        this.listUsers();
                                        this.snackBar.open('Cliente removida com sucesso.');
                                        return [2 /*return*/];
                                    });
                                }); }, function (error) {
                                    _this.snackBar.open(error.error.message);
                                })];
                        }
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (err) {
                    _this.snackBar.open(err.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ClientListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.createReport('customers').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // eslint-disable-next-line no-new
                                new JsFileDownloader({
                                    url: response.fileUrl,
                                    nameCallback: function () {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        return 'Relatório de Clientes.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientListComponent.prototype.reciverFeedback = function (returnFilter) {
        returnFilter.items.map(function (user) {
            user.fullName = user.firstName + ' ' + user.lastName;
        });
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.items);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return ClientListComponent;
}());
export { ClientListComponent };
