import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store } from 'src/app/models/store';
interface DialogData {
  cnpj: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-cancel-transaction.component.html',
  styleUrls: ['./modal-cancel-transaction.component.scss']
})
export class ModalCancelTransactionComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalCancelTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar) {
      console.log('data', data);
     }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }

    
    this.dialogRef.close(this.formGroup.value.reason);
  }

  ngOnInit() {
    

    this.formGroup = this.formBuilder.group(
      {
        reason: [null, [
          Validators.required,
        ]],
      });
  }
}

