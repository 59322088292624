import * as tslib_1 from "tslib";
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import JsFileDownloader from 'js-file-downloader';
var StoreListComponent = /** @class */ (function () {
    function StoreListComponent(router, route, storeService, dialog, snackBar, utilService, reportService) {
        this.router = router;
        this.route = route;
        this.storeService = storeService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.utilService = utilService;
        this.reportService = reportService;
        this.headersTable = ['cnpj', 'nomeFantasia', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    StoreListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.params = {
            previousRoute: 'store-list',
        };
        this.route.queryParams.subscribe(function (params) {
            if (params.storeFormGroupValue) {
                _this.formGroup = JSON.parse(params.storeFormGroupValue);
                _this.initForm();
            }
            else {
                _this.listStores();
                _this.initForm();
            }
        });
    };
    StoreListComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjStore',
                label: 'CNPJ',
                placeholder: 'CNPJ',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'nomeFantasiaStore',
                label: 'Nome Fantasia',
                placeholder: 'Nome Fantasia',
            },
        ];
    };
    StoreListComponent.prototype.listStores = function () {
        var _this = this;
        this.storeService
            .listStores("?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StoreListComponent.prototype.updateStatus = function () {
        this.listStores();
    };
    StoreListComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result > 0) {
                _this.router.navigate(['./dashboard/stores/new/' + result]);
            }
        });
    };
    StoreListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Parceiro?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.storeService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listStores();
                                this.snackBar.open('Parceiro removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    StoreListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            previousRoute: 'store-list',
            storeFormGroupValue: JSON.stringify(this.formGroup),
        };
    };
    StoreListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.storesWithMacroGroup().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // eslint-disable-next-line no-new
                                new JsFileDownloader({
                                    url: response.fileUrl,
                                    nameCallback: function () {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        return 'Parceiros com Macro grupos.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return StoreListComponent;
}());
export { StoreListComponent };
