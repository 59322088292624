import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
interface DialogData {
  cnpj: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar) { }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }
    
    this.dialogRef.close(this.formGroup.value.cnpj);
  }

  ngOnInit() {
    

    this.formGroup = this.formBuilder.group(
      {
        cnpj: [null, [
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
        ]],
      });
  }
}

