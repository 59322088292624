import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AirportsService = /** @class */ (function () {
    function AirportsService(http) {
        this.http = http;
    }
    AirportsService.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/airports").toPromise()];
            });
        });
    };
    AirportsService.prototype.getS3Url = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/airports/s3-slot").toPromise()];
            });
        });
    };
    AirportsService.prototype.uploadToS3 = function (pathAwsUpload, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("" + pathAwsUpload, data).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportsService.prototype.createAirport = function (airport) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/airport", airport)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.findOne = function (airportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/airport/" + airportId).toPromise()];
            });
        });
    };
    AirportsService.prototype.updateAirport = function (airportId, airport) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/airport/" + airportId, airport)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.deleteAirport = function (airportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/airport/" + airportId)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.listInformations = function (informationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/airport/" + informationId + "/information")
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.createInformation = function (informationId, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/airport/" + informationId + "/information", body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.updateInformation = function (airportId, informationId, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/airport/" + airportId + "/information/" + informationId, body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.informationFindOne = function (informationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/airport/information/" + informationId)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.deleteInformation = function (informationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/airport/information/" + informationId)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.listInfoFields = function (informationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/airport/information/" + informationId + "/fields")
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.deleteField = function (infoFieldId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/airport/information/field/" + infoFieldId)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.createInfoField = function (informationId, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/airport/information/" + informationId + "/fields", body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.infoFieldFindOne = function (informationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/airport/information/fields/" + informationId)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.updateInfoField = function (informationId, fieldId, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/airport/information/" + informationId + "/field/" + fieldId, body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.listCompanyAirport = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/company-airport" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.preRegisterCompaniesAirport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration-companies-airports")
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.createAirportCompany = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/company-airport", body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.createPreRegisterAirportCompany = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/pre-registration-company-airport", body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.fideOneCompanyAirport = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/company-airport/" + id)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.fideOneCompanyAirportPreRegister = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration-company-airport/" + id)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.updateAirportCompany = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/company-airport/" + id, body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.updatePreRegisterAirportCompany = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/pre-registration-company-airport/" + id, body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.deleteAirportCompany = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/company-airport/" + id)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.deletePreRegisterAirportCompany = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/pre-registration-company-airport/" + id)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.listCompanyAirportCustomers = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/company-airport/" + id + "/customers")
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.listCompanyAirportCustomersDelete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/company-airport/" + id + "/customers/delete")
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.createAirportCompanyCustomer = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/company-airport-customer", body)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.deleteAirportCompanyCustomer = function (id, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/company-airport-customer/" + id + filter)
                        .toPromise()];
            });
        });
    };
    AirportsService.prototype.listCompanyAirportHistoric = function (cnpj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/company-airport-stores/" + cnpj)
                        .toPromise()];
            });
        });
    };
    AirportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AirportsService_Factory() { return new AirportsService(i0.ɵɵinject(i1.HttpClient)); }, token: AirportsService, providedIn: "root" });
    return AirportsService;
}());
export { AirportsService };
