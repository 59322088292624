<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">CADASTRO DE PARCEIRO</h2>
    <h2 *ngIf="id">EDIÇÃO DE PARCEIRO</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/categories/{{ categoryID }}/business-partners"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <mat-card>
    <div class="store-form__actions">
      <button *ngIf="id" color="warn" mat-button (click)="remove(id, $event)">Remover</button>
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="name" placeholder="Nome" />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="addressName" placeholder="Nome do Endereço 1" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Endereço 1</mat-label>
              <input
                matInput
                formControlName="address"
                placeholder="Endereço 1"
                (change)="changeInput()"
              />
            </mat-form-field>
            <!-- <mat-error style="font-size: 12px" *ngIf="addressInvalid"
              >Campo deve ser preenchido</mat-error
            > -->

            <mat-form-field>
              <input
                matInput
                formControlName="address1GoogleMaps"
                placeholder="Link para Google Maps do Endereço 1"
              />
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="addressName2" placeholder="Nome do Endereço 2" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Endereço 2</mat-label>
              <input matInput formControlName="address2" placeholder="Endereço 2" />
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                formControlName="address2GoogleMaps"
                placeholder="Link para Google Maps do Endereço 2"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Link</mat-label>
              <input matInput formControlName="link" placeholder="Link" (change)="changeInput()" />
            </mat-form-field>
            <!-- <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="addressInvalid"
              >Campo deve ser preenchido</mat-error
            > -->

            <input
              #_fileInput
              type="file"
              (change)="onUploadImage($event)"
              accept="image/*"
              hidden
            />

            <!-- <div style="display: flex; justify-content: space-between">
              <a mat-icon-button matSuffix (click)="_fileInput.click()">
                <span>Carregar imagem</span>
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                style="width: 113px !important"
                *ngIf="cropped"
                matSuffix
                mat-icon-button
                (click)="editImage()"
              >
                <span>Editar imagem</span>
                <mat-icon>edit</mat-icon>
              </a>
            </div> -->

            <!-- <div *ngIf="cropped"><img [src]="cropped" width="474px" /></div> -->

            <mat-form-field>
              <mat-label>Imagem</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <img
                *ngIf="imageUrl != ''"
                width="200"
                src="{{ imageUrl }}"
                (error)="imgLoadError()"
              />
              <div class="btn-remove-img">
                <button
                  class="btn-primary-light"
                  mat-button
                  *ngIf="imageUrl"
                  (click)="removeImage()"
                >
                  REMOVER IMAGEM <mat-icon>cancel</mat-icon>
                </button>
              </div>
              <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
            </mat-form-field>
            <mat-error style="padding-bottom: 10px" *ngIf="imageInvalid"
              >Campo deve ser preenchido</mat-error
            >
            <!-- <p style="margin-bottom: 5px">Conteúdo</p>
            <ckeditor
              [config]="config"
              [editor]="Editor"
              formControlName="content"
              (change)="onChange($event)"
              (ready)="onReady($event)"
            ></ckeditor>
            <mat-error *ngIf="contentInvalid">Campo deve ser preenchido</mat-error> -->
            <ckeditor
              [config]="config"
              [editor]="Editor"
              formControlName="content"
              (change)="onChange($event)"
              (ready)="onReady($event)"
            ></ckeditor>
            <mat-error *ngIf="contentInvalid">Campo deve ser preenchido</mat-error>

            <mat-form-field style="padding-top: 15px">
              <input
                matInput
                formControlName="beginDate"
                placeholder="Data Inicial"
                type="date"
                (change)="alterStatus($event)"
              />
              <mat-error *ngIf="formGroup.controls.beginDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.beginDate) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="endDate"
                placeholder="Data Final"
                type="date"
                (change)="alterStatus($event)"
              />
              <mat-error *ngIf="formGroup.controls.endDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.endDate) }}
              </mat-error>
            </mat-form-field>

            <p style="margin-bottom: 5px">Sobre</p>
            <ckeditor
              [config]="config"
              [editor]="Editor"
              formControlName="about"
              (change)="onChange($event)"
              (ready)="onReady($event)"
            ></ckeditor>

            <p style="margin-bottom: 5px; margin-top: 20px">Redes Sociais</p>
            <div class="row" style="padding: 0">
              <div class="col-12 col-sm-6">
                <mat-form-field>
                  <mat-label>Perfil Facebook</mat-label>
                  <input matInput formControlName="userFacebook" placeholder="Perfil Facebook" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>User do Twitter</mat-label>
                  <input matInput formControlName="userTwitter" placeholder="User Twitter" />
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-6">
                <mat-form-field>
                  <mat-label>Link do Facebook</mat-label>
                  <input matInput formControlName="linkFacebook" placeholder="Link Facebook" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Link do Twitter</mat-label>
                  <input matInput formControlName="linkTwitter" placeholder="Link do Twitter" />
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-6">
                <mat-form-field>
                  <mat-label>User do Instagram</mat-label>
                  <input matInput formControlName="userInstagram" placeholder="User do Instagram" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Nome do Canal no Youtube</mat-label>
                  <input matInput formControlName="userYoutube" placeholder="Canal no Youtube" />
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-6">
                <mat-form-field>
                  <mat-label>Link do Instagram</mat-label>
                  <input matInput formControlName="linkInstagram" placeholder="Link Instagram" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Link do Youtube</mat-label>
                  <input matInput formControlName="linkYoutube" placeholder="Link Youtube" />
                </mat-form-field>
              </div>
            </div>

            <p>Contato:</p>
            <mat-button-toggle-group
              formControlName="contact"
              #group="matButtonToggleGroup"
              multiple
              (change)="toggleChange($event)"
            >
              <mat-button-toggle value="accountManager">
                Fale com o Account Manager
              </mat-button-toggle>
              <mat-button-toggle value="interest"> Cadastrar Interesse </mat-button-toggle>
            </mat-button-toggle-group>

            <div class="check">
              <input formControlName="isActive" type="checkbox" />
              <mat-label> &nbsp;Ativo</mat-label>
            </div>
            <div class="check">
              <input formControlName="isMain" type="checkbox" />
              <mat-label> &nbsp;Principais Parceiros</mat-label>
            </div>
            <div class="check">
              <input formControlName="onlineSale" type="checkbox" />
              <mat-label> &nbsp;Venda Online</mat-label>
            </div>
            <div class="check">
              <input formControlName="physicalStore" type="checkbox" />
              <mat-label> &nbsp;Venda Loja Física</mat-label>
            </div>
          </mat-card-content>
          <div *ngIf="isCategoriesJoin">
            <mat-form-field>
              <mat-label>Categoria</mat-label>
              <mat-select formControlName="categoryJoin">
                <mat-option *ngFor="let categoryJoin of categoriesJoin" [value]="categoryJoin.id"
                  ><p *ngIf="categoryJoin.categoryHeading">{{ categoryJoin.categoryHeading }}</p>
                  <p *ngIf="categoryJoin.categoriesName">{{ categoryJoin.categoriesName }}</p>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.categoryJoin?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.categoryJoin) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </form>
  </mat-card>
</app-logged>
