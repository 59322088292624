<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!isUpdate">NOVO PARCEIRO</h2>
    <h2 *ngIf="isUpdate">EDITAR PARCEIRO</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/stores"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="openDialogCampaignType()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-12" *ngIf="storeID != '' && storeID != null">
          <qrcode
            [qrdata]="valueQrCode"
            id="print-section"
            [width]="256"
            [elementType]="elementType"
            [errorCorrectionLevel]="'L'"
          ></qrcode>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome Fantasia</mat-label>
              <input matInput formControlName="nomeFantasia" />
              <mat-error *ngIf="formGroup.controls.nomeFantasia?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.nomeFantasia) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Razão Social</mat-label>
              <input matInput formControlName="razaoSocial" />
              <mat-error *ngIf="formGroup.controls.razaoSocial?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.razaoSocial) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <input matInput formControlName="phoneNumber" [mask]="dynamicPhoneMask()" />
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de abertura</mat-label>
              <input type="date" matInput formControlName="openingDate" />
              <mat-error *ngIf="formGroup.controls.openingDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.openingDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Segmento</mat-label>

              <input
                type="text"
                placeholder="Segmento"
                matInput
                formControlName="mcc"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySegment">
                <mat-option
                  *ngFor="let seg of segmentosOptions | async"
                  (blur)="onChange(seg)"
                  value="{{ seg.code }} - {{ seg.category }} - {{ seg.description }}"
                >
                  {{ seg.code }} - {{ seg.category }} - {{ seg.description }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="formGroup.controls.mcc?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.mcc) }}
              </mat-error>
              <!--<mat-select formControlName="mcc">
                <mat-option *ngFor="let seg of segmentos" (blur)="onChange(seg)"  value="{{ seg.code }} - {{ seg.category }} - {{ seg.description }}">
                  {{ seg.code }} - {{ seg.category }} - {{ seg.description }}
                </mat-option>
              </mat-select>-->

              <!--<mat-select formControlName="mcc">
                <mat-option *ngFor="let seg of segmentos" (blur)="onChange(seg)"  value="{{ seg.code }} - {{ seg.category }} - {{ seg.description }}">
                  {{ seg.code }} - {{ seg.category }} - {{ seg.description }}
                </mat-option>
              </mat-select>-->
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sub-empresa?</mat-label>
              <mat-select
                formControlName="isSubStore"
                (selectionChange)="changeIsSubStore($event.value)"
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isSubStore?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isSubStore) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Endereço</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CEP</mat-label>
              <input matInput (blur)="loadAddress()" formControlName="zipCode" mask="00000-000" />
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.abbr">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="neighborhood" />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Rua</mat-label>
              <input matInput formControlName="street" />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Número</mat-label>
              <input matInput formControlName="number" />
              <mat-error *ngIf="formGroup.controls.number?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.number) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Complemento</mat-label>
              <textarea matInput formControlName="complement"></textarea>
              <mat-error *ngIf="formGroup.controls.complement?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.complement) }}
              </mat-error>
            </mat-form-field>
            <p style="font-weight: bold; margin-bottom: 0px !important; margin-top: 0px !important">
              Sub-empresas
            </p>

            <div formArrayName="subStores">
              <div
                style="display: flex"
                *ngFor="let subStore of formGroup.get('subStores')['controls']; let i = index"
                [formGroupName]="i"
              >
                <div style="margin-right: 10px">
                  <mat-form-field *ngIf="isSubStore" style="margin-right: 1rem">
                    <input
                      matInput
                      formControlName="subStoreName"
                      placeholder="Sub-empresa"
                      autocomplete="subStore"
                      (keypress)="keyPress($event)"
                      (blur)="validStoreName($event)"
                    />
                  </mat-form-field>
                  <mat-error
                    style="padding-bottom: 9px; font-size: 11px"
                    *ngIf="subStoreNameInvalid"
                    >Campo deve ser preenchido</mat-error
                  >
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Macro Grupo</mat-label>
                    <mat-select
                      (selectionChange)="changeMacroGroup($event)"
                      formControlName="macroGroupId"
                    >
                      <mat-option *ngFor="let mg of macroGroups" [value]="mg.id">
                        {{ mg.macroName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error
                    style="padding-bottom: 9px; font-size: 11px"
                    *ngIf="macroGroupIdInvalid"
                    >Campo deve ser preenchido</mat-error
                  >
                </div>
                <div style="display: flex">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="removeOrClearMacroGroup($event, i)"
                    matTooltip="Remover"
                  >
                    <mat-icon>highlight_off</mat-icon>
                  </button>
                </div>
              </div>
              <button
                mat-icon-button
                color="primary"
                (click)="addMacroGroupFormGroup($event)"
                matTooltip="Adicionar"
              >
                Adicionar <mat-icon>add_circle_outline</mat-icon>
              </button>
              <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="subAndMacroInvalid"
                >Campo deve ser preenchido</mat-error
              >
            </div>
          </mat-card-content>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Configurações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Membership Ativo?</mat-label>
              <mat-select
                formControlName="isMembershipActive"
                (selectionChange)="changeIsMembershipActive($event)"
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isMembershipActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isMembershipActive) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Tipo</mat-label>
              <mat-select
                formControlName="typePayment"
                (selectionChange)="changeTypePayment($event)"
              >
                <mat-option value="cardPaymentAndJcoinsPayment" selected
                  >Acúmulo e Resgate</mat-option
                >
                <mat-option value="cardPayment">Acúmulo</mat-option>
                <mat-option value="jcoinsPayment">Resgate</mat-option>
                <mat-option value="jcoinsPriorityPayment">Prioridade para Resgate</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.typePayment?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.typePayment) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Pagamento ativo?</mat-label>
              <mat-select
                formControlName="isPayActive"
                (selectionChange)="changeIsPayActive($event)"
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isPayActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isPayActive) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Habilitar pagamento manual?</mat-label>
              <mat-select formControlName="manualPaymentEnabled">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.manualPaymentEnabled?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.manualPaymentEnabled) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Habilitar pagamento futuro?</mat-label>
              <mat-select formControlName="futurePaymentEnabled">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.futurePaymentEnabled?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.futurePaymentEnabled) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label
                >Parceiro Concierge? (usado para pagamento de transações do concierge)</mat-label
              >
              <mat-select formControlName="isConciergePaymentStore">
                <mat-option [value]="false">Não</mat-option>
                <mat-option [value]="true">Sim</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isConciergePaymentStore?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isConciergePaymentStore) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Dias para a liquidação (padrão: 30)</mat-label>
              <input type="number" matInput formControlName="liquidationDays" />
              <mat-error *ngIf="formGroup.controls.liquidationDays?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.liquidationDays) }}
              </mat-error>
            </mat-form-field>

            <div *ngIf="isUpdate">
              <mat-label>
                <div style="display: flex">
                  <p style="margin-top: 3px; margin-bottom: 5px"><b>Roadpass&nbsp;</b></p>
                  <mat-icon
                    [ngStyle]="{ color: 'green' }"
                    *ngIf="this.isActiveRoadpass"
                    matTooltip="Criado na RoadPass"
                  >
                    check
                  </mat-icon>
                  <mat-icon
                    matTooltip="Erro ao criar na RoadPass"
                    [ngStyle]="{ color: 'red' }"
                    *ngIf="!this.isActiveRoadpass"
                  >
                    close
                  </mat-icon>
                </div>
                <p
                  *ngIf="!this.isActiveRoadpass"
                  style="margin-top: 0px; font-size: 12px; color: red"
                >
                  Click em Salvar para reenviar o cadastro para <u>RoadPass</u>
                </p>
              </mat-label>

              <mat-label>
                <div style="display: flex">
                  <p style="margin-top: 3px; margin-bottom: 5px"><b>Zoop&nbsp;</b></p>
                  <mat-icon
                    [ngStyle]="{ color: 'green' }"
                    *ngIf="this.isZoopEnabled"
                    matTooltip="Criado na Zoop"
                  >
                    check
                  </mat-icon>
                  <mat-icon
                    matTooltip="Erro ao criar na Zoop"
                    [ngStyle]="{ color: 'red' }"
                    *ngIf="!this.isZoopEnabled"
                  >
                    close
                  </mat-icon>
                </div>
                <p *ngIf="!this.isZoopEnabled" style="margin-top: 0px; font-size: 12px; color: red">
                  Click em Salvar para reenviar o cadastro para <u>Zoop</u>
                </p>
              </mat-label>

              <p style="font-size: 20px; margin-bottom: 0px">Campanha Ativa</p>
              <p style="margin-bottom: 5px; text-decoration: underline">
                <b>ID da Campanha:</b> {{ idCampaign }}
              </p>
              <p style="margin-top: 5px; text-decoration: underline">
                <b>Descrição:</b> {{ descriptionCampaign }}
              </p>
            </div>
          </mat-card-content>
        </div>

        <div class="col-12 col-sm-6" *ngIf="isPayActive || (isMembershipActive && typePayment)">
          <mat-card-title>Taxas</mat-card-title>
          <mat-card-content>
            <mat-form-field
              *ngIf="
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPayment')
              "
            >
              <mat-label>Quanto vale um J-Coin na saida? </mat-label>
              <input
                matInput
                type="text"
                formControlName="pointsConversionFactorOut"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorOut?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorOut) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Quanto vale um J-Coin na entrada?</mat-label>
              <input
                matInput
                type="text"
                formControlName="pointsConversionFactorIn"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorIn?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorIn) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Taxa Cashback (J-Coins)</mat-label>
              <input
                matInput
                formControlName="taxCashback"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
                [dropSpecialCharacters]="false"
              />
              <mat-error *ngIf="formGroup.controls.taxCashback?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxCashback) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Taxa Administrativa JHSF</mat-label>
              <input
                matInput
                formControlName="taxAdministrative"
                mask="separator.4"
                [dropSpecialCharacters]="false"
                decimalMarker=","
                suffix="%"
              />
              <mat-error *ngIf="formGroup.controls.taxAdministrative?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxAdministrative) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPayment')
              "
            >
              <mat-label>Taxa Transação J-Coins</mat-label>
              <input
                matInput
                formControlName="taxRewardsTransaction"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
                [dropSpecialCharacters]="false"
              />
              <mat-error *ngIf="formGroup.controls.taxRewardsTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxRewardsTransaction) }}
              </mat-error>
            </mat-form-field>

            <!--
              <mat-form-field *ngIf="isMembershipActive">
              <mat-label>Número Máximo de Pontos por Venda</mat-label>
              <input matInput type="number" formControlName="maximumPointsPerTransaction" />
              <mat-error *ngIf="formGroup.controls.maximumPointsPerTransaction?.invalid">
                {{
                  errorsService.messageErrorFor(
                    formGroup.controls.maximumPointsPerTransaction
                  )
                }}
              </mat-error>
            </mat-form-field>
            -->

            <mat-form-field
              *ngIf="
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Expiração dos pontos (dias)</mat-label>
              <input matInput type="number" formControlName="pointsExpiration" />
              <mat-error *ngIf="formGroup.controls.pointsExpiration?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsExpiration) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isPayActive">
              <mat-label>Taxa Transação JHSF Pay</mat-label>
              <input
                matInput
                formControlName="taxCreditCardTransaction"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
              />
              <mat-error *ngIf="formGroup.controls.taxCreditCardTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxCreditCardTransaction) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                isPayActive ||
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Número Máximo de Parcelas por Transação</mat-label>
              <mat-select formControlName="maximumInstallmentsPerTransaction">
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.maximumInstallmentsPerTransaction?.invalid">
                {{
                  errorsService.messageErrorFor(
                    formGroup.controls.maximumInstallmentsPerTransaction
                  )
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                isPayActive ||
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Valor Mínimo da Parcela</mat-label>
              <input
                currencyMask
                matInput
                formControlName="minimumInstallmentValue"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.minimumInstallmentValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.minimumInstallmentValue) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="
                isPayActive ||
                (isMembershipActive && typePayment === 'cardPaymentAndJcoinsPayment') ||
                (isMembershipActive && typePayment === 'jcoinsPriorityPayment') ||
                (isMembershipActive && typePayment === 'cardPayment')
              "
            >
              <mat-label>Valor Mínimo da Transação</mat-label>
              <input
                currencyMask
                matInput
                formControlName="minimumTransactionValue"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.minimumTransactionValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.minimumTransactionValue) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Conta Bancária</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Banco</mat-label>
              <input type="text" matInput formControlName="bankCode" pattern="[0-9]*" />
              <mat-error *ngIf="formGroup.controls.bankCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Agência</mat-label>
              <input matInput formControlName="agency" />
              <mat-error *ngIf="formGroup.controls.agency?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.agency) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Conta</mat-label>
              <input matInput formControlName="accountNumber" />
              <mat-error *ngIf="formGroup.controls.accountNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Dígito</mat-label>
              <input matInput formControlName="accountDigit" />
              <mat-error *ngIf="formGroup.controls.accountDigit?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountDigit) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <mat-select formControlName="accountType">
                <mat-option [value]="'CHECKING'">Corrente</mat-option>
                <mat-option [value]="'SAVINGS'">Poupança</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.accountType?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountType) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
