import { HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ArchitectInvoiceService = /** @class */ (function () {
    function ArchitectInvoiceService(http) {
        this.http = http;
    }
    ArchitectInvoiceService.prototype.createInvoice = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/architect-invoices", data).pipe(tap(function (_) { return _this.log('cms/architect-invoices'); }), catchError(this.handleError));
    };
    ArchitectInvoiceService.prototype.updateInvoice = function (data, architectInvoiceId) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/architect-invoices/" + architectInvoiceId, data).pipe(tap(function (_) { return _this.log('cms/architect-invoices'); }), catchError(this.handleError));
    };
    ArchitectInvoiceService.prototype.searchArchitectForInvoice = function (searchTerm) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/architect-invoices/comission-receivers-search?search=" + searchTerm, {
            headers: new HttpHeaders({ 'noloading': 'true' })
        })
            .pipe(tap(function (_) { return _this.log('cms/architect-invoices/comission-receivers-search'); }), catchError(this.handleError));
    };
    ArchitectInvoiceService.prototype.getUploadUrl = function (storeBusinessUnitId, filename) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/architect-invoices/" + storeBusinessUnitId + "/create-upload-url", {
            filename: filename
        }).pipe(tap(function (_) { return _this.log("/cms/architect-invoices/" + storeBusinessUnitId + "/create-upload-url"); }), catchError(this.handleError));
    };
    ArchitectInvoiceService.prototype.list = function (_a) {
        var storeBusinessUnitId = _a.storeBusinessUnitId, filter = _a.filter;
        if (storeBusinessUnitId)
            filter = (filter || '?') + ("&storeId=" + storeBusinessUnitId);
        return this.http
            .get(environment.apiUrl + "/cms/architect-invoices" + (filter || ''))
            .toPromise();
    };
    ArchitectInvoiceService.prototype.get = function (architectInvoiceId) {
        return this.http
            .get(environment.apiUrl + "/cms/architect-invoices/" + architectInvoiceId)
            .toPromise();
    };
    ArchitectInvoiceService.prototype.getDetails = function (architectInvoiceId) {
        return this.http
            .get(environment.apiUrl + "/cms/architect-invoices/" + architectInvoiceId + "/details")
            .toPromise();
    };
    ArchitectInvoiceService.prototype.uploadInvoicePdf = function (pathAwsUpload, file) {
        var _this = this;
        var headers = {
            'Content-Type': file.type,
        };
        return this.http
            .put("" + pathAwsUpload, file, { headers: headers })
            .pipe(tap(function (_) { return _this.log('/cms/architect-invoices/'); }), catchError(this.handleError));
    };
    ArchitectInvoiceService.prototype.removeInvoicePdf = function (invoiceId, pdfUrl) {
        return this.http
            .delete(environment.apiUrl + "/cms/architect-invoices/" + invoiceId + "/remove-pdf?publicUrl=" + pdfUrl)
            .toPromise();
    };
    ArchitectInvoiceService.prototype.cancelInvoice = function (invoiceId) {
        return this.http
            .delete(environment.apiUrl + "/cms/architect-invoices/" + invoiceId)
            .toPromise();
    };
    ArchitectInvoiceService.prototype.approveInvoices = function (invoiceIds) {
        return this.http
            .post(environment.apiUrl + "/cms/architect-invoices/approve", { invoices: invoiceIds })
            .toPromise();
    };
    ArchitectInvoiceService.prototype.validateInvoices = function (invoiceIds) {
        return this.http
            .post(environment.apiUrl + "/cms/architect-invoices/validate", { invoices: invoiceIds })
            .toPromise();
    };
    ArchitectInvoiceService.prototype.invalidateInvoices = function (invoiceId, invalidateReason) {
        return this.http
            .post(environment.apiUrl + "/cms/architect-invoices/invalidate", { invoiceId: invoiceId, invalidateReason: invalidateReason })
            .toPromise();
    };
    ArchitectInvoiceService.prototype.getInvoiceStatus = function (invoice) {
        var canceled = invoice.canceled, approved = invoice.approved, validated = invoice.validated, invalidated = invoice.invalidated;
        if (invalidated)
            return { text: "Pedido rejeitado", color: "red" };
        if (canceled)
            return { text: "Cancelada", color: "red" };
        if (approved)
            return { text: "Paga", color: "green" };
        if (validated)
            return { text: "Pedido pendente", color: "orange" };
        return { text: "Aguardando validação", color: "orange" };
    };
    ArchitectInvoiceService.prototype.handleError = function (error) {
        return throwError(error);
    };
    ArchitectInvoiceService.prototype.log = function (message) {
        console.log(message);
    };
    ArchitectInvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArchitectInvoiceService_Factory() { return new ArchitectInvoiceService(i0.ɵɵinject(i1.HttpClient)); }, token: ArchitectInvoiceService, providedIn: "root" });
    return ArchitectInvoiceService;
}());
export { ArchitectInvoiceService };
