import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTableDataSource } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
var ArchitectsCompanyCustomerFormComponent = /** @class */ (function () {
    function ArchitectsCompanyCustomerFormComponent(errorsService, snackBar, formBuilder, companiesService, route, router, reportService, preRegistrationService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.companiesService = companiesService;
        this.route = route;
        this.router = router;
        this.reportService = reportService;
        this.preRegistrationService = preRegistrationService;
        this.customers = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.hideCustomer1 = true;
        this.hideEdit1 = true;
        this.addCustomer1 = true;
        this.showFirstName1 = false;
        this.totalPercentage = 100;
        this.selectedCustomers1 = [];
        this.customerControl1 = new FormControl();
        this.selectedData = [];
        this.headersTable = ['cpf', 'comissionFee', 'fullName', 'actions'];
    }
    ArchitectsCompanyCustomerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            cpf: [null, []],
            percentage: [null, []],
        });
        this.preRegistrationService.listArchitects('').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                response = response.results;
                response.map(function (item) {
                    _this.customers.push({
                        cpf: item.preRegistration.cpf,
                        name: item.preRegistration.firstName + " " + item.preRegistration.lastName,
                    });
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
        });
        this.reportService.listCustomersArchitects().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers1 = this.customerControl1.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.addCpf = function (event) {
        event.preventDefault();
        var remaining = Number(this.totalPercentage) - Number(this.formGroup.value.percentage);
        if (this.formGroup.value.percentage && this.cpf && remaining >= 0) {
            this.selectedData.push({
                cpf: this.cpf,
                comissionFee: Number(this.formGroup.value.percentage),
            });
            this.dataSource = new MatTableDataSource(this.selectedData);
            this.totalPercentage =
                Number(this.totalPercentage) - Number(this.formGroup.value.percentage);
            this.formGroup.get('percentage').setValue(null);
            this.customerInput1.nativeElement.value = '';
            this.customerControl1.setValue(null);
            this.cpf = '';
            this.selectedCustomers1 = [];
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.remove = function (cpf) {
        var e_1, _a;
        var index = this.selectedData.indexOf(cpf);
        if (index >= 0) {
            this.selectedData.splice(index, 1);
            this.totalPercentage = 100;
            try {
                for (var _b = tslib_1.__values(this.selectedData), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var d = _c.value;
                    this.totalPercentage = Number(this.totalPercentage) - Number(d.comissionFee);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.dataSource = new MatTableDataSource(this.selectedData);
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype._filter = function (customer) {
        var _this = this;
        if (!this.selectedCustomers1.length) {
            var remove_1 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_1 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers1.includes(c); })
                .filter(function (c) {
                return (String(c.name) + " " + String(c.cpf)).toLowerCase().includes(remove_1.toLowerCase());
            })
                .slice(0, 20);
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.removeCustomer = function (customer, input) {
        if (input === 1) {
            var index = this.selectedCustomers1.indexOf(customer);
            if (index >= 0) {
                this.selectedCustomers1.splice(index, 1);
            }
            this.cpf = undefined;
            this.hideCustomer1 = true;
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.selected = function (event, input) {
        if (!this.selectedCustomers1.length && input === 1) {
            this.selectedCustomers1.push(event.option.value);
            this.customerInput1.nativeElement.value = '';
            this.customerControl1.setValue(null);
            this.cpf = this.selectedCustomers1[0].cpf;
            this.hideCustomer1 = false;
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .findOne(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var e_2, _a, _b, _c, c;
                            return tslib_1.__generator(this, function (_d) {
                                this.company = response;
                                this.totalPercentage = 100;
                                try {
                                    for (_b = tslib_1.__values(response.customers), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        c = _c.value;
                                        this.selectedData.push({
                                            cpf: c.customerCpf,
                                            comissionFee: c.comissionFee,
                                            fullName: c.firstName + " " + c.lastName,
                                        });
                                        this.totalPercentage =
                                            Number(this.totalPercentage) - Number(c.comissionFee);
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                                this.dataSource = new MatTableDataSource(this.selectedData);
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectsCompanyCustomerFormComponent.prototype.valueInputCpf = function (e, input) {
        if (input === 1 && this.selectedCustomers1.length === 0) {
            this.cpf = e.target.value;
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3, _a, total, _b, _c, d;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                total = 0;
                try {
                    for (_b = tslib_1.__values(this.selectedData), _c = _b.next(); !_c.done; _c = _b.next()) {
                        d = _c.value;
                        total = total + Number(d.comissionFee);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                if (this.totalPercentage !== 0 && total !== 100) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.selectedData.map(function (cs) { return delete cs.fullName; });
                this.companiesService.update(this.id, { customers: this.selectedData }).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response) {
                            this.snackBar.open('Cliente cadastrado com sucesso.');
                            this.router.navigate(['./dashboard/architect-companies']);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return ArchitectsCompanyCustomerFormComponent;
}());
export { ArchitectsCompanyCustomerFormComponent };
