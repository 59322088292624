import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '../../../../services/uploadAdapter.service';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatSnackBar, } from '@angular/material';
var NoticationFormComponent = /** @class */ (function () {
    function NoticationFormComponent(formBuilder, errorsService, businessPartnersService, reportService, router, snackBar, dialog, route, categoriesService, location, notificationService, segmentationService) {
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.businessPartnersService = businessPartnersService;
        this.reportService = reportService;
        this.router = router;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.categoriesService = categoriesService;
        this.location = location;
        this.notificationService = notificationService;
        this.segmentationService = segmentationService;
        this.id = '';
        this.contentInvalid = false;
        this.initalized = false;
        this.Editor = ClassicEditor;
        this.selectedCustomers = [];
        this.selectedSegmentation = [];
        this.customerControl = new FormControl();
        this.segmentationControl = new FormControl();
        this.checkListUsers = false;
        this.checkListSegmentation = false;
        this.segmentationInvalid = false;
        this.customerInvalid = false;
        this.forAll = false;
        this.isManual = false;
        this.redirectUrl = false;
        this.return = '';
        this.idCustomer = [];
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.params = { formGroupValue: [] };
        this.customers = [];
        this.segmentation = [];
    }
    NoticationFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.id = this.route.snapshot.paramMap.get('id');
        this.initPromise().catch(function (error) {
            console.log(error);
        });
        this.initForm();
        this.config = {
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                    'undo',
                    'redo',
                    '|',
                ],
            },
        };
    };
    NoticationFormComponent.prototype.initPromise = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.customerListPromise = this.reportService
                            .listCustomers()
                            .toPromise()
                            .then(function (response) {
                            response.map(function (item) {
                                _this.customers.push(item);
                            });
                        }, function (error) {
                            console.log(error);
                        });
                        this.segmentationListPromise = this.segmentationService.list().then(function (response) {
                            response.results.map(function (item) {
                                _this.segmentation.push(item);
                            });
                        }, function (error) {
                            console.log(error);
                        });
                        _a = this;
                        return [4 /*yield*/, this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }))];
                    case 1:
                        _a.filteredCustomers = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.segmentationControl.valueChanges.pipe(startWith(''), map(function (segmentation) {
                                return segmentation ? _this._filterSegmentation(segmentation) : _this.segmentation.slice(0, 20);
                            }))];
                    case 2:
                        _b.filteredSegmentation = _c.sent();
                        if (!this.id) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getById()];
                    case 3:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.listTypesPromise = this.notificationService.listTypes('?type=MANUAL').then(function (response) {
                            _this.listTypes = response;
                        }, function (error) {
                            console.log(error);
                        });
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    NoticationFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            title: [null, [Validators.required]],
            description: [null, [Validators.required]],
            imageUrl: [null, []],
            content: [null, [Validators.required]],
            sendPush: [null, []],
            scheduledPush: [null, []],
            scheduledDate: [null, [Validators.required]],
            scheduledTime: [null, [Validators.required]],
            sendForAllCustomers: [null, []],
            sendCustomers: [null, []],
            customer: [null, [Validators.required]],
            name: [null, [Validators.required]],
            customersInput: [null, [Validators.required]],
            segmentationsInput: [null, []],
            useSegmentation: [null, []],
            options: [null, [Validators.required]],
            type: [null, [Validators.required]],
        });
    };
    NoticationFormComponent.prototype.getTime = function (datetime) {
        return new Date(datetime).getHours().toString() + ":" + (new Date(datetime).getMinutes() < 10 ? '0' : '') + new Date(datetime).getMinutes().toString();
    };
    NoticationFormComponent.prototype.getById = function () {
        var _this = this;
        this.notificationService.findOne(this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var date, time, filter, filterSegmentations, filterCustomer, array;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (response.scheduledPush && response.scheduledDateTime) {
                            date = response.scheduledDateTime.split('T')[0];
                            time = this.getTime(response.scheduledDateTime);
                            this.formGroup.patchValue({
                                title: response.title,
                                description: response.description,
                                imageUrl: response.image,
                                content: response.content,
                                sendPush: response.sendPush,
                                scheduledPush: response.scheduledPush,
                                scheduledDate: date,
                                scheduledTime: time,
                                useSegmentation: response.segmentations,
                                sendForAllCustomers: response.forAll,
                                type: response.eventsTypeSegmentationId,
                            });
                        }
                        else {
                            this.formGroup.patchValue({
                                title: response.title,
                                description: response.description,
                                imageUrl: response.image,
                                content: response.content,
                                sendPush: response.sendPush,
                                scheduledPush: response.scheduledPush,
                                useSegmentation: response.segmentations,
                                sendForAllCustomers: response.forAll,
                                type: response.eventsTypeSegmentationId,
                            });
                        }
                        this.forAll = !!response.forAll;
                        this.imageUrl = response.image;
                        this.isManual = response.type === 'AUTOMATIC';
                        filter = response.type === 'AUTOMATIC' ? '?type=AUTOMATIC' : '?type=MANUAL';
                        this.listTypesPromise = this.notificationService.listTypes(filter).then(function (response) {
                            _this.listTypes = response;
                        }, function (error) {
                            console.log(error);
                        });
                        if (response.type === 'AUTOMATIC' || response.redirectUrl) {
                            this.formGroup.get('sendPush').disable();
                            this.formGroup.get('customer').disable();
                            this.formGroup.get('sendForAllCustomers').disable();
                            this.formGroup.get('title').disable();
                            this.formGroup.get('description').disable();
                            this.formGroup.get('content').disable();
                            this.formGroup.get('customersInput').disable();
                            this.formGroup.get('useSegmentation').disable();
                            this.formGroup.get('segmentationsInput').disable();
                            this.formGroup.get('options').disable();
                            this.formGroup.get('type').disable();
                            this.formGroup.get('scheduledPush').disable();
                        }
                        if (!response.forAll) return [3 /*break*/, 1];
                        this.formGroup.patchValue({
                            options: 'checkAllCustomers',
                        });
                        return [3 /*break*/, 6];
                    case 1:
                        if (!response.segmentations) return [3 /*break*/, 4];
                        this.checkListSegmentation = true;
                        this.formGroup.patchValue({
                            options: 'checkSegmentation',
                        });
                        if (!response.segmentations) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.segmentationListPromise];
                    case 2:
                        _a.sent();
                        filterSegmentations = this.segmentation.filter(function (item) {
                            return response.segmentations.includes(item.id);
                        });
                        filterSegmentations.map(function (item) {
                            _this.selectedSegmentation.push(item);
                        });
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        this.checkListUsers = true;
                        this.checkListSegmentation = false;
                        this.formGroup.patchValue({
                            options: 'checkCustomers',
                        });
                        if (!response.customers) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.customerListPromise];
                    case 5:
                        _a.sent();
                        filterCustomer = this.customers.filter(function (item) {
                            return response.customers.includes(item.customerId);
                        });
                        filterCustomer.map(function (item) {
                            _this.selectedCustomers.push(item);
                        });
                        _a.label = 6;
                    case 6:
                        this.redirectUrl = !!response.redirectUrl;
                        if (response.redirectUrl) {
                            array = response.redirectUrl.split('/').filter(function (item) { return item; });
                            if (array[1] === 'quizzes') {
                                this.nameRedirectUrl = 'Questionário';
                                this.routeRedirectUrl = "/dashboard/quiz/" + array[2] + "/questions/list";
                            }
                            if (array[1] === 'transaction') {
                                this.nameRedirectUrl = 'Transação';
                                this.routeRedirectUrl = "/dashboard/transactions/detail/" + array[2];
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    NoticationFormComponent.prototype.checkAllCustomers = function (event) {
        this.forAll = true;
        this.segmentationInvalid = false;
        this.customerInvalid = false;
        this.checkListUsers = false;
        this.checkListSegmentation = false;
        this.selectedCustomers = [];
        this.selectedSegmentation = [];
        this.formGroup.patchValue({
            useSegmentation: false,
            sendCustomers: false,
        });
    };
    NoticationFormComponent.prototype.checkCustomers = function (event) {
        this.forAll = false;
        this.segmentationInvalid = false;
        this.checkListUsers = true;
        this.checkListSegmentation = false;
        this.selectedSegmentation = [];
        this.formGroup.patchValue({
            useSegmentation: false,
        });
    };
    NoticationFormComponent.prototype.checkSegmentation = function (event) {
        this.forAll = false;
        this.customerInvalid = false;
        this.checkListSegmentation = true;
        this.checkListUsers = false;
        this.selectedCustomers = [];
        this.formGroup.patchValue({
            sendCustomers: false,
        });
    };
    NoticationFormComponent.prototype.selectFile = function (event) {
        var _this = this;
        var file = event.target.files[0];
        this.uploadData = file;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
            _this.imageUrl = e.target.result;
        };
    };
    NoticationFormComponent.prototype.removeImage = function () {
        this.imageUrl = undefined;
        this.uploadData = null;
        this.approveDoc = undefined;
    };
    NoticationFormComponent.prototype.imgLoadError = function () {
        this.imageUrl = undefined;
    };
    NoticationFormComponent.prototype.onChange = function (_a) {
        var editor = _a.editor;
        if (!this.initalized) {
            return;
        }
        this.contentInvalid = !this.formGroup.value.content;
    };
    NoticationFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    NoticationFormComponent.prototype.submit = function () {
        var _this = this;
        var imageUrl = this.imageUrl;
        var data;
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, readUrl, uploadUrl, scheduledDate;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.contentInvalid = !this.formGroup.value.content;
                        this.initalized = !this.formGroup.value.content;
                        !this.selectedCustomers.length && this.checkListUsers
                            ? (this.customerInvalid = true)
                            : (this.customerInvalid = false);
                        !this.selectedSegmentation.length && this.checkListSegmentation
                            ? (this.segmentationInvalid = true)
                            : (this.segmentationInvalid = false);
                        if (!this.formGroup.value.title ||
                            !this.formGroup.value.description ||
                            this.contentInvalid ||
                            this.segmentationInvalid ||
                            this.customerInvalid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (this.formGroup.value.scheduledPush) {
                            if (!this.formGroup.value.scheduledDate || !this.formGroup.value.scheduledTime) {
                                this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                                return [2 /*return*/, false];
                            }
                        }
                        if (!this.uploadData) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.notificationService.getS3Url()];
                    case 1:
                        _a = _b.sent(), readUrl = _a.readUrl, uploadUrl = _a.uploadUrl;
                        imageUrl = readUrl;
                        return [4 /*yield*/, this.notificationService.uploadToS3(uploadUrl, this.uploadData)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        scheduledDate = null;
                        if (this.formGroup.value.scheduledPush) {
                            scheduledDate = this.formGroup.value.scheduledDate + " " + this.formGroup.value.scheduledTime + ":00";
                        }
                        data = {
                            title: this.formGroup.value.title,
                            description: this.formGroup.value.description,
                            image: imageUrl,
                            content: this.formGroup.value.content,
                            sendPush: !!this.formGroup.value.sendPush,
                            scheduledPush: !!this.formGroup.value.scheduledPush,
                            scheduledDateTime: scheduledDate,
                            customers: !this.forAll && !this.selectedCustomers.length
                                ? undefined
                                : JSON.stringify(this.forAll ? [] : this.selectedCustomers.map(function (c) { return c.customerId; })),
                            segmentations: JSON.stringify(this.selectedSegmentation.map(function (c) { return c.id; })),
                            forAll: this.forAll,
                            eventsTypeSegmentationId: this.formGroup.value.type,
                        };
                        if (this.id) {
                            this.notificationService.update(data, this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Notificação alterada com sucesso.');
                                    this.router.navigate(['./dashboard/notifications']);
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        else {
                            this.notificationService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Notificação cadastrada com sucesso.');
                                    this.router.navigate(['./dashboard/notifications']);
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); })().catch(function (error) { return _this.snackBar.open(error.error.message); });
    };
    NoticationFormComponent.prototype._filter = function (customer) {
        var _this = this;
        var remove = String(customer).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.customers
            .filter(function (c) { return !_this.selectedCustomers.includes(c); })
            .filter(function (c) {
            return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove.toLowerCase());
        })
            .slice(0, 20);
    };
    NoticationFormComponent.prototype._filterSegmentation = function (segmentation) {
        var _this = this;
        var remove = String(segmentation).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.segmentation
            .filter(function (c) { return !_this.selectedSegmentation.includes(c); })
            .filter(function (c) { return ("" + String(c.name)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    NoticationFormComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    NoticationFormComponent.prototype.removeSegmentation = function (segmentation) {
        var index = this.selectedSegmentation.indexOf(segmentation);
        if (index >= 0) {
            this.selectedSegmentation.splice(index, 1);
        }
    };
    NoticationFormComponent.prototype.selected = function (event) {
        if (event.option.value.id) {
            this.selectedSegmentation.push(event.option.value);
            this.segmentationInput.nativeElement.value = '';
            this.segmentationControl.setValue(null);
            this.segmentationInvalid = false;
        }
        else {
            this.selectedCustomers.push(event.option.value);
            this.customerInput.nativeElement.value = '';
            this.customerControl.setValue(null);
            this.customerInvalid = false;
        }
    };
    return NoticationFormComponent;
}());
export { NoticationFormComponent };
