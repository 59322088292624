import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { LoaderService } from '../services/loader.service'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private readonly requests: Array<HttpRequest<any>> = []

  constructor (private readonly router: Router, private readonly loaderService: LoaderService) {}

  removeRequest (req: HttpRequest<any>) {
    const i = this.requests.indexOf(req)
    if (i >= 0) {
      this.requests.splice(i, 1)
    }
    this.loaderService.isLoading.next(this.requests.length > 0)
  }

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // set loading
    if(!request.headers.has('noloading'))
    this.loaderService.isLoading.next(true)

    const token = localStorage.getItem('token')
    const url = new URL(request.url)    
    if (token && !`${url.origin+url.pathname}`.includes('s3.amazonaws.com')) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      })
    }

    const storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
    if (storeSelected) {
      request = request.clone({
        setHeaders: {
          storeId: storeSelected.storeId,
        },
      })
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
      })
    } else if (request.headers.get('Content-Type') == 'multipart/form-data') {
      request = request.clone({
        headers: request.headers.delete('Content-Type', 'multipart/form-data'),
      })
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
        .delete('noloading'),
    })    

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event)
          this.loaderService.isLoading.next(this.requests.length > 0)
        }
        return event
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.status) {
            this.router.navigate(['/'])
          } else {
            this.router.navigate(['/'])
          }
        }

        this.loaderService.isLoading.next(false)
        return throwError(error)
      }),
    )
  }
}
