import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PreRegistrationService = /** @class */ (function () {
    function PreRegistrationService(http) {
        this.http = http;
    }
    PreRegistrationService.prototype.list = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.create = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/pre-registration", body).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/pre-registration/" + id).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/pre-registration/" + id).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.update = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/pre-registration/" + id, body).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.listAddress = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration/" + id + "/address")
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.deleteAddress = function (addressId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/pre-registration/address/" + addressId)
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.createAddress = function (body, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/pre-registration/" + id + "/address", body)
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.updateAddress = function (addressId, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/pre-registration/address/" + addressId, body)
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.addressFindOne = function (addressId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration/address/" + addressId)
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.listHistoric = function (cpf) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration-stores/" + cpf)
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.findCpf = function (cpf) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration/find-cpf/" + cpf)
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.listArchitects = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/pre-registration-architects" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.findOneArchitect = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/pre-registration-architect/" + id).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.createArchitect = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/pre-registration-architect", body).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.updateArchitect = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/pre-registration-architect/" + id, body).toPromise()];
            });
        });
    };
    PreRegistrationService.prototype.deleteArchitect = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/pre-registration-architect/" + id).toPromise()];
            });
        });
    };
    PreRegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreRegistrationService_Factory() { return new PreRegistrationService(i0.ɵɵinject(i1.HttpClient)); }, token: PreRegistrationService, providedIn: "root" });
    return PreRegistrationService;
}());
export { PreRegistrationService };
