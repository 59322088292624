import { OnInit } from '@angular/core';
import { ModalHelpComponent } from '../modal-help/modal-help.component';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
var LoggedComponent = /** @class */ (function () {
    function LoggedComponent(router, dialog, permissionsService, loaderService) {
        this.router = router;
        this.dialog = dialog;
        this.permissionsService = permissionsService;
        this.loaderService = loaderService;
        // public items = ['FINANCEIRO', 'APLICATIVO', 'CLIENTES', 'MARKETING', 'LL LOYALTY', 'INCORPORAÇÃO']
        this.items = ['FINANCEIRO', 'APLICATIVO', 'CLIENTES', 'MARKETING', 'LL LOYALTY', 'INCORPORAÇÃO', 'AEROPORTO', 'ARQUITETOS'];
        this.itemsStorekeeper = ['AEROPORTO'];
        this.expandedIndex = 0;
        this.stores = [];
        this.userPermissions = [];
        this.showButtonsPreRegistration = false;
        this.showButtonsArchitect = false;
        this.showButtonsArchitectPreRegister = false;
        this.cmsPermissions = enumCmsPermissions;
        this.menuOpened = !!localStorage.getItem('menuOpened');
    }
    LoggedComponent.prototype.ngOnInit = function () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = localStorage.getItem('userRole');
        this.stores = JSON.parse(localStorage.getItem('storesUser'));
        this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
    };
    LoggedComponent.prototype.onChangeStore = function (store) {
        var _this = this;
        console.log(store);
        this.storeSelected = store;
        localStorage.setItem('storeSelected', JSON.stringify(store));
        this.loaderService.isLoading.next(true);
        setTimeout(function () {
            _this.router.navigate(['/dashboard/home']).then(function () {
                window.location.reload();
            });
        }, 1500);
    };
    LoggedComponent.prototype.teste = function () {
        this.router.navigate(['/pagamentos']);
    };
    LoggedComponent.prototype.toggleMenu = function () {
        this.menuOpened = !this.menuOpened;
        this.menuOpened
            ? localStorage.setItem('menuOpened', 'true')
            : localStorage.removeItem('menuOpened');
    };
    LoggedComponent.prototype.openDialogHelp = function () {
        var dialogRef = this.dialog.open(ModalHelpComponent, {
            width: '600px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    LoggedComponent.prototype.showButtons = function (e) {
        this.showButtonsPreRegistration = !this.showButtonsPreRegistration;
    };
    LoggedComponent.prototype.showArchitect = function (e) {
        this.showButtonsArchitect = !this.showButtonsArchitect;
    };
    LoggedComponent.prototype.showArchitectPreRegister = function (e) {
        this.showButtonsArchitectPreRegister = !this.showButtonsArchitectPreRegister;
    };
    return LoggedComponent;
}());
export { LoggedComponent };
