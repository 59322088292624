<app-logged class="store-form">
  <header class="store-form__header">
    <h2>CREDITAR PONTOS</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Cliente</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="cpf" mask="000.000.000-00" />
              <mat-error *ngIf="formGroup.controls.cpf?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cpf) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Quantidade de Pontos</mat-label>
              <input
                type="number"
                matInput
                formControlName="jcoins"
                (blur)="calcValuePoints($event)"
              />
              <mat-error *ngIf="formGroup.controls.jcoins?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.jcoins) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Parceiro</mat-label>

              <input
                type="text"
                placeholder="Parceiro"
                matInput
                formControlName="store"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayStore"
                (optionSelected)="calcValue($event)"
              >
                <mat-option *ngFor="let store of storeOptions | async" [value]="store">
                  {{ store.nomeFantasia }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="formGroup.controls.store?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.store) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Comentário (Limite máximo de 60 caracteres)</mat-label>
              <input matInput formControlName="commit" maxlength="60" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor equivalente em R$</mat-label>
              <input
                matInput
                formControlName="pointsConversionFactorOut"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorOut?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorOut) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
