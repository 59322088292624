<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">Cadastro de Empresa</h2>
    <h2 *ngIf="id">Editar Empresa</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-companies">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" required />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Nome da Empresa</mat-label>
              <input matInput formControlName="companyName" required />
              <mat-error *ngIf="formGroup.controls.companyName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.companyName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Nome do responsavel</mat-label>
              <input matInput formControlName="responsibleName" required />
              <mat-error *ngIf="formGroup.controls.responsibleName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.responsibleName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" required />
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CEP</mat-label>
              <input matInput formControlName="zipCode" (blur)="loadAddress()" mask="00000-000" />
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Rua</mat-label>
              <input matInput formControlName="street" />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Número</mat-label>
              <input matInput formControlName="addressNumber" />
              <mat-error *ngIf="formGroup.controls.addressNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.addressNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Complemento</mat-label>
              <input matInput formControlName="complement" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="neighborhood" />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Estado</mat-label>
              <input matInput formControlName="state" />
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field> -->
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.abbr">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
