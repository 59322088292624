import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalUserComponent } from 'src/app/components/modal-user/modal-user.component';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ModalResendCredentialsComponent } from '../../../../components/modal-resend-credentials/modal-resend-credentials.component';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var StoreUserListComponent = /** @class */ (function () {
    function StoreUserListComponent(storeService, router, route, dialog, snackBar, utilService, authService) {
        this.storeService = storeService;
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.utilService = utilService;
        this.authService = authService;
        this.headersTable = ['name', 'phoneNumber', 'emailAddress', 'actions'];
        this.headersTableDeleted = ['name', 'phoneNumber', 'emailAddress', 'deletedAt'];
        this.users = [];
        this.params = { storeFormGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    StoreUserListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.listUsers();
        this.initForm();
        this.getStore();
        this.listProfilesDeleted().catch(function (err) { return console.log(err); });
        this.userRole = localStorage.getItem('userRole');
    };
    StoreUserListComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameStoreUser',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'phoneStoreUser',
                label: 'Telefone',
                placeholder: 'Telefone',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'emailStoreUser',
                label: 'E-mail',
                placeholder: 'E-mail',
            },
        ];
    };
    StoreUserListComponent.prototype.getStore = function () {
        var _this = this;
        this.storeService
            .getStore(this.storeID)
            .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.nomeFantasia = response.nomeFantasia;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreUserListComponent.prototype.listUsers = function () {
        var _this = this;
        this.storeService
            .listUsersByStore("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"users_stores.store_id\"], \"op\": \"=\", \"value\":\"" + this.storeID + "\"}]}")
            .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                response.items.map(function (user) {
                    user.fullName = user.firstName + ' ' + user.lastName;
                });
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.items);
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreUserListComponent.prototype.listProfilesDeleted = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService
                            .listProfilesDeleted(this.storeID)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        results = (_a.sent()).results;
                        this.dataSourceDeleted = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreUserListComponent.prototype.openDialogNewUser = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalUserComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != 'cancel') {
                _this.storeService.getUserInfo("email=" + result).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.router.navigate(['./dashboard/users/' + response.userId + '/edit/stores/' + this.storeID]);
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.router.navigate(['./dashboard/users/new/' + result + '/stores/' + _this.storeID]);
                });
            }
        });
    };
    StoreUserListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.items);
    };
    StoreUserListComponent.prototype.openDialogResendCredentials = function (emailAddress) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                dialogRef = this.dialog.open(ModalResendCredentialsComponent, {
                    width: '550px',
                    data: { text: 'Deseja fazer o reenvio das credenciais do lojista?' },
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result > 0) {
                        _this.authService
                            .passwordRecover({ emailAddress: emailAddress })
                            .toPromise()
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.snackBar.open('Credenciais enviadas com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            alert(error.error.message);
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    StoreUserListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Usuário?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.authService.deleteProfile(id, this.storeID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listUsers();
                                this.listProfilesDeleted().catch(function (err) { return console.log(err); });
                                this.snackBar.open('Usuário removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return StoreUserListComponent;
}());
export { StoreUserListComponent };
