<app-logged class="store-list">
  <header class="store-list__header">
    <h2>LISTA DE NOTIFICAÇÃO</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/notifications/form">
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter actions="form" type="notification" [fieldsForm]="fieldsForm" [formGroupValue]="formGroup"
    (returnFilter)="reciverFeedback($event)"></app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.title }}" class="cellText">
          {{ element.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.description }}" class="cellText">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="150px">Hora</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'HH:mm' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Clientes" matTooltipPosition="left"
            routerLink="/dashboard/notifications/{{ element.id }}/customers" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>contact_page</mat-icon>
          </button>
          <button *ngIf="element.type == 'MANUAL' && !element.redirectUrl" mat-icon-button matTooltip="Editar"
            matTooltipPosition="left" routerLink="/dashboard/notifications/form/{{ element.id }}" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="element.type == 'AUTOMATIC' || element.redirectUrl" mat-icon-button matTooltip="Visualizar"
            matTooltipPosition="left" routerLink="/dashboard/notifications/form/{{ element.id }}" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="remove(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>

      <!-- Group header -->
      <ng-container matColumnDef="groupHeader">
        <td colspan="999" mat-cell *matCellDef="let row" class="groupCell">
          {{ row.group }}
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="groupRow"></tr>
    </table>
    <app-form-filter actions="pagination" type="notification" [length]="pageTotal" [formGroupValue]="formGroup"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  </div>
</app-logged>
