import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatSort, MatPaginator, MatTableDataSource, } from '@angular/material';
var NotificationListComponent = /** @class */ (function () {
    function NotificationListComponent(notificationService, snackBar, dialog, location, route) {
        this.notificationService = notificationService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.route = route;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    NotificationListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
                _this.initForm();
            }
            else {
                _this.initForm();
                _this.notificationList();
            }
        });
    };
    NotificationListComponent.prototype.initForm = function () {
        this.headersTable = ['title', 'description', 'date', 'actions'];
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'titleNotification',
                label: 'Título',
                placeholder: 'Título',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'descriptionNotification',
                label: 'Descrição',
                placeholder: 'Descrição',
            },
            {
                field: 'input',
                type: 'date',
                formControlName: 'dateNotification',
            },
            {
                field: 'select',
                formControlName: 'typeNotification',
                label: 'Tipo',
                list: [
                    {
                        key: 'MANUAL',
                        value: 'Manual',
                    },
                    {
                        key: 'AUTOMATIC',
                        value: 'Automático',
                    },
                ],
            },
        ];
    };
    NotificationListComponent.prototype.notificationList = function () {
        var _this = this;
        this.notificationService
            .list("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(this.groupNotification(response.results));
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    NotificationListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa Notificação?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.notificationService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.notificationList();
                                this.snackBar.open('Notificação removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    NotificationListComponent.prototype.groupNotification = function (data) {
        var e_1, _a;
        var groups = _(data)
            .groupBy(function (v) { return moment(v.createdAt).format('LL'); })
            .value();
        var result = [];
        for (var group in groups) {
            result.push({ group: group });
            try {
                for (var _b = tslib_1.__values(groups[group]), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    result.push(item);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return result;
    };
    NotificationListComponent.prototype.isGroup = function (index, item) {
        return !!item.group;
    };
    NotificationListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(this.groupNotification(returnFilter.results));
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return NotificationListComponent;
}());
export { NotificationListComponent };
