import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { Params } from '@angular/router'
import { AirportsService } from '../../../../../services/airports.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'airport-company-list',
  templateUrl: './airport-company-list.component.html',
  styleUrls: ['./airport-company-list.component.scss'],
})
export class AirportCompanyListComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'name', 'isCpf', 'balance', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameAirportCompany',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjAirportCompany',
        label: 'CNPJ',
        placeholder: 'CNPJ',
      },
      {
        field: 'select',
        formControlName: 'isCpfAirportCompany',
        label: 'Tem CPF cadastrado',
        placeholder: 'Tem CPF cadastrado',
        list: [
          { key: 'true', value: 'Sim' },
          { key: 'false', value: 'Não' },
        ],
        optionDefault: 'TODOS',
      },
    ]
  }

  async list () {
    const { results, total } = await this.airportsService
      .listCompanyAirport()
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = total
    this.resultsAll = results
  }

  remove (id, preRegistration) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Empresa Aeroporto?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          if (preRegistration) {
            return this.airportsService.deletePreRegisterAirportCompany(id).then(
              async response => {
                this.list().catch(error => console.log(error))
                this.snackBar.open('Empresa Aeroporto removida com sucesso.')
              },
              error => {
                console.log(error)
                this.snackBar.open(error.error.message)
              },
            )
          } else {
            return this.airportsService.deleteAirportCompany(id).then(
              async response => {
                this.list().catch(error => console.log(error))
                this.snackBar.open('Empresa Aeroporto removida com sucesso.')
              },
              error => {
                console.log(error)
                this.snackBar.open(error.error.message)
              },
            )
          }
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
