import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async create (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/companies`, body)
      .toPromise()
  }

  async list (filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/companies${filter || ''}`)
      .toPromise()
  }

  async findOne (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/companies/${id}`)
      .toPromise()
  }

  async update (id, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/companies/${id}`, body)
      .toPromise()
  }

  async delete (id, filter?): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/companies/${id}`)
      .toPromise()
  }

  searchForCompaniesByNameOrCnpj(searchTerm): Promise<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/cms/companies/search?search=${searchTerm}`,
      {
        headers: new HttpHeaders({'noloading': 'true'})
      })
      .toPromise()
  }
  async listStoresBusinessUnits (filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/store-business-units${filter || ''}`)
      .toPromise()
  }

  async createStoresBusinessUnits (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/store-business-units`, body)
      .toPromise()
  }

  async createCampaignStoresBusinessUnits (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/campaign-store-business-units`, body)
      .toPromise()
  }

  async findOneStoreBusinessUnit (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/store-business-units/${id}`)
      .toPromise()
  }

  async findOneCampaignStoreBusinessUnit (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/campaign-store-business-units/${id}`)
      .toPromise()
  }

  async updateStoresBusinessUnits (id, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/store-business-units/${id}`, body)
      .toPromise()
  }

  async updateCampaignStoresBusinessUnits (id, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/campaign-store-business-units/${id}`, body)
      .toPromise()
  }

  async deleteStoresBusinessUnits (id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/store-business-units/${id}`)
      .toPromise()
  }

  async usersListStoresBusinessUnits (id: number, filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/users-store-business-unit/${id}${filter || ''}`)
      .toPromise()
  }

  async createStoreBusinessUnitUser (body): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/cms/store-business-unit-user-signup`, body)
      .toPromise()
  }

  async userStoresBusinessUnit (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/user-store-business-unit/${id}`)
      .toPromise()
  }

  async updateStoreBusinessUnitUser (id, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/users-store-business-unit/${id}`, body)
      .toPromise()
  }
}
