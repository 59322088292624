import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalConfirmationPointsComponent } from 'src/app/components/modal-confirmation-points/modal-confirmation-points.component';
import { map, startWith } from 'rxjs/operators';
var FundPointsFormComponent = /** @class */ (function () {
    function FundPointsFormComponent(errorsService, zipCodeService, formBuilder, storeService, authService, fundService, router, route, snackBar, dialog, location) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.fundService = fundService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.fundId = '';
        this.customerId = '';
        this.stores = [];
        this.storeName = '';
        this.store = [];
        this.params = { formGroupValue: [] };
        this.initForm();
    }
    FundPointsFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.fundId = this.route.snapshot.paramMap.get('fundId');
        this.loadInfosUser().catch(function (err) {
            console.log(err);
            _this.snackBar.open(err.error.message);
        });
    };
    FundPointsFormComponent.prototype.initForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            name: [{ value: null, disabled: true }, [Validators.required]],
            cnpj: [{ value: null, disabled: true }, [Validators.required]],
            jcoins: [
                null,
                [Validators.required, Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')],
            ],
            pointsConversionFactorOut: [{ value: null, disabled: true }, []],
            store: [null, [Validators.required]],
        });
        this.storeOptions = this.formGroup.controls.store.valueChanges.pipe(startWith(''), map(function (value) {
            return _this.stores.filter(function (store) {
                var newValue = typeof value === 'object' ? value.nomeFantasia : value;
                return (store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
                    store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
                    store.cnpj.toLowerCase().includes(newValue.toLowerCase()));
            });
        }));
        this.loadStores();
    };
    FundPointsFormComponent.prototype.loadInfosUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fund;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fundService.find(this.fundId)];
                    case 1:
                        fund = _a.sent();
                        this.customerId = fund.customerId;
                        this.formGroup.patchValue({
                            name: fund.name,
                            cnpj: fund.cnpj,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FundPointsFormComponent.prototype.loadStores = function () {
        var _this = this;
        this.storeService
            .listStores()
            .toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.stores = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    FundPointsFormComponent.prototype.calcValuePoints = function (event) {
        this.formGroup.patchValue({
            store: this.formGroup.value.store,
            jcoins: event.target.value,
            pointsConversionFactorOut: this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100),
        });
        this.pointsConversionFactorOut =
            this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100);
    };
    FundPointsFormComponent.prototype.displayStore = function (store) {
        return store ? "" + store.nomeFantasia : '';
    };
    FundPointsFormComponent.prototype.calcValue = function (event) {
        var _this = this;
        this.stores.forEach(function (store) {
            if (store.id === event.option.value.id) {
                _this.store.push(store);
                _this.storeName = store.nomeFantasia;
                _this.pointsConversionFactorOut =
                    _this.formGroup.value.jcoins * (_this.store[0].pointsConversionFactorOut / 100);
                _this.formGroup.patchValue({
                    store: _this.formGroup.value.store,
                    pointsConversionFactorOut: _this.formGroup.value.jcoins * (_this.store[0].pointsConversionFactorOut / 100),
                });
            }
        });
    };
    FundPointsFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (!this.formGroup.value.store || !_.isObject(this.formGroup.value.store)) {
                    this.snackBar.open('Preencha corretamente o campo Parceiro.');
                    return [2 /*return*/, false];
                }
                data = {
                    customerId: this.customerId,
                    storeId: this.formGroup.value.store.id,
                    jcoins: parseInt(this.formGroup.value.jcoins),
                };
                dialogRef = this.dialog.open(ModalConfirmationPointsComponent, {
                    width: '500px',
                    data: {
                        store: this.storeName,
                        points: this.formGroup.value.jcoins,
                        value: this.pointsConversionFactorOut,
                    },
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result && result !== 'cancel') {
                        _this.authService
                            .applyPoints(data)
                            .toPromise()
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.snackBar.open('Pontos creditados com sucesso.');
                                        return [4 /*yield*/, this.router.navigate(['./dashboard/funds'])];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    FundPointsFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    return FundPointsFormComponent;
}());
export { FundPointsFormComponent };
