import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { ModalConfirmComponent } from "src/app/components/modal-confirm/modal-confirm.component";
import { enumCmsPermissions } from "src/app/models/enumCmsPermissions";
var ArchitectInvoiceListAdminComponent = /** @class */ (function () {
    function ArchitectInvoiceListAdminComponent(architectInvoiceService, snackBar, permissionsService, dialog, location) {
        this.architectInvoiceService = architectInvoiceService;
        this.snackBar = snackBar;
        this.permissionsService = permissionsService;
        this.dialog = dialog;
        this.location = location;
        this.headersTable = ['storeCnpj', 'storeName', 'receiver', 'invoiceNumbber', 'amount', 'createdAt', 'approved', 'actions'];
        this.selectedInvoices = {};
        this.allSelected = false;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ArchitectInvoiceListAdminComponent.prototype.ngOnInit = function () {
        if (this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_approve))
            this.headersTable.unshift('bulkSelector');
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'select',
                type: 'text',
                formControlName: 'architectInvoiceState',
                label: 'Status',
                placeholder: 'Status',
                list: [
                    { key: 'APPROVED', value: 'PAGO' },
                    { key: 'CANCELED', value: 'CANCELADO' },
                    { key: 'PENDING', value: 'PEDIDO PENDENTE' },
                    { key: 'WAITING_VALIDATION', value: 'AGUARDANDO VALIDAÇÃO' },
                    { key: 'INVALIDATED', value: 'PEDIDO RECUSADO' },
                    { key: '', value: 'TODOS' },
                ],
                optionDefault: '',
            },
            {
                field: 'input',
                type: 'number',
                label: 'CNPJ do parceiro',
                formControlName: 'architectInvoiceStoreCnpj',
            },
            {
                field: 'input',
                type: 'text',
                label: 'Nome do parceiro',
                formControlName: 'architectInvoiceStoreName',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Nota Criada a partir de',
                formControlName: 'architectInvoiceStartDate',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Nota Criada até',
                formControlName: 'architectInvoiceEndDate',
            },
        ];
    };
    ArchitectInvoiceListAdminComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, invoices, TotalRegisters;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.architectInvoiceService
                            .list({ filter: "?&page=" + this.page + "&limit=" + this.pageSize })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { invoices: [], TotalRegisters: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), invoices = _a.invoices, TotalRegisters = _a.TotalRegisters;
                        this.dataSource = new MatTableDataSource(invoices);
                        this.pageTotal = TotalRegisters;
                        this.selectAllToggle(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceListAdminComponent.prototype.filterCallback = function (returnFilter) {
        this.returnedFilters = returnFilter.invoices;
        this.pageTotal = returnFilter.TotalRegisters;
        this.dataSource = new MatTableDataSource(returnFilter.invoices);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
        this.selectAllToggle(false);
    };
    ArchitectInvoiceListAdminComponent.prototype.cancelInvoice = function (invoiceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, res, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.dialog
                                .open(ModalConfirmComponent, {
                                width: '500px',
                                data: {
                                    text: 'Deseja Cancelar essa Nota Fiscal? Essa operação não pode ser desfeita.',
                                    buttonConfirmText: 'Cancelar',
                                },
                            })
                                .afterClosed()
                                .toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.architectInvoiceService.cancelInvoice(invoiceId)];
                    case 2:
                        res = _a.sent();
                        this.snackBar.open("Nota fiscal cancelada com sucesso");
                        this.list();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.snackBar.open("Não foi possível cancelar essa Nota fiscal");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceListAdminComponent.prototype.showCancelButton = function (invoiceData) {
        if (invoiceData.approved || invoiceData.canceled)
            return false;
        var hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_cancel);
        if (!hasPermission)
            return false;
        return true;
    };
    ArchitectInvoiceListAdminComponent.prototype.getInvoiceStatus = function (invoice) {
        return this.architectInvoiceService.getInvoiceStatus(invoice);
    };
    ArchitectInvoiceListAdminComponent.prototype.approveInvoices = function (invoiceIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var text, successText, result, res, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        text = invoiceIds.length > 1
                            ? 'Deseja Aprovar as notas fiscais selecionadas? Essa ação irá liberar os Jcoins para todos os respectivos clientes.'
                            : 'Deseja Aprovar essa Nota Fiscal? Essa ação irá liberar os Jcoins para os respectivos clientes.';
                        successText = invoiceIds.length > 1
                            ? 'Notas fiscais aprovadas com sucesso'
                            : 'Nota fiscal aprovada com sucesso';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.dialog
                                .open(ModalConfirmComponent, {
                                width: '500px',
                                data: {
                                    text: text,
                                    buttonConfirmText: 'Aprovar',
                                },
                            })
                                .afterClosed()
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.architectInvoiceService.approveInvoices(invoiceIds)];
                    case 3:
                        res = _a.sent();
                        this.snackBar.open(successText);
                        this.list();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        this.snackBar.open("Não foi possível aprovar essa(s) Nota(s) fiscal(is)");
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceListAdminComponent.prototype.canApprove = function (invoiceData) {
        if (invoiceData.approved || invoiceData.canceled || !invoiceData.validated)
            return false;
        var hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_approve);
        if (!hasPermission)
            return false;
        return true;
    };
    ArchitectInvoiceListAdminComponent.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectInvoiceListAdminComponent.prototype.selectAllToggle = function (isChecked) {
        var _this = this;
        this.selectedInvoices = {};
        this.dataSource.data.forEach(function (k) { return _this.selectedInvoices[k.id] = isChecked; });
        console.log('this.selectedInvoices', this.selectedInvoices);
    };
    ArchitectInvoiceListAdminComponent.prototype.onInvoiceSelect = function (isChecked, invoiceId) {
        this.selectedInvoices[invoiceId] = isChecked;
        if (isChecked == false)
            this.allSelected = false;
    };
    ArchitectInvoiceListAdminComponent.prototype.isAnySelected = function () {
        return Object.values(this.selectedInvoices).filter(function (a) { return a; }).length == 0;
    };
    ArchitectInvoiceListAdminComponent.prototype.bulkApproveInvoices = function () {
        var invoiceIds = Object.entries(this.selectedInvoices).filter(function (_a) {
            var _b = tslib_1.__read(_a, 2), k = _b[0], v = _b[1];
            return v;
        }).map(function (_a) {
            var _b = tslib_1.__read(_a, 2), k = _b[0], v = _b[1];
            return k;
        });
        this.approveInvoices(invoiceIds);
    };
    ArchitectInvoiceListAdminComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    return ArchitectInvoiceListAdminComponent;
}());
export { ArchitectInvoiceListAdminComponent };
