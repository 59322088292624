import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { AuthService } from '../../../../services/auth.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { ReportService } from '../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
import * as moment from 'moment'

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsListComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'nomeFantasia', 'cpf', 'name', 'cpfBeneficiary', 'nameBeneficiary', 'totalValue', 'totalPoints', 'tax', 'dateCreatedAt', 'dateCredit', 'isClient']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    // erro
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameIntegration',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfIntegration',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Inicio',
        formControlName: 'dateInitIntegration',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Fim',
        formControlName: 'dateEndIntegration',
      },
      {
        field: 'select',
        formControlName: 'percentageIntegration',
        label: '% Jcoins',
        list: [
          { key: '0.05', value: '% > 0.05' },
          { key: 'all', value: 'TODOS' },
        ],
      },
    ]
  }

  async list () {
    // teste
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)

    const { results, total } = await this.authService
      .clientsIncorporations(`?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${moment(firstDay).format('YYYY-MM-DD')}"}, {"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${moment(lastDay).format('YYYY-MM-DD')}"}]}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = total
    this.resultsAll = results
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse beneficiário',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.clientsBeneficiaryDelete(id).then(
            async response => {
              this.list().catch(err => { console.log(err) })
              this.snackBar.open('Beneficiário removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async report () {
    await this.reportService.incorporationsConsult().then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Consulta Base.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }
}
