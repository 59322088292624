import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { BusinessPartnersService } from '../../../../services/businessPartners.service'
import { CategoriesService } from '../../../../services/categories.service'
import { UploadAdapter } from '../../../../services/uploadAdapter.service'
import { Location } from '@angular/common'

import { LyDialog } from '@alyle/ui/dialog'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'

import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'

import { NgxImageCompressService } from 'ngx-image-compress'
import { base64ToBlob } from 'base64-blob'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatButtonModule,
  MatButtonToggleModule,
} from '@angular/material'
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable'
import * as moment from 'moment'
@Component({
  selector: 'app-business-partners-form',
  templateUrl: './business-partners-form.component.html',
  styleUrls: ['./business-partners-form.component.scss'],
})
export class BusinessPartnersFormComponent implements OnInit {
  public formGroup: FormGroup
  public config: any
  public Editor = ClassicEditor
  public textDescription = ''
  public categoryID = ''
  public id = ''
  public imageExt = ''
  public arrayBuffer: any
  public imageUrl
  public contentInvalid = false
  public imageInvalid = false
  public approveDoc: any
  public uploadData: any
  public addressInvalid = false
  public accountManager = false
  public interest = false
  private initalized = false
  private readonly adressInitalized = false
  categoriesJoin: any
  isCategoriesJoin: any

  originalImage: any
  imagePosition: any
  MaterialComponents = [MatButtonModule, MatButtonToggleModule]
  cropped?: any
  constructor (
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    public businessPartnersService: BusinessPartnersService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    public categoriesService: CategoriesService,
    public location: Location,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    private readonly http: HttpClient,
  ) { }

  ngOnInit () {
    this.categoryID = this.route.snapshot.paramMap.get('categoryID')
    this.id = this.route.snapshot.paramMap.get('id')
    this.initForm()
    if (this.id) {
      this.getById()
    }

    this.config = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'undo',
          'redo',
          '|',
        ],
      },
    }
    // image: {
    //   toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
    // },
  }

  onUploadImage (event: Event) {
    this.originalImage = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalImage = reader.result
    }
    try {
      reader.readAsDataURL(this.originalImage)
    } catch (e) {
      this.snackBar.open(e.message)
    }
    console.log(this.originalImage)
    this.cropped = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.cropped = result.img.dataURL
          this.imagePosition = result.config
          this.uploadData = await base64ToBlob(this.cropped)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      address: [null, []],
      addressName: [null, []],
      address1GoogleMaps: [null, []],
      address2: [null, []],
      addressName2: [null, []],
      address2GoogleMaps: [null, []],
      content: [null, [Validators.required]],
      accountManager: [null, []],
      isActive: [null, []],
      link: [null, []],
      isMain: [null, []],
      onlineSale: [null, []],
      physicalStore: [null, []],
      contact: [null, []],
      imageUrl: [null, [Validators.required]],
      categoryJoin: [null, []],
      beginDate: [null, []],
      endDate: [null, []],
      about: [null, []],
      userFacebook: [null, []],
      linkFacebook: [null, []],
      userTwitter: [null, []],
      linkTwitter: [null, []],
      userInstagram: [null, []],
      linkInstagram: [null, []],
      userYoutube: [null, []],
      linkYoutube: [null, []],
    })
  }

  getById () {
    this.businessPartnersService.findOne(this.categoryID, this.id).then(
      async response => {
        let contact = ['']
        if (response.showTwamButton) {
          contact = ['accountManager']
          this.accountManager = true
        }
        if (response.showInterestButton) {
          contact = ['interest']
          this.interest = true
        }

        this.formGroup.patchValue({
          name: response.name,
          address: response.address,
          addressName: response.addressName,
          address1GoogleMaps: response.address1GoogleMaps,
          address2: response.address2,
          addressName2: response.addressName2,
          address2GoogleMaps: response.address2GoogleMaps,
          imageUrl: response.image,
          content: response.content,
          isActive: response.isActive,
          link: response.link,
          isMain: response.isMain,
          onlineSale: response.onlineSale,
          physicalStore: response.physicalStore,
          contact: contact,
          categoryJoin: response.categoryJoinId,
          beginDate: response.beginDate ? moment(response.beginDate).format('YYYY-MM-DD') : null,
          endDate: response.endDate ? moment(response.endDate).format('YYYY-MM-DD') : null,
          about: response.about,
          userFacebook: response.userFacebook,
          linkFacebook: response.linkFacebook,
          userTwitter: response.userTwitter,
          linkTwitter: response.linkTwitter,
          userInstagram: response.userInstagram,
          linkInstagram: response.linkInstagram,
          userYoutube: response.userYoutube,
          linkYoutube: response.linkYoutube,
        })
        this.cropped = response.image
        this.imageUrl = response.image
        this.initalized = true
        if (response.beginDate || response.endDate) {
          this.formGroup.get('isActive').disable()
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  async editImage () {
    try {
      const img = this.originalImage
        ? this.originalImage
        : await this.getBase64ImageFromUrl(this.cropped)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: { img, config: this.imagePosition },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.cropped = result.img.dataURL
        this.imagePosition = result.config
        this.uploadData = await base64ToBlob(this.cropped)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  selectFile (event) {
    const file = event.target.files[0]
    this.uploadData = file
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result
      this.imageInvalid = !e.target.result
    }
  }

  removeImage () {
    this.imageUrl = null
    this.uploadData = null
    this.approveDoc = undefined
  }

  imgLoadError () {
    this.imageUrl = null
  }

  changeInput () {
    if (!this.adressInitalized) {
      return
    }

    !this.formGroup.value.address || !this.formGroup.value.link
      ? (this.addressInvalid = false)
      : (this.addressInvalid = true)
  }

  onChange ({ editor }: ChangeEvent) {
    if (!this.initalized) {
      return
    }
    this.contentInvalid = !this.formGroup.value.content
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  toggleChange (event) {
    const toggle = event.source
    if (toggle) {
      switch (toggle.value) {
        case 'accountManager':
          this.accountManager = true
          this.interest = false
          break
        case 'interest':
          this.interest = true
          this.accountManager = false
          break
        default:
          this.accountManager = false
          this.interest = false
          break
      }

      const group = toggle.buttonToggleGroup
      if (event.value.some(item => item === toggle.value)) {
        group.value = [toggle.value]
      }
    } else {
      this.accountManager = false
      this.interest = false
    }
  }

  alterStatus (e) {
    if (e.target.value) {
      this.formGroup.patchValue({
        isActive: false,
      })
      this.formGroup.get('isActive').disable()
    } else {
      this.formGroup.get('isActive').enable()
    }
  }

  submit () {
    (async () => {
      // this.imageInvalid = !this.cropped
      // this.contentInvalid = !this.formGroup.value.content
      // this.initalized = !this.formGroup.value.content
      // this.addressInvalid = !this.formGroup.value.address && !this.formGroup.value.link
      // this.adressInitalized = !this.formGroup.value.address && !this.formGroup.value.link
      // !this.formGroup.value.address && !this.formGroup.value.link
      //   ? (this.addressInvalid = true)
      //   : (this.addressInvalid = false)
      if (
        !this.formGroup.value.content
        // this.addressInvalid
      ) {
        this.snackBar.open('Preencha corretamente os campos e tente novamente.')
        return false
      }

      if (this.id) {
        let imageUrl = ''
        if (this.uploadData) {
          const { readUrl, uploadUrl } = await this.businessPartnersService.getS3Url(
            this.categoryID,
          )
          imageUrl = readUrl
          await this.businessPartnersService.uploadToS3(uploadUrl, this.uploadData)
        }
        const data = {
          name: this.formGroup.value.name,
          address: this.formGroup.value.address || '',
          addressName: this.formGroup.value.addressName || '',
          address1GoogleMaps: this.formGroup.value.address1GoogleMaps || '',
          address2: this.formGroup.value.address2 || '',
          addressName2: this.formGroup.value.addressName2 || '',
          address2GoogleMaps: this.formGroup.value.address2GoogleMaps || '',
          image: imageUrl,
          content: this.formGroup.value.content,
          showTwamButton: this.accountManager,
          isActive: !!this.formGroup.value.isActive,
          link: this.formGroup.value.link || '',
          isMain: !!this.formGroup.value.isMain,
          onlineSale: !!this.formGroup.value.onlineSale,
          physicalStore: !!this.formGroup.value.physicalStore,
          showInterestButton: this.interest,
          beginDate: this.formGroup.value.beginDate ? this.formGroup.value.beginDate : null,
          endDate: this.formGroup.value.endDate ? this.formGroup.value.endDate : null,
          about: this.formGroup.value.about || '',
          userFacebook: this.formGroup.value.userFacebook || '',
          linkFacebook: this.formGroup.value.linkFacebook || '',
          userTwitter: this.formGroup.value.userTwitter || '',
          linkTwitter: this.formGroup.value.linkTwitter || '',
          userInstagram: this.formGroup.value.userInstagram || '',
          linkInstagram: this.formGroup.value.linkInstagram || '',
          userYoutube: this.formGroup.value.userYoutube || '',
          linkYoutube: this.formGroup.value.linkYoutube || '',
        }

        this.businessPartnersService.update(this.categoryID, this.id, data).then(
          async response => {
            this.location.back()
            this.snackBar.open('Parceiro alterado com sucesso.')
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      } else {
        await this.businessPartnersService.getS3Url(this.categoryID).then(
          async reponse => {
            const { readUrl, uploadUrl } = reponse
            await this.businessPartnersService.uploadToS3(uploadUrl, this.uploadData)

            const data = {
              name: this.formGroup.value.name,
              address: this.formGroup.value.address || '',
              addressName: this.formGroup.value.addressName || '',
              address1GoogleMaps: this.formGroup.value.address1GoogleMaps || '',
              address2: this.formGroup.value.address2 || '',
              addressName2: this.formGroup.value.addressName2 || '',
              address2GoogleMaps: this.formGroup.value.address2GoogleMaps || '',
              image: readUrl,
              content: this.formGroup.value.content,
              showTwamButton: this.accountManager,
              isActive: !!this.formGroup.value.isActive,
              link: this.formGroup.value.link || '',
              isMain: !!this.formGroup.value.isMain,
              onlineSale: !!this.formGroup.value.onlineSale,
              physicalStore: !!this.formGroup.value.physicalStore,
              showInterestButton: this.interest,
              beginDate: this.formGroup.value.beginDate ? this.formGroup.value.beginDate : null,
              endDate: this.formGroup.value.endDate ? this.formGroup.value.endDate : null,
              about: this.formGroup.value.about || '',
              userFacebook: this.formGroup.value.userFacebook || '',
              linkFacebook: this.formGroup.value.linkFacebook || '',
              userTwitter: this.formGroup.value.userTwitter || '',
              linkTwitter: this.formGroup.value.linkTwitter || '',
              userInstagram: this.formGroup.value.userInstagram || '',
              linkInstagram: this.formGroup.value.linkInstagram || '',
              userYoutube: this.formGroup.value.userYoutube || '',
              linkYoutube: this.formGroup.value.linkYoutube || '',
            }

            this.businessPartnersService.create(this.categoryID, data).then(
              async response => {
                this.location.back()
                this.snackBar.open('Parceiro cadastrado com sucesso.')
              },
              error => {
                this.snackBar.open(error.message)
              },
            )
          })
      }
    })().catch(error => this.snackBar.open(error.error.message))
  }

  remove (id, event) {
    event.preventDefault()
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Parceiro?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.businessPartnersService.delete(this.categoryID, id).then(
            async response => {
              this.location.back()
              this.snackBar.open('Parceiro removido com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
